import React, { useState, useEffect, memo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactCardFlip from 'react-card-flip';

//import callPostApi from '../requests/PostRequests';
import IncidentManagementTable from '../IncidentManagementDashboard/IncidentManagementTable';
import DashboardLineChartWithCalendarInterval from '../CarbonComponents/DashboardLineChartWithCalendarInterval';
import { PayloadWithCalendarInterval } from '../PayloadWithCalendarInterval';
import DashboardHeatmapChart from '../CarbonComponents/DashboardHeatmapChart';
import DashboardSimpleBarChart from '../CarbonComponents/DashboardSimpleBarChart';
import DashboardStackedBarChart from '../CarbonComponents/DashboardStackedBarChart';

import IncidentPerformance from "./MockData/incidentPerformance.json";
import IncidentPerformanceHr from "./MockData/IncidentPerformanceHeatHr.json";
import IncidentPerformanceDay from "./MockData/incidentPerformanceHeatDay.json";
import OpenCloseInc from "./MockData/openCloseIncident.json";
import IncidentTopCat from "./MockData/TopincidentCategory.json";
import TicketAging from "./MockData/ticketAging.json";
import IncidentAssignment from "./MockData/incidentByAssignment.json";
import IncidentIssue from "./MockData/incidentByIssue.json";
import IncidentResolution from "./MockData/incidentByResolution.json";
import { preparePerformanceTrendData, prepareMonthvsDayData, prepareWeekdayVsOpenHoursData, prepareAgeingGraphData, prepareTableDataFromGraph, prepareIncidentList, generateMonthsArray, getDownloadableData } from './Utils';

const IncidentManagement = (props) => {
    const { t } = useTranslation();

    let filterSelections = {
        tenant: '',
        org: ''
    }
    const [performanceTrendLoading, setPerformanceTrendLoading] = useState(true);
    const [performanceTrendGraphData, setPerformanceTrendGraphData] = useState([]);
    const [performanceTrendTableData, setPerformanceTrendTableData] = useState([]);
    const [PerformanceTrendTableHeaders, setPerformanceTrendTableHeaders] = useState([]);
    const [performanceTrendeGraphView, setPerformanceTrendeGraphView] = useState(t('DigitalOperations.Graph'));
    const [currentIncidentDetailListForPerformanceTrend, setCurrentIncidentDetailListForPerformanceTrend] = useState([]);
    const [isPerformanceTrendTableFlipped, setIsPerformanceTrendTableFlipped] = useState(false);

    const [openVsCloseIncidentGraphData, setOpenVsCloseIncidentGraphData] = useState([]);
    const [openVsCloseIncidentTableData, setOpenVsCloseIncidentTableData] = useState([]);
    const [openVsCloseIncidentListLoading, setOpenVsCloseIncidentListLoading] = useState(true);
    const [openVsCloseGraphView, setOpenVsCloseGraphView] = useState(t('DigitalOperations.Graph'));
    const [isOpenVsCloseTableFlipped, setIsOpenVsCloseTableFlipped] = useState(false);
    const [currentIncidentDetailListForOpenVsClose, setCurrentIncidentDetailListForOpenVsClose] = useState([]);
    const [incidentDetailListForOpenVsClose, setIncidentDetailListForOpenVsClose] = useState([]);

    const [ticketAgingData, setTicketAgingData] = useState([]);
    const [ticketAgingGraphData, setTicketAgingGraphData] = useState([]);
    const [ticketAgingDataLoading, setTicketAgingDataLoading] = useState(false);
    const [isIncidentAgingTableFlipped, setIsIncidentAgingTableFlipped] = useState(false);
    const [currentIncidentDetailListForTicketAging, setCurrentIncidentDetailListForTicketAging] = useState([]);
    const [incidentAgingList, setIncidentAgingList] = useState([]);
    const [incidentAgingGraphView, setIncidentAgingGraphView] = useState(t('DigitalOperations.Graph'));

    const [assignmentWiseIncidentLoading, setAssignmentWiseIncidentLoading] = useState([]);
    const [assignmentWiseIncidentTableData, setAssignmentWiseIncidentTableData] = useState([]);
    const [incidentDetailListForAssignment, setIncidentDetailListForAssignment] = useState([]);
    const [assignmentWiseDownloadableData, setAssignmentWiseDownloadableData] = useState([]);
    const [currentIncidentDetailListForAssignment, setCurrentIncidentDetailListForAssignment] = useState([]);
    const [isAssignmentWiseTableFlipped, setIsAssignmentWiseTableFlipped] = useState(false);

    const [resolutionWiseIncidentLoading, setResolutionWiseIncidentLoading] = useState(false);
    const [resolutionWiseIncidentTableData, setResolutionWiseIncidentTableData] = useState([]);
    const [isResolutionWiseTableFlipped, setIsResolutionWiseTableFlipped] = useState(false);
    const [resolutionCategoryDownloadableData, setResolutionCategoryDownloadableData] = useState([]);
    const [currentIncidentDetailListForResolution, setCurrentIncidentDetailListForResolution] = useState([]);

    const [issueCategoryIncidentLoading, setIssueCategoryIncidentLoading] = useState([]);
    const [issueCategoryIncidentTableData, setIssueCategoryIncidentTableData] = useState([]);
    const [isIssueCategoryTableFlipped, setIsIssueCategoryTableFlipped] = useState(false);
    const [issueCategoryDownloadableData, setIssueCategoryDownloadableData] = useState([]);
    const [currentIncidentDetailListForIssue, setCurrentIncidentDetailListForIssue] = useState([]);

    const [top5IssueCategoryGraphData, setTop5IssueCategoryGraphData] = useState([]);
    const [top5IssueCategoryLoading, setTop5IssueCategoryLoading] = useState(false);
    const [isTop5IssueCategoryGraphFlipped, setIsTop5IssueCategoryGraphFlipped] = useState(false);
    const [currentIncidentDetailListForTop5IssueCategory, setCurrentIncidentDetailListForTop5IssueCategory] = useState([]);
    const [incidentDetailListForTop5IssueCategory, setIncidentDetailListForTop5IssueCategory] = useState([]);
    const [top5IssueCategoryGraphView, setTop5IssueCategoryGraphView] = useState(t('DigitalOperations.Graph'));
    const [top5IssueCategoryIncidentList, setTop5IssueCategoryIncidentList] = useState([]);

    const [rcaWiseIncidentLoading, setRcaWiseIncidentLoading] = useState(false);
    const [rcaWiseIncidentTableData, setRcaWiseIncidentTableData] = useState([]);
    const [incidentDetailListForRca, setIncidentDetailListForRca] = useState([]);
    const [currentIncidentDetailListForRca, setCurrentIncidentDetailListForRca] = useState([]);
    const [timeFilter, setTimeFilter] = useState(filterSelections);
    const [showMainView, setShowMainView] = useState(true);
    const [selectedValue, setSelectedValue] = useState({});
    const [monthvsDayLoading, setMonthvsDayLoading] = useState(true);
    const [monthvsDayGraphData, setMonthvsDayGraphData] = useState([]);
    const [weekdayvsOpenHourGraphLoading, setWeekdayvsOpenHourGraphLoading] = useState(true);
    const [weekdayvsOpenHourGraphData, setWeekdayvsOpenHourGraphData] = useState([]);
    const [selectedAgeingValue, setSelectedAgeingValue] = useState({});
    const [selectedOpenVsClosedTicketsValue, setSelectedOpenVsClosedTicketsValue] = useState({});

    const assignmentWiseTableHeaders = [
        {
            key: 'item',
            header: t('DigitalOperations.AssignmentGp')
        },
        {
            key: 'count',
            header: t('DigitalOperations.Count')
        },
        {
            key: 'percentage',
            header: t('DigitalOperations.PercentageSymbol')
        },
        {
            key: 'incidentList',
            header: t('DigitalOperations.IncidentList'),
            hidden: true
        }
    ];

    const issueCategoryTableHeaders = [
        {
            key: 'item',
            header: t('DigitalOperations.IssueCategory')
        },
        {
            key: 'count',
            header: t('DigitalOperations.Count')
        },
        {
            key: 'percentage',
            header: t('DigitalOperations.PercentageSymbol')
        },
        {
            key: 'incidentList',
            header: t('DigitalOperations.IncidentList'),
            hidden: true
        }
    ];

    const downloadableHeaders = [
        {
            key: 'item',
            header: t('DigitalOperations.IssueCategory')
        },
        {
            key: 'count',
            header: t('DigitalOperations.Count')
        },
        {
            key: 'percentage',
            header: t('DigitalOperations.PercentageSymbol')
        }
    ];

    const resolutionWiseTableHeaders = [
        {
            key: 'item',
            header: t('DigitalOperations.ResolutionCategory')
        },
        {
            key: 'count',
            header: t('DigitalOperations.Count')
        },
        {
            key: 'percentage',
            header: t('DigitalOperations.PercentageSymbol')
        },
        {
            key: 'incidentList',
            header: t('DigitalOperations.IncidentList'),
            hidden: true
        }
    ];

    const incidentTableHeaders = [
        {
            key: 'key',
            header: t('DigitalOperations.IncidentId')
        },
        {
            key: 'status',
            header: t('DigitalOperations.Status')
        },
        {
            key: 'priority',
            header: t('DigitalOperations.priority')
        },
        {
            key: 'category',
            header: t('DigitalOperations.Category')
        },
        {
            key: 'client',
            header: t('DigitalOperations.client'),
            hidden: true
        },
        {
            key: 'sysId',
            header: t('DigitalOperations.SysId'),
            hidden: true
        }
    ];

    const openVsCloseIncidentTableHeaders = [
        {
            key: 'item',
            header: t('DigitalOperations.Date')
        },
        {
            key: 'openCount',
            header: t('DigitalOperations.Open')
        },
        {
            key: 'closedCount',
            header: t('DigitalOperations.Closed')
        },
    ];

    const incidentAgingListTableHeaders = [
        {
            key: 'item',
            header: t('DigitalOperations.Aging')
        },
        {
            key: 'count',
            header: t('DigitalOperations.Count')
        }
    ];

    const top5IssueCategoryListTableHeaders = [
        {
            key: 'item',
            header: t('DigitalOperations.Category')
        },
        {
            key: 'count',
            header: t('DigitalOperations.Count')
        }
    ];


    useEffect(() => {
        setTimeFilter(filterSelections)
    }, [])

    useEffect(() => {
        getIncidentPerformanceTrend(filterSelections);
        getMonthVsDayTrend(filterSelections);
        getWeekdayVsOpenHoursData(filterSelections);
        getOpenVsCloseIncidentTrend(filterSelections);
        getStatusWiseIncidentsCount(filterSelections);
        getTicketAging(filterSelections);
        getIncidentsByAssignment(filterSelections);
        getIncidentsByIssueCategory(filterSelections);
        getIncidentsByResolutionCategory(filterSelections);
        getTop5IssueCategory(filterSelections);
        getIncidentsCategoryBySLA(filterSelections);
    }, [sessionStorage.getItem('localeLanguage')])



    const handleFlip = (setParam, param, key, tableType, currentHeaderKey, currentRow) => {
        // console.log("flip passed param", param, key, tableType, currentRow)
        if (key !== 'goBack') {
            setShowMainView(false);
            let filteredArray = [];
            switch (tableType) {
                case 'resolution':
                    filteredArray = (currentRow.cells).find(cell => cell.info.header === "incidentList");
                    setCurrentIncidentDetailListForResolution(filteredArray.value);
                    break;
                case 'assignment':
                    filteredArray = (currentRow.cells).find(cell => cell.info.header === "incidentList");
                    setCurrentIncidentDetailListForAssignment(filteredArray.value);
                    break;
                case 'issue':
                    filteredArray = (currentRow.cells).find(cell => cell.info.header === "incidentList");
                    setCurrentIncidentDetailListForIssue(filteredArray.value);
                    break;
                case 'rca':
                    filteredArray = incidentDetailListForRca.filter(item => item.key == key);
                    //remove if condition after rca api fix
                    if (filteredArray.length)
                        filteredArray = filteredArray[0].list.map((item, index) => ({ ...item, id: index + 1 }));
                    setCurrentIncidentDetailListForRca(filteredArray);
                    break;
                case 'openVsClose':
                    filteredArray = incidentDetailListForOpenVsClose.filter(item => item.item == key);
                    if (currentHeaderKey == 'openCount') {
                        filteredArray = filteredArray[0].openIncidentList;
                    } else {
                        filteredArray = filteredArray[0].closedIncidentList;
                    }
                    setCurrentIncidentDetailListForOpenVsClose(filteredArray);
                    break;
                case 'ticketAging':
                    filteredArray = ticketAgingData;
                    if (key == '0-7 days') {
                        filteredArray = ticketAgingData.timeInterval1Table;
                    } else if (key == '8-14 days') {
                        filteredArray = ticketAgingData.timeInterval2Table;
                    } else {
                        filteredArray = ticketAgingData.timeInterval3Table;
                    }
                    setCurrentIncidentDetailListForTicketAging(filteredArray);
                    break;
                case 'top5IssueCategory':
                    filteredArray = incidentDetailListForTop5IssueCategory.filter(item => item.category == key);
                    setCurrentIncidentDetailListForTop5IssueCategory(filteredArray);
                    break;
                case 'performanceTrend':
                    let formattedData = [];
                    filteredArray = performanceTrendTableData.filter(obj => obj.week == currentRow.cells[0].value);
                    filteredArray = filteredArray[0][key + 'Table'];
                    formattedData = prepareIncidentList(filteredArray, key);
                    setCurrentIncidentDetailListForPerformanceTrend(formattedData);
                    break
                default:
                    // code block
                    break;
            }
        } else {
            setShowMainView(true);
        }
        setParam(!param);
    };

    const switchView = (e, setGraphView) => {
        setGraphView(e.text);
    }

    const getIncidentPerformanceTrend = (timeFilterSelections, dashboardFilterSelectionsAutomation, dashboardFilterSelectionsSAP, dashboardFilterSelectionsSalesforce, workspace, id) => {
        let headers = [
            {
                key: 'week',
                header: t('DigitalOperations.TimePeriod')
            },
            {
                key: 'total',
                header: t('DigitalOperations.Total')
            }
        ];
        let formattedData = preparePerformanceTrendData(IncidentPerformance, headers);
        setPerformanceTrendTableHeaders(formattedData.headers);
        setPerformanceTrendGraphData(formattedData.graphData);
        setPerformanceTrendTableData(formattedData.tableData);
        setPerformanceTrendLoading(false);
        if (timeFilterSelections && workspace && (dashboardFilterSelectionsAutomation || dashboardFilterSelectionsSAP || dashboardFilterSelectionsSalesforce)) {
            let formattedData = [];
            setPerformanceTrendLoading(true);
            let bodyJSON = PayloadWithCalendarInterval(timeFilterSelections, dashboardFilterSelectionsAutomation, dashboardFilterSelectionsSAP, dashboardFilterSelectionsSalesforce, workspace, id);
            // callPostApi(Constants.GETINCIDENTPERFORMANCETREND, {
            //     'Content-Type': 'application/json'
            // }, bodyJSON)
            //     .then(resp => {
            //         formattedData = preparePerformanceTrendData(resp);
            //         setPerformanceTrendGraphData(formattedData.graphData);
            //         setPerformanceTrendTableData(formattedData.tableData);
            //         setPerformanceTrendLoading(false);
            //     }).catch((error) => {
            //         setPerformanceTrendLoading(false);
            //     });
        }
    }

    const getWeekdayVsOpenHoursData = (timeFilterSelections, dashboardFilterSelectionsAutomation, dashboardFilterSelectionsSAP, dashboardFilterSelectionsSalesforce, workspace, id) => {

        let formattedData = prepareWeekdayVsOpenHoursData(IncidentPerformanceHr);
        setWeekdayvsOpenHourGraphData(formattedData.graphData)
        setWeekdayvsOpenHourGraphLoading(false);
        if (timeFilterSelections && workspace && (dashboardFilterSelectionsAutomation || dashboardFilterSelectionsSAP || dashboardFilterSelectionsSalesforce)) {
            let formattedData = [];
            setWeekdayvsOpenHourGraphLoading(true);
            let bodyJSON = PayloadWithCalendarInterval(timeFilterSelections, dashboardFilterSelectionsAutomation, dashboardFilterSelectionsSAP, dashboardFilterSelectionsSalesforce, workspace, id);
            // callPostApi(Constants.GETINCIDENTPERFORMANCETRENDHEATMAP, {
            //     'Content-Type': 'application/json'
            // }, bodyJSON)
            //     .then(resp => {
            //         formattedData = prepareWeekdayVsOpenHoursData(resp);
            //         setWeekdayvsOpenHourGraphData(formattedData.graphData)
            //         setWeekdayvsOpenHourGraphLoading(false);
            //     }).catch((error) => {
            //         setWeekdayvsOpenHourGraphLoading(false);
            //     });
        }
    }

    const getMonthVsDayTrend = (timeFilterSelections, dashboardFilterSelectionsAutomation, dashboardFilterSelectionsSAP, dashboardFilterSelectionsSalesforce, workspace, id) => {
        let formattedData = prepareMonthvsDayData(IncidentPerformanceDay);
        setMonthvsDayGraphData(formattedData.graphData);
        setMonthvsDayLoading(false);
        if (timeFilterSelections && workspace && (dashboardFilterSelectionsAutomation || dashboardFilterSelectionsSAP || dashboardFilterSelectionsSalesforce)) {
            let formattedData = [];
            setMonthvsDayLoading(true);
            let bodyJSON = PayloadWithCalendarInterval(timeFilterSelections, dashboardFilterSelectionsAutomation, dashboardFilterSelectionsSAP, dashboardFilterSelectionsSalesforce, workspace, id);
            // callPostApi(Constants.GETINCIDENTPERFORMANCETRENDHEATMAP, {
            //     'Content-Type': 'application/json'
            // }, bodyJSON)
            //     .then(resp => {
            //         formattedData = prepareMonthvsDayData(resp);
            //         setMonthvsDayGraphData(formattedData.graphData);
            //         setMonthvsDayLoading(false);
            //     }).catch((error) => {
            //         setMonthvsDayLoading(false);
            //     });
        }
    }

    const getOpenVsCloseIncidentTrend = (timeFilterSelections, dashboardFilterSelectionsAutomation, dashboardFilterSelectionsSAP, dashboardFilterSelectionsSalesforce, workspace, id) => {
        setOpenVsCloseIncidentGraphData(OpenCloseInc.graphData);
        setOpenVsCloseIncidentTableData(OpenCloseInc.tableData);
        setIncidentDetailListForOpenVsClose(OpenCloseInc.tableData);
        setOpenVsCloseIncidentListLoading(false);
        if (timeFilterSelections && workspace && (dashboardFilterSelectionsAutomation || dashboardFilterSelectionsSAP || dashboardFilterSelectionsSalesforce)) {
            setOpenVsCloseIncidentListLoading(true);
            let bodyJSON = PayloadWithCalendarInterval(timeFilterSelections, dashboardFilterSelectionsAutomation, dashboardFilterSelectionsSAP, dashboardFilterSelectionsSalesforce, workspace, id);
            // callPostApi(Constants.GETOPENVSCLOSEINCIDENTTREND, {
            //     'Content-Type': 'application/json'
            // }, bodyJSON)
            //     .then(resp => {
            //         setOpenVsCloseIncidentGraphData(resp.graphData);
            //         setOpenVsCloseIncidentTableData(resp.tableData);
            //         setIncidentDetailListForOpenVsClose(resp.tableData);
            //         setOpenVsCloseIncidentListLoading(false);
            //     }).catch((error) => {
            //         setOpenVsCloseIncidentListLoading(false);
            //     });
        }
    };

    const getStatusWiseIncidentsCount = (timeFilterSelections, dashboardFilterSelectionsAutomation, dashboardFilterSelectionsSAP, dashboardFilterSelectionsSalesforce, workspace) => {
        if (timeFilterSelections && workspace && (dashboardFilterSelectionsAutomation || dashboardFilterSelectionsSAP || dashboardFilterSelectionsSalesforce)) {
            let formattedData = [];
            let bodyJSON = props.getPayload(timeFilterSelections, dashboardFilterSelectionsAutomation, dashboardFilterSelectionsSAP, dashboardFilterSelectionsSalesforce, workspace);
            // callPostApi(Constants.GETSTATUSWISEINCIDENTSCOUNT, {
            //     'Content-Type': 'application/json'
            // }, bodyJSON)
            //     .then(resp => {
            //     }).catch((error) => {
            //     });
        }
    };

    const getTop5IssueCategory = (timeFilterSelections, dashboardFilterSelectionsAutomation, dashboardFilterSelectionsSAP, dashboardFilterSelectionsSalesforce, workspace) => {
        let formattedData = prepareTableDataFromGraph(IncidentTopCat.graphData);
        setTop5IssueCategoryGraphData(IncidentTopCat.graphData.sort((a, b) => { return a.value - b.value }));
        setTop5IssueCategoryIncidentList(formattedData);
        setIncidentDetailListForTop5IssueCategory(IncidentTopCat.tableData);
        setTop5IssueCategoryLoading(false);
        if (timeFilterSelections && workspace && (dashboardFilterSelectionsAutomation || dashboardFilterSelectionsSAP || dashboardFilterSelectionsSalesforce)) {
            setTop5IssueCategoryLoading(true);
            let formattedData = [];
            let bodyJSON = props.getPayload(timeFilterSelections, dashboardFilterSelectionsAutomation, dashboardFilterSelectionsSAP, dashboardFilterSelectionsSalesforce, workspace);
            // callPostApi(Constants.GETTOP5INCIDENTSCATEGORY, {
            //     'Content-Type': 'application/json'
            // }, bodyJSON)
            //     .then(resp => {
            //         formattedData = prepareTableDataFromGraph(resp.graphData);
            //         setTop5IssueCategoryGraphData(resp.graphData.sort((a, b) => { return a.value - b.value }));
            //         setTop5IssueCategoryIncidentList(formattedData);
            //         setIncidentDetailListForTop5IssueCategory(resp.tableData);
            //         setTop5IssueCategoryLoading(false);
            //     }).catch((error) => {
            //         setTop5IssueCategoryLoading(false);
            //     });
        }
    };

    const getIncidentsCategoryBySLA = (timeFilterSelections, dashboardFilterSelectionsAutomation, dashboardFilterSelectionsSAP, dashboardFilterSelectionsSalesforce, workspace) => {
        if (timeFilterSelections && workspace && (dashboardFilterSelectionsAutomation || dashboardFilterSelectionsSAP || dashboardFilterSelectionsSalesforce)) {
            let formattedData = [];
            let bodyJSON = props.getPayload(timeFilterSelections, dashboardFilterSelectionsAutomation, dashboardFilterSelectionsSAP, dashboardFilterSelectionsSalesforce, workspace);
            // callPostApi(Constants.GETINCIDENTSCATEGORYBYSLA, {
            //     'Content-Type': 'application/json'
            // }, bodyJSON)
            //     .then(resp => {
            //     }).catch((error) => {
            //     });
        }
    };

    const getTicketAging = (timeFilterSelections, dashboardFilterSelectionsAutomation, dashboardFilterSelectionsSAP, dashboardFilterSelectionsSalesforce, workspace, id) => {
        setTicketAgingData(TicketAging);
        setTicketAgingGraphData(prepareAgeingGraphData(TicketAging.graphData));
        props.setAgingSummary(TicketAging.avgAging);
        let formattedData = prepareTableDataFromGraph(TicketAging.graphData);
        setIncidentAgingList(formattedData);
        setTicketAgingDataLoading(false);
        if (timeFilterSelections && workspace && (dashboardFilterSelectionsAutomation || dashboardFilterSelectionsSAP || dashboardFilterSelectionsSalesforce)) {
            setTicketAgingDataLoading(true);
            let formattedData = [];
            let bodyJSON = PayloadWithCalendarInterval(timeFilterSelections, dashboardFilterSelectionsAutomation, dashboardFilterSelectionsSAP, dashboardFilterSelectionsSalesforce, workspace, id);
            // callPostApi(Constants.GETTICKETAGING, {
            //     'Content-Type': 'application/json'
            // }, bodyJSON)
            //     .then(resp => {
            //         setTicketAgingData(resp);
            //         setTicketAgingGraphData(prepareAgeingGraphData(resp.graphData));
            //         props.setAgingSummary(resp.avgAging);
            //         formattedData = prepareTableDataFromGraph(resp.graphData);
            //         setIncidentAgingList(formattedData);
            //         setTicketAgingDataLoading(false);
            //     }).catch((error) => {
            //         setTicketAgingDataLoading(false);
            //     });
        }
    };

    const getIncidentsByAssignment = (timeFilterSelections, dashboardFilterSelectionsAutomation, dashboardFilterSelectionsSAP, dashboardFilterSelectionsSalesforce, workspace) => {
        setAssignmentWiseIncidentTableData(IncidentAssignment.assignmentList);
        let downloadableData = getDownloadableData(IncidentAssignment.assignmentList);
        setAssignmentWiseDownloadableData(downloadableData);
        setIncidentDetailListForAssignment(IncidentAssignment.incidentList);
        setAssignmentWiseIncidentLoading(false);
        if (timeFilterSelections && workspace && (dashboardFilterSelectionsAutomation || dashboardFilterSelectionsSAP || dashboardFilterSelectionsSalesforce)) {
            setAssignmentWiseIncidentLoading(true);
            let downloadableData = [];
            let bodyJSON = props.getPayload(timeFilterSelections, dashboardFilterSelectionsAutomation, dashboardFilterSelectionsSAP, dashboardFilterSelectionsSalesforce, workspace);
            // callPostApi(Constants.GETINCIDENTSBYASSIGNMENT, {
            //     'Content-Type': 'application/json'
            // }, bodyJSON)
            //     .then(resp => {
            //         setAssignmentWiseIncidentTableData(resp.assignmentList);
            //         downloadableData = getDownloadableData(resp.assignmentList);
            //         setAssignmentWiseDownloadableData(downloadableData);
            //         setIncidentDetailListForAssignment(resp.incidentList);
            //         setAssignmentWiseIncidentLoading(false);
            //     }).catch((error) => {
            //         setAssignmentWiseIncidentLoading(false);
            //     });
        }
    };

    const getIncidentsByIssueCategory = (timeFilterSelections, dashboardFilterSelectionsAutomation, dashboardFilterSelectionsSAP, dashboardFilterSelectionsSalesforce, workspace) => {
        setIssueCategoryIncidentTableData(IncidentIssue.issueList);
        let downloadableData = getDownloadableData(IncidentIssue.issueList);
        setIssueCategoryDownloadableData(downloadableData);
        setIssueCategoryIncidentLoading(false);
        if (timeFilterSelections && workspace && (dashboardFilterSelectionsAutomation || dashboardFilterSelectionsSAP || dashboardFilterSelectionsSalesforce)) {
            let downloadableData = [];
            setIssueCategoryIncidentLoading(true);
            let bodyJSON = props.getPayload(timeFilterSelections, dashboardFilterSelectionsAutomation, dashboardFilterSelectionsSAP, dashboardFilterSelectionsSalesforce, workspace);
            // callPostApi(Constants.GETINCIDENTSBYISSUECATEGORY, {
            //     'Content-Type': 'application/json'
            // }, bodyJSON)
            //     .then(resp => {
            //         setIssueCategoryIncidentTableData(resp.issueList);
            //         downloadableData = getDownloadableData(resp.issueList);
            //         setIssueCategoryDownloadableData(downloadableData);
            //         setIssueCategoryIncidentLoading(false);
            //     }).catch((error) => {
            //         setIssueCategoryIncidentLoading(false);
            //     });
        }
    };

    const getIncidentsByResolutionCategory = (timeFilterSelections, dashboardFilterSelectionsAutomation, dashboardFilterSelectionsSAP, dashboardFilterSelectionsSalesforce, workspace) => {
        setResolutionWiseIncidentTableData(IncidentResolution.resolutionList);
        let downloadableData = getDownloadableData(IncidentResolution.resolutionList);
        setResolutionCategoryDownloadableData(downloadableData);
        setResolutionWiseIncidentLoading(false);
        if (timeFilterSelections && workspace && (dashboardFilterSelectionsAutomation || dashboardFilterSelectionsSAP || dashboardFilterSelectionsSalesforce)) {
            setResolutionWiseIncidentLoading(true);
            let downloadableData = [];
            let bodyJSON = props.getPayload(timeFilterSelections, dashboardFilterSelectionsAutomation, dashboardFilterSelectionsSAP, dashboardFilterSelectionsSalesforce, workspace);
            // callPostApi(Constants.GETINCIDENTSBYRESOLUTIONCATEGORY, {
            //     'Content-Type': 'application/json'
            // }, bodyJSON)
            //     .then(resp => {
            //         setResolutionWiseIncidentTableData(resp.resolutionList);
            //         downloadableData = getDownloadableData(resp.resolutionList);
            //         setResolutionCategoryDownloadableData(downloadableData);
            //         setResolutionWiseIncidentLoading(false);
            //     }).catch((error) => {
            //         setResolutionWiseIncidentLoading(false);
            //     });
        }
    };

    const handleRefresh = (item, title) => {
        let id = item.id
        switch (title) {
            case t('DigitalOperations.PerformanceTrendTicketData'):
                setSelectedValue(item);
                getIncidentPerformanceTrend(props.timeFilterSelections, props.filterSelectionsAutomation, props.filterSelectionsSAP, props.filterSelectionsSalesforce, props.workspace, id);
                break;
            case t('DigitalOperations.AgeOfOpenTickets'):
                setSelectedAgeingValue(item);
                getTicketAging(props.timeFilterSelections, props.filterSelectionsAutomation, props.filterSelectionsSAP, props.filterSelectionsSalesforce, props.workspace, id);
                break;
            case t('DigitalOperations.OpenvsCloseIncidentTrend'):
                setSelectedOpenVsClosedTicketsValue(item);
                getOpenVsCloseIncidentTrend(props.timeFilterSelections, props.filterSelectionsAutomation, props.filterSelectionsSAP, props.filterSelectionsSalesforce, props.workspace, id);
                break;
            default:
                break;
        }
    }

    const colors = {
        "New": "#82CFFF", //cyan30 
        "On Hold": "#003A6D", //cyan80
        "Resolved": "#33B1FF", //cyan40
        "In Queue": "#BAE6FF", //cyan20
        "Assigned": "#08BDBA", //teal40
        "Rejected": "#3DDBD9", //$teal30
        "Closed": "#0072C3", //$cyan60 
        "Cancelled": "#007d79", //$teal60
        "対応中": "#005d5d", //$teal70
        "Work In Progress": "#009d9a", //$teal50
        "Pending": "#00539a", //$cyan70 
        "In Progress": "#9EF0F0", //$teal20 
        "Open": "#012749",//cyan90
        "null": "#061727" //$cyan100 
    };

    return (
        <div className="top-margin dashboard incident-management">
            <div className="dashboard-detail-container col-lg-12 no-gutters">
                <div className="dashboard-charts-container">
                    <section>
                        <div className="tile-view hide-hover">
                            <div className="report-container">
                                <DashboardHeatmapChart
                                    loading={monthvsDayLoading}
                                    data={monthvsDayGraphData}
                                    title={t('DigitalOperations.MonthVsDay')}
                                    bottomAxestitle={t('DigitalOperations.Day')}
                                    leftAxestitle={t('DigitalOperations.Month')}
                                    colorLegendTitle={t('DigitalOperations.TicketCount')}
                                    bottomDomain={[...Array(31).keys()].map(i => (i + 1).toString())}
                                    leftDomain={generateMonthsArray(monthvsDayGraphData, 6)}
                                    graphDescription={t('DigitalOperations.MonthlyOverviewHeatmapDesc')}
                                />
                            </div>
                            <div className="report-container">
                                <DashboardHeatmapChart
                                    loading={weekdayvsOpenHourGraphLoading}
                                    data={weekdayvsOpenHourGraphData}
                                    title={t('DigitalOperations.WeekdayVsOpenHour')}
                                    bottomAxestitle={t('DigitalOperations.Hour')}
                                    leftAxestitle={t('DigitalOperations.Day')}
                                    colorLegendTitle={t('DigitalOperations.TicketCount')}
                                    bottomDomain={Array.from(Array(24).keys(), i => i.toString())}
                                    graphDescription={t('DigitalOperations.DailyOverviewHeatmapDesc')}
                                    leftDomain={['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].reverse()}
                                />
                            </div>
                        </div>
                    </section>
                    <section className="border-bottom trend-graph-table">
                        {performanceTrendeGraphView === t('DigitalOperations.Graph') ?
                            <DashboardStackedBarChart
                                stackedBarChartData={performanceTrendGraphData}
                                title={t('DigitalOperations.PerformanceTrendTicketData')}
                                loading={performanceTrendLoading}
                                bottomTitle={t('DigitalOperations.TotalTickets')}
                                leftAxesTitle={t('DigitalOperations.Date')}
                                modalTitle=""
                                leftSwitcherName={t('DigitalOperations.Table')}
                                rightSwitcherName={t('DigitalOperations.Graph')}
                                showSwitcher={true}
                                switchView={(e) => switchView(e, setPerformanceTrendeGraphView)}
                                handleRefresh={handleRefresh}
                                selectedValue={selectedValue}
                                timeFilterSelections={props.timeFilterSelections}
                                leftStack={true}
                                colors={colors}
                                showTooltip={true}
                                graphDescription={t('DigitalOperations.TicketDataPerformanceDesc')}
                            />
                            :
                            <div>
                                <ReactCardFlip isFlipped={isPerformanceTrendTableFlipped} flipDirection="horizontal">
                                    <IncidentManagementTable
                                        loading={performanceTrendLoading}
                                        rows={performanceTrendTableData}
                                        headers={PerformanceTrendTableHeaders}
                                        showPagination={true}
                                        isSortable={true}
                                        isExpandable={false}
                                        tableType="performanceTrend"
                                        leftSwitcherName={t('DigitalOperations.Table')}
                                        rightSwitcherName={t('DigitalOperations.Graph')}
                                        showFlipClose={false}
                                        handleFlip={handleFlip}
                                        flipState={isPerformanceTrendTableFlipped}
                                        setFlipState={setIsPerformanceTrendTableFlipped}
                                        showSwitcher={true}
                                        title={t('DigitalOperations.PerformanceTrendTicketData')}
                                        switchView={(e) => switchView(e, setPerformanceTrendeGraphView)}
                                        handleRefresh={handleRefresh}
                                        selectedValue={selectedValue}
                                        timeFilterSelections={timeFilter}
                                        hideWeeklyMonthlyView={true}
                                        graphDescription={t('DigitalOperations.TicketDataPerformanceDesc')}
                                    />
                                    <IncidentManagementTable key={showMainView} loading={performanceTrendLoading} rows={currentIncidentDetailListForPerformanceTrend} headers={incidentTableHeaders}
                                        showPagination={true} isSortable={false} isExpandable={false} workspace={props.workspace}
                                        leftSwitcherName={t('DigitalOperations.Table')}
                                        rightSwitcherName={t('DigitalOperations.Graph')} showFlipClose={true}
                                        handleFlip={handleFlip}
                                        flipState={isPerformanceTrendTableFlipped} setFlipState={setIsPerformanceTrendTableFlipped}
                                        showSwitcher={true} title={t('DigitalOperations.PerformanceTrendTicketData')} switchView={(e) => switchView(e, setPerformanceTrendeGraphView)}
                                        handleRefresh={handleRefresh}
                                        selectedValue={selectedValue}
                                        timeFilterSelections={timeFilter}
                                        hideWeeklyMonthlyView={true}
                                        graphDescription={t('DigitalOperations.TicketDataPerformanceDesc')}
                                    />
                                </ReactCardFlip>
                            </div>
                        }
                    </section>
                    <section className="border-bottom">
                        <div className="tile-view">
                            <div className="report-container no-padding">
                                {openVsCloseGraphView === t('DigitalOperations.Graph') ?
                                    <DashboardLineChartWithCalendarInterval
                                        lineGraphData={openVsCloseIncidentGraphData}
                                        title={t('DigitalOperations.OpenvsCloseIncidentTrend')}
                                        loading={openVsCloseIncidentListLoading}
                                        graphTitle={t('DigitalOperations.TotalTickets')}
                                        graphBottomTitle={t('DigitalOperations.Time')} modalTitle=""
                                        leftSwitcherName={t('DigitalOperations.Table')}
                                        rightSwitcherName={t('DigitalOperations.Graph')}
                                        showSwitcher={true}
                                        handleRefresh={handleRefresh}
                                        selectedValue={selectedOpenVsClosedTicketsValue}
                                        timeFilterSelections={props.timeFilterSelections}
                                        switchView={(e) => switchView(e, setOpenVsCloseGraphView)}
                                        graphDescription={t('DigitalOperations.OpenvsCloseIncidentTrendDesc')} />
                                    :
                                    <div>
                                        <ReactCardFlip isFlipped={isOpenVsCloseTableFlipped} flipDirection="horizontal">
                                            <IncidentManagementTable
                                                loading={openVsCloseIncidentListLoading}
                                                rows={openVsCloseIncidentTableData}
                                                headers={openVsCloseIncidentTableHeaders}
                                                showPagination={true}
                                                isSortable={true}
                                                isExpandable={false}
                                                tableType="openVsClose"
                                                leftSwitcherName={t('DigitalOperations.Table')}
                                                rightSwitcherName={t('DigitalOperations.Graph')}
                                                showFlipClose={false}
                                                handleFlip={handleFlip}
                                                flipState={isOpenVsCloseTableFlipped}
                                                setFlipState={setIsOpenVsCloseTableFlipped}
                                                showSwitcher={true}
                                                title={t('DigitalOperations.OpenvsCloseIncidentTrend')}
                                                handleRefresh={handleRefresh}
                                                selectedValue={selectedOpenVsClosedTicketsValue}
                                                timeFilterSelections={timeFilter}
                                                hideWeeklyMonthlyView={false}
                                                switchView={(e) => switchView(e, setOpenVsCloseGraphView)} graphDescription={t('DigitalOperations.OpenvsCloseIncidentTrendDesc')} />

                                            <IncidentManagementTable
                                                key={showMainView}
                                                loading={openVsCloseIncidentListLoading}
                                                rows={currentIncidentDetailListForOpenVsClose}
                                                headers={incidentTableHeaders}
                                                showPagination={true}
                                                isSortable={false}
                                                isExpandable={false}
                                                leftSwitcherName={t('DigitalOperations.Table')}
                                                rightSwitcherName={t('DigitalOperations.Graph')}
                                                showFlipClose={true}
                                                handleFlip={handleFlip}
                                                flipState={isOpenVsCloseTableFlipped}
                                                setFlipState={setIsOpenVsCloseTableFlipped}
                                                showSwitcher={true}
                                                title={t('DigitalOperations.OpenvsCloseIncidentTrend')}
                                                handleRefresh={handleRefresh}
                                                selectedValue={selectedOpenVsClosedTicketsValue}
                                                timeFilterSelections={timeFilter}
                                                hideWeeklyMonthlyView={false}
                                                switchView={(e) => switchView(e, setOpenVsCloseGraphView)} graphDescription={t('DigitalOperations.OpenvsCloseIncidentTrendDesc')} />
                                        </ReactCardFlip>
                                    </div>
                                }
                            </div>
                            <div className="report-container no-padding">
                                {incidentAgingGraphView === t('DigitalOperations.Graph') ?
                                    <DashboardLineChartWithCalendarInterval
                                        lineGraphData={ticketAgingGraphData}
                                        title={t('DigitalOperations.AgeOfOpenTickets')}
                                        loading={ticketAgingDataLoading}
                                        handleFlip={handleFlip}
                                        tableType="ticketAging"
                                        leftSwitcherName={t('DigitalOperations.Table')}
                                        rightSwitcherName={t('DigitalOperations.Graph')}
                                        showSwitcher={true}
                                        graphTitle={t('DigitalOperations.TotalTickets')}
                                        graphBottomTitle={t('DigitalOperations.Time')}
                                        handleRefresh={handleRefresh}
                                        selectedValue={selectedAgeingValue}
                                        timeFilterSelections={props.timeFilterSelections}
                                        hideWeeklyMonthlyView={true}
                                        switchView={(e) => switchView(e, setIncidentAgingGraphView)}
                                        graphDescription={t('DigitalOperations.IncidentAgingDesc')}
                                    />
                                    :
                                    <div>
                                        <ReactCardFlip isFlipped={isIncidentAgingTableFlipped} flipDirection="horizontal">
                                            <IncidentManagementTable
                                                loading={ticketAgingDataLoading}
                                                rows={incidentAgingList}
                                                headers={incidentAgingListTableHeaders}
                                                showPagination={true}
                                                isSortable={true}
                                                isExpandable={false}
                                                tableType="ticketAging"
                                                leftSwitcherName={t('DigitalOperations.Table')}
                                                rightSwitcherName={t('DigitalOperations.Graph')}
                                                showFlipClose={false}
                                                handleFlip={handleFlip}
                                                flipState={isIncidentAgingTableFlipped}
                                                setFlipState={setIsIncidentAgingTableFlipped}
                                                showSwitcher={true}
                                                title={t('DigitalOperations.AgeOfOpenTickets')}
                                                hideWeeklyMonthlyView={true}
                                                switchView={(e) => switchView(e, setIncidentAgingGraphView)} graphDescription={t('DigitalOperations.IncidentAgingDesc')} />
                                            <IncidentManagementTable
                                                key={showMainView}
                                                loading={ticketAgingDataLoading}
                                                rows={currentIncidentDetailListForTicketAging}
                                                headers={incidentTableHeaders}
                                                showPagination={true}
                                                isSortable={false}
                                                isExpandable={false}
                                                workspace={props.workspace}
                                                showFlipClose={true}
                                                handleFlip={handleFlip}
                                                flipState={isIncidentAgingTableFlipped}
                                                setFlipState={setIsIncidentAgingTableFlipped}
                                                showSwitcher={true}
                                                title={t('DigitalOperations.AgeOfOpenTickets')}
                                                switchView={(e) => switchView(e, setIncidentAgingGraphView)}
                                                leftSwitcherName={t('DigitalOperations.Table')}
                                                rightSwitcherName={t('DigitalOperations.Graph')}
                                                hideWeeklyMonthlyView={true} graphDescription={t('DigitalOperations.IncidentAgingDesc')}
                                            />
                                        </ReactCardFlip>
                                    </div>}
                            </div>
                        </div>
                    </section>
                    <section className="border-bottom">
                        <div className="tile-view">
                            <div className="report-container">
                                <div className="bold-border-table">
                                    <ReactCardFlip isFlipped={isAssignmentWiseTableFlipped} flipDirection="horizontal">
                                        <IncidentManagementTable
                                            loading={assignmentWiseIncidentLoading}
                                            rows={assignmentWiseIncidentTableData}
                                            headers={assignmentWiseTableHeaders}
                                            showPagination={true}
                                            isSortable={true}
                                            isExpandable={false}
                                            handleFlip={handleFlip}
                                            flipState={isAssignmentWiseTableFlipped}
                                            setFlipState={setIsAssignmentWiseTableFlipped}
                                            tableType="assignment" showIndicator={true}
                                            title={t('DigitalOperations.AssignmentGroup')}
                                            workspace={props.workspace}
                                            graphDescription={t('DigitalOperations.AssignmentGroupDesc')}
                                            downloadableData={assignmentWiseDownloadableData}
                                            downloadableHeaders={downloadableHeaders}
                                        />
                                        <IncidentManagementTable
                                            key={showMainView}
                                            loading={assignmentWiseIncidentLoading}
                                            rows={currentIncidentDetailListForAssignment}
                                            headers={incidentTableHeaders}
                                            showPagination={true}
                                            isSortable={false}
                                            isExpandable={false}
                                            workspace={props.workspace}
                                            showIndicator={true}
                                            title={t('DigitalOperations.AssignmentGp')}
                                            showFlipClose={true}
                                            handleFlip={handleFlip}
                                            flipState={isAssignmentWiseTableFlipped}
                                            setFlipState={setIsAssignmentWiseTableFlipped}
                                            tableType="assignment"
                                            graphDescription={t('DigitalOperations.AssignmentGroupDesc')} />
                                    </ReactCardFlip>
                                </div>
                            </div>
                            <div className="report-container">
                                <div className="bold-border-table">
                                    <ReactCardFlip isFlipped={isIssueCategoryTableFlipped} flipDirection="horizontal">
                                        <IncidentManagementTable
                                            loading={issueCategoryIncidentLoading}
                                            rows={issueCategoryIncidentTableData}
                                            headers={issueCategoryTableHeaders}
                                            downloadableHeaders={downloadableHeaders}
                                            graphDescription={t('DigitalOperations.IssueCategoryDesc')}
                                            showPagination={true}
                                            isSortable={true}
                                            isExpandable={false}
                                            handleFlip={handleFlip} flipState={isIssueCategoryTableFlipped}
                                            setFlipState={setIsIssueCategoryTableFlipped}
                                            tableType="issue"
                                            showIndicator={true}
                                            title={t('DigitalOperations.IssueCategory')}
                                            workspace={props.workspace}
                                            downloadableData={issueCategoryDownloadableData} />
                                        <IncidentManagementTable
                                            key={showMainView}
                                            loading={issueCategoryIncidentLoading}
                                            rows={currentIncidentDetailListForIssue}
                                            headers={incidentTableHeaders}
                                            showPagination={true}
                                            isSortable={false}
                                            isExpandable={false}
                                            workspace={props.workspace}
                                            graphDescription={t('DigitalOperations.IssueCategoryDesc')}
                                            showIndicator={true}
                                            title={t('DigitalOperations.IssueCategory')}
                                            showFlipClose={true}
                                            handleFlip={handleFlip}
                                            flipState={isIssueCategoryTableFlipped}
                                            setFlipState={setIsIssueCategoryTableFlipped}
                                            tableType="issue" />
                                    </ReactCardFlip>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="border-bottom">
                        <div className="tile-view">
                            <div className="report-container">
                                <div className="bold-border-table">
                                    <ReactCardFlip isFlipped={isResolutionWiseTableFlipped} flipDirection="horizontal">
                                        <IncidentManagementTable
                                            loading={resolutionWiseIncidentLoading}
                                            rows={resolutionWiseIncidentTableData}
                                            headers={resolutionWiseTableHeaders}
                                            showPagination={true}
                                            isSortable={true}
                                            isExpandable={false}
                                            handleFlip={handleFlip}
                                            flipState={isResolutionWiseTableFlipped}
                                            setFlipState={setIsResolutionWiseTableFlipped}
                                            tableType="resolution"
                                            title={t('DigitalOperations.ResolutionCategory')}
                                            showIndicator={false}
                                            hideColoredCell={true}
                                            graphDescription={t('DigitalOperations.ResolutionCategoryDesc')}
                                            downloadableData={resolutionCategoryDownloadableData}
                                            downloadableHeaders={downloadableHeaders} />
                                        <IncidentManagementTable
                                            key={showMainView}
                                            loading={resolutionWiseIncidentLoading}
                                            rows={currentIncidentDetailListForResolution}
                                            headers={incidentTableHeaders}
                                            showPagination={true}
                                            isSortable={false}
                                            isExpandable={false}
                                            workspace={props.workspace}
                                            title={t('DigitalOperations.ResolutionCategory')}
                                            showIndicator={false}
                                            graphDescription={t('DigitalOperations.ResolutionCategoryDesc')}
                                            handleFlip={handleFlip}
                                            flipState={isResolutionWiseTableFlipped}
                                            setFlipState={setIsResolutionWiseTableFlipped}
                                            tableType="resolution"
                                            showFlipClose={true} />
                                    </ReactCardFlip>
                                </div>
                            </div>
                            <div className="report-container no-border">
                                {top5IssueCategoryGraphView === t('DigitalOperations.Graph') ?
                                    <DashboardSimpleBarChart
                                        simpleBarChartData={top5IssueCategoryGraphData}
                                        title={t('DigitalOperations.top5IssueCategory')}
                                        loading={top5IssueCategoryLoading}
                                        handleFlip={handleFlip}
                                        tableType="top5IssueCategory"
                                        leftSwitcherName={t('DigitalOperations.Table')}
                                        rightSwitcherName={t('DigitalOperations.Graph')}
                                        graphBottomTitle='count'
                                        leftAxesTitle='Top 5 Issues'
                                        showSwitcher={true}
                                        showIntervalDropdown={false}
                                        switchView={(e) => switchView(e, setTop5IssueCategoryGraphView)}
                                        showTooltip={true}
                                        graphDescription={t('DigitalOperations.Top5IssueCategoriesDesc')}
                                    />
                                    :
                                    <div>
                                        <ReactCardFlip
                                            isFlipped={isTop5IssueCategoryGraphFlipped} flipDirection="horizontal">
                                            <IncidentManagementTable
                                                loading={top5IssueCategoryLoading}
                                                rows={top5IssueCategoryIncidentList}
                                                headers={top5IssueCategoryListTableHeaders}
                                                showPagination={true}
                                                isSortable={true}
                                                isExpandable={false}
                                                graphDescription={t('DigitalOperations.Top5IssueCategoriesDesc')}
                                                tableType="top5IssueCategory"
                                                leftSwitcherName={t('DigitalOperations.Table')}
                                                rightSwitcherName={t('DigitalOperations.Graph')}
                                                showFlipClose={false}
                                                handleFlip={handleFlip}
                                                title={t('DigitalOperations.top5IssueCategory')}
                                                flipState={isTop5IssueCategoryGraphFlipped}
                                                setFlipState={setIsTop5IssueCategoryGraphFlipped} hideWeeklyMonthlyView={true}
                                                showSwitcher={true}
                                                switchView={(e) => switchView(e, setTop5IssueCategoryGraphView)} />
                                            <IncidentManagementTable
                                                key={showMainView}
                                                loading={top5IssueCategoryLoading}
                                                rows={currentIncidentDetailListForTop5IssueCategory}
                                                headers={incidentTableHeaders}
                                                showPagination={true}
                                                isSortable={false}
                                                isExpandable={false}
                                                showFlipClose={true}
                                                graphDescription={t('DigitalOperations.Top5IssueCategoriesDesc')}
                                                handleFlip={handleFlip}
                                                title={t('DigitalOperations.top5IssueCategory')}
                                                flipState={isTop5IssueCategoryGraphFlipped}
                                                setFlipState={setIsTop5IssueCategoryGraphFlipped}
                                                showSwitcher={true}
                                                leftSwitcherName={t('DigitalOperations.Table')}
                                                rightSwitcherName={t('DigitalOperations.Graph')}
                                                switchView={(e) => switchView(e, setTop5IssueCategoryGraphView)} hideWeeklyMonthlyView={true}
                                            />
                                        </ReactCardFlip>
                                    </div>}
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default memo(IncidentManagement);
