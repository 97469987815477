/** @format */

import { Information } from "@carbon/icons-react";
import { Breadcrumb, BreadcrumbItem, Button, Column, ProgressIndicator, ProgressStep, Row, Tooltip } from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import PassiveModal from "Carbon-Components/Modals/PassiveModal";
import DynamicForm from "Components/Common/DynamicForm";
import InlineLoading from "Components/Common/loaderModule/InlineLoader";
import { getTenantAPI, postTenantDetails } from "Services/RBACServerApi";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import FeatureSelection from "./FeatureSelection";
import addTenantStep1JSON from "./addTenantStep1.json";
import addTenantStep2JSON from "./addTenantStep2.json";
// import apaAdapterJSON from "./apaAdapter.json";

function CreateTenant(props) {
  const { keycloak } = useKeycloak();
  let timer = null;
  const [dupilicate, setDupilicate] = useState(false);
  const duplicateMessage = "Tenant Already Exists";

  const [isFormValidStep1, setFormValididtyStep1] = useState(false);
  const [formDataStep1, setFormDataStep1] = useState({});

  const [isFormValidStep2, setFormValididtyStep2] = useState(false);
  const [formDataStep2, setFormDataStep2] = useState({});

  const [isFormValidStep3, setFormValididtyStep3] = useState(false);
  const [formDataStep3, setFormDataStep3] = useState({});

  const [reload_required, setReloadRequired] = useState(false);
  const [isLoading, setIsLoading] = useState();
  const [checking, setChecking] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPublic, setPublic] = useState(true);

  const [step1CompleteStatus, setStep1CompleteStatus] = useState(false);
  const [step2CompleteStatus, setStep2CompleteStatus] = useState(false);
  const [selectedNodesArray, setSelectedNodesArray] = useState([]);
  const [selectedParentsArray, setSelectedParentsArray] = useState([]);
  const [loadStaticData, setLoadStaticData] = useState(false);

  const [currentStep, setCurrentStep] = useState(0); // Track current step

  useEffect(() => {
    //empty the addTenantStep1JSON
    let updatedJSON1 = addTenantStep1JSON.map((field) => ({
      ...field,
      inputValue: ""
    }));
    addTenantStep1JSON = JSON.parse(JSON.stringify(updatedJSON1));

    //empty the addTenantStep2JSON
    let updatedJSON2 = addTenantStep2JSON.map((field) => ({
      ...field,
      inputValue: ""
    }));
    addTenantStep2JSON = JSON.parse(JSON.stringify(updatedJSON2));
  }, []);

  const handleFormValidityStep1 = (isValid) => {
    setFormValididtyStep1(isValid);
  };
  const handleFormValidityStep2 = (isValid) => {
    setFormValididtyStep2(isValid);
  };
  const handleFormValidityStep3 = (isValid) => {
    setFormValididtyStep3(isValid);
  };

  const handleFormChangeStep1 = (label, value, id) => {
    if (label === "tenant") {
      clearTimeout(timer);
      setDupilicate(false);
      timer = setTimeout(() => {
        if (Boolean(value)) checkDupilicate(value);
      }, 500);
    }
    const targetId = label;
    const targetValue = value;
    const data = formDataStep1;
    data[targetId] = targetValue;
    setFormDataStep1(data);
  };

  const handleFormChangeStep2 = (label, value, id) => {
    const targetId = label;
    const targetValue = value;
    const data = formDataStep2;
    data[targetId] = targetValue;
    setFormDataStep2(data);
  };

  const handleFormChangeStep3 = (label, value, id) => {
    const targetId = label;
    const targetValue = value;
    const data = formDataStep3;
    data[targetId] = targetValue;
    setFormDataStep3(data);
  };

  const handleSelectedNodesArray = (array) => {
    setSelectedNodesArray(array);
  }

  const handleSelectedParentsArray = (array) => {
    let updatedArray = [...array];

    let itGovernanceChildren = ["development", "security", "operations", "it_value_stream_management"];
    const itGovernanceChildrenSet = new Set(itGovernanceChildren);
    const commonItems = updatedArray.filter(item => itGovernanceChildrenSet.has(item));

    if (commonItems.length > 0 && !updatedArray.includes("it_governance_reporting")) {
      updatedArray.push("it_governance_reporting");
    }

    setSelectedParentsArray(updatedArray);
  };

  const handleLoadStaticData = (bool) => {
    setLoadStaticData(bool);
  };

  const checkDupilicate = async (value) => {

    setChecking(true);
    const res = await getTenantAPI(
      keycloak,
      uuidv4(),
      value,
    );
    if (res?.status === 204) {
      setDupilicate(false);
    } else {
      setDupilicate(true);
    }
    setChecking(false);
  };

  const removeChildTags = (features) => {
    let updatedFeatures = [];
    features.forEach((feature) => {
      if (feature.includes("_child")) {
        updatedFeatures.push(feature.substring(0, feature.length - 6));
      } else {
        updatedFeatures.push(feature);
      }
    })
    return updatedFeatures;
  }

  const closeErrorPopup = () => {
    setErrorMessage("");
  };

  const getPrimaryButtonText = () => {
    if (step2CompleteStatus === false) { return "Next" }
    else {
      return "Create Tenant"
    }
  }

  const enhanceFormDataWithTooltips = (formData) => {
    return formData.map((field) => ({
      ...field,
      tooltip: field.tooltip ?
        ( // Pass tooltip as a separate prop
          <Tooltip label={field.tooltip}>
            <Information size={16} />
          </Tooltip>
        ) :
        null,
    }));
  };

  const getPrimaryButtonDisabled = () => {

    if (dupilicate || checking) {
      return true;
    } else if (currentStep === 0 && !isFormValidStep1) {
      return true;
    } else
      if (currentStep === 1 && !isFormValidStep2) {
        return true;
      } else
        if (currentStep === 2 && !isFormValidStep3) {
          return true;
        } else return false;
  }

  const populateForm = (formJSON, formData) => {
    let updatedFormDataStep = formJSON.map((field) => ({
      ...field,
      inputValue: formData[field.id]
    }));
    return updatedFormDataStep;
  }

  const handleProgressStepChange = (index) => {
    switch (index) {
      case 0:
        setStep1CompleteStatus(false);
        setStep2CompleteStatus(false);
        addTenantStep1JSON = JSON.parse(JSON.stringify(populateForm(addTenantStep1JSON, formDataStep1)));
        setCurrentStep(0);
        break;
      case 1:
        setStep1CompleteStatus(true);
        setStep2CompleteStatus(false);
        addTenantStep2JSON = JSON.parse(JSON.stringify(populateForm(addTenantStep2JSON, formDataStep2)));
        setCurrentStep(1);
        break;
      case 2:
        setStep1CompleteStatus(true);
        setStep2CompleteStatus(true);
        setCurrentStep(2);
        break;
    }
  }


  const onCreateTenant = async () => {
    if (currentStep === 0) {
      setStep1CompleteStatus(true);
      addTenantStep2JSON = JSON.parse(JSON.stringify(populateForm(addTenantStep2JSON, formDataStep2)));
      setCurrentStep(1);
      return;
    } else if (currentStep === 1) {
      setStep2CompleteStatus(true);

      setCurrentStep(2);
      return;
    }

    const combinedFeatures = [...new Set([...selectedNodesArray, ...selectedParentsArray])];

    handleFormChangeStep3("features", removeChildTags(combinedFeatures));

    const data = { ...formDataStep1, ...formDataStep2, ...formDataStep3 };
    data["loadStaticData"] = loadStaticData;

    setIsLoading(true);
    const response = await postTenantDetails(keycloak, uuidv4(), data);

    try {
      if (response?.data?.err) {
        throw new Error("msg");
      } else {
        setReloadRequired(true);
        setErrorMessage("Tenant creation successful");
      }
    } catch (error) {
      setReloadRequired(false);
      setErrorMessage("Failed to create Tenant");
    } finally {
      setIsLoading(false);
    }
  };

  const getProgressStepEnabled = (index) => {

    if (currentStep === 0) {
      //Step1
      switch (index) {
        case 0: return false;
        case 1: return getPrimaryButtonDisabled() === false;
        case 2: return step1CompleteStatus === true && step2CompleteStatus === true;
      }
    } else {
      if (currentStep === 1) {
        //Step2
        switch (index) {
          case 0: return true;
          case 1: return false;
          case 2: return getPrimaryButtonDisabled() === false;
        }
      } else if (currentStep === 2) {
        //Step3
        switch (index) {
          case 0: return true;
          case 1: return true;
          case 2: return false;
        }
      }
    }
  };

  return (
    <>
      <Row>
        <Column lg={16} md={8} sm={4}>
          <Breadcrumb noTrailingSlash>
            <BreadcrumbItem href="#/adminPanel" onClick={() => {
              props.createdTenant();
            }}>Tenant List</BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              Create Tenant
            </BreadcrumbItem>
          </Breadcrumb>
        </Column>
      </Row>
      <Row>
        <Column lg={16} md={8} sm={4}>
          <div style={{ marginTop: "1rem" }}>
            {errorMessage || isLoading ? (
              <PassiveModal
                canCloseDetailsPopup={() => {
                  closeErrorPopup();
                  props.createdTenant(reload_required);
                }}
              >
                <h4>{errorMessage}</h4>
                {isLoading && (
                  <InlineLoading status="active" description="Creating Tenant" />
                )}
              </PassiveModal>
            ) : (
              <>
                <ProgressIndicator
                  spaceEqually
                  // currentIndex={currentStep}
                  onChange={(index) => handleProgressStepChange(index)}
                >
                  <ProgressStep
                    current={currentStep === 0}
                    complete={step1CompleteStatus}
                    label="Tenant Information"
                    description="Step 1: Tenant Info"
                    secondaryLabel="Information related to tenant"
                    disabled={!getProgressStepEnabled(0)}
                  />
                  <ProgressStep
                    current={currentStep === 1}
                    complete={step2CompleteStatus}
                    label="Org, App, User Information"
                    description="Step 2: Feature Selection"
                    secondaryLabel="Add information related to Default Org, App, and User"
                    disabled={!getProgressStepEnabled(1)}
                  />
                  <ProgressStep
                    current={currentStep === 2}
                    label="Feature Selection"
                    description="Step 3: Feature Selection"
                    secondaryLabel="Select features to be enabled at UI"
                    disabled={!getProgressStepEnabled(2)}
                  />
                </ProgressIndicator>

                <div style={{ marginTop: "2rem" }}>
                  {
                    currentStep === 0 ?
                      <DynamicForm
                        formData={enhanceFormDataWithTooltips(JSON.parse(JSON.stringify(addTenantStep1JSON)))}
                        dupilicate={{ id: "tenant", exists: dupilicate }}
                        duplicateMessage={duplicateMessage}
                        getFormValidity={handleFormValidityStep1} // Triggers step change
                        handleFormChange={handleFormChangeStep1}
                      /> : ""
                  }
                  {
                    currentStep === 1 ?
                      <DynamicForm
                        formData={enhanceFormDataWithTooltips(JSON.parse(JSON.stringify(addTenantStep2JSON)))}
                        dupilicate={{ id: "name", exists: dupilicate }}
                        duplicateMessage={duplicateMessage}
                        getFormValidity={handleFormValidityStep2} // Triggers step change
                        handleFormChange={handleFormChangeStep2}
                      /> : ""
                  }
                  {
                    currentStep === 2 ?
                      <FeatureSelection
                        preSelectedNodesArray={selectedNodesArray}
                        preSelectedParentsArray={selectedParentsArray}
                        getFormValidity={handleFormValidityStep3}
                        setSelectedNodesArray={handleSelectedNodesArray}
                        setSelectedParentsArray={handleSelectedParentsArray}
                        setLoadStaticData={handleLoadStaticData}
                      /> : ""
                  }
                </div>
                <div style={{ marginTop: "1rem" }}>
                  <Button id="primary" size="lg" onClick={() => { onCreateTenant() }} disabled={getPrimaryButtonDisabled()}>{getPrimaryButtonText()}</Button>
                  <Button id="secondary" size="lg" kind="secondary" onClick={() => { props.createdTenant(reload_required) }}>Close</Button>
                </div>
              </>
            )}
          </div>
        </Column>
      </Row>
    </>
  );
}

export default CreateTenant;
