import React, { useState, useEffect } from 'react';
import {
    FormLabel,
    Button,
    FilterableMultiSelect,
    ToggleSmall,
    Dropdown
} from '@carbon/react';
import { useTranslation } from 'react-i18next';

import { getDOClientsApi, getDOFranchiseApi } from "./Services/ServerApi";
import './scss/Filters.scss'

const handleTranslationIds = (id) => {
    switch (id) {
        case 'close.menu': return 'Close menu';
        case 'open.menu': return 'Open menu';
        case 'clear.all': return 'Clear all';
        case 'clear.selection': return 'Clear selection';
        default: return ""
    }
}

export default function Filters(props) {
    const { t } = useTranslation();
    const [filterSelections, setFilterSelections] = useState(props?.filterSelections);
    const [disabledSelects, setDisabledSelects] = useState(props?.disabledSelects);
    const [showSelectedFranchise, setShowSelectedFranchise] = useState(false);
    const [clients, setClients] = useState(props?.filterData?.clients || []);
    const [franchise, setFranchise] = useState(props?.filterData.franchise || []);
    const [clientKey, setClientKey] = useState(true);
    const [clear, setClear] = useState(false);

    useEffect(() => {
        getDOFranchiseList();
    }, [])

    useEffect(() => {
        setFilterSelections(props?.filterSelections);
        props?.filterSelections?.franchise?.length > 0 && getDOClients(props?.filterSelections?.franchise);
        if (props?.filterSelections?.franchise?.length > 0) {
            setDisabledSelects({ 'client-select': false });
        }
    }, [props])

    useEffect(() => {
        console.log(filterSelections, 'filterSelections')
        console.log(clients, "clients");
        if (filterSelections?.franchise?.length === 0) {
            setFilterSelections({ ...filterSelections, 'client': [] })
        }

    }, [filterSelections]);

    useEffect(() => {
        if (showSelectedFranchise) {
            let selectedClientsList = [];
            selectedClientsList.push(clients.map((clientItem) => { return clientItem.name }));
            let selectedClients = filterSelections?.client?.filter(item => selectedClientsList[0].includes(item));
            setFilterSelections({ ...filterSelections, "client": selectedClients });
            setClientKey(!clientKey)

        }
    }, [clients])

    const getDOFranchiseList = async () => {
        const doFranchiseData = await getDOFranchiseApi();
        let franchiseData = doFranchiseData?.mergedData?.franchises?.buckets || [];
        let franchiseList = [];
        franchiseData.map((element, index) => {
            let franchise = {};
            franchise.id = index;
            franchise.name = element.key
            franchiseList.push(franchise);
        })
        setFranchise(franchiseList);
    }

    const getDOClients = async () => {
        const doClientsData = await getDOClientsApi();
        let clientData = doClientsData.data?.aggregations?.filtered?.clients?.buckets || [];
        let clientsList = [];
        clientData?.map((element, index) => {
            let client = {};
            client.id = index;
            client.name = element.key;
            clientsList.push(client);
            setClients(clientsList)
        })

        console.log(doClientsData, "clients data do");
    }

    const handleSelectionChange = (type, event) => {
        let previouslySelectedFilter = Object.assign({}, filterSelections);
        let franchiseArr = [], clientArr = [], isIncidentValue;
        if (type === 'franchise') {
            let franchiseLength = event?.selectedItems?.length || 0;
            let oldFranchiseLength = filterSelections?.franchise?.length || 0;
            if (oldFranchiseLength > franchiseLength) {
                setShowSelectedFranchise(true);
            }
            else setShowSelectedFranchise(false);
            var selectedValues = event.selectedItems.map(function (k, v) {
                return k.name;
            });
            getDOClients(selectedValues);
            if (selectedValues.length > 0) {
                setDisabledSelects({ 'client-select': false });
            } else {
                setDisabledSelects({ 'client-select': true });
            }

            if (franchiseLength) {
                for (let i = 0; i < event?.selectedItems?.length; i++) {
                    if (franchiseArr.indexOf(event.selectedItems[i].name) === -1) {
                        franchiseArr.push(event.selectedItems[i].name);
                    }
                    previouslySelectedFilter.franchise = franchiseArr;
                }
            } else {
                previouslySelectedFilter.franchise = [];
                previouslySelectedFilter.client = [];
            }
        }
        else if (type === 'client-select') {
            if (event?.selectedItems?.length) {
                for (let i = 0; i < event?.selectedItems?.length; i++) {
                    if (clientArr.indexOf(event?.selectedItems[i].name) === -1) {
                        clientArr.push(event?.selectedItems[i].name);
                    }
                    previouslySelectedFilter.client = clientArr;
                }
            } else {
                previouslySelectedFilter.client = [];
            }
        }
        else {
            if (Object.keys(event?.selectedItem).length) {
                if (!isIncidentValue) {
                    isIncidentValue = event?.selectedItem.name;
                }
                previouslySelectedFilter.isIncident = isIncidentValue;

            } else {
                previouslySelectedFilter.isIncident = '';
            }
        }
        setFilterSelections(Object.assign({}, previouslySelectedFilter));
    };


    const populateInitalSelectedItems = (items, filterName) => {
        let initialValues = [], uniq = [];
        if (filterName === 'franchise' || filterName === 'client') {
            if (clear && !Object.keys(props.filterSelections).length) {
                uniq = [];
            }
            else {
                console.log(items, filterName, "populate");
                console.log(props?.filterSelections, "aiopsfilters");
                if (Object.keys(props?.filterSelections).length > 0) {
                    for (const value in props?.filterSelections) {
                        console.log(value, "111");
                        if (props.filterSelections.hasOwnProperty(value) && value === filterName) {
                            items.map((filterItem) => {
                                if (props.filterSelections[value].includes(filterItem.name) && value.includes(filterName)) {
                                    let filterObj = {
                                        id: filterItem.id,
                                        name: filterItem.name
                                    }
                                    initialValues.push(filterObj);
                                }
                            });
                        }
                    }
                }
            }
            uniq = [...new Set(initialValues)];
            console.log(uniq, "uniq");
        }
        return uniq;
    }

    const clearAll = (e) => {
        console.log(e, "clear");
        setClear(true);
        setTimeout(() => {
            document.querySelectorAll('.cds--multi-select .cds--tag__close-icon').forEach(e => e.click());
            document.getElementsByClassName('cds--list-box__label')[0].style.visibility = 'hidden';
        }, 0)
        setFilterSelections({
            franchise: [],
            client: [],
            isIncident: ''
        });
    };


    const handleFilterChange = () => {
        props.updateState('alertFilterSelections', filterSelections);
        props.updateState('alertFilterData_clients', clients);
        props.updateState('disabledSelects', disabledSelects);
        props.updateState('alertFilterdata_franchise', franchise)
        props.handleClose();
    }

    const isIncidentValues = [
        {
            id: 0,
            name: 'Ticketed'
        },
        {
            id: 1,
            name: 'Non-Ticketed'
        }
    ]

    return (
        <div hidden={props.hidden} class="cds--grid cds--grid--full-width filter--page font-family">
            <div className='filter-grid-manage-ops'>
                <div class="cds--row">
                    <div class="cds--col-lg-12 cds--col-md-6">
                        <FormLabel className="filter--text">
                            {t('DigitalOperations.filterBy')}
                        </FormLabel>
                    </div>
                </div>
                <div className='alert-filters'>
                    <div class="multi-select-filter-alert">
                        <FilterableMultiSelect
                            id='select'
                            titleText={t('DigitalOperations.franchise')}
                            helperText=''
                            disabled={false}
                            label='Choose options'
                            items={franchise}
                            itemToString={(item) => (item ? item.name : '')}
                            initialSelectedItems={populateInitalSelectedItems(props?.filterData?.franchises, 'franchise')}
                            translateWithId={(id) => handleTranslationIds(id)}
                            selectionFeedback="top-after-reopen"
                            onChange={(e) => handleSelectionChange('franchise', e)}
                            placeholder='Choose options'
                        />
                    </div>
                    <div class="multi-select-filter-alert client-filter">
                        <FilterableMultiSelect
                            id='client-select'
                            titleText={t('DigitalOperations.client')}
                            key={clientKey}
                            disabled={disabledSelects["client-select"]}
                            label={t('DigitalOperations.chooseOptions')}
                            items={clients}
                            itemToString={(item) => (item ? item.name : '')}
                            initialSelectedItems={populateInitalSelectedItems(clients, 'client')}
                            translateWithId={(id) => handleTranslationIds(id)}
                            selectionFeedback="top-after-reopen"
                            onChange={(e) => handleSelectionChange('client-select', e)}
                            placeholder={t('DigitalOperations.chooseOptions')}
                        />
                    </div>
                    {/* <div class="multi-select-filter-alert">
                        <Dropdown
                            id='isIncident-select'
                            titleText={t('DigitalOperations.alertType')}
                            disabled={disabledSelects["isIncident-select"]}
                            label={t('DigitalOperations.chooseOptions')}
                            items={isIncidentValues}
                            itemToString={(item) => (item ? item.name : '')}
                            initialSelectedItem={props?.filterSelections?.isIncident ? { 'name': props?.filterSelections?.isIncident } : ''}
                            translateWithId={(id) => handleTranslationIds(id)}
                            selectionFeedback="top-after-reopen"
                            onChange={(e) => handleSelectionChange('isIncident-select', e)}
                            placeholder={t('DigitalOperations.chooseOptions')}
                        />
                    </div> */}
                </div>
            </div>
            <div class="bottom-button">
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                    <Button kind='ghost' onClick={clearAll}>
                        {t('DigitalOperations.clear')}
                    </Button>
                    <Button kind='ghost' onClick={handleFilterChange}>
                        {t('DigitalOperations.apply')}
                    </Button>
                </div>
            </div>

            {/* Filters */}
        </div>
    );
}