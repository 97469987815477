import React, { useState, useEffect } from 'react';
import {
    DataTable, TableContainer, Table, TableHead,
    TableRow, TableHeader, TableBody, TableCell, Pagination,
    TableExpandRow, TableExpandedRow, TableExpandHeader, Button, InlineLoading, ContentSwitcher, Switch, Tooltip
} from '@carbon/react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import DashboardTable from './DashboardTable';
import { SkipForwardFilled32, Information } from '@carbon/react/icons/index';
import TableToolbarDownload from './TableToolbarDownload';
import { getColors } from './ColorGradient';
import '../scss/_dashboard.scss';

const IncidentManagementTable = (props) => {
    console.log("rahhhh", props)
    const defaultFirstRowIndex = 0;
    const defaultCurrentPageSize = props.defaultCurrentPageSize || 5;
    const [paginatedRows, setPaginatedRows] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [firstRowIndex, setFirstRowIndex] = useState(defaultFirstRowIndex);
    const [currentPage, setCurrentPage] = useState(0);
    const [TotalPages, setTotalPages] = useState(0);
    const { t } = useTranslation();

    let options = [{
        style: 'constant',
        range: '0-4.99',
        color: '#0E6027'
    },
    {
        style: 'constant',
        range: '5-9.99',
        color: '#FDDC69'
    },
    {
        style: 'constant',
        range: '10-19.99',
        color: '#F1C21B'
    },
    {
        style: 'constant',
        range: '20-100',
        color: '#DA1E28'
    }];
    let gradientColorForTable = getColors(options);
    console.log(gradientColorForTable, 'gradient');
    useEffect(() => {
        if (props.rows && props.rows.length) {
            setTotalItems(props.rows.length);
            getPaginatedRows(defaultFirstRowIndex, defaultCurrentPageSize);
        }
    }, [props.rows])

    function getPaginatedRows(param, pageSize) {
        let startingIndex = Number.isInteger(param) ? param : firstRowIndex;
        let slicedRows = props.rows.slice(startingIndex, startingIndex + pageSize);
        if (props.isExpandedTable && props.isExpandedTable === true) {
            setPaginatedRows(props.rows);
        }
        else setPaginatedRows(slicedRows);
    }

    function displayHeader(header) {
        if (header.hidden) {
            return false;
        } else {
            return header.header;
        }
    };

    function getTextColor(backgroundColor) {
        if (backgroundColor == '#da1e28' || backgroundColor == '#0e6027') {
            return '#FFFFFF';
        } else {
            return '#000000';
        }
    };

    function displayCell(row, cell) {
        let currentHeader = props.headers.filter(function (header) { return header.key === cell.info.header });
        if (currentHeader[0].hidden) {
            return false;
        } else {
            if ((currentHeader[0].key).toLowerCase().includes('count')) {
                return (
                    <TableCell key={cell.id} onClick={() => props.handleFlip(props.setFlipState, props.flipState, (row.cells).find(cell => cell.info.header === "item").value, props.tableType, currentHeader[0].key, row)}>
                        {parseInt(cell.value) ?
                            <Link className="remove-underline" to="#">
                                {cell.value ? typeof cell.value === "boolean" ? (cell.value).toString() : cell.value : "-"}
                            </Link>
                            :
                            cell.value ? typeof cell.value === "boolean" ? (cell.value).toString() : cell.value : "-"
                        }
                    </TableCell>
                )
            } else if (currentHeader[0].header === 'Inc No.') {
                return <TableCell key={cell.id}>
                    {cell.value}
                </TableCell>
            } else if (currentHeader[0].key === 'percentage') {
                return (
                    <TableCell key={cell.id}>
                        <div className="percentage" style={{ backgroundColor: props.hideColoredCell === true ? "transparent" : gradientColorForTable[cell.value] }}>
                            <span className="carbon-display-Semibold-14" style={{ color: props.hideColoredCell === true ? "#525252" : getTextColor(gradientColorForTable[cell.value]) }}>{cell.value}%</span>
                        </div>
                    </TableCell>
                )
            } else if (props.tableType === 'performanceTrend' && currentHeader[0].key !== 'week' && currentHeader[0].key !== 'total') {
                return (<TableCell key={cell.id} onClick={() => props.handleFlip(props.setFlipState, props.flipState, currentHeader[0].key, props.tableType, currentHeader[0].key, row)}>
                    {console.log("abc", cell.value)}
                    {parseInt(cell.value) ?
                        <Link className="remove-underline" to="#">
                            {cell.value ? typeof cell.value === "boolean" ? (cell.value).toString() : cell.value : "-"}
                        </Link>
                        :
                        cell.value ? typeof cell.value === "boolean" ? (cell.value).toString() : cell.value : "-"
                    }
                </TableCell>)
            } else {
                return (
                    <TableCell key={cell.id}>
                        {cell.value ? typeof cell.value === "boolean" ? (cell.value).toString() : cell.value : "-"}
                    </TableCell>
                )
            }
        }
    };

    console.log("props ========== >>>>>>>", props)

    return (
        props.loading ?
            <>
                <div className="graph-title carbon-display-Regular-20">{props.title}</div>
                <div><InlineLoading description={t('DigitalOperations.loading')} /></div>
            </>
            :
            <div>
                <div className="graph-title carbon-display-Regular-20">
                    <div>{props.title}
                        {props.graphDescription ?
                            <Tooltip
                                align="right"
                                label={props.graphDescription}
                                style={{ color: "black", fontSize: "14px" }}
                            >
                                <button
                                    className="tooltip-button"
                                    type="button"
                                >
                                    <Information />
                                </button>
                            </Tooltip> : null}
                    </div>
                    <div>
                        <div className="progress-close-container">
                            {props.showIndicator &&
                                <div className="progress-bar-container">
                                    <div className="title">Percentage</div>
                                    <div className="progress-bar-indicator" id="indicator"></div>
                                    <div className="range">
                                        <div>0</div>
                                        <div>100</div>
                                    </div>
                                </div>
                            }
                            {!props.showSwitcher &&
                                <div className='download-close-container'>
                                    {props.hideDownloadButton !== true &&
                                        <div className="download-btn">
                                            <TableToolbarDownload iconDescription={t('DigitalOperations.export')} rows={props.downloadableData ? props.downloadableData : props.rows || []} headers={(props.downloadableHeaders || props.headers || []).filter((item) => !(item.hidden === true))} title={props.title} filename={t('DigitalOperations.export')} label={t('DigitalOperations.download')} />
                                        </div>}
                                    {props.showFlipClose &&
                                        <div className="flip-button" onClick={() => props.handleFlip(props.setFlipState, props.flipState, 'goBack', props.tableType)}>
                                            <div>x</div>
                                        </div>}
                                </div>
                            }
                        </div>

                        {props.showSwitcher &&
                            <div className="switcher-container">
                                <ContentSwitcher onChange={(e) => props.switchView(e)} className="content-switcher" size='sm' >
                                    <Switch name={'GraphView'} text={props.leftSwitcherName || props.leftSwitcher} />
                                    <Switch name={'TableView'} text={props.rightSwitcherName || props.rightSwitcher} />
                                </ContentSwitcher>
                                <div className='download-close-container'>
                                    {props.hideDownloadButton !== true &&
                                        <div className="download-btn">
                                            <TableToolbarDownload iconDescription={t('DigitalOperations.export')} rows={props.downloadableData ? props.downloadableData : props.rows || []} headers={(props.downloadableHeaders || props.headers || []).filter((item) => !(item.hidden === true))} title={props.title} filename={t('DigitalOperations.export')} label={t('DigitalOperations.download')} />
                                        </div>}
                                    {props.showFlipClose &&
                                        <div className="flip-button" onClick={() => props.handleFlip(props.setFlipState, props.flipState, 'goBack', props.tableType)}>
                                            <div>x</div>
                                        </div>}
                                </div>
                            </div>
                        }
                    </div>

                </div>
                {props.rows && props.rows.length && !props.loading ?
                    <div className="incident-management-table">
                        <div className="table-container">
                            <DataTable size={props.size} rows={paginatedRows} headers={props.headers} isSortable={props.isSortingAllowed} render={({
                                rows,
                                headers,
                                getHeaderProps,
                                getRowProps,
                                getTableProps }) => (
                                <TableContainer>
                                    <Table {...getTableProps()}>
                                        <TableHead>
                                            <TableRow>
                                                {props.isExpandable && <TableExpandHeader />}
                                                {headers.map(header => (
                                                    header.header && <TableHeader {...getHeaderProps({ header })}>
                                                        {displayHeader(header)}
                                                    </TableHeader>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row, i) => (
                                                props.isExpandable ?
                                                    <React.Fragment key={row.id}>
                                                        <TableExpandRow {...getRowProps({ row })}>
                                                            {row.cells.map(cell => (
                                                                <TableCell key={cell.id}>{cell.value}</TableCell>
                                                            ))}
                                                        </TableExpandRow>
                                                        {row.isExpanded && (
                                                            <TableExpandedRow colSpan={props.headers.length + 1}>
                                                                <div className="expanded-table-title carbon-display-Semibold-14">{props.expandedTableTitle}</div>
                                                                <DashboardTable loading={false} rows={paginatedRows[i] ? paginatedRows[i].expandedItems : []} headers={props.headers} isExpandedTable={true} />
                                                            </TableExpandedRow>
                                                        )}
                                                    </React.Fragment>
                                                    :
                                                    <TableRow key={"row" + i} {...getRowProps({ row })}>
                                                        {row.cells.map((cell, index) => (
                                                            displayCell(row, cell)
                                                        ))}
                                                    </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>)}
                            />
                            {props.showPagination && props.rows.length > (props.tablePaginationLimit || 5) &&
                                <div className='pagination-container'>
                                    <Pagination
                                        totalItems={totalItems}
                                        backwardText={t('DigitalOperations.previousPage')}
                                        forwardText={t('DigitalOperations.nextPage')}
                                        pageSize={props.defaultCurrentPageSize || 5}
                                        pageSizes={[5, 10, 15, 25]}
                                        itemsPerPageText={props.itemsPerPageText ? props.itemsPerPageText : t('DigitalOperations.itemsPage')}
                                        onChange={({ page, pageSize }) => {
                                            let newFirstRowIndex = pageSize * (page - 1);
                                            setFirstRowIndex(newFirstRowIndex)
                                            getPaginatedRows(newFirstRowIndex, pageSize);
                                        }}
                                        pageRangeText={(current, total) => {
                                            props.getPages && setTotalPages(total);
                                            props.getPages && setCurrentPage(current);
                                            props.getPages && props.getPages(total, current);
                                            return `of ${total} pages`
                                        }}
                                        itemRangeText={(min, max, total) => props.paginateRecords && props.paginateRecords === true && total !== props.totalItems ? `${min}–${max} of Top ${total} from ${props.totalItems} items` : `${min}–${max} of ${total} items`}
                                    />
                                    {props.paginateRecords && props.totalItems && totalItems < props.totalItems && TotalPages === currentPage ?
                                        <div className='fastforward'><Button renderIcon={SkipForwardFilled32} hasIconOnly onClick={() => props.handleNextClick()} kind="ghost" iconDescription="Load more records"
                                        /></div> : null}
                                </div>
                            }
                        </div>
                    </div>
                    :
                    <div className="incident-management-table">
                        <div className="message">{t('DigitalOperations.noDataAvailable')}</div>
                    </div>
                }
            </div>
    );
}

export default IncidentManagementTable;
