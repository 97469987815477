import { FlexGrid } from "@carbon/react";
import React, { useEffect, useState } from "react";
import CpgTilesComponent from "./CpgTilesComponent";
import CpgWorkflow from "./Cpgworkflow.json";
import { getCpgWorkflowData } from "Services/ServerApi";
import { useQuery } from "@tanstack/react-query";
import "./cpg.scss";
import { useKeycloak } from "@react-keycloak/web";
import { v4 as uuidv4 } from "uuid";

const CpgIndex = () => {
    const {keycloak} = useKeycloak();
    const [cpgWorkflowInfo, setcpgWorkflowData] = useState([]);

    const { data: cpgWorkflowData } = useQuery(['CPGWorkflow'], async () => await getCpgWorkflowData(keycloak, uuidv4()));

    useEffect(() => {
        console.log("cpgWorkflowData============>>>", cpgWorkflowData)
        if(cpgWorkflowData !== undefined && cpgWorkflowData.data !== undefined) {
            setcpgWorkflowData(cpgWorkflowData.data.data)
        }
    }, [cpgWorkflowData])
    
    const progressLine = cpgWorkflowData?.data?.data?.[0]
    ?.data?.find(item => item.name === 'supplyChain' || item.name === 'procure_to_pay')
    ?.workflow?.find(item => item.title === 'Manage & Monitor' || item.title === 'Direct Procurement')?.data
    ?.find(item => item.name === 'In-Bound Logistics' || item.name === 'INTRA Company Stock Transfer Orders')
    ?.businessProcess?.data || [];

    console.log("progressLine=======::::", progressLine);

    return (
        <FlexGrid className="Cpg">
            <CpgTilesComponent dashboardData={cpgWorkflowInfo} />
        </FlexGrid>
    )
}

export default CpgIndex;