
import React, { useState } from 'react';
import { InlineLoading } from '@carbon/react';
import { CaretDown32  } from "@carbon/react/icons/index";
import { useTranslation } from 'react-i18next';

require('./scss/DashboardSummary.scss');

const DashboardSummary = (props) => {
    const { t } = useTranslation();
    const summary = props.summary;
    const tenat = sessionStorage.getItem("tenant")
    return (
        <div className="dashboard-summary-new">
            <div className="summary-wrapper">
                <div className="title">
                    {t('DigitalOperations.summary')}
                </div>
                <div className="dashboard-title">
                    {tenat === "SFGarage" ? "Platform Observability" : props.title}
                </div>
            </div>
            <div className="tile-container">
                {props.loading ?
                    <div className="tile loading-container">
                        <InlineLoading
                            description={t('DigitalOperations.loading')}
                        />
                    </div>
                    :
                    props.summary && props.summary.length ?
                        summary.map((type, index) => (
                            <div className="tiles" key={index}>
                                <div className="tile-title carbon-display-Semibold-16">
                                    {type.title}
                                </div>
                                {/* children element will be present only for bifurcated summary section */}
                                {type.children && type.children.length > 0 ?
                                    <div className="tile-container">
                                        {type.children.map((child, index) => (
                                            <>
                                                <div className="child-tiles" key={index}>
                                                    <div className="tile-title carbon-display-Semibold-16">
                                                        {child.title}
                                                    </div>
                                                    <div style={{ display: 'flex', columnGap: '4px' }}>

                                                        <div className="tile-value carbon-display-Light-48">
                                                            {typeof child.value === 'number' ? Math.round(child.value || 0) : child.value}
                                                        </div>
                                                        {child?.showPercent && (child.value !== 0) && (child.value !== '-') ?
                                                            <div className="additional-data-wrapper">
                                                                %
                                                            </div> : null}
                                                        {child?.bracketText && child?.bracketText?.length > 0 ?
                                                            <div className="additional-data-wrapper">
                                                                ({child.bracketText})
                                                            </div> : null}
                                                        {child.target ? <div className="statusIndicator">
                                                            <CaretDown32
                                                                style={{ height: '20px', width: '20px' }}
                                                            />
                                                            {child.target}
                                                        </div> : ''}
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                    :
                                    <div style={{ display: 'flex', columnGap: '4px' }}>
                                        <div className="tile-value carbon-display-Light-48">
                                            {typeof type.value === 'number' ? Math.round(type.value || 0) : type.value}
                                        </div>
                                        {type?.showPercent && (type.value !== 0) && (type.value !== '-') ?
                                            <div className="additional-data-wrapper">
                                                %
                                            </div> : null}
                                        {type?.bracketText && type?.bracketText?.length > 0 ?
                                            <div className="additional-data-wrapper">
                                                ({type.bracketText})
                                            </div> : null}
                                        {type?.target ? <div className="statusIndicator">
                                            <CaretDown32
                                                style={{ height: '20px', width: '20px' }}
                                            />
                                            {type.target}
                                        </div> : ''}
                                    </div>
                                }
                            </div>
                        ))
                        :
                        <div style={{ margin: '50px' }}>
                            {t('DigitalOperations.noDataAvailable')}
                        </div>
                }
            </div>
        </div >
    );
};

export default DashboardSummary;
