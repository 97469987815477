import { GaugeChart } from "@carbon/charts-react";
import { Accordion, AccordionItem, Breadcrumb, BreadcrumbItem, ClickableTile, Column, ErrorBoundary, FlexGrid, Row, Tag } from "@carbon/react";
import { PlayFilledAlt } from "@carbon/react/icons"
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "@tanstack/react-query";
import DataTable from "Carbon-Components/DataTable";
import { getAnyCollection } from "Services/ServerApi";
import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import PncIncidents from "../Pnc/pncIncident";

const AtoBusiness = (props) => {
    const { name } = useParams();
    const { keycloak } = useKeycloak();
    const [detailData, setDetailData] = useState({});
    const [accordionIndex, setAccordionIndex] = useState(1);
    const [atoCjFetchData, setCjAtoData] = useState({});
    const [flowName, setFlowName] = useState("");
    const [msTeamslink, setMSTeamslink] = useState({});
    const [msTeamsPop, showMSTeamsPop] = useState(false);
    const [clientEvent, setClientEvent] = useState("");

  const { data: AtoCjFetchData, refetch: atoRefetch } = useQuery(['ato_cj_data'], async () => await getAnyCollection(keycloak, "ato_cj_details"), { retry: 1 });

  const { data: snowTableData } = useQuery(["servicenTab"]);

//   const atoName = sessionStorage.getItem("atoName")

    useEffect(() => {
        if(AtoCjFetchData !== undefined) {
            const filt = AtoCjFetchData.data.ato_cj_details.filter((fil) => fil.name === props.name);
            console.log("filt", filt)
            if(filt !== undefined && filt.length > 0){
                const query = filt[0].data[0];
                const result = query.Application.map((app, index) => {
                    return {
                        ...app,
                        id: (index+1).toString()
                    }
                })
                setCjAtoData(result)
                setFlowName(query["Flow Name"])
            }
        }
    }, [AtoCjFetchData, props])

    // useEffect(() => {
    //     if (msTeams !== undefined) {
    //       setMSTeamslink(msTeams);
    //     }
    //   }, [msTeams]);

    // const getRowCellData = (id, data, row) => {
    //     if (id.includes("Status")) {
    //         return data === 0 ? <Tag className="some-class" type="green">
    //             Success
    //           </Tag> : <Tag className="some-class" type="red">
    //             Failed
    //           </Tag>
    //     }
    //     return data;
    // };

    // const getIncRowCellData = (id, data, row) => {
    //     if (id.includes("severity")) {
    //         return getPriority(data);
    //     }
    //     return data;
    // };

    // const getIncTableHeader = (header) => {
    //     return header;
    // };

    // const getTableHeader = (header) => {
    //     return header;
    // };

    // const getPriority = (num) => {
    //     switch (num) {
    //       case "1":
    //         return (
    //           <div>
    //             P1 - Critical{" "}
    //           </div>
    //         );
    //       case "2":
    //         return (
    //           <div>
    //             P2 - High
    //           </div>
    //         );
    //       case "3":
    //         return (
    //           <div>
    //             P3 - Medium{" "}
    //           </div>
    //         );
    //       case "4":
    //         return (
    //           <div>
    //           </div>
    //         );
    //       case "5":
    //         return (
    //           <div>
    //             P5 - Very Low{" "}
    //           </div>
    //         );
    //       default:
    //         return "null";
    //     }
    //   };

    // const getSnowActions = (row) => {
    //     return (
    //       <ul className="action-icons">
    //        {getRowActions(row)[0].Status === 1 ? <li
    //           className="enable"
    //           title="Run Book"
    //         >
    //           <PlayFilledAlt />
    //         </li> : null }
    //         {/* <li className="enable" 
    //         onClick={() => onClickTeams(row)}
    //         >
    //           <img
    //             src={require(`../../../../Assets/External/ms-teams.svg`).default}
    //             alt="ms-teams"
    //             className="ms-teams"
    //             width={20}
    //             height={20}
    //             style={{ cursor: "pointer" }}
    //           />
    //         </li> */}
    //       </ul>
    //     );
    //   };

      function getRowActions(row) {
        let tableRow = [];
        row.cells.forEach((cell) => {
            detailData.Details.forEach((rows) => {
            if (cell.id.includes("Name")) {
              if (cell.value === rows.Name) {
                tableRow.push(rows);
              }
            }
          });
        });
        return tableRow;
      }

    //   const onClickTeams = (row) => {
    //     const tableRow = getRowActions(row);
    //     setSelectTableRow(tableRow);
    //     showMSTeamsPop(true);
    //     setMSTeamslink();
    //     setTimeout(() => {
    //       msTeamsFetch();
    //     }, 1000);
    //   };

    // const getGaugeChart = (data) => {
    //     const chat = data !== undefined ? data[0].data.chartData : [];
    //     const opt = {
    //         title: "Success/Failure",
    //         resizable: true,
    //         height: '150px',
    //         gauge: {
    //           type: 'semi',
    //           status: 'danger'
    //         },
    //         legend: {
    //             enabled: false
    //         },
    //         toolbar: {
    //             enabled: false,
    //         },
    //         color: {
    //             scale: {
    //                 value: "#6fdc8c"
    //             },
    //         },
    //       };
          
    //     return <GaugeChart
    //     data={chat}
    //     options={opt}
    //   ></ GaugeChart>
    // }

    const getAlertsInfo = (data) => {
        let ajo = 0;
        if(Object.entries(detailData).length > 0) {
            ajo = detailData.Alerts;
        } else if(props.flow === "component") {

        } else {
            atoCjFetchData.forEach((fet) => {
                ajo += fet.Alerts
            })
        }
        return <>
        <ul className="basic-info ato">
        <li><h6>Alerts</h6><span className={ajo > 0 ? "red" : ""}>{ajo}</span></li>
        </ul>
        </>
    }

    const getIncidents = (data) => {

        let ajo = 0;
        if(Object.entries(detailData).length > 0) {
            ajo = detailData.Incidents;
        } else {
            atoCjFetchData.forEach((fet) => {
                ajo += fet.Incidents
            })
        }

        return <>
        <ul className="basic-info ato">
            <li><h6>Incidents</h6><span className={ajo > 0 ? "red" : ""}>{ajo}</span>
        </li>
        </ul>
        </>
    }

    const getDetailsStatus = (detail) => {
        return <ul className="detail-stat">{detail !== undefined && detail.length > 0 && detail.map((det) => <li title={det.Name} className={det.Status === 0 ? `success` : 'fail'}></li>)}</ul>
    }

    const handleOnClick = (data) => {
        const onData = {
                ...data,
                Details: data.Details !== undefined && data.Details.map((ail, index) => {
                    return {
                        ...ail,
                        id: (index+1).toString()
                    }
                })
            }
        setDetailData(onData)
    }

    // const headerData = [
    //     {
    //         key: "Name",
    //         header: "Name"
    //     },
    //     {
    //         key: "Channel",
    //         header: "Channel"
    //     },
    //     {
    //         key: "Stream",
    //         header: "Stream"
    //     },
    //     {
    //         key: "Status",
    //         header: "Status"
    //     },
    //     {
    //         key: "Date",
    //         header: "Date"
    //     }
    // ]

    // const IncheaderData = [
    //     {
    //         key: "number",
    //         header: "Incident"
    //     },
    //     {
    //         key: "description",
    //         header: "Description"
    //     },
    //     {
    //         key: "Status",
    //         header: "Status"
    //     },
    //     {
    //         key: "severity",
    //         header: "Severity"
    //     }
    // ]

    const getSnowIncident = (data) => {
        const snwTab = snowTableData.data.data.ato_incident_analysis.filter((ana) => ana.Component === data.Name && ana["Engaged By"] !== "Alerting");
        return snwTab;
        // return <div className="snw-inc">{snwTab.map((ta) => {
        //     return <ul>
        //         <li><span>Number: </span>{ta.number}</li>
        //         <li><span>Description: </span>{ta.description}</li>
        //     </ul>
        // })}</div>
    }

    const getSnowAlerts = (data) => {
        const snwTab = snowTableData.data.data.ato_incident_analysis.filter((ana) => ana.Component === data.Name && ana["Engaged By"] === "Alerting")
        return snwTab;
        // <div className="snw-inc">{snwTab.map((ta) => {
        //     return <ul>
        //         <li><span>Number: </span>{ta.number}</li>
        //         <li><span>Description: </span>{ta.description}</li>
        //     </ul>
        // })}</div>
    }

    // const getResponse = () => {
    //     return         <ul className="basic-info ato">
    //     <li><h5>Response</h5><span>{detailData.Details.length}</span>
    // </li>
    // </ul>
    // }

    const getPercent = (data) => {
        return <p className="avail">Availability: <span className={data.Availability === 100 ? "green" : "red"}>{data.Availability}</span></p>
    }

    return (
        <>
                 {/* <Row>
                    <Column lg={12} md={8} sm={4}>
                        <Breadcrumb noTrailingSlash>
                        <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
                        <BreadcrumbItem href="#/valueStream">IT Value Stream Observability</BreadcrumbItem>
                        <BreadcrumbItem href={`#/atoDashboard/${atoName}`}>{atoName}</BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage>{name}</BreadcrumbItem>
                        </Breadcrumb>
                    </Column>
                </Row> */}
                <Row>
                    <Column lg={16}>
                        <h4>{flowName}</h4>
                    </Column>
                </Row>
                <Row className="ato-row details ato--detail" >
                    {/* <Column lg={16}>
                        <ErrorBoundary fallback={"Data is unavailable"}>
                            <DataTable
                                title="Fire and Forget"
                                rowData={atoCjFetchData}
                                headerData={headerData}
                                getRowCellData={getRowCellData}
                                getTableHeader={getTableHeader}
                                actionsNeeded={false}
                                className="snow-table"
                                table_particular_actions={false}
                            />
                        </ErrorBoundary>
                    </Column> */}
                    {atoCjFetchData.length > 0 && 
                    <Column lg={4} className="colum" >
                        <ClickableTile className={clientEvent === "incidents" ? "active" : ""} onClick={() => setClientEvent("incidents")}>
                            {getIncidents()}
                        </ClickableTile>
                        <ClickableTile className={clientEvent === "alerts" ? "active" : ""} onClick={() => setClientEvent("alerts")}>
                            {getAlertsInfo()}
                        </ClickableTile>
                        <ClickableTile className={clientEvent === "response" ? "active" : ""} onClick={() => setClientEvent("response")}>
                        <ul className="basic-info ato">
                        <li><h6>Platform Alerts</h6><span>{0}
                </span>
        </li>
        </ul>
                        </ClickableTile>
                        <ClickableTile className={clientEvent === "response" ? "active" : ""} onClick={() => setClientEvent("response")}>
                        <ul className="basic-info ato">
            <li><h6>Application Alerts</h6><span>{0}</span>
        </li>
        </ul>
                        </ClickableTile>
                    </Column>}
                    <Column
                        lg={12}
                        md={2}
                        sm={4}
                        className="clickable--tile insights-hb insights-bus-hb"
                        key={2}
                    >
                    {atoCjFetchData.length > 0 && atoCjFetchData.map((data, index) => (
                        <ClickableTile
                            id={index}
                            onClick={() => handleOnClick(data)
                            }
                            className={detailData.Name === data.Name ? "active" : "disable-tile"}
                            aria-label={`clickableTile-${index}`}
                        >
                            <h5 className="report-title">
                                {data.Name}
                            </h5>
                            
                            {/* {getIncTileInfo(data)} */}
                            {/* {getBasicInfo(data)} */}
                            <div className="chart-box-ato">
                            {/* {getGaugeChart(data.chart)} */}
                            {props.flow !== "component" ? <> <h6>Response Status</h6>
                            {getDetailsStatus(data.Details)}
                            </> : getPercent(data)}
                            </div>
                            
                            {/* {<div className="chart-box">{getChartData(data.metrics, chartRef)}</div>} */}
                            {/* <div className="rgt-aw">
                                <ArrowRight size={16} className="arrow-icon"></ArrowRight>
                            </div> */}
                        </ClickableTile>
                    ))}
                    </Column>
                </Row>
                {Object.entries(detailData).length > 0 && <Row className="show-detail">
                    {/* <Column lg={16} md={8}>
                        <h5>{detailData.Name}</h5>
                        {showDetailData()}
                        
                    </Column> */}

                    {clientEvent === "incidents" && 
                        <ErrorBoundary fallback={"Data is unavailable"}>
                            {detailData.Details !== undefined && <PncIncidents flow="ato" servnow={getSnowIncident(detailData)} /> }
                        </ErrorBoundary>
                    }

                    {clientEvent === "alerts" &&
                        <ErrorBoundary fallback={"Data is unavailable"}>
                            {detailData.Details !== undefined && <PncIncidents flow="ato" servnow={getSnowAlerts(detailData)} />}
                        </ErrorBoundary>
                    } 
                    <Column lg={6}>
                    {/* <Accordion>
                        <AccordionItem id={1} title="Incident" open={1 === accordionIndex ? true : false} onClick={() => setAccordionIndex(1)}>
                            {getSnowIncident(detailData)}
                        </AccordionItem>
                        <AccordionItem id={2} title="Alerts" open={2 === accordionIndex ? true : false} onClick={() => setAccordionIndex(2)}>
                            {getSnowAlerts(detailData)}
                        </AccordionItem>
                    </Accordion> */}
                    </Column>
                </Row>}
                {/* {msTeamsPop ? (
          <PassiveModal
            heading={
              selectedTableRow.length > 0 ? selectedTableRow[0]["number"] : ""
            }
            size="sm"
            canCloseDetailsPopup={() => closepopup()}
          >
            <div>
              {msTeamslink !== undefined && msTeamslink.data !== undefined ? (
                <> {getMsTeamsLink()} </>
              ) : (
                <InlineLoading
                  status="active"
                  iconDescription="Loading"
                  description="Fetching Channel..."
                />
              )}
            </div>
          </PassiveModal>
        ) : null} */}
        </>
    )
}

export default AtoBusiness