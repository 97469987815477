import React, { useEffect, useState } from "react";
import {
  addHyperlinks,
  attachClickHandlers,
} from "Common-Modules/Utilities";

const ShowMoreText = ({ text="", maxLength = 500 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    attachClickHandlers();
  }, [isExpanded]);

  if(!text){
    return ""
  }

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  const displayedText = isExpanded
  ? String(text) // Ensure text is a string
  : `${String(text).slice(0, maxLength)}${text.length > maxLength ? "..." : ""}`;

  const linkedText = displayedText;
  if (!text || text?.length <= maxLength) {
    return <span dangerouslySetInnerHTML={{ __html: linkedText }} />;
  }

  return (
    <span className="more-text">
      <p dangerouslySetInnerHTML={{ __html: linkedText }} />
      <button onClick={toggleText} className="table-show-more">
        {isExpanded ? "Show Less" : "Show More"}
      </button>
    </span>
  );
};

export default ShowMoreText;
