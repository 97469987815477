import moment from 'moment';

export function getCalenderInterval(timeFilterSelections) {
  if (timeFilterSelections.endDateTime && timeFilterSelections.startDateTime) {
    let endDate = moment(timeFilterSelections.endDateTime);
    let startDate = moment(timeFilterSelections.startDateTime);

    let days = endDate.diff(startDate, 'days');
    if (days <= 7) {
      return "1d";
    } else if (days <= 92) {
      return "1w";
    } else if (days <= 365) {
      return "1M";
    } else if (days > 365) {
      return "1q";
    }
  }
  else {
    return "";
  }
}

export const combineAndRemoveElementsFromArrayOfObjects = (arr, duplicateProperty, combineProperty,) => {
  const arraySet = arr.reduce((acc, el) => {
    const existingEl = acc.find(accEl => accEl[duplicateProperty] === el[duplicateProperty])
    if (existingEl) existingEl[combineProperty] += (el[combineProperty])
    else acc.push({ ...el, [duplicateProperty]: el[duplicateProperty], [combineProperty]: el[combineProperty] })
    return acc
  }, [])
  return arraySet;
}

export function WeeklyMonthlyInterval(id) {
    if (id === "weekly" || id === "monthly") {
        return id === "weekly" ? "1w" : "1M";
    }
    else if (id === "day") {
        return "1d";
    }
    else if (id === "hour") {
        return "1h";
    }
    else return "";
}
