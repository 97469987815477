import React from 'react';
import { StackedBarChart } from "@carbon/charts-react";
import { Tooltip, InlineLoading } from '@carbon/react';
import { Information } from "@carbon/react/icons/index";
import TableToolbarDownload from './TableToolbarDownload';

// import TableToolbarDownload from './TableToolbarDownload';


const DashboardStackedBarChart = (props) => {
    //pass leftStack as true if you want the stacks should be on x axis;

    function tickFormatter(n) {
        if (props?.bottomIntegerTick) {
            if (parseInt(n) === n) {
                return n
            }
        } else return n
    }

    let leftStack = props.leftStack || false;
    const defaultStackedGraphOptions = {
        "title": props.tableTitle || '',
        "axes": {
            "left": {
                "title": props.leftAxesTitle || '',
                "mapsTo": leftStack ? "key" : "value",
                "scaleType": leftStack ? "labels" : null,
                "stacked": leftStack ? false : true,
                "truncation": {
                    "numCharacter": props.leftTickNumCharacter || 15 //default num character is 15
                }
            },
            "bottom": {
                "title": props.bottomTitle || 'Time',
                "mapsTo": leftStack ? "value" : "key",
                "scaleType": leftStack ? null : "labels",
                "stacked": leftStack ? true : false,
                "ticks": {
                    formatter: tickFormatter
                }
            }
        },
        "legend": {
            "position": props.legendPosition || "bottom",
            "orientation": props.legendOrientation || "horizontal",
            "alignment": props.legendAlignment || "left",
            "truncation": {
                "type": props.legendTruncation || "end_line", // "end_line", "front_line", "middle_line, "none"
                "numCharacter": props.legendNumCharacter || 15 //default num character is 15
            }
        },
        "data": {
            "loading": props.loading || false
        },
        "color": {
            "scale": props.colors || null
        },
        "bars": {
            "width": props.barWidth || ''
        },
        "tooltip": {
            "enabled": props.tooltipEnabled === false ? false : true,
            "showTotal": props.showTotal === false ? false : true,
            "customHTML": (data, defaultHTML) => props.getCustomTooltip ? props.getCustomTooltip(data, defaultHTML) : defaultHTML
        },
        "height": props.height || "400px",
        "toolbar": { "enabled": props.toolbarEnabled === false ? false : true },
        "theme": props.theme || 'white'
    };

    const stackedGraphHeaders = [{ key: 'group', header: 'Group' }, { key: 'key', header: (leftStack ? props.leftAxesTitle : props.bottomTitle || 'Date') },
    { key: 'value', header: 'Value' }];

    const getTitleWithToolTip = () => {
        return (<div>
            {props.title ?
                <div style={{ display: "flex" }}>
                    <div className="graphTitle">{props.title}
                        {props.graphDescription && <span>
                            <Tooltip
                                align="right"
                                label={props.graphDescription}
                                style={{ color: "black", fontSize: "14px" }}
                            >
                                <button
                                    className="tooltip-button"
                                    type="button"
                                >
                                    <Information />
                                </button>
                            </Tooltip>
                        </span>}
                    </div>
                </div> : null}
        </div>)
    }

    return (
        <>
            {!defaultStackedGraphOptions.data.loading && !props.stackedBarChartData.length ?
                <>
                    <div className="graph-title carbon-display-Regular-20">{getTitleWithToolTip()}</div>
                    <div>No Data Available</div>
                </> :
                defaultStackedGraphOptions.data.loading ?
                    <>
                        <div className="graph-title carbon-display-Regular-20">{getTitleWithToolTip()}</div>
                        <div><InlineLoading description="Loading" /></div>
                    </>
                    :
                    <div className="dashboard-stacked-bar-chart">
                        <div className="graph-title carbon-display-Regular-20">
                            {getTitleWithToolTip()}
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {props.hideDownloadButton !== true ? <div className="download-btn">
                                    <TableToolbarDownload iconDescription='Export' rows={props.stackedBarChartData} headers={stackedGraphHeaders} title={props.title} filename={props.title || 'Export'} label='Download' />
                                </div> : null}
                            </div>
                        </div>
                        <StackedBarChart
                            data={props.stackedBarChartData}
                            options={defaultStackedGraphOptions}>
                        </StackedBarChart>
                    </div>
            }
        </>
    );
};

export default DashboardStackedBarChart;
