import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {  useQuery } from "@tanstack/react-query";
import { ClickableTile, Row, Column, Toggle, FlexGrid, Breadcrumb, BreadcrumbItem } from "@carbon/react";
import { Analytics, ChartClusterBar, CurrencyDollar, CurrencyEuro, Wallet, Receipt, CheckmarkFilled, ArrowUp, WarningFilled } from "@carbon/react/icons/index";
import "./Pm.scss"

const CpgPlusSubdomain = () => {
    const history = useHistory();
    const [toggle, setToggle] = useState(false);
    const [domainInfo, setDomainData] = useState([]);
    const [toggleTitle, setToggleTitle] = useState("");

    const { data: domainData } = useQuery(['domain']);

    useEffect(() => {
        console.log("domainData", domainData)
        if(domainData !== undefined && domainData.data !== undefined) {
            const indexWithProjects = domainData.data.findIndex(item => item.projects && item.projects.length > 0);
            
            if (indexWithProjects !== -1) {
                const project = domainData.data[indexWithProjects].projects.map((pro) => {
                    return {
                        ...pro,
                        toggle: false
                    }
                })
                setDomainData(project)
            } else {
                setDomainData([]);
            }
        }
    }, [domainData])


    const onClickTile = (e, domain) => {

        e.stopPropagation();
        history.push({
            pathname: "/cpgPlusProcess",
            state: { data: domain },
        })
    }

    const onClickToggle = (e, name, ind) => {
        console.log("e.target ====", ind)
        e.stopPropagation();
        setToggleTitle(name)
        if(toggle) {
            setToggle(false)
        } else {
            setToggle(true)
        }

        setDomainData(prevState => prevState.map((prev, index) => {
            return {
                ...prev,
                toggle: ind === index ? prev.toggle ? false : true : false
            }
        }))
    }

    console.log("domainInfo ========", domainInfo)

    return (
        <FlexGrid>
            <Row>
                <Column lg={4} md={4} sm={4}>
                    <Breadcrumb noTrailingSlash>
                        <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
                        <BreadcrumbItem href="#/valueStream">Value Stream</BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage>
                            Sub Domains
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Column>
            </Row>
            <Row className="pm-domain">
                <Column lg={16} md={8} sm={4} className="pd-lft pd-rgt">
                    {domainInfo.length > 0 ? domainInfo.map((domain, index) => <ClickableTile onClick={(e) => {
            if(domain.projectTitle === "INTER Company Stock Transfer Orders") {
                onClickTile(e, domain)
            }
        }} className={`domin-tile ${domain.toggle} ${domain.projectTitle === "INTER Company Stock Transfer Orders" ? "red" : "green"}`}>
                        <div className="header">
                            <h4>
                                {domain.projectTitle}
                            </h4>
                            <span>
                            <Toggle defaultChecked={domain.toggle} onClick={(e) => onClickToggle(e, domain.projectTitle, index)} size="sm" labelText="" labelA="Details" labelB="Details" id="toggle-1" />
                            </span>
                        </div>
                        <div className="prjct">
                            <h6><span>Sub Domain</span>{domain.projectName}</h6>
                             <ul>
                                    <li className="case">
                                        <div className="icon-svg"><Analytics /></div>
                                        <p>
                                            <span>Order Fulfilment Rate</span>
                                            {domain.projectTotalCaseCount}
                                        </p>
                                    </li>
                                    <li className="event">
                                        <div className="icon-svg"><ChartClusterBar /></div>
                                        <p>
                                        <span>Inventory Turnover</span>
                                        {domain.projectTotalEventCount}
                                    </p></li>
                                    <li className="currency">
                                        <p>
                                            <span>Currency</span>
                                            {domain.projectCurrency === "USD" ? <CurrencyDollar fill="#121619" /> : <CurrencyEuro fill="#121619" />} {domain.projectCurrency}
                                        </p>
                                    </li>
                            </ul> 
                            {domain.toggle && <ul className="ul-details">
                                    <li className="case">
                                        <div className="icon-svg"><Wallet /></div>
                                        <p>
                                            <span>On-Time Delivery (OTD)</span>
                                            {domain.ON_TIME_PAYMENTS}
                                            {/* {domain.projectCurrency === "USD" ? <CurrencyDollar fill="#121619" /> : <CurrencyEuro fill="#121619" />} {domain.ON_TIME_PAYMENTS} */}
                                        </p>
                                    </li>
                                    <li className="event">
                                        <div className="icon-svg"><Receipt /></div>
                                        <p>
                                        <span>Perfect Order Rate</span>
                                        {domain.OUTSTANDING_BILL_TOTAL}
                                        {/* {domain.projectCurrency === "USD" ? <CurrencyDollar fill="#121619" /> : <CurrencyEuro fill="#121619" />} {domain.OUTSTANDING_BILL_TOTAL} */}
                                    </p></li>
                                    <li className="currency">
                                        <p>
                                            <span>Supplier Performance</span>
                                            {domain.OVERRIDE_RISK}
                                        </p>
                                    </li>
                                    <li className="remain">
                                        <p>
                                            <span>Lead Time</span>
                                            {domain.CUSTOMER_LOYALTY !== undefined ? <>{domain.CUSTOMER_LOYALTY} <CheckmarkFilled fill="#24a148" /> </> : "-"}
                                        </p>
                                    </li>
                                    <li className="remain cust">
                                        <p>
                                            <span>Cash-to-Cash Cycle Time</span>
                                            {domain.CUSTOMER_TURNOVER !== undefined ? <>{domain.CUSTOMER_TURNOVER} <ArrowUp fill="#24a148" /> </> : "-" }
                                        </p>
                                    </li>
                                    <li className="remain last">
                                        <p>
                                            <span>Forecast Accuracy</span>
                                            {domain.OVERRIDE_RISK !== undefined ? <>{domain.OVERRIDE_RISK} <WarningFilled fill="#ff832b" /></>: "-"}
                                        </p>
                                    </li>
                            </ul> }
                        </div>
                    </ClickableTile>) : null}   
                </Column>
            </Row>
        </FlexGrid>
    )
}

export default CpgPlusSubdomain;