/** @format */
import { ClickableTile, Column } from "@carbon/react";
import {
    DocumentView,
    SettingsEdit,
    Tuning,
    Undefined
} from "@carbon/react/icons";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import PowerBi from "../../Assets/External/powerBi.svg";

import PassiveModal from "Carbon-Components/Modals/PassiveModal";
import "../HomePage/css/Home.scss";
import MLModal from "../InstrumentationHub/Mlmodel";
import ToolFormFields from "../InstrumentationHub/ToolFormFieldscopy";

function TilesComponent(props) {
    const history = useHistory();
    const { t } = useTranslation();
    const [expectOp, setExpectOp] = useState(null);
    const [selectedTool, setSelectedTool] = useState("")

    const SVC_URL = sessionStorage.getItem("svc") || "localhost";

    // const openExternalLink = (url) => {
    //     window.open(url, "_blank");
    // };

    const handleOnClick = useCallback(
        (route, data) => {
            if (route === "warRoom") {
                window.location.href = "https://ibm-appops-devops.slack.com/archives/C02DQHU26H1";
            } else if (route === "resPerformance") {
                window.location.href = "https://aiops.turbonomic.io/vmturbo/oauth2/authorization/login.ibm.com";
            } else if (route === "appPerformance") {
                window.location.href = "https://aiopsri-ibmdso.instana.io/";
            } else if (route === "devOpsCommander") {
                window.location.href = "https://dc-stg.edst.ibm.com/";
            } else if (route === "icpsa") {
                window.location.href = "https://ibmaiops4aws.edst.ibm.com/ui/";
            } else if (route === "swagger") {
                window.location.href = `${SVC_URL}/swagger-ui/index.html`;
            } else if (route === "cloudFabrix") {
                window.location.href = data.url;
            } else if (route === "cfxPopUp") {
                props.showPopUp("true");
            } else if (route === "watson-aiops") {
                window.location.href = data.url;
            } else if (route === "doServiceDesk") {
                window.location.href = data.url;
            } else if (route === "doCommandCenter") {
                window.location.href = data.url;
            } else {
                history.push(`/${route}`);
            }
        },
        [history, props]
    );    

    // const getTitleIcons = (icon) => {
    //     switch (icon) {
    //         case "genai":
    //             return <Tuning />;
    //         default:
    //             return <Undefined />;
    //     }
    // }

    const expecteOp = (e, data) => {
        if (e && e.stopPropagation) e.stopPropagation();
        setExpectOp(data);
    }

        // const getMLDesc = (data) => {
    //     // return data["Expected_output"].map((err) => {
    //     //     const newEr = Object.entries(err).map(([key, value]) => ({ key, value }))
    //     //     return <ul className="err-log">{newEr.map((erlog) => {
    //     //         return <li>
    //     //             <h5>{erlog.key}</h5><span>{erlog.value}</span>
    //     //         </li>
    //     //     })}</ul>
    //     // })
    // }

    const { title } = props
    return (
        <>
            {props.tileData.map((data, index) => (
                <Column
                    lg={props.lg ? props.lg : 4}
                    md={8}
                    sm={4}
                    className="clickable--tile insights-hb"
                    key={index}
                >
                    <ClickableTile
                        id={index}
                        onClick={() =>
                            data.id !== undefined && handleOnClick(data.id, data)
                        }
                        disabled={data.disabled}
                        className={!data.disabled ? `${data["Expected_output"] ? "ml-modal" : ""}` : "disable-tile"}
                        aria-label={`clickableTile-${index}`}
                    >
                         {/* <div> */}
                        {data.isPowerBIReport && (
                            <img className="powerbi" src={PowerBi} alt="power BI" />
                        )}
                        {/* </div> */}
                        <h5 className="report-title" onClick={(e) => e.stopPropagation()}>
                            <div className="left-tit">{t(`DataAICore.dataAICoreCommon.title`)} <span>{data.title}</span></div>
                            {title === "Data Catalogue" ? (

    
                                      // <TooltipCarbon align="right"   description="configure">
                                    //     <Information size={14} 
                                    //         className="pointer" 
                                    //         // style={{ paddingLeft: "4px" }}
                                    //         onClick={(e) => {
                                    //             e.stopPropagation()
                                    //             setSelectedTool(data.title)
                                    //         }} />
                                    // </TooltipCarbon>
                                <div className="rgt-icon" title="Tool Configure">
                                    <SettingsEdit
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setSelectedTool(data.title)
                                        }}
                                    />
                                </div>
                            ) : data.description && data.description !== "" ? null
                                      // (
                                        //     <TooltipCarbon
                                        //         align="right"
                                        //         description={data.description}
                                        //     >
                                        //         <Information size={14} 
                                        //         // style={{ paddingLeft: "4px" }} 
                                        //         className="pointer"
                                        //         />
                                        //     </TooltipCarbon>
                                        // ) 
                            : null
                            }

                        </h5>
                        {data.Description !== undefined && (
                            <p className="desc" title={data.Description}>
                                <span>{t(`DataAICore.dataAICoreCommon.description`)}</span>{t(data.transDesc).substring(0, 100) + "..."}
                            </p>
                        )}
                           {/* {data["Models"] && data["Models"].length > 0 && (
                            data["Models"][0].name ? <div className="model-head"><span className="model">Model: </span><span className="model-data">{data["Models"][0].name}</span></div> : ""
                            // data["Models"][0].name ? <><Model fill="#78a9ff" /><span className="model-data">{data["Models"][0].name}</span></> : ""
                        )}
                        {data["Expected_output"] && (
                            <h5 className="expect">Expected Output: <span className="view" onClick={(e) => expecteOp(e, data)}><ViewFilled fill="#78a9ff" /></span></h5>
                        )} */}
                        {data["sub-title"] && <span className="subtitle">{data["sub-title"]}</span>}
                          {/* <div className="rgt-aw">
                            <ArrowRight size={16} className="arrow-icon"></ArrowRight>
                        </div> */}
                        {["GenAI", "Traditional AI"].includes(props.tabTitle) && (
                            <div
                                title="View Details"
                                className="rgt-aw"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    expecteOp(e, data);
                                }}
                            >
                                <DocumentView /> {t(`DataAICore.dataAICoreCommon.view`)}
                            </div>
                        )}
                    </ClickableTile>
                </Column>
            ))}
            {expectOp !== null && Object.entries(expectOp).length > 0 ? (
                <PassiveModal
                    size="lg"
                    heading={
                        <span className="modal-head">
                            <h4>
                                <img src={require("../../Assets/Common/certificate.png")} alt="certificate" />
                                {expectOp.title}
                            </h4>
                        </span>
                    }
                    canCloseDetailsPopup={(e) => {
                        e.stopPropagation()
                        setExpectOp(null)
                    }}
                >
                 {/* {getMLDesc(expectOp)} */}

                    <MLModal details={expectOp} title={props.tabTitle} />
                </PassiveModal>
            ) : null}
            {selectedTool && (
                <div onClick={(e) => e.stopPropagation()} className="tool--configuration">
                    <ToolFormFields tool={selectedTool} close={() => setSelectedTool("")} />
                </div>
            )}
        </>
    );
}

export default TilesComponent;
