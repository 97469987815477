import { NOC_PDF_URL } from "Services/ServerApi";

export const setChartOptions = (data) => {
  if (data.aggregationType !== "Percentage") {
    return data;
  }
  const axes = data.data.chartOptions.axes;
  Object.entries(axes).forEach(([key, value]) => {
    if (value.add_percenatage) {
      axes[key]["ticks"]["formatter"] = (n) => `${n}%`;
    }
  });
  return data;
};

export const capitalize = (inputString) => {
  const lowerCase = inputString.toLowerCase();
  return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
};

export const isValidDate = (dateString) => {
  let date = new Date(dateString);
  return !isNaN(date.getTime());
};

export const authorizationValidity = (authority, userAuthority) => {
  return userAuthority.includes(authority);
};

export const authorizationValidityTest = (authority, userAuthority) => {
  if (userAuthority) {
    const { length } = userAuthority;
    for (let i = 0; i < length; i += 1) {
      if (userAuthority[i] === authority) {
        return "";
      }
    }
    return "not-authorized";
  }
  return "not-authorized";
};

export const isValidUrl = (url) => {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.protocol === "http:" || parsedUrl.protocol === "https:";
  } catch (error) {
    return false;
  }
};

export const addHyperlinks = (text) => {
  // Regular expression to capture 'INCWLS' followed by exactly 7 digits
  const pattern = /(INCWLS\d{7})/g;

  // Replace the pattern with a hyperlink formatted span
  const linkedText = text.replace(pattern, (match) => {
    return `<span class="hyperlink" data-url="${match}">${match}</span>`;
  });

  return linkedText;
};

export const openPdf = (incidentNumber) => {
  const apiEndpoint = `${NOC_PDF_URL}/watsonx/rca/pdf`;

  const data = {
    incident_number: incidentNumber,
  };

  // Open a new tab immediately and keep a reference to it
  const newTab = window.open("", "_blank");

  if (!newTab) {
    console.error("Failed to open a new tab. It might be blocked by the browser.");
    return;
  }

  // Display a styled loading message with a Carbon-like loader
  newTab.document.write(`
    <html>
      <head>
        <style>
          body, html {
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #f4f4f4; /* Carbon light background */
            font-family: "IBM Plex Sans", Arial, sans-serif;
          }
          .loader-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
          .loader {
            border: 4px solid rgba(0, 0, 0, 0.1);
            border-left-color: #0f62fe; /* Carbon primary color */
            border-radius: 50%;
            width: 50px;
            height: 50px;
            animation: spin 0.75s linear infinite;
          }
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
          .message {
            margin-top: 16px;
            font-size: 16px;
            color: #525252; /* Carbon text color */
          }
        </style>
      </head>
      <body>
        <div class="loader-container">
          <div class="loader"></div>
          <div class="message">Loading PDF...</div>
        </div>
      </body>
    </html>
  `);

  fetch(apiEndpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.blob();
    })
    .then((blob) => {
      const fileURL = URL.createObjectURL(blob);

      // Use the new tab reference to change the location to the PDF blob URL
      newTab.location.href = fileURL;
    })
    .catch((error) => {
      console.error("Error fetching the PDF file:", error);

      // Display an error message in the new tab if there's a problem
      newTab.document.body.innerHTML = `
        <h1 style="text-align: center; color: red;">Error</h1>
        <p style="text-align: center;">Failed to load PDF: ${error.message}</p>
      `;
    });
};

// After adding the text to the DOM, attach click event listeners
export const attachClickHandlers = () => {
  const elements = document.querySelectorAll(".hyperlink");
  elements.forEach((element) => {
    // Check if the event listener is already attached
    if (!element.getAttribute("data-listener-attached")) {
      element.addEventListener("click", (event) => {
        event.stopPropagation();
        const incidentNumber = event.target.getAttribute("data-url");
        openPdf(incidentNumber);
        console.log("Hyperlink clicked! URL:", incidentNumber);
      });

      // Mark this element to avoid adding the listener again
      element.setAttribute("data-listener-attached", "true");
    }
  });
};
