/** @format */

import { Breadcrumb, BreadcrumbItem, Column, Layer, Row, Tag } from "@carbon/react";
import DynamicForm from "Components/Common/DynamicForm";
import { default as React, useEffect, useState } from "react";
import UserList from "./tenantuser/UserList";
import { useKeycloak } from "@react-keycloak/web";
import { getTenantsAPI } from "Services/RBACServerApi";
import { v4 as uuidv4 } from "uuid";

function TenantDetails(props) {
  const [tenantDetails, setTenantDetails] = useState({});
  const { keycloak } = useKeycloak();
  const [renderedTags,setRenderedTags]=useState([]);

  const getTenantFeatures = async (name) => {
    try {
        const tenants = await getTenantsAPI(keycloak, uuidv4());
        let filteredLabels = []; 
        tenants.forEach((tenant) => {
            if (tenant.name === name) {
                const features = tenant.attributes.features;
                const newLabels = features.map((feature) => {
                    const parsedFeature = JSON.parse(feature);

                    if (parsedFeature.disabled === false) {
                        return parsedFeature.label;
                    } else {
                        return null;
                    }
                });   
                filteredLabels = newLabels.filter((item) => item !== null);
            }
        });

      
        return filteredLabels;

    } catch (error) {
        console.error("Error getting Tenant features:", error);
    }
};

  const handleClose = removedTag => {
    const newTags = renderedTags.filter(tag => tag !== removedTag);
    setRenderedTags(newTags);
  };
  const resetTabs = () => {
    setRenderedTags(getTenantFeatures(props.tenantRow.name));
  };

  useEffect(() => {
    setTenantDetails(props.tenantRow);
    async function GetFeatures() {
      const labels = await getTenantFeatures(props.tenantRow.name);
      return labels
  }
  GetFeatures().then((labels) => {
    setRenderedTags(labels);
  }).catch((error) => {
    console.error("Error:", error);
  });
  }, []);


  return (
    <>
      <Row>
        <Column lg={16} md={8} sm={4}>
          <Breadcrumb noTrailingSlash>
            <BreadcrumbItem href="#/adminPanel" onClick={() => {
              props.closeTenantDetails();
            }}>Tenant List</BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              Tenant Details
            </BreadcrumbItem>
          </Breadcrumb>
        </Column>
      </Row>
      <Row>
        <Column lg={5} md={8} sm={4}>
          <div style={{ marginTop: "1rem" }}>
            <DynamicForm
              formData={JSON.parse(JSON.stringify(props.addTenant))}
              dupilicate={{ id: "name", exists: false }}
            // duplicateMessage={duplicateMessage}
            // getFormValidity={handleFormValidity}
            // handleFormChange={handleFormChange}
            />
          </div>
        </Column>
        <Column lg={11} md={8} sm={4}>
          <Row>
            <Column lg={16} md={8} sm={4}>
              <div style={{ marginTop: "1rem" }}>
                <UserList tenant={props.tenantRow.name} />
              </div>
            </Column>
            

            <Column lg={16} md={8} sm={4}>

              <Layer style={{ paddingTop: "2rem" }}>
                <Column lg={8} >
                  <div
                  // style={{ display: "flex" }}
                  >
                    <h4>Selected Features</h4>
                  </div>
                </Column>

                <Column lg={16}>

                <>
                <div aria-label="Tags" role="group" style={{
                  background: "white", marginTop: "1rem", marginBottom: "1rem", padding: "1rem"
                }}>
                  {renderedTags.map((tag, index) =>
                    <Tag
                      //className="some-class"
                      key={index}
                      size="md"
                      title="Clear filter"
                      type="warm-gray"
                    >
                      {tag}
                    </Tag> 
                  )}
                </div> 
                
                </>
                </Column>
              </Layer>
            </Column>

          </Row>
        </Column>
      </Row>
    </>
  );
}

export default TenantDetails;
