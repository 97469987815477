import {
  Breadcrumb,
  BreadcrumbItem,
  Column,
  ErrorBoundary,
  FlexGrid,
  Row,
  SkeletonText,
  StructuredListBody,
  StructuredListCell,
  StructuredListHead,
  StructuredListRow,
  StructuredListWrapper,
  Tile,
} from "@carbon/react";
import ChartContainer from "Carbon-Charts/ChartContainer";
import Context from "Context/Context";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import valueStream from "./ValueStream.json";
import CommonMLModal from "./mlModal";
import { useHistory } from "react-router-dom";
import {
  CheckmarkFilled
} from "@carbon/react/icons/index";
import "./vs.scss";


// import DynatraceIndex from "Components/Real-time Dashboard/Dynatrace";

const Go2Application = (props) => {
  const { workflow } = useParams();
  const context = useContext(Context);
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [recentIncidents, setRecentIncidents] = useState({});
  const [dateIndexChange, onDateIndexChange] = useState(2);
  const [dateChange, onDateChange] = useState("1 Day");
  const [valueStreamData, setValueStream] = useState();

 // const { data: valueStream } = useQuery(["valueStream"]);

  useEffect(() => {
    if (valueStream !== undefined && valueStream.data !== undefined) {
      const response = valueStream.data.valueStreamDetails.filter(
        (fil) => fil.title === context.valueStreamFlow
      );
      console.log("context.valueStreamFlow", context.valueStreamFlow)
      const filterWF = response[0].data.filter(
        (fil) => fil["name"] === workflow
      );
      setValueStream(filterWF);
    }
  }, [valueStream, context]);

  const getRowData = (key) => {
    let keyData = [];
    valueStreamData.forEach((vs) => {
      vs.workflowDetails.forEach((workflow) => {
        workflow["Row2"] !== undefined &&
          workflow["Row2"].forEach((row2) => {
            row2.steps !== undefined &&
              row2.steps.forEach((step) => {
                if (
                  sessionStorage.getItem("CusJouStep") === step["name"] &&
                  step[key]
                ) {
                  keyData = step[key];
                }
              });
          });
      });
    });
    return keyData;
  };

  const onTicketIdClick = (details) => {
    setRecentIncidents(details);
    setShowModal(true);
  };

  // const getMSteams = () => {
  //   return (
  //     <img
  //       src={require(`../../../Assets/External/ms-teams.svg`)}
  //       alt="ms-teams"
  //       className="ms-teams"
  //       width={20}
  //       height={20}
  //       style={{ cursor: "pointer" }}
  //     />
  //   );
  // };

  const getEvents = () => {
    return context.valueStreamFlow === "Kenvue" ? (
      <StructuredListWrapper className="linked-cust" isCondensed={true}>
        <StructuredListHead>
          <StructuredListRow head>
            <StructuredListCell head>Incident</StructuredListCell>
            <StructuredListCell head>Idoc</StructuredListCell>
            <StructuredListCell head>Created Date</StructuredListCell>
            <StructuredListCell head>Description</StructuredListCell>
            {/* <StructuredListCell head>Actions</StructuredListCell> */}
          </StructuredListRow>
        </StructuredListHead>

        {valueStreamData !== undefined &&
          valueStreamData.length > 0 &&
          getRowData("recentIncidents").map((similar) => {
            return (
              <>
                <StructuredListBody>
                  <StructuredListRow
                  // className={`table-row ${
                  //   similar.severity === 1 ? "error" : ""
                  // }`}
                  >
                    <StructuredListCell
                      noWrap
                      onClick={() => onTicketIdClick(similar)}
                    >
                      <div className={`bridge-sum`}>{similar.incident}</div>
                    </StructuredListCell>
                    <StructuredListCell>{similar.idoc}</StructuredListCell>
                    <StructuredListCell>{similar.date}</StructuredListCell>
                    <StructuredListCell>{similar.error}</StructuredListCell>
                    {/* <StructuredListCell>{getMSteams()}</StructuredListCell> */}
                  </StructuredListRow>
                </StructuredListBody>
              </>
            );
          })}
      </StructuredListWrapper>
    ) : (
      <StructuredListWrapper className="linked-cust" isCondensed={true}>
        <StructuredListHead>
          <StructuredListRow head>
            <StructuredListCell head>Ticket</StructuredListCell>
            <StructuredListCell head>Sev</StructuredListCell>
            <StructuredListCell head>Description</StructuredListCell>
          </StructuredListRow>
        </StructuredListHead>

        {valueStreamData !== undefined &&
          valueStreamData.length > 0 &&
          getRowData("recentIncidents").map((similar) => {
            return (
              <>
                <StructuredListBody>
                  <StructuredListRow
                    className={`table-row ${
                      similar.severity === 1 ? "error" : ""
                    }`}
                  >
                    <StructuredListCell noWrap>
                      <div
                        className={`bridge-sum  ${
                          similar.severity === 1 ? "error" : ""
                        }`}
                      >
                        {similar.ticket}
                      </div>
                    </StructuredListCell>
                    <StructuredListCell>{similar.severity}</StructuredListCell>
                    <StructuredListCell>
                      {similar.description}
                    </StructuredListCell>
                  </StructuredListRow>
                </StructuredListBody>
              </>
            );
          })}
      </StructuredListWrapper>
    );
  };

  const getInstanaInfraMemoryMetrics = () => {
    if (valueStreamData !== undefined && valueStreamData.length > 0) {
      const infraMetrics = getRowData("metrics");
      return {
        label: "",
        dataType: "area",
        data: {
          chartData: infraMetrics["calls"],
          chartOptions: {
            title: infraMetrics.FirstChartTitle,
            axes: {
              left: {
                mapsTo: "value",
              },
              bottom: {
                mapsTo: "date",
                scaleType: "time",
                ticks: {
                  rotation: "always",
                },
              },
            },
            // style: {
            //   /**
            //    * optional prefixing string for css classes (defaults to 'cc')
            //    */
            //   prefix: "myClass"
            // },
            height: "250px",
            curve: "curveNatural",
            grid: {
              x: {
                enabled: false,
              },
              y: {
                enabled: false,
              },
            },
            color: {
              scale: {
                calls: "#004144",
              },
            },
          },
        },
      };
    }
  };

  const getErrorMetrics = () => {
    if (valueStreamData !== undefined && valueStreamData.length > 0) {
      const infraMetrics = getRowData("metrics");
      return {
        label: "",
        dataType: "bar",
        data: {
          chartData: infraMetrics["errors"],
          chartOptions: {
            title: infraMetrics.SecondChartTitle,
            axes: {
              left: {
                title: "",
                mapsTo: "value",
                scaleType: "linear",
              },
              bottom: {
                title: "Time",
                mapsTo: "date",
                scaleType: "time",
              },
            },
            // style: {
            //   /**
            //    * optional prefixing string for css classes (defaults to 'cc')
            //    */
            //   prefix: "myClass"
            // },
            height: "250px",
            grid: {
              x: {
                enabled: false,
              },
              y: {
                enabled: false,
              },
            },
            legend: {
              enabled: false,
            },
            color: {
              scale: {
                errors: "#fa4d56",
              },
            },
          },
        },
      };
    }
  };

  const getInstanaInfraLatency = () => {
    if (valueStreamData !== undefined && valueStreamData.length > 0) {
      const infraMetrics = getRowData("metrics");
      return {
        label: "Latency",
        dataType: "line",
        data: {
          chartData: infraMetrics["latency"],
          chartOptions: {
            title: infraMetrics.ThirdChartTitle,
            axes: {
              left: {
                mapsTo: "value",
                title: "ms",
              },
              bottom: {
                mapsTo: "date",
                scaleType: "time",
                ticks: {
                  rotation: "always",
                },
              },
            },
            // style: {
            //   /**
            //    * optional prefixing string for css classes (defaults to 'cc')
            //    */
            //   prefix: "myClass"
            // },
            height: "250px",
            color: {
              scale: {
                latency: "#3ddbd9",
              },
            },
            grid: {
              x: {
                enabled: false,
              },
              y: {
                enabled: false,
              },
            },
          },
        },
      };
    }
  };

  const getIdocProcessingTime = () => {
    if (valueStreamData !== undefined && valueStreamData.length > 0) {
      const infraMetrics = getRowData("metrics");
      return {
        label: "Idoc Processing Time",
        dataType: "area",
        data: {
          chartData: infraMetrics["idoc"],
          chartOptions: {
            title: "Idoc Processing Time",
            axes: {
              left: {
                mapsTo: "value",
                thresholds: [
                  {
                    value: 40,
                    label: "",
                    fillColor: "red",
                  },
                ],
              },
              bottom: {
                mapsTo: "date",
                scaleType: "time",
                ticks: {
                  rotation: "always",
                },
              },
            },
            color: {
              gradient: {
                enabled: true,
              },
            },
            points: {
              enabled: false,
            },
            legend: {
              enabled: false,
            },
            // style: {
            //   /**
            //    * optional prefixing string for css classes (defaults to 'cc')
            //    */
            //   prefix: "myClass"
            // },
            height: "250px",
            grid: {
              x: {
                enabled: false,
              },
              y: {
                enabled: false,
              },
            },
          },
        },
      };
    }
  };

  // const getPastMinutes = (minutes, group, startDate = new Date()) => {
  //   return [...Array(minutes)].map((i, idx) => {
  //     let newDate = subtractMinutes(startDate, idx);
  //     let updatedDate = getCurrentDateTime(newDate);
  //     return { group: `${group}`, date: updatedDate, value: 0 };
  //   });
  // };

  // const getCurrentDateTime = (newDate) => {
  //   let date =
  //     newDate.getDate() <= 9 ? `0${newDate.getDate()}` : newDate.getDate();
  //   let month =
  //     newDate.getMonth() + 1 <= 9
  //       ? `0${newDate.getMonth() + 1}`
  //       : newDate.getMonth() === 12
  //       ? newDate.getMonth()
  //       : newDate.getMonth() + 1;
  //   let year = newDate.getFullYear();
  //   let hour =
  //     newDate.getHours() <= 9 ? `0${newDate.getHours()}` : newDate.getHours();
  //   let minute =
  //     newDate.getMinutes() <= 9
  //       ? `0${newDate.getMinutes()}`
  //       : newDate.getMinutes();
  //   let sec =
  //     newDate.getSeconds() <= 9
  //       ? `0${newDate.getSeconds()}`
  //       : newDate.getSeconds();
  //   return `${year}-${month}-${date}T${hour}:${minute}:${sec}.000Z`;
  // };

  //const subtractMinutes = (date, minutes) => new Date(date - minutes * 300000);

  const dateOnChange = (e, index) => {
    onDateIndexChange(index);
    onDateChange(e);
  };

  const dateSelection = () => {
    const dateArr = ["1 Hr", "6 Hr", "12 Hr"];
    return dateArr.map((date, index) => (
      <li
        onClick={() => dateOnChange(date, index)}
        className={index === dateIndexChange && "active"}
        key={date}
      >
        {date}
      </li>
    ));
  };

  const getRecentChanges = () => {
    return valueStreamData !== undefined && valueStreamData.length > 0 ? (
      getRowData("RecentChanges").map((details) => {
        return (
          <>
            <StructuredListBody>
              <StructuredListRow className="table-row">
                <StructuredListCell noWrap>{details.Ticket}</StructuredListCell>
                <StructuredListCell>{details.Description}</StructuredListCell>
              </StructuredListRow>
            </StructuredListBody>
          </>
        );
      })
    ) : (
      <StructuredListBody>
        <StructuredListRow className="table-row">
          <StructuredListCell noWrap>
            <SkeletonText />
          </StructuredListCell>
          <StructuredListCell>
            <SkeletonText />
          </StructuredListCell>
        </StructuredListRow>
      </StructuredListBody>
    );
  };

  const getErrorMessage = () => {
    return valueStreamData !== undefined && valueStreamData.length > 0 ? (
      getRowData("errorMessage").map((details) => {
        return (
          <>
            <StructuredListBody>
              <StructuredListRow className="table-row">
                <StructuredListCell noWrap>/{details.apis}</StructuredListCell>
                <StructuredListCell>{details["types"]}</StructuredListCell>
                <StructuredListCell></StructuredListCell>
                <StructuredListCell>{details.Count}</StructuredListCell>
                <StructuredListCell>
                  {details["Failure Rate"]}
                </StructuredListCell>
                <StructuredListCell>{details.latency}</StructuredListCell>
              </StructuredListRow>
            </StructuredListBody>
          </>
        );
      })
    ) : (
      <StructuredListBody>
        <StructuredListRow className="table-row">
          <StructuredListCell noWrap>
            <SkeletonText />
          </StructuredListCell>
          <StructuredListCell>
            <SkeletonText />
          </StructuredListCell>
          <StructuredListCell>
            <SkeletonText />
          </StructuredListCell>
          <StructuredListCell>
            <SkeletonText />
          </StructuredListCell>
        </StructuredListRow>
      </StructuredListBody>
    );
  };

  const getInfraAlerts = () => {
    return valueStreamData !== undefined && valueStreamData.length > 0 ? (
      getRowData("InfraAlerts").map((details) => {
        return (
          <>
            <StructuredListBody>
              <StructuredListRow className="table-row">
                <StructuredListCell noWrap>/{details.apis}</StructuredListCell>
                <StructuredListCell>
                  {details["Failure Rate"]}
                </StructuredListCell>
                <StructuredListCell>{details.Count}</StructuredListCell>
                <StructuredListCell>{details.error}</StructuredListCell>
              </StructuredListRow>
            </StructuredListBody>
          </>
        );
      })
    ) : (
      <StructuredListBody>
        <StructuredListRow className="table-row">
          <StructuredListCell noWrap>
            <SkeletonText />
          </StructuredListCell>
          <StructuredListCell>
            <SkeletonText />
          </StructuredListCell>
          <StructuredListCell>
            <SkeletonText />
          </StructuredListCell>
          <StructuredListCell>
            <SkeletonText />
          </StructuredListCell>
        </StructuredListRow>
      </StructuredListBody>
    );
  };

  const getFailedIdocs = () => {
    return valueStreamData !== undefined && valueStreamData.length > 0 ? (
      getRowData("failedIdocs").map((details) => {
        return (
          <>
            <StructuredListBody>
              <StructuredListRow>
                <StructuredListCell noWrap>{details.idoc}</StructuredListCell>
                <StructuredListCell>{details.delivery}</StructuredListCell>
                <StructuredListCell>{details.time}</StructuredListCell>
                <StructuredListCell>{details.error}</StructuredListCell>
              </StructuredListRow>
            </StructuredListBody>
          </>
        );
      })
    ) : (
      <StructuredListBody>
        <StructuredListRow className="table-row">
          <StructuredListCell noWrap>
            <SkeletonText />
          </StructuredListCell>
          <StructuredListCell>
            <SkeletonText />
          </StructuredListCell>
          <StructuredListCell>
            <SkeletonText />
          </StructuredListCell>
          <StructuredListCell>
            <SkeletonText />
          </StructuredListCell>
        </StructuredListRow>
      </StructuredListBody>
    );
  };

  // const getSuccessIdocs = () => {
  //   return valueStreamData !== undefined && valueStreamData.length > 0 ? (
  //     getRowData("successIdocs").map((details) => {
  //       return (
  //         <>
  //           <StructuredListBody>
  //             <StructuredListRow>
  //               <StructuredListCell noWrap>{details.idoc}</StructuredListCell>
  //               <StructuredListCell>{details.delivery}</StructuredListCell>
  //               <StructuredListCell>{details.time}</StructuredListCell>
  //               <StructuredListCell>{details.error}</StructuredListCell>
  //             </StructuredListRow>
  //           </StructuredListBody>
  //         </>
  //       );
  //     })
  //   ) : (
  //     <StructuredListBody>
  //       <StructuredListRow className="table-row">
  //         <StructuredListCell noWrap>
  //           <SkeletonText />
  //         </StructuredListCell>
  //         <StructuredListCell>
  //           <SkeletonText />
  //         </StructuredListCell>
  //         <StructuredListCell>
  //           <SkeletonText />
  //         </StructuredListCell>
  //         <StructuredListCell>
  //           <SkeletonText />
  //         </StructuredListCell>
  //       </StructuredListRow>
  //     </StructuredListBody>
  //   );
  // };

  const cpgworkflowRredirect = (page) => {
    history.push({
      pathname: page === workflow ? `/workflowViewCpg/${workflow}` : `/cpgWorkflow`,
      state: {
        tileData: props.location.state.tileData,
        flow: "valueStream",
        data: props.location.state.workflow,
      },
    });
  };

  const getTileInfo = () => {
    return valueStreamData !== undefined && valueStreamData.length > 0 ? (
      getRowData("tileInfo").map((tile) => { 
        return <Column lg={5} md={4} sm={4} className="pl-0 pr-10">
           <Tile className="full-height">
            <div className="inference1">
              <h5>{tile.key}</h5>
              <ul>{tile.value.map((val) => <li><CheckmarkFilled fill={val.color} /> {val.key}
              </li>)}</ul>
            </div>
          </Tile>
        </Column>
      })
    ) : []
  }
 
  return (
    <FlexGrid fullWidth>
                 {workflow !== "INTRA Company Stock Transfer Orders" ? <Row>
        <Column lg={12} md={8} sm={4}>
          <Breadcrumb noTrailingSlash>
            <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
            <BreadcrumbItem href="#/valueStream">
              IT Value Stream Observability
            </BreadcrumbItem>
            {/* <BreadcrumbItem href="#/VsStream">Customer Journey</BreadcrumbItem> */}
            {workflow === "INTRA Company Stock Transfer Orders" ? <>
            <BreadcrumbItem onClick={() => cpgworkflowRredirect()}>
              Consumer Package Goods Workflow
            </BreadcrumbItem>
            <BreadcrumbItem onClick={() => cpgworkflowRredirect(workflow)}>{workflow}</BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>Workflow view Details</BreadcrumbItem>
            </> : <>
            <BreadcrumbItem href={`#/VsworkflowView/${workflow}`}>
              {workflow}  
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>Workflow view Details</BreadcrumbItem>
            </>}
          </Breadcrumb> 
        </Column>
        <Column lg={4} md={2} sm={2}>
          <ul className="portfolio-fss">{dateSelection()}</ul>
        </Column> 
      </Row> : null}
      {/* <Row className="go2">
        <Column lg={16} md={8} className="tickets pr-0">
          <Tile className="full-height">
            <h5 className="last-row-title" style={{ fontWeight: "800" }}>
              Recent Changes
            </h5>
            <StructuredListWrapper>
              <StructuredListHead>
                <StructuredListRow head>
                  <StructuredListCell head>Change Ticket</StructuredListCell>
                  <StructuredListCell head>Description</StructuredListCell>
                </StructuredListRow>
              </StructuredListHead>
              {getRecentChanges()}
            </StructuredListWrapper>
          </Tile>
        </Column>
      </Row> */}
        <Row className="row-sec go2">
          {getTileInfo()}
        </Row>
      <Row className="row-sec go2">
        <Column lg={8} md={4} sm={4} className="recent-head pl-0 pr-0">
          <Tile className="full-height">
            <h5 className="box-heading">Recent Incidents</h5>
            {getEvents()}
          </Tile>
        </Column>
        <Column lg={8} md={4} sm={4}>
          <Tile className="full-height">
            <h5 className="last-row-title" style={{ fontWeight: "800" }}>
              Recent Changes
            </h5>
            <StructuredListWrapper>
              <StructuredListHead>
                <StructuredListRow head>
                  <StructuredListCell head>Change Ticket</StructuredListCell>
                  <StructuredListCell head>Description</StructuredListCell>
                </StructuredListRow>
              </StructuredListHead>
              {getRecentChanges()}
            </StructuredListWrapper>
          </Tile>
        </Column>
        {/* <Column
          lg={8}
          md={4}
          sm={4}
          className="pr-0"
          style={{ position: "relative" }}
        >
          <TreeDiagram />
        </Column> */}
      </Row>
      {context.valueStreamFlow === "Kenvue" && (
        <Row className="row-sec go2">
          <Column lg={8} md={8} sm={4} className="pl-0 pr-10">
            <Tile className="full-height">
              <h5>Failed Idocs</h5>
              <StructuredListWrapper className="linked-cust" isCondensed={true}>
                <StructuredListHead>
                  <StructuredListRow head>
                    <StructuredListCell head>Idoc </StructuredListCell>
                    <StructuredListCell head>Delivery</StructuredListCell>
                    <StructuredListCell head>
                      Processing Time(Min){" "}
                    </StructuredListCell>
                    <StructuredListCell head>Error</StructuredListCell>
                  </StructuredListRow>
                </StructuredListHead>
                {getFailedIdocs()}
              </StructuredListWrapper>
            </Tile>
          </Column>
          {/* <Column lg={8} md={4} sm={4} className="pl-0 pr-0">
            <Tile className="full-height">
              <h5>Success Idocs</h5>
              <StructuredListWrapper className="linked-cust" isCondensed={true}>
                <StructuredListHead>
                  <StructuredListRow head>
                    <StructuredListCell head>Idoc </StructuredListCell>
                    <StructuredListCell head>Delivery</StructuredListCell>
                    <StructuredListCell head>Time </StructuredListCell>
                  </StructuredListRow>
                </StructuredListHead>
                {getSuccessIdocs()}
              </StructuredListWrapper>
            </Tile>
          </Column> */}
          <Column lg={8} md={8} sm={4} className="pr-0">
            <ErrorBoundary fallback={"Data is unavailable"}>
              <ChartContainer data={getIdocProcessingTime()} />
            </ErrorBoundary>
          </Column>
        </Row>
      )}
      <Row className="row-sec go2">
        <Column lg={6} md={8} sm={4} className="pl-0 pr-0">
          <ErrorBoundary fallback={"Data is unavailable"}>
            <ChartContainer data={getInstanaInfraMemoryMetrics()} />
          </ErrorBoundary>
        </Column>
        <Column lg={6} md={8} sm={4} className="pr-0">
          <ErrorBoundary fallback={"Data is unavailable"}>
            <ChartContainer data={getErrorMetrics()} />
          </ErrorBoundary>
        </Column>
        <Column lg={4} md={8} sm={4} className="pr-0">
          <ErrorBoundary fallback={"Data is unavailable"}>
            <ChartContainer data={getInstanaInfraLatency()} />
          </ErrorBoundary>
        </Column>
      </Row>
      {context.valueStreamFlow !== "Kenvue" && (
        <Row>
          <Column lg={8} md={8} sm={4} className="pl-0 pr-10">
            <Tile className="full-height">
              <StructuredListWrapper>
                <StructuredListHead>
                  <StructuredListRow head>
                    <StructuredListCell head>API</StructuredListCell>
                    <StructuredListCell head>Types</StructuredListCell>
                    <StructuredListCell head>Technologies</StructuredListCell>
                    <StructuredListCell head>Count</StructuredListCell>
                    <StructuredListCell head>Failure Rate</StructuredListCell>
                    <StructuredListCell head>Latency</StructuredListCell>
                  </StructuredListRow>
                </StructuredListHead>
                {getErrorMessage()}
              </StructuredListWrapper>
            </Tile>
          </Column>
          <Column lg={8} md={4} sm={4} className="pl-0 pr-0">
            <Tile className="full-height infra-alert">
              <h5>Infrastructure Alerts</h5>
              <StructuredListWrapper>
                <StructuredListHead>
                  <StructuredListRow head>
                    <StructuredListCell head>Event Name</StructuredListCell>
                    {/* <StructuredListCell head>Logs</StructuredListCell> */}
                    <StructuredListCell head>Failure Rate</StructuredListCell>
                    <StructuredListCell head>Count</StructuredListCell>
                    <StructuredListCell head>Error</StructuredListCell>
                  </StructuredListRow>
                </StructuredListHead>
                {getInfraAlerts()}
              </StructuredListWrapper>
            </Tile>
          </Column>
        </Row>
      )}
      {showModal && (
        <CommonMLModal
          setShowModal={setShowModal}
          ticketDetails={recentIncidents}
        />
      )}
    </FlexGrid>
  );
};

export default Go2Application;
