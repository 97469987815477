export const getIconSrc = (type) => {
    // Map of type substrings to corresponding image paths
    const iconMapping = {
        "HTTP": "../../../Assets/aws/http.png",
        "ApiGateway": "../../../Assets/aws/ApiGateway.png",
        "DynamoDB": "../../../Assets/aws/DynamoDB.png",
        "ECS": "../../../Assets/aws/ECS.png",
        "EC2": "../../../Assets/aws/EC2.png",
        "SNS": "../../../Assets/aws/SNS.png",
        "Lambda": "../../../Assets/aws/Lambda.png",
        "S3": "../../../Assets/aws/S3.png",
        "SimpleNotificationService": "../../../Assets/aws/SimpleNotificationService.png",
        "SQS": "../../../Assets/aws/SQS.png",
        "StepFunctions": "../../../Assets/aws/StepFunctions.png",
        "SSM": "../../../Assets/aws/SSM.png",
        "SimpleSystemsManagement": "../../../Assets/aws/SSM.png",
        "STS": "../../../Assets/aws/STS.png",
        "remote": "../../../Assets/aws/apps.png",
        "Database": "../../../Assets/aws/RDS.png",
        "RDS": "../../../Assets/aws/RDS.png",
        "client": "../../../Assets/aws/client.png",
        "user": "../../../Assets/aws/user.png",
        "Fargate": "../../../Assets/aws/Fargate.png",
    };

    // Iterate over the mapping and return the first match
    if (type) {
        for (let key in iconMapping) {
            if (type.includes(key)) {
                return require(iconMapping[key]);
            }
        }
    }
    
    // Default case if no match is found
    return require("../../../Assets/aws/object.png");
}


export const getErrorFaultyClass = (node) => {
    return node.error ? "node_dark_orange" :  node.fault ? "node_orange" : "node_green";
}

export const getColorClass = (node) => {
    return node.hasAnomaly ? "node_red" : getErrorFaultyClass(node);
}

const nodeHeight = 64;
const nodeWidth = 64;

export const getEdgeStart = (nodeData, nodeId) => {
    const node = nodeData.find((nodeObj) => nodeObj.id === nodeId);
    if (node) {
        return {
            x: `${node.x + nodeWidth / 2}`,
            y: `${node.y + nodeHeight / 2}`,
        };
    }
}

export const getEdgeEnd = (nodeData, nodeId) => {
    const node = nodeData.find((nodeObj) => nodeObj.id === nodeId);
    if (node) {
        return {
            x: `${node.x}`,
            y: `${node.y + nodeHeight / 2}`,
        };
    }
}
