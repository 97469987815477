import React from 'react';
import { addHyperlinks } from 'Common-Modules/Utilities';


const Text = ({ content }) => {

    // Split the content by newlines
    const parts = content?.split('\n');

    return (
        <>
            {parts?.map((part, index) => (
                <React.Fragment key={index}>
                 <p dangerouslySetInnerHTML={{ __html: addHyperlinks(part) }} />
                    {index < parts.length - 1 && <><br /></>}
                </React.Fragment>
            ))}
        </>
    );
};

export default Text;