import MailContent from "Components/ICTE/Citi/Tables/MailContent";
import React from "react";

const EMailContent = (props) => {


    console.log("propssss", props)
    return (
        <>
            <MailContent flow="DO" content={props.content} doRemediation={props.doRemediation} probableRootCause={props.probableRootCause}  />
        </>
    )
}

export default EMailContent