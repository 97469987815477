import React, { useEffect, useState } from "react";
import PMJson from "./Pm.json";
import { getElasticAnyCollection } from "Services/ServerApi";
import { useHistory } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { QueryCache, useQuery } from "@tanstack/react-query";
import { ClickableTile, Row, Column, Toggle, Tag } from "@carbon/react";
import { Analytics, ChartClusterBar, CurrencyDollar, Wallet, Receipt, CheckmarkFilled, ArrowUp, WarningFilled } from "@carbon/react/icons/index"

const CpgPlusDomains = () => {
    const history = useHistory();
    const { keycloak } = useKeycloak();
    const queryCache = new QueryCache();
    const [toggle, setToggle] = useState(false);
    const [domainInfo, setDomainData] = useState([]);

    const { data: domainData } = useQuery(['domain'], async () => await getElasticAnyCollection(keycloak, "domain", "organization_name", "Telco Network", "cpg_plus"), { cache: queryCache })

    useEffect(() => {
        console.log("domainData", domainData)
        if(domainData !== undefined && domainData.data !== undefined) {
            setDomainData(domainData.data)
        }
    }, [domainData])


    const onClickTile = (e, domain) => {

        e.stopPropagation();
        history.push({
            pathname: "/cpgPlusSubDomains",
            state: { data: domain },
        })
    }

    // const onClickToggle = (e) => {
    //     console.log("e.target ====", e)
    //     e.stopPropagation();

    //     if(toggle) {
    //         setToggle(false)
    //     } else {
    //         setToggle(true)
    //     }
    // }

    console.log("domain ===", PMJson)

    return (
        <>
            <Row className="pm-domain">
                <Column lg={16} md={8} sm={4} className="pd-lft pd-rgt">
                    {domainInfo.length > 0 ? domainInfo.map((domain) => <ClickableTile onClick={(e) => {
            if(domain.projects.length !== 0) {
                onClickTile(e, domain)
            }
        }} className={`domin-tile main ${toggle} ${domain.project_status === "unhealthy" ? "red" : "green"}`}>
                        <div className="header">
                            <h4>
                                {domain.name}
                            </h4>
                            {/* <span>
                            <Toggle onClick={(e) => onClickToggle(e)} size="sm" labelText="" labelA="Summary" labelB="Details" id="toggle-1" />
                            </span> */}
                            <span>
                                <Tag className="some-class" type={domain.project_status	=== "unhealthy" ? "red" : "green"}>
                                    {domain.project_status}
                                </Tag>
                            </span>
                        </div>
                        <div className="prjct">
                            <h6><span>Sub Domains</span>{domain.projects.length}</h6>
                        </div>
                        {/* <div className="prjct">
                            <h6><span>Project Title</span>{domain.projectTitle}</h6>
                             <ul>
                                    <li className="case">
                                        <div className="icon-svg"><Analytics /></div>
                                        <p>
                                            <span>Case Count</span>
                                            {domain.projectTotalCaseCount}
                                        </p>
                                    </li>
                                    <li className="event">
                                        <div className="icon-svg"><ChartClusterBar /></div>
                                        <p>
                                        <span>Event Count</span>
                                        {domain.projectTotalEventCount}
                                    </p></li>
                                    <li className="currency">
                                        <p>
                                            <span>Currency</span>
                                            <CurrencyDollar fill="#121619" />{domain.projectCurrency}
                                        </p>
                                    </li>
                            </ul> 
                            {toggle  && <ul className="ul-details">
                                    <li className="case">
                                        <div className="icon-svg"><Wallet /></div>
                                        <p>
                                            <span>On Time Payment</span>
                                            {Math.floor(domain.ON_TIME_PAYMENTS)}
                                        </p>
                                    </li>
                                    <li className="event">
                                        <div className="icon-svg"><Receipt /></div>
                                        <p>
                                        <span>Outstanding Bill Total</span>
                                        {Math.floor(domain.OUTSTANDING_BILL_TOTAL)}
                                    </p></li>
                                    <li className="currency">
                                        <p>
                                            <span>Override Risk</span>
                                            {Math.floor(domain.OVERRIDE_RISK)}
                                        </p>
                                    </li>
                                    <li className="remain">
                                        <p>
                                            <span>Customer Loyalty</span>
                                            {Math.floor(domain.CUSTOMER_LOYALTY)} <CheckmarkFilled fill="#24a148" />
                                        </p>
                                    </li>
                                    <li className="remain cust">
                                        <p>
                                            <span>Customer Turnover</span>
                                            {Math.floor(domain.CUSTOMER_TURNOVER)} <ArrowUp fill="#24a148" />
                                        </p>
                                    </li>
                                    <li className="remain last">
                                        <p>
                                            <span>Low Risk Customer</span>
                                            {Math.floor(domain.OVERRIDE_RISK)} <WarningFilled fill="#ff832b" />
                                        </p>
                                    </li>
                            </ul> }
                        </div> */}
                    </ClickableTile>) : <h5 style={{ width: "100%", textAlign: "center" }} >No Data Available</h5> }  
                </Column>
            </Row>
        </>
    )
}

export default CpgPlusDomains;