/** @format */
import {
  Checkbox,
  Column,
  DismissibleTag,
  Layer,
  Row,
  Toggle,
  TreeNode,
  TreeView
} from "@carbon/react";
import { Add } from "@carbon/react/icons";
import React, { useEffect, useState } from "react";
import dashboard from "./dashboard.json";
import dashboardJSON from "./dashboardTree.json";

// Create parent-feature mapping
const createParentMap = (json) => {
  const map = {};

  const processNode = (node, parent = null) => {
    // If this node has a parent, add it to the map
    if (parent) {
      map[node.id] = {
        parentId: parent.id,
        parentLabel: parent.label
      };
    }

    // If node has children, process each child with current node as parent
    if (node.children) {
      node.children.forEach(child => {
        processNode(child, node);
      });
    } else {
      // If no children and no parent entry yet, add self as parent
      if (!map[node.id]) {
        map[node.id] = {
          parentId: node.id,
          parentLabel: node.label
        };
      }
    }
  };

  // Process each top-level node
  json.forEach(node => processNode(node));

  return map;
};

const parentFeatureMap = createParentMap(dashboard);

const selectionTiles = {
  "it_governance_reporting": [],
  "development": [
    {
      "id": "version_control",
      "label": "Version Control",
      "adapter": "GitHub Adapter"
    },
    {
      "id": "pipeline_usage",
      "label": "Pipeline Usage",
      "adapter": "AWS Code Pipeline and Build Adapter"
    },
    {
      "id": "deployment_insights",
      "label": "Deployment Insights",
      "adapter": "Jenkins Adapter"
    },
    {
      "id": "release_insights",
      "label": "Release Insights"
    }
  ],
  "security": [
    {
      "id": "dast",
      "label": "DAST"
    },
    {
      "id": "sast",
      "label": "SAST",
      "adapter": "Sonar Code Quality Adapter"
    }
  ],
  "operations": [
    {
      "id": "incident_analytics",
      "label": "Incident Analytics"
    },
    {
      "id": "operation_insights",
      "label": "Operation Insights"
    },
    {
      "id": "event_insights",
      "label": "Event Insights",
      "adapter": "AWS Event Bridge Adapter"
    },
    {
      "id": "patch_events",
      "label": "Patch Events"
    },
    {
      "id": "query_analytics",
      "label": "Query Analytics"
    },
    {
      "id": "incident_analytics_reports",
      "label": "Incident Analytics Reports"
    }
  ],
  "it_value_stream_management": [
    {
      "id": "banking",
      "label": "Banking"
    },
    {
      "id": "consumer_packaged_goods",
      "label": "Consumer Packaged Goods"
    },
    {
      "id": "energy_utilities",
      "label": "Energy & Utilities"
    },
    {
      "id": "global_banking",
      "label": "Global Banking"
    },
    {
      "id": "north_america_cpg",
      "label": "North America CPG"
    },
    {
      "id": "north_america_financial_services",
      "label": "North America Financial Services"
    },
    {
      "id": "norfolk",
      "label": "Norfolk"
    },
    {
      "id": "ato",
      "label": "ATO"
    },
    {
      "id": "telco_industry",
      "label": "Telco Industry"
    },
    {
      "id": "telco_with_ibm_process_mining",
      "label": "Telco with IBM Process Mining",
      "adapter": "IBM Process Mining Adapter"
    }
  ],
  "integrated_operations": [
    {
      "id": "application_performance_availability",
      "label": "Application Performance & Availability",
      "adapter": "AWS Topology Trace Anomaly Adapter"
    },
    {
      "id": "job_observability",
      "label": "Job Observability"
    },
    {
      "id": "nerve_center",
      "label": "Nerve Center"
    },
    {
      "id": "platform_observability",
      "label": "Platform Observability",
      "adapter": "Instana Infrastructure Insights Adapter"
    },
    {
      "id": "job_insights",
      "label": "Job Insights"
    },
    {
      "id": "alert_summary",
      "label": "Alert Summary"
    }
  ],
  "security_operations": [
    {
      "id": "contianer_security",
      "label": "Container Security",
      "adapter": "Twistlock Adapter"
    },
    {
      "id": "application_security",
      "label": "Application Security"
    }
  ]
};

function FeatureSelection(props) {

  const initialLevel = 0;
  const [selectedNode, setSelectedNode] = useState("");
  const [selectedNodeLabel, setSelectedNodeLabel] = useState("");
  const [selectedNodesArray, setSelectedNodesArray] = useState([]);
  const [selectedParentsArray, setSelectedParentsArray] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    setSelectedNodesArray(props.preSelectedNodesArray);
    setSelectedParentsArray(props.preSelectedParentsArray);
  }, [])

  const [renderedTags, setRenderedTags] = useState(props.preSelectedNodesArray);
  const handleClose = removedTag => {
    const newTags = renderedTags.filter(tag => tag !== removedTag);
    setRenderedTags(newTags);
    setSelectedNodesArray(newTags);
    props.setSelectedNodesArray(newTags);
  };
  const resetTabs = () => {
    setRenderedTags(selectedNodesArray);
  };

  // Helper function to check if a parent has any selected children
  const hasSelectedChildren = (parentId) => {
    const children = selectionTiles[parentId] || [];
    return children.some(child => selectedNodesArray.includes(child.id));
  };

  // Modified updateParentSelection function
  const updateParentSelection = (nodeId, isAdding) => {
    if (parentFeatureMap[nodeId]) {
      const parentId = parentFeatureMap[nodeId].parentId;

      if (isAdding) {
        // Add parent if not already in arrays
        setSelectedParentsArray(prev => {
          if (!prev.includes(parentId)) {
            return [...prev, parentId];
          }
          return prev;
        });
        setSelectedNodesArray(prev => {
          if (!prev.includes(parentId)) {
            return [...prev, parentId];
          }
          return prev;
        });
      } else {
        // Check if this was the last selected child
        const hasOtherChildrenSelected = selectedNodesArray.some(
          selectedId =>
            selectedId !== nodeId &&
            parentFeatureMap[selectedId]?.parentId === parentId
        );

        // If no other children are selected, remove parent from both arrays
        if (!hasOtherChildrenSelected) {
          setSelectedParentsArray(prev =>
            prev.filter(id => id !== parentId)
          );
          setSelectedNodesArray(prev =>
            prev.filter(id => id !== parentId)
          );
        }
      }
    }
  };

  // List of disabled features
  const disabledFeatures = [
    { id: "product_engineering", label: "Product Engineering" },
    { id: "banking", label: "Banking" },
    { id: "consumer_packaged_goods", label: "Consumer Packaged Goods" },
    { id: "energy_utilities", label: "Evergy & Utilities" },
    { id: "global_banking", label: "Global Banking" },
    { id: "norfolk", label: "Norfolk" },
    { id: "ato", label: "ATO" },
    { id: "telco_industry", label: "Telco Industry" },
    { id: "north_america_cpg", label: "North America CPG" },
    { id: "north_america_financial_services", label: "North America Financial Services" },
    { id: "sustainable_it_operations", label: "Sustainable IT Operations" },
    { id: "security_operations", label: "Security Operations" },
    { id: "contianer_security", label: "Container Security" },
    { id: "application_security", label: "Application Security" },
    { id: "nerve_center", label: "Nerve Center" }
  ];

  const isDisabled = (nodeId, nodeLabel) => {
    return disabledFeatures.some(
      feature => feature.id === nodeId || feature.label === nodeLabel
    );
  };

  function renderTree({
    dashboardJSON,
    expanded,
    level
  }) {
    level = level + 1;

    if (!dashboardJSON) {
      return;
    }
    return dashboardJSON.map(({
      children,
      isExpanded,
      ...nodeProps
    }) =>
      <TreeNode key={nodeProps.id}
        isExpanded={expanded ?? isExpanded}
        {...nodeProps} onSelect={(e) => {
          setSelectedNode(nodeProps.id)
          setSelectedNodeLabel(nodeProps.label)
        }}>
        {
          renderTree({
            dashboardJSON: children, expanded: true, level
          })
        }
      </TreeNode>

    );
  }

  const getAllParentIds = () => {
    const parentIds = new Set();
    const processNode = (node) => {
      // Add current node as it's a parent
      parentIds.add(node.id);
      // Process children if they exist
      if (node.children) {
        node.children.forEach(child => processNode(child));
      }
    };
    // Process all top-level nodes
    dashboardJSON.forEach(node => processNode(node));
    return Array.from(parentIds);
  };

  // Helper function to get all feature IDs (parents and children)
  const getAllFeatureIds = () => {
    const allIds = new Set();
    // Add all parent nodes from dashboardJSON
    getAllParentIds().forEach(id => allIds.add(id));
    // Add all child nodes
    Object.values(selectionTiles).forEach(tileGroup => {
      tileGroup.forEach(tile => {
        allIds.add(tile.id);
      });
    });
    return Array.from(allIds);
  };

  const onMultiSelectTilesChange = (e) => {
    const nodeId = e.target.id;
    const isSelected = !selectedNodesArray.includes(nodeId);

    let tempArray = [...selectedNodesArray];
    let tempParentArray = [...selectedParentsArray];

    if (isSelected) {
      tempArray.push(nodeId);

      if (parentFeatureMap[nodeId]) {
        tempParentArray.push(parentFeatureMap[nodeId].parentId);
      }
      updateParentSelection(nodeId, true);
    } else {
      tempArray = tempArray.filter(id => id !== nodeId);

      if (parentFeatureMap[nodeId]) {
        const hasOtherChildrenSelected = tempArray.some(
          selectedId =>
            selectedId !== nodeId &&
            parentFeatureMap[selectedId]?.parentId === parentFeatureMap[nodeId].parentId
        );
        if (!hasOtherChildrenSelected) {
          // remove all the occurances of parentFeatureMap[nodeId].parentId from tempParentArray
          tempParentArray = tempParentArray.filter(item => item !== parentFeatureMap[nodeId].parentId);
        }
      }
      updateParentSelection(nodeId, false);

      // If this is a child node, check parent status
      if (selectionTiles[parentFeatureMap[nodeId]?.parentId]) {
        const parentId = parentFeatureMap[nodeId].parentId;
        // If parent has no more selected children, remove it from both arrays
        if (!hasSelectedChildren(parentId)) {
          tempArray = tempArray.filter(id => id !== parentId);
        }
      }
    }

    setSelectedNodesArray(tempArray);
    setRenderedTags(tempArray);

    // Update selectAll state
    setSelectAll(tempArray.length === getAllFeatureIds().length);

    if (tempArray.length > 0) {
      props.setSelectedNodesArray(tempArray);
      // Added a prop for parent array
      const uniqueSelectedParentsArray = [...new Set(selectedParentsArray)];
      props.setSelectedParentsArray?.(uniqueSelectedParentsArray);
      props.getFormValidity(true);
    }
  };

  const ontoggle = (bool) => {
    props.setLoadStaticData(bool);
  }

  const featureConf = (tag) => {
    console.log(tag + " clicked!")
  }

  return (
    <Row>
      <Column lg={16} md={16} sm={16}>
      </Column>
      <Column lg={5} md={6} sm={6} >
        <TreeView label="Details" size="sm">
          {renderTree({
            dashboardJSON, expanded: true, initialLevel
          })}
        </TreeView>
      </Column>
      <Column lg={5} md={10} sm={10} >
        {
          selectedNode === "" ? "" :
            <div>
              {
                selectionTiles[selectedNode] === undefined ?
                  <Checkbox
                    onChange={(e) => onMultiSelectTilesChange(e)}
                    id={selectedNode + "_child"}
                    checked={selectedNodesArray.includes(selectedNode + "_child")}
                    labelText={selectedNodeLabel}
                    disabled={isDisabled(selectedNode, selectedNodeLabel)}
                  /> :
                  selectionTiles[selectedNode].map((option) => {
                    return <Checkbox
                      onChange={(e) => onMultiSelectTilesChange(e)}
                      id={option.id}
                      checked={selectedNodesArray.includes(option.id)}
                      labelText={option.label}
                      disabled={isDisabled(option.id, option.label)}
                    />
                  })
              }
            </div>
        }
      </Column>
      <Column lg={6} md={10} sm={10} >

        <Layer>
          <Column lg={8} >
            <div
            // style={{ display: "flex" }}
            >
              <h6>Selected Features</h6>
            </div>
          </Column>

          <Column lg={16} >
            <>
              <div aria-label="Dismissible tags" role="group" style={{
                background: "white", marginTop: "1rem", marginBottom: "1rem", padding: "1rem"
              }}>
                {
                  renderedTags.map((tag, index) => <>
                    <span style={{
                      // backgroundColor: "red",
                      width: "1.4rem",
                      height: "1.4rem",
                      borderRadius: "1rem",
                      // position: "absolute",
                      marginTop: "0.3rem",
                      opacity: "0.5",
                      marginLeft: "0.04rem",
                    }} onClick={() => featureConf(tag)}></span>
                    <DismissibleTag key={index}
                      onClick={e => { console.log("onClick e:", e) }}
                      onChange={e => { console.log("onChange e:", e) }}
                      // type={tag.type}//blue/null
                      className="some-class"
                      renderIcon={Add}
                      text={tag} tagTitle={tag} title="Dismiss" onClose={e => {
                        e.preventDefault();
                        handleClose(tag);
                      }} />
                  </>
                  )}
              </div>
            </>
          </Column>
        </Layer>
      </Column>
      <Column lg={16} md={16} sm={16}>
        <div className="toggle">
          <Toggle onToggle={(e) => ontoggle(e)} labelText="Load static data" labelA="No" labelB="Yes" id="toggle-1" />
        </div>
      </Column>

    </Row >

  );
}

export default FeatureSelection;
