
import { Breadcrumb, BreadcrumbItem, Column, FlexGrid, Row } from "@carbon/react";
import PerformanceAvailabilityComponent from "Components/ICPSA/AppPerformanceAvailabililty/PerformanceAvailabilityComponent";
import React, { useEffect, useState } from "react";
import AdaniIndex from "../../AdaniNerve";
import PncIncidents from "../Pnc/pncIncident";

const AtoDetails = (props) => {
    const [title, setTitle] = useState("");

    const escapeHtml = (str) => {
      return str.replace(/[&<>"']/g, (char) => ({
          '&': '&amp;',
          '<': '&lt;',
          '>': '&gt;',
          '"': '&quot;',
          "'": '&#39;',
      })[char] || char);
  };

    const atoName = sessionStorage.getItem("atoName");
    const safeAtoName = atoName ? escapeHtml(atoName) : '';
    const atoFlowTitle = sessionStorage.getItem("atoFlowTitle")
    useEffect(() => {
        if(props.location.state !== undefined) {
            setTitle(props.location.state.data)
            sessionStorage.setItem("atoName", props.location.state.name)
        }
    }, [props])

    console.log("props.location.state", atoFlowTitle, props.location.state)
    return (
        <FlexGrid fullWidth>
        <Row>
          <Column lg={12} md={8} sm={4}>
            <Breadcrumb noTrailingSlash>
              <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
              <BreadcrumbItem href="#/valueStream">IT Value Stream Observability</BreadcrumbItem>
              {props.location.state !== undefined? <BreadcrumbItem  href={`#/atoDashboard/${props.location.state.name}`}>{props.location.state.name}</BreadcrumbItem> : <BreadcrumbItem  href={`#/atoDashboard/${atoName}`}>{atoName}</BreadcrumbItem>}
              <BreadcrumbItem isCurrentPage>{props.location.state.data}</BreadcrumbItem>
            </Breadcrumb>
          </Column>
        </Row>
        <Row>
        <Column lg={16} md={8} sm={4}>
            {["Incidents", "Recent Incidents", "Recent Alerts"].includes(atoFlowTitle) ? <PncIncidents flow="ato" servnow={props.location.state.servnow} toggle={props.location.state.toggle} /> : atoFlowTitle === "Anomaly" ? <PerformanceAvailabilityComponent flow="ato" /> : <AdaniIndex />}
        </Column>
        </Row>
        </FlexGrid>
    )
}

export default AtoDetails;