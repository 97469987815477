import i18n from "../locale/i18n";

// COMMON TEXT
export const PANEL_SIDE_LEFT = "left";
export const PANEL_SIDE_RIGHT = "right";
export const NEED_TO_TEST = "Need to test";
export const GOBACK_TYPE_BACK = "back";
export const APPROACH_EXPERT = "expert";
export const CONNECTION_TYPE_PLATFORM = "platform";
export const CONNECTION_TYPE_CLOUD = "cloud";
export const WORKFLOW_CLOUD = "cloud";
export const WORKFLOW_TOOLCHAIN = "toolchain";
export const WORKFLOW_ENVIRONMENT = "environment";
export const WORKFLOW_PLATFORM = "platform";
export const DECISION = "decision";
export const GOBACK_TYPE_CONTINUE = "continue";
export const MAX_CLOUD_COUNT = 5;
export const MAX_ADD_USER_COUNT = 4;
export const RESPONSE_TYPE_QUERY = "query";
export const SUCCESS = "success";
export const ERROR = "Error";
export const FAILED = "failed";
export const IN_PROGRESS = "InProgress";
export const FALSE = "false";
export const TRUE = "true";
export const SEARCH = "search";
export const EMPTY = "empty";

export const FUNCTION = "function";
export const UNDEFINED = "undefined";
export const NO = "no";
export const YES = "yes";
export const TOP = "top";
export const BOTTOM = "bottom";
export const CHAR_YES = "Y";
export const CHAR_NO = "N";
export const TEXTAREA_ROWS = 3;
export const TEXTAREA_COLS = 66;

export const FIELD_KEY = "key";
export const FIELD_NAME = "name";
export const SESSION_FIELD_USER_ID = "user_id";
export const ADD = "add";
export const VIEW = "view";
export const EDIT = "edit";

// // INPUT FIELD TYPES
export const INPUT_TYPE_KEYVALUE = "keyValue";
export const INPUT_TYPE_TEXT = "text";
export const INPUT_TYPE_MULTI_SELECT = "multiselect";
export const INPUT_TYPE_DROPDOWN = "dropdown";
export const INPUT_TYPE_DROPDOWN_CARBON = "dropdown-carbon";
export const INPUT_TYPE_DATALIST = "datalist";
export const INPUT_TYPE_DATE = "date";
export const INPUT_TYPE_PANEL = "panel";
export const INPUT_TYPE_LABEL = "label";
export const INPUT_TYPE_TEXTAREA = "textarea";
export const INPUT_TYPE_TOGGLE = "toggle";
export const INPUT_TYPE_TILE = "tile";
export const INPUT_TYPE_URL = "url";

// // CLOUD CONNECTION AND TOOLCHAIN
export const PROGRESS_BAR_CREATE_TOOLCHAIN_PREPARE = "PREPARE";
export const PROGRESS_BAR_CREATE_TOOLCHAIN_CONFIGURE = "CONFIGURE";
export const PROGRESS_BAR_CREATE_TOOLCHAIN_PROVISION = "PROVISION";

// // PIPELINE
export const TOOLCHAIN = "toolchain";
export const CODE_TEMPLATE = "codetemplate";
export const PIPELINE_TEMPLATE = "pipelinetemplate";
export const PROJECT_PROFILE = "projectprofile";

// // SEARCH
export const SEARCH_TOOLCHAIN_TEMPLATE_NAME = "templateToolchainName";
export const SEARCH_TOOLCHAIN_NAME = "toolchainName";
export const SEARCH_PIPELINE_NAME = "pipelineName";
export const SEARCH_PIPELINE_TEMPLATE_NAME = "pipelinetemplateName";
export const SEARCH_PIPELINE_LIST = "Pipelinelist";
export const SEARCH_TOOLCHAIN_LIST = "toolchainlist";
export const SEARCH_ORGANIZATION_LIST = "organizationName";
export const SEARCH_PROJECT_LIST = "projectName";
export const SEARCH_TENANT_LIST = "tenantName";
export const SEARCH_USER_LIST = "userName";
export const SEARCH_ROLE_LIST = "roleName";

// // TOOLCHAIN_WIZARD

export const DEVOPS_PHASES = [
  "PLAN",
  "DEVELOP",
  "BUILD",
  "TEST",
  "SECURE",
  "DEPLOY",
  "OPERATE",
  "MONITOR",
  "SCALE",
  "ADAPT",
];
export const DEVOPS_PHASES_HEADERS = ["PHASES", "TOOLS"];

// // CREATE TOOLCHAIN
export const EDIT_VALUES = "Edit by Value";

// SELECTTASK/NOVICE
export const APPROACH = "approach";
export const APPROACH_NOVICE = "novice";
export const PURPOSE = "purpose";
export const PURPOSE_TOOLCHAIN = "toolchain";
export const ROUTE_PIPELINE = "/createpipelinemodal";
export const ROUTE_TOOLCHAIN = "/cloudconnection/toolchain";
export const SESSION_TOOLCHAINPROP = "toolprop";
export const SESSION_TOOLCHAINPURPOSE = "toolpurpose";
export const AUTHORITIES_CREATE_PIPELINE = "CREATE_PIPELINE";
export const AUTHORITIES_CREATE_TOOLCHAIN = "CREATE_TOOLCHAIN";
export const DISABLE_TENANT_HEADER_ROUTES = [
  "/manageTenants",
  "/tenant",
  "/project",
  "/license",
  "/createpipelinecontainer",
];
export const DISABLE_ORG_HEADER_ROUTES = [
  "/manageOrganizations",
  "/manageTenants",
  "/organizationDetails",
  "/tenant",
  "/project",
  "/license",
  "/orgprojectareatools",
  "/MultipleToolchain",
  "/createpipelinecontainer",
  "/manageProjectAreas",
  "/projectAreaDetails",
];
export const PA_STATUS_INACTIVE = "INACTIVE";
export const PA_STATUS_ACTIVE = "ACTIVE";
export const PA_STATUS_INPROGRESS = "INPROGRESS";
export const LOADER_STATUS_INACTIVE = "inactive";
export const LOADER_STATUS_ACTIVE = "active";
export const LOADER_STATUS_FINISHED = "finished";
export const LOADER_STATUS_ERROR = "error";
export const REQUEST_DATA_TYPE_HIDDEN = "hidden";
export const REQUEST_DATA_TYPE_BODY = "body";
export const REQUEST_DATA_TYPE_QUERY = "query";
export const FIELD_TENANT_ID = "tenantId";
export const FIELD_ORG_ID = "orgId";
export const FIELD_PROJECT_ID = "projectId";
export const FIELD_TENANT_NAME = "tenantName";
export const FIELD_ORG_NAME = "orgName";
export const FIELD_EMAIL = "email";
export const FIELD_ROLE_ID = "roleId";
export const FIELD_CREDENTIAL = "credential";
export const FIELD_PIPELINE_NAME = "pipeline-name";
export const FIELD_PROJECT_NAME = "project-name";

export const FIELD_PROFILE_ID = "profileId";
export const FIELD_TOOLCHAIN_ID = "toolchain_id";
export const FIELD_PIPELINE_TEMPLATE_ID = "pipeline_template_id";
export const FIELD_CODE_TEMPLATE_ID = "code_template_id";
export const IS_TEMPLATE = "isTemplate";

export const ROUTE_MANAGE_ORGANIZATIONS = "/manageOrganizations";
export const ROUTE_MANAGE_PROJECTAREAS = "/manageProjectAreas";
export const ROUTE_MANAGE_PROJECTS = "/projectDetails";
export const ROUTE_MANAGE_TENANTS = "/tenantDetails";
export const ROUTE_HOME = "/home";

export const CLOUD_CONNECTION_STATUS_ACTIVE = "ACTIVE";
export const CLOUD_CONNECTION_FIELD_STATUS = "status";

export const COMPONENT_MANAGE_TENANT = "ManageTenant";
export const COMPONENT_MANAGE_ORGANIZATION = "ManageOrganization";
export const COMPONENT_MANAGE_PROJECT = "ManageProject";
export const COMPONENT_MANAGE_PROJECTAREA = "ManageProjectArea";
export const COMPONENT_CREATE_PIPELINE_HOME = "CreatePipelineHome";

export const COLON_WITH_SPACE = ": ";
export const SPACE = " ";
export const FULL_STOP = ".";
export const PIPE = " | ";

export const STATUS_CODE_STRING_200 = "200";
export const STATUS_CODE_STRING_400 = "400";
export const HYPEN_WITH_PADDING = " - ";
export const GUEST_USER = "ROLE_GUEST_USER";
export const ROLE_PRODUCT_ADMIN = "ROLE_PRODUCT_ADMIN";
export const TYPE_ADD_TENANT_USER = "addTenantUser";

export const NO_HEADER_ROUTES = ["404"];

export const STATUS_CODE_NOT_FOUND = "404";
export const STATUS_CODE_UNAUTHORIZED = "401";
export const STATUS_CODE_INTERNAL_SERVER_ERROR = "500";

export const DEFAULT_TOOL_GROUP = "project_id";
export const TOOL_CONFIG_FOLDERNAME = "foldername";
export const TOOL_CONFIG_GROUP = "group";
export const TOOL_CONFIG_GROUP_PARENT = "group_parent";

export const LICENSE_POLICY = [
  { id: 0, text: "Entry" },
  { id: 4, text: "Standard" },
  { id: 2, text: "Enterprise" },
  { id: 1, text: "Enterprise Plus" },
];

// HEADERS

export const TENANT_USER_HEADER_KEYS = [
  {
    key: "firstName",
    header: i18n.t("user.tenantUser.header", { returnObjects: true })[0],
  },
  {
    key: "email",
    header: i18n.t("user.tenantUser.header", { returnObjects: true })[1],
  },
  {
    key: "organizations",
    header: i18n.t("user.tenantUser.header", { returnObjects: true })[2],
  },
  {
    key: "role",
    header: i18n.t("user.tenantUser.header", { returnObjects: true })[3],
  },
  {
    key: "createdDate",
    header: i18n.t("user.tenantUser.header", { returnObjects: true })[4],
  },
  {
    key: "updatedDate",
    header: i18n.t("user.tenantUser.header", { returnObjects: true })[5],
  },
  {
    key: "deleteEnabled",
    header: i18n.t("user.tenantUser.header", { returnObjects: true })[6],
  },
];

export const ORG_USER_HEADER_KEYS = [
  {
    key: "firstName",
    header: i18n.t("user.organizationUser.header", { returnObjects: true })[0],
  },
  {
    key: "email",
    header: i18n.t("user.organizationUser.header", { returnObjects: true })[1],
  },
  {
    key: "role",
    header: i18n.t("user.organizationUser.header", { returnObjects: true })[2],
  },
  {
    key: "createdDate",
    header: i18n.t("user.organizationUser.header", { returnObjects: true })[4],
  },
  {
    key: "updatedDate",
    header: i18n.t("user.organizationUser.header", { returnObjects: true })[5],
  },
  {
    key: "deleteEnabled",
    header: i18n.t("user.tenantUser.header", { returnObjects: true })[6],
  },
];

export const JENKINS_JOBS_HEADERS = [
  {
    key: "name",
    header: i18n.t("pipeline.folderOrJobName"),
  },
  {
    key: "isWorkflowJob",
    header: "isWorkflowJob",
  },
  {
    key: "isJobNameExist",
    header: "isJobNameExist",
  },
  {
    key: "importedPipeline",
    header: "importedPipeline",
  },
  {
    key: "description",
    header: i18n.t("common.description"),
  },
  {
    key: "url",
    header: i18n.t("common.url"),
  },
  {
    key: "type",
    header: "type",
  },
];

export const MATCHED_HEADER_DATA = [
  {
    key: "name",
    header: i18n.t("pipeline.pipelineName"),
  },
  {
    key: "pipeline_attributes",
    header: i18n.t("pipeline.importPipeline.gitRepoUrl"),
  },
];

export const UNMATCHED_HEADER_DATA = [
  {
    key: "pipelineJobname",
    header: i18n.t("pipeline.importPipeline.unmatchedPipeline"),
  },
  {
    key: "repoUrlInJenkins",
    header: "repoUrlInJenkins",
  },
  {
    key: "developToolUrl",
    header: "developToolUrl",
  },
  {
    key: "errorMessage",
    header: i18n.t("common.description"),
  },
];

export const DATA_UPLOADS_HEADER_DATA = [
  {
    key: "name",
    header: "File Name",
  },
  {
    key: "uploaded_date",
    header: "Created Date",
  },
  {
    key: "uploaded_by",
    header: "Uploaded By",
  },
  {
    key: "status",
    header: "Status",
  },
];

export const APPLICATION_HEADER_KEYS = [
  {
    key: "name",
    header: "Application Name",
  },
  {
    key: "pipeline_attributes",
    header: "Tools",
  },
  {
    key: "importedPipeline",
    header: "importedPipeline",
  },
  {
    key: "created_date",
    header: "Created On",
  },
  {
    key: "created_by",
    header: "creadtedBy",
  },
  {
    key: "updated_date",
    header: "Updated On",
  },
  {
    key: "updated_by",
    header: "updatedBy",
  },
  {
    key: "labels",
    header: "Labels",
  },
];

export const TICKET_SUMMARY_HEADER_DATA = [
  {
    key: "number",
    header: "number",
  },
  {
    key: "opened_at",
    header: "opened_at",
  },
  {
    key: "short_description",
    header: "short_description",
  },
  {
    key: "impact",
    header: "impact",
  },
  {
    key: "opened_by",
    header: "opened_by",
  },
  {
    key: "cmdb_ci",
    header: "cmdb_ci",
  },
  {
    key: "category",
    header: "category",
  },
  {
    key: "u_resolution_category",
    header: "Issue category",
  },
  {
    key: "u_resolution_code",
    header: "Resolution_code",
  },
];

export const SUSOPS_LANGUAGE_SHOWDOWN = [
  {
    key: "language",
    header: "Programming Language",
  },
  {
    key: "name",
    header: "Name",
  },
  {
    key: "accuracy",
    header: "Accuracy",
  },
  {
    key: "cpu",
    header: "CPU Consumed",
  },
  {
    key: "memory",
    header: "Memory Consumed",
  },
];

// Susops - EA
export const monthlyLegend = "In-Month Benefit (Hrs.)";
export const yearlyLegend = "In-Year Benefit (Hrs.)";
export const oneTimeLegend = "One Time Benefit (Hrs.)";
export const accumulatedLegend = "Accumulated Benefit (Hrs.)";

export const HP_440_G7_CE = 0.00788;

// export const TABLE_HEADING_MAPPING = {
//   incident_number: "Incident Number",
//   incident_date: "Date of Incident",
//   incident_description: "Incident Description",
//   region: "Region",
//   market: "Market",
//   incident_duration_in_minutes: "Incident Duration (Minutes)",
//   service_impact: "Service Impact",
//   involved_engineers: "Involved Engineers",
//   names_of_people_involved: "Names of People Involved",
//   organization: "Organization",
//   classification_of_cause: "Classification of Cause",
//   description_of_cause: "Description of Cause",
//   comments: "Comments",
//   preventive_measures: "Preventive Measures",
//   restoration_steps: "Restoration Steps",
//   incident_impact_summary: "Incident Impact Summary",
//   incident_rca_summary: "Incident RCA Summary",
//   incident_flow_summary: "Incident Flow Summary",
//   vendor: "Vendor",
//   incident_month: "Month",
//   total_incidents: "Total Incidents",
//   number_of_times_occurred: "Number of Times Occurred",
//   number_of_times_service_impacted: "Number of Times Service Impacted",
//   mttr_in_minutes: "MTTR in minutes",
//   number_of_times_region_impacted: "Number of Times Region Impacted",
//   similarity_distance: "Similarity Distance",
//   number_of_incidents: "Number of Incidents",
//   quarter : "Quarter",
//   total_markets_impacted : "Total Markets Impacted",
//   total_vendors_involved : "Total Vendors Involved",
//   total_causes : "Total Causes",
//   average_mttr : "Average MTTR",
//   similarity_score: "Similarity Score",
//   year : "Year",
//   mttr : "MTTR (Minutes)",
//   classification_of_cause_code_predicted : "Classification of Cause Predicted",
//   count: "Count",
// }

export const TABLE_HEADING_MAPPING = {
  number: "Incident Number",
  description: "Incident Description",
  short_description: "Short Description",
  assignment_group: "Assignment Group",
  site_id: "Site ID",
  subcategory: "Sub Category",
  close_notes: "Close Notes",
  status: "Status",
  u_probable_cause: "Probable Cause",
  u_problem_code: "Problem Code",
  priority: "Priority",
  severity:"Severity",
  u_region:"Region",
  resolution_notes:"Resolution Notes",
  incident_description_noc_incident_number:"NOC Incident Number",
  incident_summary_incident_rca_summary:"Incident Summary",
  incident_description_market_location:"Market Location",
  incident_description_problem_description:"Incident Description",
  incident_overview_incident_reported_time:"Incident Reported Time",
  incident_overview_service_impact:"Service Impact",
  steps_restoration:"Steps Restoration",
  incident_summary_incident_impact_summary:"Incident Impact Summary",
  defect_id:"Defect ID",
  business_benefits:"Business Benefits",
  defect_short_descriptions:"Defect Short Descriptions",
  defect_description:"Defect Description",
  vendor:"Vendor",
  application:"Application"
};
