import { Breadcrumb, BreadcrumbItem, Column, FlexGrid, Row } from "@carbon/react";
import { useTranslation } from 'react-i18next';
import CarbonTabs from "Carbon-Components/Tabs";
import React, { useEffect, useState } from "react";
import PerformanceAvailabilityComponent from "Components/ICPSA/AppPerformanceAvailabililty/PerformanceAvailabilityComponent";
import PlatformInsights from "Components/ICPSA/PlatformInsights/PlatformInsights";
import GetPermissions from "Roles-n-Permissions/PermissionScopeNew";
import AlertSummary from "../../DO/AlertsSummary/AlertSummary";
import AdaniIndex from "../AdaniNerve";
import KenvueIndex from "../JobObservability/JobObservability";
import { useHistory } from "react-router-dom";

const IntegratedITOps = (props) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [tabSelect, setTabSelection] = useState(sessionStorage.getItem("selectedOpsTab") !== null ? parseInt(sessionStorage.getItem("selectedOpsTab")) : 0)

    const hubs = sessionStorage.getItem("HubFlow");

    let selectedTenant = sessionStorage.getItem("tenant");
    let tenants = JSON.parse(sessionStorage.getItem("tenants"));
    let userRoles = [];

    tenants.forEach((tenantObj) => {
        if (tenantObj.name === selectedTenant) {
            userRoles = tenantObj.roles;
        }
    })

    console.log("userRoles", userRoles);
    const hasProductAdminOrOwner = userRoles.some(role => role === "productadmin" || role === "productowner");
    console.log("hasProductAdminOrOwner",hasProductAdminOrOwner);
    
    // useEffect(() => {
    //     if(hubs === "insights") {
    //         setTabSelection(0)
    //     } else {
    //         const tab = sessionStorage.getItem("selectedTab")
    //         setTabSelection(0)
    //     }
    // }, [])

    const getTabList = () => {
        if (hubs === "operations") {
            return [
                t(`CarbonTabs.integratedOperations.alertSummary`),
                t(`CarbonTabs.integratedOperations.apa`),
                t(`CarbonTabs.integratedOperations.jobObsv`),
                t(`CarbonTabs.integratedOperations.nerveCenter`),
                t(`CarbonTabs.integratedOperations.platformObsv`),
                // "Incident Management",
                // "Talk to Docs"
            ];
        }
        else {
            return [
                t(`CarbonTabs.integratedOperations.alertSummary`),
                t(`CarbonTabs.integratedOperations.apa`),
                t(`CarbonTabs.integratedOperations.jobObsv`),
                t(`CarbonTabs.integratedOperations.nerveCenter`),
                t(`CarbonTabs.integratedOperations.platformObsv`),
                // "Job Insights",
                // "Ticket Analytics"
                // "Incident Management",
                // "Talk to Docs"
            ];
        }
    }

    const getTabPanelsList = () => {
        if (hubs === "operations") {
            let panelList = [];
            if ((GetPermissions("alert_summary::read")) && hasProductAdminOrOwner) {
                panelList.push(<AlertSummary />);
            }
            if (GetPermissions("application_performance_availability::read")) {
                panelList.push(<PerformanceAvailabilityComponent />);
            }
            if (GetPermissions("job_observability::read")) {
                panelList.push(<KenvueIndex />);
            }
            if (GetPermissions("nerve_center::read")) {
                panelList.push(<AdaniIndex />);
            }
            if (GetPermissions("platform_observability::read")) {
                panelList.push(<PlatformInsights />);
            }
 
            return panelList;
            // return [
            //     <AlertSummary/>,
            //     <PerformanceAvailabilityComponent />,
            //     <KenvueIndex />,
            //     <AdaniIndex />,
            //     <PlatformInsights />,
            //     // <PncIncidents />,
            //     // <TalktoDocs />
            // ];
        }
        else {
            let panelList = [];
                
                if ((GetPermissions("alert_summary::read")) && !hasProductAdminOrOwner) {
                    panelList.push(null);
                }
                if ((GetPermissions("alert_summary::read")) && hasProductAdminOrOwner) {
                    panelList.push(<AlertSummary />);
                }
                if (GetPermissions("application_performance_availability::read")) {
                    panelList.push(<PerformanceAvailabilityComponent />);
                }
                if (GetPermissions("job_observability::read")) {
                    panelList.push(<KenvueIndex />);
                }
                if (GetPermissions("nerve_center::read")) {
                    panelList.push(<AdaniIndex />);
                }
                if (GetPermissions("platform_observability::read")) {
                    panelList.push(<PlatformInsights />);
                }
            // if (GetPermissions("job_insights::read")) {
            //     panelList.push(<KenvueIndex1 />);
            // }
            console.log("panelList", panelList);
            
            return panelList;
            // return [
            //     <AlertSummary />,
            //     <PerformanceAvailabilityComponent />,
            //     <KenvueIndex />,
            //     <AdaniIndex />,
            //     <PlatformInsights />,
            //     <KenvueIndex1 />,
            //     // <HCSCIncidents />
            //     // <PncIncidents />,
            //     // <TalktoDocs />
            // ];
        }
    }

    const tabSelection = (e) => {
        sessionStorage.setItem("selectedOpsTab", e)
        setTabSelection(e);

    }


    const getDisabledList = () => {

        let disabledList = [];
        
        if (!GetPermissions("alert_summary::read")) {
            disabledList.push("AlertSummary");
        }
        if (GetPermissions("alert_summary::read") && !hasProductAdminOrOwner) {
            disabledList.push("AlertSummary");
        }
        if (!GetPermissions("application_performance_availability::read")) {
            disabledList.push("Application Performance & Availability");
        }
        if (!GetPermissions("job_observability::read")) {
            disabledList.push("Job Observability");
        }
        if (!GetPermissions("nerve_center::read")) {
            disabledList.push("Nerve Center");
        }
        if (!GetPermissions("platform_observability::read")) {
            disabledList.push("Platform Observability");
        }
        if (!GetPermissions("job_insights::read")) {
            disabledList.push("Job Insights");
        }

        console.log("disabledList: ", disabledList);
        return disabledList;
    }

    const redirectHRef = () => {
        history.push({ pathname: "/workflowViewCpg/INTRA Company Stock Transfer Orders", state: { tileData: props.location.state.tileData, workflow: props.location.state.workflow }})
    }

    const onClickHome = () => {
        sessionStorage.removeItem("childFlow");
        history.push({ pathname: "/home" })
    }

    return (
        <FlexGrid className="integratedOps">
            <Row>
                <Column lg={13} md={8} sm={4}>
                    <Breadcrumb noTrailingSlash>
                        <BreadcrumbItem onClick={() => onClickHome()} href="#/home">{t(`breadcrumb.home`)}</BreadcrumbItem>
                        {sessionStorage.getItem("childFlow") === "nerve" ? <BreadcrumbItem  onClick={() => redirectHRef()}>Consumer Package Goods Workflow</BreadcrumbItem> : null}
                        <BreadcrumbItem isCurrentPage>
                        {t(`common.landingPage.integratedOperations`)}
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Column>
            </Row>
            <Row>
                <Column lg={16} md={8} sm={4}>
                    <CarbonTabs
                        tabIndex={sessionStorage.getItem("selectedOpsTab") !== "NaN" ? parseInt(sessionStorage.getItem("selectedOpsTab")) : 1}
                        selectionMode="automatic"
                        type="default"
                        Tablist={getTabList()}
                        tabSelection={true}
                        tabSelected={tabSelection}
                        disabledList={getDisabledList()}
                        TabPanelsList={getTabPanelsList()}
                    />
                </Column>
            </Row>
        </FlexGrid>
    )
}

export default IntegratedITOps;