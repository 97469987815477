import axios from "axios";
import { NOC_RCA_NAV_URL, SUPER_NOC_URL } from "Services/ServerApi";

const loggedInUserId = sessionStorage.getItem("user_name");

export const generateToken = () => {
  return axios
    .post(
      `${SUPER_NOC_URL}/generate_token?username=TelcoNOC&password=P%40ssw0rd`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error("There was an error creating the conversation!", error);
    });
};

export const getQuestions = async () => {
  return axios
    .get(`${SUPER_NOC_URL}/getquestions`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const getConversation = (data) => {
  const { userName } = data;
  const emailId = loggedInUserId || userName;

  const payload = {
    user_id: emailId,
    use_case: "super_noc",
  };

  return axios
    .post(`${NOC_RCA_NAV_URL}/conversations/get-conversations/v2`, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("There was an error creating the conversation!", error);
    });
};

export const getMessages = (data) => {
  const { conversation_id } = data;
  return axios
    .get(`${NOC_RCA_NAV_URL}/conversations/get-messages/${conversation_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("There was an error creating the conversation!", error);
    });
};

export const deleteConversationAPI = (data) => {
  const { conversation_id } = data;
  return axios
    .delete(
      `${NOC_RCA_NAV_URL}/conversations/delete-conversation/${conversation_id}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("There was an error creating the conversation!", error);
    });
};

export const addFeedback = (data) => {
  const { review, rating, messageId } = data;

  const message = {
    message_id: messageId,
    use_case: "rca_core",
    user_id: loggedInUserId,
    user_rating: rating,
    user_feedback: review,
  };

  return axios
    .post(`${NOC_RCA_NAV_URL}/feedback/add-feedback`, message)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error("There was an error creating the conversation!", error);
    });
};

export const updateConversationTitle = (data) => {
  const { conversation_id, title } = data;

  const payload = {
    conversation_id: conversation_id,
    user_id: loggedInUserId,
    title: title,
  };

  return axios
    .put(`${NOC_RCA_NAV_URL}/conversations/update-title`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error("There was an error creating the conversation!", error);
    });
};

export const getPDFContent = async (data) => {
  const { fileName } = data;

  try {
    // Generate token and extract the bearer token
    const tokenData = await generateToken();
    // @ts-ignore
    const bearerToken = tokenData.data.access_token;

    // Make the request to get the PDF content, including headers
    return axios
      .get(`${SUPER_NOC_URL}/getkbfile?file_name=${fileName}`, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${bearerToken}`,
        },
        responseType: "blob",
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("There was an error fetching the PDF content!", error);
        throw error;
      });
  } catch (error) {
    console.error("There was an error generating the token!", error);
    throw error;
  }
};

export const analyze = async (data) => {
  const { loggedInUserId, conversation_id, messageId } = data;

  try {
    // Generate token and extract the bearer token
    const tokenData = await generateToken();
    // @ts-ignore
    const bearerToken = tokenData.data.access_token;

    const payload = {
      message_id: messageId,
      use_case: "super_noc",
      user_id: loggedInUserId,
      conversation_id: conversation_id,
    };

    const headers = {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${bearerToken}`,
    };

    return axios
      .post(`${SUPER_NOC_URL}/analyze`, payload, { headers })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("There was an error creating the conversation!", error);
      });
  } catch {}
};
