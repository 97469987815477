import { Button, ErrorBoundary, Row, TextArea, Tooltip } from "@carbon/react";
import {
  Download,
  SendFilled,
  Close,
  StopOutlineFilled,
  StopFilled,
} from "@carbon/react/icons/index";

import React, { useEffect, useRef, useState } from "react";
import "react-chat-elements/dist/main.css";
import "./TelcoRCA.scss";
import DynamicRenderer from "./DynamicChat/DynamicRenderer";
import { SUPER_NOC_URL } from "Services/ServerApi";
import { analyze, generateToken, getQuestions } from "./Helper";
import { v4 as uuidv4 } from "uuid";

const Chatbot = (props) => {
  const {
    likedMessageIds,
    setLikedMessageIds,
    dislikedMessageIds,
    setDislikedMessageIds,
    chatHistory,
    setChatHistory,
    conversationData,
    setConversationData,
    setIsLoading,
    isLoading,
    reloadNavItems,
    setInputValueCopy,
    inputValueCopy,
    controller,
    setController,
  } = props;

  const [inputValue, setInputValue] = useState("");
  const [callApi, setCallApi] = useState(false);
  const [showHelpText, setShowHelpText] = useState(chatHistory.length === 0);
  const [errorMsg, setErrorMsg] = useState("");
  const [isWriting, setIsWriting] = useState(false);
  const [defaultQues, setDefaultQues] = useState([]);
  const [showQues, setShowQues] = useState(false);
  const setWrapperRef = useRef(null);

  const loggedInUserId = sessionStorage.getItem("user_name");

  useEffect(() => {
    getDefaultQuestions();
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      setWrapperRef.current !== null &&
      !setWrapperRef.current.contains(event.target)
    ) {
      setShowQues(false);
    }
  };

  const getDefaultQuestions = async () => {
    const res = await getQuestions();
    console.log(res);

    try {
      setDefaultQues(res.data.questions_data);
    } catch (error) {
      setDefaultQues([]);
    }
  };

  useEffect(() => {
    chatHistory?.length > 0 && setShowHelpText(false);
    chatHistory?.length === 0 && setShowHelpText(true);

    const likedIds = chatHistory
      .filter((item) => item?.feedback_resp_models?.user_rating === "like")
      .map((item) => item.messageId);

    const dislikedIds = chatHistory
      .filter((item) => item?.feedback_resp_models?.user_rating === "dislike")
      .map((item) => item.messageId);

    setLikedMessageIds([...likedMessageIds, ...likedIds]);
    setDislikedMessageIds([...dislikedMessageIds, ...dislikedIds]);

    // Auto scroll to the bottom of chat-box
    const chatDiv = document.getElementById("pdf-content");
    if (chatDiv) {
      chatDiv.scrollTop = chatDiv.scrollHeight;
    }
  }, [chatHistory]);

  useEffect(() => {
    if (callApi) {
      sendQuestion();
    }
  }, [callApi]);

  let text = "";

  const handleStreamedItem = (item, newChatHistory) => {
    let updatedChatHistory = [...newChatHistory];
    switch (item.type) {
      case "text": {
        // Update the last chat history entry with the received answer
        text += item.content;
        const content = {
          type: "text",
          content: text,
        };

        const contentArray = [{ ...content, content: text }];
        if (
          Array.isArray(
            updatedChatHistory[updatedChatHistory.length - 1].answer
          )
        ) {
          // Check if the last element in the array is of type 'text'
          const lastAnswer =
            updatedChatHistory[updatedChatHistory.length - 1].answer;
          const lastElement = lastAnswer[lastAnswer.length - 1];

          if (lastElement.type === "text") {
            // Remove the last element if it is 'text'
            lastAnswer.pop();
          }

          // Push the new content after removing the last one
          lastAnswer.push(content);
        } else {
          updatedChatHistory[updatedChatHistory.length - 1].answer =
            contentArray;
        }
        setChatHistory(updatedChatHistory);
        break;
      }

      case "table": {
        text = "";

        const tableContent = {
          type: "table",
          content: item.content,
        };

        if (
          Array.isArray(
            updatedChatHistory[updatedChatHistory.length - 1].answer
          )
        ) {
          updatedChatHistory[updatedChatHistory.length - 1].answer.push(
            tableContent
          );
        } else {
          updatedChatHistory[updatedChatHistory.length - 1].answer = [
            { ...tableContent },
          ];
        }

        setChatHistory(updatedChatHistory);
        break;
      }
      case "FileName": {
        text = "";

        const pdfContent = {
          type: "FileName",
          content: item.content,
        };

        const pdfContentArray = [{ ...pdfContent }];

        if (
          Array.isArray(
            updatedChatHistory[updatedChatHistory.length - 1].answer
          )
        ) {
          updatedChatHistory[updatedChatHistory.length - 1].answer.push(
            pdfContent
          );
        } else {
          updatedChatHistory[updatedChatHistory.length - 1].answer =
            pdfContentArray;
        }
        setChatHistory(updatedChatHistory);
        break;
      }

      case "conversation_id": {
        text = "";

        setConversationData((prevData) => ({
          ...prevData,
          conversation_id: item.content,
        }));
        break;
      }

      case "title": {
        text = "";

        setConversationData((prevData) => ({
          ...prevData,
          title: item.content,
        }));
        break;
      }

      case "message_id": {
        text = "";
        const updatedHistory = [...newChatHistory];
        updatedHistory[updatedHistory.length - 1].messageId = item.content;
        setChatHistory(updatedHistory);
        break;
      }

      default:
        break;
    }
  };

  const sendQuestion = async () => {
    setIsWriting(true);
    setIsLoading(true);
    ResponseApi(false);
    setErrorMsg("");

    // Immediately add the question to the chat history with an empty answer
    const newChatHistory = [
      ...chatHistory,
      {
        question: inputValue,
        answer: [{ type: "text", content: "" }], // Initially, the answer is empty
        hasSenderKey: "hasSenderKey",
        messageId: "",
      },
    ];
    setChatHistory(newChatHistory);

    const tokenData = await generateToken();
    // @ts-ignore
    const bearerToken = tokenData?.data?.access_token;

    const conversationId = conversationData.conversation_id || uuidv4();

    const payload = {
      prompt: inputValue,
      user_id: loggedInUserId,
      conversation_id: conversationId,
      use_case: "super_noc",
      // message_id: "75f9cc02-e556-4ca1-b570-3ae666a0f7e3",
      message_id: "",
    };

    const url = `${SUPER_NOC_URL}/process_query`;

    const abortController = new AbortController(); // Create an AbortController to stop request
    setController(abortController);
    const { signal } = abortController;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${bearerToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
        signal,
      });

      //console.log(response);
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let buffer = "";

      if (!response.ok) {
        // Handle non-200 HTTP responses
        setErrorMsg(
          "Apologies, we couldn't process your request at this moment"
        );
      }

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          setIsWriting(false);
          break;
        }
        setIsLoading(false);

        buffer += decoder.decode(value, { stream: true });

        // Use a regular expression to split the buffer correctly
        const parts = buffer.split(/(?<=})\s*(?={)/);

        for (let i = 0; i < parts.length - 1; i++) {
          try {
            const item = JSON.parse(parts[i]);
            // if (
            //   key === "sender" &&
            //   (item[key] === "sa_output" || item[key] === "ga_output")
            // ) {
            //   isAnalyzeAPINeeded = true;
            // }
            handleStreamedItem(item, newChatHistory);
          } catch (e) {
            setIsLoading(false);
            setIsWriting(false);
          }
        }
        buffer = parts[parts.length - 1];
      }
      // if (isAnalyzeAPINeeded) {
      //   const messageId =
      //     chatHistory[chatHistory.length - 1]?.answer?.messageId || "";
      //    analyze({ loggedInUserId, conversation_id: conversationId, messageId });
      // }

      if (buffer) {
        try {
          const item = JSON.parse(buffer);
          handleStreamedItem(item, newChatHistory);
          if (!conversationData.conversation_id) {
            reloadNavItems();
          }

          ResponseApi(true);
          setIsLoading(false);
        } catch (e) {
          setIsLoading(false);
          ResponseApi(false);
          setIsWriting(false);

          // setResponseData({});
          setChatHistory([...chatHistory]);
        }
      }
    } catch (error) {
      setIsLoading(false);
      setIsWriting(false);
      if (error.name === "AbortError") {
        console.log("Request was canceled", error);
      } else {
        setErrorMsg(
          "Apologies, we couldn't process your request at this moment."
        );
      }
    }
  };

  const handleKeyPress = (e, isSend) => {
    setCallApi(false);
    if (
      ((e.key === "Enter" && !e.shiftKey) || isSend) &&
      !isLoading &&
      !isWriting
    ) {
      setCallApi(true);
      // setShowHelpText(true);
      setInputValueCopy("");
    }
  };

  const onChangeInput = (e) => {
    if (e.target.value !== "\n") {
      setInputValue(e.target.value);
      setInputValueCopy(e.target.value);
    }
  };

  const ResponseApi = (bool) => {
    setCallApi(false);
  };

  const handleDownload = () => {
    if (!chatHistory) return;

    const json = JSON.stringify(chatHistory, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "data.json";
    a.click();

    URL.revokeObjectURL(url);
  };

  const loaderStyle = {
    padding: "20px",
    fontSize: "1rem",
    display: "inline-flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  };

  const getErrorMessage = () => {
    if (errorMsg) {
      return (
        <h3 className="errMsg" style={loaderStyle}>
          {errorMsg}
        </h3>
      );
    }
    return null;
  };

  const stopChat = () => {
    if (controller) {
      controller.abort(); // Abort the fetch request
      setIsWriting(false);
      setIsLoading(false);
    }
  };

  const onClickInput = () => {
    setShowQues(true);
  };

  const onClickQuestion = (ques) => {
    setInputValue(ques);
    setInputValueCopy(ques);
    setShowQues(false)
    handleKeyPress({key: "Enter"}, ques)
  }

  const inputLineCount = (inputValueCopy.match(/\n/g) || []).length;

  return (
    <div className="chat-container">
      <div className="chat-wdw">
        <div className="chat-title-download">
          <p>NOC AI Assistant</p>
          <div>
            <Tooltip
              align="left"
              label="Download Data"
              style={{ paddingLeft: "4px" }}
            >
              <Button
                kind="tertiary"
                // disabled={!Object.keys(responseData).length > 0}
                disabled={!chatHistory.length}
                onClick={handleDownload}
                className="download-btn"
              >
                <Download size={14} />
              </Button>
            </Tooltip>
          </div>
        </div>

        {chatHistory?.length > 0 ? (
          <div className="chart-history" id="pdf-content">
            {chatHistory.map((entry, index) => (
              <Row style={{ width: "100%" }} key={index}>
                <>
                  <div className="chat-ques-container">
                    <p className="chat-ques">{entry.question}</p>
                  </div>
                  <Row className="chat-dashboard">
                    <ErrorBoundary fallback={<h3>Something went wrong.</h3>}>
                      <DynamicRenderer
                        likedMessageIds={likedMessageIds}
                        setLikedMessageIds={setLikedMessageIds}
                        dislikedMessageIds={dislikedMessageIds}
                        setDislikedMessageIds={setDislikedMessageIds}
                        chunks={entry}
                        lastObject={index + 1 === chatHistory.length}
                        key={index}
                        isLoading={isLoading}
                      />
                    </ErrorBoundary>
                  </Row>
                </>
              </Row>
            ))}
          </div>
        ) : null}
        <div
          className="chart-dashboard"
          style={{ height: chatHistory.length === 0 ? "80vh" : "" }}
        >
          {!showHelpText || isLoading ? (
            <Row style={{ width: "100%" }}>{getErrorMessage()}</Row>
          ) : (
            showHelpText && (
              <h2 className="help-txt">How can I help you today?</h2>
            )
          )}
        </div>
        <div className="msg-input">
          <span className="inputSpan">
            <TextArea
              rows={inputLineCount <= 3 ? inputLineCount + 1 : 4}
              id="msg-input"
              type="text"
              onChange={(e) => onChangeInput(e)}
              value={inputValueCopy}
              helperText=""
              placeholder="Type Here..."
              onKeyPress={(e) => handleKeyPress(e)}
              cols={300}
              labelText={""}
              onClick={() => onClickInput()}
            />
          </span>
          {showQues && (
            <div ref={setWrapperRef} className="noc-ques">
              {defaultQues.length > 0 && (
                <ul>
                  {defaultQues.map((qu) => (
                    <li onClick={() => onClickQuestion(qu)}>{qu}</li>
                  ))}
                </ul>
              )}{" "}
            </div>
          )}
          {inputValueCopy && !isLoading && !isWriting ? (
            <span
              className="sendIcon filled-icon"
              onClick={(e) => handleKeyPress(e, true)}
            >
              <SendFilled size="32" />
            </span>
          ) : (
            <>
              {isLoading || isWriting ? (
                <span className="sendIcon " onClick={stopChat}>
                  <StopFilled size="32" className="stop" />
                </span>
              ) : (
                <span className="sendIcon disabled" onClick={() => {}}>
                  <SendFilled size="32" />
                </span>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
