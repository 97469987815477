import { GaugeChart } from "@carbon/charts-react";
import {
  Accordion,
  AccordionItem,
  Breadcrumb,
  BreadcrumbItem,
  ClickableTile,
  Column,
  ErrorBoundary,
  FlexGrid,
  Row,
  Tag,
} from "@carbon/react";
import { PlayFilledAlt } from "@carbon/react/icons";
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "@tanstack/react-query";
import DataTable from "Carbon-Components/DataTable";
import { getAnyCollection } from "Services/ServerApi";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import PncIncidents from "../Pnc/pncIncident";

const NorkfolkBusiness = (props) => {
  const history = useHistory();
  const { name } = useParams();
  const { keycloak } = useKeycloak();
  const [detailData, setDetailData] = useState({});
  const [accordionIndex, setAccordionIndex] = useState(1);
  const [atoCjFetchData, setCjAtoData] = useState({});
  const [flowName, setFlowName] = useState("");
  const [msTeamslink, setMSTeamslink] = useState({});
  const [msTeamsPop, showMSTeamsPop] = useState(false);
  const [clientEvent, setClientEvent] = useState("");

  const { data: AtoCjFetchData, refetch: atoRefetch } = useQuery(
    ["ato_cj_data"],
    async () => await getAnyCollection(keycloak, "ato_cj_details"),
    { retry: 1 }
  );

  const { data: snowTableData } = useQuery(["servicenTab"]);

  // const atoName = sessionStorage.getItem("atoName");

  const { data: CJIncidentDetails, refetch } = useQuery(
    ["cj_incident_analysis"],
    async () => await getAnyCollection(keycloak, "cj_incident_analysis"),
    { retry: 1 }
  );

  useEffect(() => {
    if (AtoCjFetchData !== undefined) {
      const filt = AtoCjFetchData.data.ato_cj_details.filter(
        (fil) => fil.name === props.name
      );
      if (filt !== undefined && filt.length > 0) {
        const query = filt[0].data[0];
        const result = query.Application.map((app, index) => {
          return {
            ...app,
            id: (index + 1).toString(),
          };
        });
        setCjAtoData(result);
        setFlowName(query["Flow Name"]);
      }
    }
  }, [AtoCjFetchData, props]);

  // const getPriority = (num) => {
  //   switch (num) {
  //     case "1":
  //       return <div>P1 - Critical </div>;
  //     case "2":
  //       return <div>P2 - High</div>;
  //     case "3":
  //       return <div>P3 - Medium </div>;
  //     case "4":
  //       return <div></div>;
  //     case "5":
  //       return <div>P5 - Very Low </div>;
  //     default:
  //       return "null";
  //   }
  // };

  function getRowActions(row) {
    let tableRow = [];
    row.cells.forEach((cell) => {
      detailData.Details.forEach((rows) => {
        if (cell.id.includes("Name")) {
          if (cell.value === rows.Name) {
            tableRow.push(rows);
          }
        }
      });
    });
    return tableRow;
  }

  const getAlertsInfo = (data) => {
    let ajo = 0;
    data.forEach((inc) => {
      ajo = inc.Alerts;
    });
    return (
      <>
        <ul className="basic-info ato">
          <li>
            <h6>Alerts</h6>
            <span className={ajo > 0 ? "red" : ""}>{ajo}</span>
          </li>
        </ul>
      </>
    );
  };

  const getIncidents = (data) => {
    let ajo = 0;
    data.forEach((inc) => {
      ajo = inc.Incidents;
    });

    return (
      <>
        <ul className="basic-info ato">
          <li>
            <h6>Incidents</h6>
            <span className={ajo > 0 ? "red" : ""}>{ajo}</span>
          </li>
        </ul>
      </>
    );
  };

  const getDetailsStatus = (detail) => {
    return (
      <ul className="detail-stat">
        {detail !== undefined &&
          detail.length > 0 &&
          detail.map((det) => (
            <li
              title={det.Name}
              className={det.Status === 0 ? `success` : "fail"}
            ></li>
          ))}
      </ul>
    );
  };

  const handleOnClick = (data) => {
    const finalRes = CJIncidentDetails.data.cj_incident_analysis.filter(
      (fil) => fil.Component === data.Name
    );
    console.log("data", data);
    console.log("CJIncidentDetails", CJIncidentDetails);
    console.log("finalRes", finalRes);

    // const onData = {
    //   ...data,
    //   Details:
    //     data.Details !== undefined &&
    //     data.Details.map((ail, index) => {
    //       return {
    //         ...ail,
    //         id: (index + 1).toString(),
    //       };
    //     }),
    // };

    let details = [];
    if (finalRes.length > 0) {
      details = finalRes.map((aio, index) => {
        return {
          ...aio,
          id: (index + 1).toString(),
        };
      });
    }
    setDetailData(details);
  };

  // const getSnowIncident = (data) => {
  //   const snwTab = snowTableData.data.data.ato_incident_analysis.filter(
  //     (ana) => ana.Component === data.Name && ana["Engaged By"] !== "Alerting"
  //   );
  //   return snwTab;
  // };

  const getSnowAlerts = (data) => {
    const snwTab = snowTableData.data.data.ato_incident_analysis.filter(
      (ana) => ana.Component === data.Name && ana["Engaged By"] === "Alerting"
    );
    return snwTab;
  };

  const getPercent = (data) => {
    
    return (
      <p className="avail">
        Availability:{" "}
        <span className={data.Availability === 100 ? "green" : "red"}>
          {data.Availability}
        </span>
      </p>
    );
  };

  const setAlertClick = () => {
    sessionStorage.setItem("portfolioTitle", "Apply for a Product");
   // window.history.replaceState(null, '', '#/workflowView/Apply for a Product');
   history.push(`workflowView/Apply for a Product`)
  }

  console.log("detailData",detailData);
  
  return (
    <>
      <Row>
        <Column lg={16}>
          <h4>{flowName}</h4>
        </Column>
      </Row>
      <Row className="ato-row details ato--detail">
        {props.chartTitle.data.Application.length > 0 && (
          <Column lg={4} className="colum">
            <ClickableTile
              className={clientEvent === "incidents" ? "active" : ""}
              // onClick={() => setClientEvent("incidents")}
            >
              {getIncidents(props.chartTitle.data.Application)}
            </ClickableTile>
            <ClickableTile
              className={clientEvent === "alerts" ? "active" : ""}
              onClick={() => setAlertClick("alerts")}
            >
              {getAlertsInfo(props.chartTitle.data.Application)}
            </ClickableTile>
            <ClickableTile
              className={clientEvent === "response" ? "active" : ""}
              // onClick={() => setClientEvent("response")}
            >
              <ul className="basic-info ato">
                <li>
                  <h6>Platform Alerts</h6>
                  <span>{0}</span>
                </li>
              </ul>
            </ClickableTile>
            <ClickableTile
              className={clientEvent === "response" ? "active" : ""}
              // onClick={() => setClientEvent("response")}
            >
              <ul className="basic-info ato">
                <li>
                  <h6>Application Alerts</h6>
                  <span>{0}</span>
                </li>
              </ul>
            </ClickableTile>
          </Column>
        )}
        <Column
          lg={12}
          md={2}
          sm={4}
          className="clickable--tile insights-hb insights-bus-hb"
          key={2}
        >
          {props.chartTitle.data.Application.length > 0 &&
            props.chartTitle.data.Application.map((data, index) => (
              <ClickableTile
                id={index}
                onClick={() => handleOnClick(data)}
                className={
                  detailData.Name === data.Name ? "active" : "disable-tile"
                }
                aria-label={`clickableTile-${index}`}
              >
                <h5 className="report-title">{data.Name}</h5>
                <div className="chart-box-ato">
                  {props.flow !== "component" ? (
                    <>
                      {" "}
                      <h6>Response Status</h6>
                      {getDetailsStatus(data.Details)}
                    </>
                  ) : (
                    getPercent(data)
                  )}
                </div>
              </ClickableTile>
            ))}
        </Column>
      </Row>
      {detailData.length > 0 && (
        <Row className="show-detail">
            <ErrorBoundary fallback={"Data is unavailable"}>
              {/* {detailData.Details !== undefined && ( */}
                <PncIncidents
                  flow="ato"
                  servnow={detailData}
                />
              {/* )} */}
            </ErrorBoundary>

          {clientEvent === "alerts" && (
            <ErrorBoundary fallback={"Data is unavailable"}>
              {detailData.Details !== undefined && (
                <PncIncidents flow="ato" servnow={getSnowAlerts(detailData)} />
              )}
            </ErrorBoundary>
          )}
          {/* <Column lg={6}></Column> */}
        </Row>
      )}
    </>
  );
};

export default NorkfolkBusiness;
