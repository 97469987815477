// Mapping of icon names to their corresponding SVG paths
const iconMap = {
  client: "laptop.svg",
  user: "user.svg",
  ApplicationMobile: "application--mobile.svg",
  ApplicationWeb: "application--web.svg",
  Plan: "plan.svg",
  Catalog: "catalog.svg",
  Currency: "currency.svg",
  Concept: "concept.svg",
  MediaLibrary: "media--library.svg",
  Product: "product.svg",
  Stop: "stop.svg",
  WindPower: "wind-power.svg",
  AddComment: "add-comment.svg",
  ChooseItem: "choose-item.svg",
  SelectWindow: "select--window.svg",
  ShoppingCartPlus: "shopping--cart--plus.svg",
  ShoppingCartMinus: "shopping--cart--minus.svg",
  Purchase: "purchase.svg",
  InventoryManagement: "inventory-management.svg",
  PaymentGateway: "PaymentGateway.svg",
  UPI: "UPI.svg",
  Merchant: "Merchant.svg",
  IbmBluepay: "ibm--bluepay.svg",
  Finance: "finance.svg",
  Upload: "upload.svg",
  InProgress: "in-progress.svg",
  Assembly: "assembly.svg",
  Delivery: "delivery.svg",
  Db2Database: "db2--database.svg",
};

export const getIconSrc = (icon) => {
  if (!icon) return require("Assets/aws/bp/circle--outline.svg").default;

  // Check if the icon exists in the map and return the corresponding SVG
  for (let key in iconMap) {
    if (icon.includes(key)) {
      return require(`Assets/aws/bp/${iconMap[key]}`).default;
    }
  }

  // Default case if no match is found
  return require("Assets/aws/bp/circle--outline.svg").default;
};

export const getErrorFaultyClass = (node) => {
  return node.error || node.fault ? "node_orange" : "";
};

export const getColorClass = (node) => {
  return node.hasAnomaly ? "node_red" : getErrorFaultyClass(node);
};

const nodeHeight = 64;
const nodeWidth = 64;

// Utility function to get edge coordinates
const getEdgeCoordinates = (nodeData, nodeId, isEnd = false) => {
  const node = nodeData.find((nodeObj) => nodeObj.id === nodeId);
  if (node) {
    return {
      x: `${node.x + (isEnd ? 0 : nodeWidth / 2)}`,
      y: `${node.y + nodeHeight / 2}`,
    };
  }
};

export const getEdgeStart = (nodeData, nodeId) => {
  return getEdgeCoordinates(nodeData, nodeId, false);
};

export const getEdgeEnd = (nodeData, nodeId) => {
  return getEdgeCoordinates(nodeData, nodeId, true);
};
