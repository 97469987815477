import React, { useState, useEffect } from "react";
import { Tile, CodeSnippet } from "@carbon/react";
import PassiveModal from "Carbon-Components/Modals/PassiveModal";

const ErrorLog = (props) => {
    const [errorLogs, setErrorLogs] = useState()
    const [errorMessage, setErrorMessage] = useState("")

    useEffect(() => {
        let newObj = [];
        props.logs.stages.length > 0 && props.logs.stages.forEach((stag) => {
            if(stag.status === "FAILED") {
                newObj.push(stag)
            }
        })
        if(newObj.length === 0) {
            setErrorMessage("No error logs found")
        } else if(newObj.length > 0) {
            setErrorLogs(newObj[0].error_logs)
        }
    }, [props])

    const canCloseDetailsPopup = () => {
        props.onCloseModal()    
    }

    console.log("errorLogs =========>>>>>>>", errorLogs)

    return (
        <PassiveModal size="lg" className="modal" heading="Logs" canCloseDetailsPopup={canCloseDetailsPopup}>
            <Tile>
                <CodeSnippet hideCopyButton={true} minCollapsedNumberOfRows={20} minExpandedNumberOfRows={20} maxCollapsedNumberOfRows={20} maxExpandedNumberOfRows={20} aria-label="Copy" copyButtonDescription="Copy" className="copy-clip" type="multi" feedback="Copied" copyText="Copy">
                    {errorMessage !== "" ? errorMessage : errorLogs}
                </CodeSnippet>
            </Tile>
        </PassiveModal>
    )
}

export default ErrorLog;