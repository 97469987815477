import { Dropdown, ErrorBoundary, FormLabel } from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import { formatDate, formatDateWithYear } from "Common-Modules/ConvertUTCDate";
// import FormValidation from "Common-Modules/formModule/FormValidation";
// import { authorizationValidity } from "Components/Common-Components/Utilities";
// import ErrorFallback from "Components/Common/ErrorFallback";
// import { findApiError } from "Components/Common/ErrorHandling";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// import { getOrgDetails } from "services/serverapi";
import DataUnAvailable from "Common-Modules/DataUnavailable";
import FormValidation from "Common-Modules/Forms/FormValidation";
import CarbonDataTableRBAC from "Components/Common/CarbonDataTableRBAC";
import ErrorFallback from "Components/Common/ErrorFallback";
import { findApiError } from "Components/Common/ErrorHandling";
import { getOrganizationsAPI, getTenantOrganizationsAPI } from "Services/RBACServerApi";
import { v4 as uuidv4 } from "uuid";
import CreateOrganization from "./CreateOrganization";
import DeleteOrModal from "./DeleteOrModal";
import EditOrg from "./EditOrg";
import OrganizationDetails from "./OrganizationDetails";
import addOrg from "./addOrg.json";
import { storeOrgs } from "./store/action/actionTriggers";

const OrganizationList = () => {
  const [createEnable, setCreateEnable] = useState(false);
  const [rowData, setRowData] = useState("");
  const { keycloak } = useKeycloak();
  const [showError, setShowError] = useState("");
  const [canShowDeleteOrgPopUp, setCanShowDeleteOrgPopUp] = useState(false);
  const [deletedRow, setDeletedRow] = useState("");
  const [editBox, showEditBox] = useState(false);
  const [editOrg, setEditOrg] = useState(undefined);
  const dispatch = useDispatch();
  //const UserInformation = useSelector((state) => state.UserInformation);

  const [isRecordsEmpty, setIsRecordsEmpty] = useState(false);
  const [tenants, setTenants] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState();
  const [organizationRow, setOrganizationRow] = useState({});
  const [showOrganizationDetails, setShowOrganizationDetails] = useState(false);
  const [addOrgJSONUpdated, setAddOrgJSONUpdated] = useState([]);

  const headerData = [
    {
      key: "name",
      header: "Organization Name",
    },
    {
      key: "displayName",
      header: "Display Name",
    },
    {
      key: "description",
      header: "Description",
    },
    {
      key: "startDate",
      header: "Starts on",
    },
    {
      key: "endDate",
      header: "Ends on",
    }
  ];

  useEffect(() => {
    setTenants(JSON.parse(sessionStorage.getItem("alltenants")));
    setSelectedTenant(sessionStorage.getItem("tenant"));
    setSelectedTenantinAddOrgJSON(sessionStorage.getItem("tenant"));
    getOrganisationTableData();
  }, []);
  sessionStorage.setItem("selectedTenant", selectedTenant);

  const getOrganisationTableData = async () => {
    const res = await getOrganizationsAPI(keycloak, uuidv4());

    try {
      if (Boolean(res.error) && !Array.isArray(res?.data)) throw new Error(res.message);

      const errCheck = findApiError(res);
      if (errCheck.error && !Array.isArray(res?.data)) {
        throw new Error(errCheck.message);
      }
      // if (res.data.length === 0) {
      //   setIsRecordsEmpty(true);
      // } else {
      //   setIsRecordsEmpty(false);
      let orgData = res.data;
      let orgs = [];
      orgData.forEach((org) => {
        if (org.name !== "dso" && org.name !== "itvsdp") {
          let orgObj = {};
          orgObj.id = org.id;
          orgObj.name = org.name;
          orgObj.displayName = org.attributes?.displayName?.toString();
          orgObj.description = org.attributes?.description?.toString();
          orgObj.startDate = org.attributes?.startDate?.toString();
          orgObj.endDate = org.attributes?.endDate?.toString();
          orgs.push(orgObj);
        }
      });
      setRowData(orgs);
      sessionStorage.setItem("selectedtenantorgs", JSON.stringify(orgs));
      dispatch(storeOrgs(orgs));

      // }
    } catch (error) {
      setShowError("Something went wrong!. Please, Try again after sometime");
    }
  };

  const getTenantOrganisationTableData = async (tenant) => {
    const res = await getTenantOrganizationsAPI(keycloak, uuidv4(), tenant);
    try {
      if (Boolean(res.error) && !Array.isArray(res?.data)) throw new Error(res.message);

      const errCheck = findApiError(res);
      if (errCheck.error && !Array.isArray(res?.data)) {
        throw new Error(errCheck.message);
      }
      // if (res.data.length === 0) {
      //   setIsRecordsEmpty(true);
      // } else {
      //   setIsRecordsEmpty(false);
      let orgData = res.data;
      let orgs = [];
      orgData.forEach((org) => {
        if (org.name !== "dso" && org.name !== "itvsdp") {
          let orgObj = {};
          orgObj.id = org.id;
          orgObj.name = org.name;
          orgObj.displayName = org.attributes?.displayName?.toString();
          orgObj.description = org.attributes?.description?.toString();
          orgObj.startDate = org.attributes?.startDate?.toString();
          orgObj.endDate = org.attributes?.endDate?.toString();
          orgs.push(orgObj);
        }
      });
      setRowData(orgs);
      sessionStorage.setItem("selectedtenantorgs", JSON.stringify(orgs));
      dispatch(storeOrgs(orgs));
      // }
    } catch (error) {
      setShowError("Something went wrong!. Please, Try again after sometime");
    }
  };

  const createOrgFunc = () => {
    setCreateEnable(true);
  };

  const onRefresh = () => {
    setRowData([]);
    // getOrganisationTableData();
    getTenantOrganisationTableData(selectedTenant);
  };

  const handleClose = (reload) => {
    setDeletedRow("");
    setCanShowDeleteOrgPopUp(false);
    setCreateEnable(false);
    if (reload) {
      onRefresh();
    }
  };

  const getTableHeader = (header) => {
    if (!["createdBy", "updatedBy"].includes(header)) {
      return header;
    }
  };

  const getOrganizationRow = (details, detailsId) => {
    let detailRow = {};
    details.forEach((row) => {
      if (row.id === detailsId) {
        detailRow = row;
        return;
      }
    });
    return detailRow;
  };

  const onClickNode = (id) => {
    let row = getOrganizationRow(rowData, id);
    setOrganizationRow(row);
    setSelectedOrganizationinJSON(row);
    setShowOrganizationDetails(true);
  };
  sessionStorage.setItem("selectedOrg", organizationRow.name);

  const getRowCellData = (id, data, row) => {
    // if (id.includes(":name") || id.includes(":tenant")) {
    //   return <>{data}</>;
    // }
    if (id.includes(":startDate")) {
      return FormValidation.isDateValid(data) ? <>{formatDate(data)}</> : "-";
    }
    if (id.includes(":endDate")) {
      return FormValidation.isDateValid(data) ? (
        <>{formatDateWithYear(data)}</>
      ) : (
        "-"
      );
    }
    if (id.includes(":name")) {
      return (
        <a
          className="event-status"
          onClick={() => {
            onClickNode(row.id);
          }}
        >
          {data}
        </a>
      );
    }
    return data;
  };

  // const onEdit = (selected) => {
  //   let data = "";
  //   rowData.forEach((row) => {
  //     if (row.id === selected.id) data = row;
  //   });
  //   setEditOrg(data);
  //   showEditBox(true);
  // };

  // const getActions = (row, rows) => {
  //   return (
  //     <div className="flex-display">
  //       <li className="actions-icon ">
  //         <Tooltip align="bottom" description="Edit">
  //           <button type="button" className="tooltip-button">
  //             <Edit size={16} fill="#525252" onClick={() => onEdit(row)} />
  //           </button>
  //         </Tooltip>
  //       </li>
  //       <li className="actions-icon ">
  //         <Tooltip align="bottom" description="Delete">
  //           <button type="button" className="tooltip-button">
  //             <TrashCan
  //               size={16}
  //               fill="#525252"
  //               onClick={() => deleteOrg(row, rows)}
  //             />
  //           </button>
  //         </Tooltip>
  //       </li>
  //     </div>
  //   );
  // };

  const deleteOrg = (row, rows) => {
    let selectedRow;
    rowData.forEach((data) => {
      if (row.id === data.id) {
        selectedRow = data;
      }
    });
    setDeletedRow(selectedRow);
    setCanShowDeleteOrgPopUp(true);
  };

  const setSelectedTenantinAddOrgJSON = (tenant) => {
    addOrg.forEach((formField) => {
      if (formField.id === "tenant") {
        formField.inputValue = tenant;
      }
    })
    console.log("addOrg: ", addOrg);
  }

  const onDropdownChange = (event) => {
    setSelectedTenant(event.selectedItem.text);
    setSelectedTenantinAddOrgJSON(event.selectedItem.text);
    getTenantOrganisationTableData(event.selectedItem.text);
  }

  const getDropdownList = (data) => {
    return data.map((dataObject) => ({
      id: dataObject.id,
      text: dataObject.name,
    }));
  }

  // const initialSelectedItem = () => {
  //   let selectedTenant = sessionStorage.getItem("tenant");
  //   let initialSelectedItemObj = {};
  //   tenants.forEach((tenant) => {
  //     if (tenant.text === selectedTenant) {
  //       initialSelectedItemObj.id = tenant.id;
  //       initialSelectedItemObj.text = tenant.name;
  //     }
  //   })
  //   return initialSelectedItemObj;
  // }

  const onCloseOrganizationDetails = () => {
    setShowOrganizationDetails(false)
  };

  const setSelectedOrganizationinJSON = (tenant) => {
    let addOrgJSONUpdatedTemp = JSON.parse(JSON.stringify(addOrg));
    addOrgJSONUpdatedTemp.forEach((formField) => {
      if (formField.id === "name") {
        formField.inputValue = tenant.name;
        formField.readOnly = true;
      }
      if (formField.id === "displayName") {
        formField.inputValue = tenant.displayName;
        formField.readOnly = true;
      }
      if (formField.id === "description") {
        formField.inputValue = tenant.description;
        formField.readOnly = true;
      }
      if (formField.id === "startDate") {
        formField.inputValue = tenant.startDate;
        formField.readOnly = true;
      }
      if (formField.id === "endDate") {
        formField.inputValue = tenant.endDate;
        formField.readOnly = true;
      }
    })
    setAddOrgJSONUpdated(addOrgJSONUpdatedTemp);
  }

  return (
    showOrganizationDetails ? <OrganizationDetails tenant={selectedTenant} addOrganization={addOrgJSONUpdated} organizationRow={organizationRow} closeOrganizationDetails={onCloseOrganizationDetails} /> :
      <>
        <Dropdown
          size="md"
          // label="Select Tenant"
          label={selectedTenant}
          itemToString={(item) => (item ? item.text : "")}
          items={getDropdownList(tenants)}
          onChange={(event) => {
            onDropdownChange(event);
          }}
          titleText="Tenants"
        // selectedItem={selectedTenant}
        // initialSelectedItem={initialSelectedItem()}
        />
        {!showError ? (
          <>
            <ErrorBoundary fallback={<ErrorFallback />}>
              <FormLabel className="form-lable--default">Orgs</FormLabel>
              <CarbonDataTableRBAC
                rowData={rowData}
                headerData={headerData}
                title="Organizations"
                createTitle="Create Organization"
                getRowCellData={getRowCellData}
                getTableHeader={getTableHeader}
                // disableCreate={
                //   // !authorizationValidity(
                //   //   "org::create",
                //   //   UserInformation.userCurrentPermissions,
                //   // )
                //   !keycloak.realmAccess.roles.includes("Dashboard_Admin")
                // }
                deleteRow={deleteOrg}
                createFunction={createOrgFunc}
                onRefresh={onRefresh}
                actionsNeeded={false}
              // table_particular_actions={true}
              // getActions={getActions}
              />
            </ErrorBoundary>
          </>
        ) : (
          // <ErrorFallback />
          <DataUnAvailable />
        )}
        {createEnable && <CreateOrganization closeCreateOrg={handleClose} selectedTenant={selectedTenant} addOrg={addOrg} />}
        {canShowDeleteOrgPopUp ? (
          <DeleteOrModal org={deletedRow} cancel={handleClose} />
        ) : null}
        {editBox ? (
          <EditOrg
            org={editOrg}
            cancel={(refresh) => {
              setEditOrg("");
              showEditBox(false);
              if (refresh) onRefresh();
            }}
          />
        ) : null}
      </>
  );
};

export default OrganizationList;
