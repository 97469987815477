import { ComboBox } from "@carbon/react";
import PowerBi from "Components/PowerBI/PowerBI";
import React, { useState, useEffect } from "react";

function OperationInsights() {
  const tenant = sessionStorage.getItem("tenant");
  // const [powerBiTitle, setPoweBiTitle] = useState("Operations Insights")
  const defaultTitle = tenant === "Pfizer" ? "Overview - All Ticket Types" : "Operations Insights";
  const [powerBiTitle, setPoweBiTitle] = useState(defaultTitle);
  const [operationsReportId, setOperationsReportId] = useState("");
  const [incidentAnalyticsReportId, setIncidentAnalyticsReportId] = useState("");

  useEffect(() => {
    if (tenant === "Pfizer-Orals") {
      setOperationsReportId("15b476ae-8866-4d67-8df6-0670fc397a65");
    } else {
      setOperationsReportId("a6261dfc-197e-4234-822a-71b9170ceee0");
    }
  }, []);

  useEffect(() => {
    if (tenant === "Pfizer-Orals") {
      setIncidentAnalyticsReportId("f52fb730-cfa2-4e86-892b-44d6703b8368");
    } else {
      setIncidentAnalyticsReportId("905d9f94-8f5b-4b6b-b660-91915183b0d2");
    }
  }, []);

  const getComboBox = () => {
    const items = tenant === "Pfizer" ? 
        ["Overview - All Ticket Types", "Incident Ticket Analytics", "Service Ticket Analytics", "Problem Ticket Analytics", "Change Ticket Analytics"] :
        ["Operations Insights", "Inference based Incident Analytics", "SLM Exception Handling"].sort((a, b) => {
            let fa = a.toLowerCase(),
                fb = b.toLowerCase();
            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        });
    return <ComboBox
    id="ComboBox1"
    items={items}
    itemToString={(item) =>
        item ? item  : ""
    }
    placeholder={powerBiTitle}
    titleText=""
    size="sm"
    defaultValue="Operations Insights"
    onChange={(e) => getSelectedFlow(e)}
  />
}

const getSelectedFlow = (e) => {

  if(e.selectedItem !== null) {
      setPoweBiTitle(e.selectedItem)
  } else {
    if (tenant === "Pfizer") {
      setPoweBiTitle("Overview - All Ticket Types");
  } else {
      setPoweBiTitle("Operations Insights");
  }
  }
}

  return (
    <>
      <div style={{ float: "right", width: "20%", marginBottom: "15px" }}>{getComboBox()}</div>
      {powerBiTitle === "Operations Insights" && <PowerBi reportId={operationsReportId} />}
      {powerBiTitle === "Inference based Incident Analytics" && <PowerBi reportId={incidentAnalyticsReportId}/>}
      {powerBiTitle === "SLM Exception Handling" && <PowerBi reportId="83807eab-18c5-4dca-92fe-bd13848e7176" />}
      {/* Pfizer tenant reports */}
      {powerBiTitle === "Incident Ticket Analytics" && <PowerBi reportId="94a72178-5b77-46d0-8228-06ea9b458305" />}
      {powerBiTitle === "Service Ticket Analytics" && <PowerBi reportId="e61db569-d1d2-4a62-b3aa-3962e53eef49"/>}
      {powerBiTitle === "Problem Ticket Analytics" && <PowerBi reportId="975b6580-000e-4f3c-b864-c3b1623d06ea" />}
      {powerBiTitle === "Change Ticket Analytics" && <PowerBi reportId="d89b1a5e-8815-4665-af6e-db920eaa0d18" />}
      {powerBiTitle === "Overview - All Ticket Types" && <PowerBi reportId="219c33c7-f95a-4a5d-a4c1-9396c51aa4cb" />}
    </>
  );
}

export default OperationInsights;
