import { useKeycloak } from "@react-keycloak/web";
import React, { useEffect, useReducer, useState } from "react";
import { useTranslation } from 'react-i18next';
// import { useSelector } from "react-redux";
import { Button, InlineLoading } from "@carbon/react";
import { getToolFormFields } from "Services/ServerApi";
import { v4 as uuidv4 } from "uuid";
// import { Token, Secret } from "fernet";
import DefaultModal from "Carbon-Components/Modals/CommonModal";
import PassiveModal from "Carbon-Components/Modals/PassiveModal";
import DynamicFormClass from "Common-Modules/Forms/DynamicFormClass";
import InlineLoader from "Common-Modules/loaderModule/InlineLoader";
import axios from "axios";

const ACTION_TYPES = {
  YET_TO_START: 'yetToStart',
  IN_PROGRESS: 'inProgress',
  SUCCESS: 'success',
  FAILED: 'failed'
};

function statusReducer(state, action) {
  switch (action.type) {
    case ACTION_TYPES.YET_TO_START:
    case ACTION_TYPES.IN_PROGRESS:
    case ACTION_TYPES.SUCCESS:
    case ACTION_TYPES.FAILED:
      return action.type;
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
}

const ToolFormFields = ({ tool, close }) => {
  
  const initialState = ACTION_TYPES.YET_TO_START;

  const [isLoading, setIsLoading] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [fields, setFields] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [testStatus, dispatchtestStatus] = useReducer(statusReducer, initialState)
  const [url, setUrl] = useState("")
  const [validUrl, setValidUrl] = useState(false)
  const { t } = useTranslation();

  // const secretK = useSelector((state) => state.SecretKey.secretKey);

  const { keycloak } = useKeycloak();

  useEffect(() => {
    setIsLoading(true);
    setErrMsg("");
    setSuccessMsg("");
    getFields();
  }, []);

  const testUrlReach = async (keycloak, uuid) => {
    const BFF_URL = sessionStorage.getItem("bff");
    dispatchtestStatus({
      type: ACTION_TYPES.IN_PROGRESS
    })
    const config = {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        "X-Tenant": keycloak.realm,
        "logged-in": keycloak.tokenParsed.email,
        uuid: uuid,
      },
    };
    const body = {
      url
    }
    try {
      const result = await axios.post(`${BFF_URL}/checkUrl`, body, config)
      // console.log("result ", result);
      if(result.data.reachable === true){
        dispatchtestStatus({
          type: ACTION_TYPES.SUCCESS
        })
      }
      else{
        throw new Error("")
      }
    } catch (error) {
      dispatchtestStatus({
        type: ACTION_TYPES.FAILED
      })
    }
  }

  // const handleDetailsPage = (data) => {
  //   // To prefill url in details page
  //   const fields = data.map((field) => {
  //     if (field.label === "url") {
  //       field.inputValue = tool.tool_url || "";
  //     }
  //     return field;
  //   });
  //   setFields(fields);
  // }

  const getFields = async () => {
    const res = await getToolFormFields(
      `${tool}`,
      keycloak,
      uuidv4(),
    );
    try {
      // console.log("res ====> ", res);
      const data = res.data[0].trans_formfield_toolchain !== undefined ? res.data[0].trans_formfield_toolchain : res.data[0].formfield_toolchain ;

      if (!Array.isArray(data) || data.length === 0) {
        throw new Error("");
      }

      setFields(data);
      // if (!manageUrls.includes(window.location.hash)) {
      //   handleCreatePage(data)
      // } 
      
      // else {
      //   handleDetailsPage(data)
      // }

    } catch (error) {
      // console.log("error ===> ", error);
      setErrMsg("Failed to fetch form fields.");
    } finally {
      setIsLoading(false);
    }
  };

  // const onSubmit = async () => {
  //   setSuccessMsg("");
  //   setErrMsg("");

  //   let secret = new Secret(secretK);
  //   let tokens = new Token({
  //     secret: secret,
  //   });

  //   let userInputs = {};
  //   let toolSubmitted = {};
  //   let name = ""

  //   fields.forEach((field) => {
  //     if (field.label === "token" || field.label === "password") {
  //       userInputs[field.label] = tokens.encode(field.inputValue);
  //     } else {
  //       if(field.label === "name"){
  //         name = field.inputValue
  //       }
  //       userInputs[field.label] = field.inputValue;
  //       toolSubmitted[field.label] = field.inputValue;
  //     }
  //   });

  //   let data;
  //   if (!manageUrls.includes(window.location.hash)) {
  //     data = {
  //       toolchainAttributeId: tool.id,
  //       status: {
  //         // provisionStatus: tool.provisionStatus,
  //         connectionId: 1,
  //         // provisionMsg: tool.provision_msg,
  //       },
  //       name,
  //       credential: {
  //         name: tool.name,
  //         toolId: tool.toolIdref,
  //         credentialJson: {
  //           ...userInputs,
  //         },
  //         prefix: "tool",
  //         orgId: 1,
  //       },
  //     };
  //   } else {
  //     data = {
  //       toolchainAttributeId: tool.toolchain_attribute_idref,
  //       status: {
  //         provisionStatus: "49",
  //         connectionId: 1,
  //         provisionMsg: "",
  //       },
  //       name,
  //       credential: {
  //         name: tool.name,
  //         toolId: tool.toolIdref,
  //         credentialJson: {
  //           ...userInputs,
  //         },
  //         prefix: "tool",
  //         orgId: 1,
  //       },
  //     };
  //   }
  //   setIsLoading(true);
  //   const res = await submitToolConfig(keycloak, data, uuidv4());
  //   try {
  //     if (res.err) throw new Error();
  //     if (res.credential_id) {
  //       setSuccessMsg("Tool configuration submitted successfully");
  //       if (!manageUrls.includes(window.location.hash)) {
  //         setToolSubmitted((prevState) => ({
  //           ...prevState,
  //           [tool.name + tool.id]: toolSubmitted,
  //         }));
  //       }
  //     } else throw new Error();
  //   } catch {
  //     setErrMsg("Failed to update data");
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const getStatus = () => {
    switch (testStatus) {
        case ACTION_TYPES.YET_TO_START:
          return 'inactive';
        case ACTION_TYPES.IN_PROGRESS:
          return 'active';
        case ACTION_TYPES.SUCCESS:
          return 'finished';
        case ACTION_TYPES.FAILED:
          return 'error';
        default:
         return "inactive"
      }
  }

  const handleFormChangeEdit = (event) => {
    const eventID = event.target.id;
    const eventValue = event.target.value;
    setFields((prevState) =>
      prevState.map((Formfield) => {
        // console.log("executed ====>");
        const formData = Formfield;
        if (Formfield.label === eventID) {
          formData.inputValue = eventValue;
        }
        try {
          if (Formfield.label === "url") {
            // console.log("executed ====>");
            setUrl(eventValue)
            dispatchtestStatus({
              type: ACTION_TYPES.YET_TO_START
            })
            if(!eventValue || eventValue.length < Formfield.minLength || eventValue.length > Formfield.maxLength || eventValue.charAt(eventValue.length - 1) === "/") setValidUrl(false)
            else setValidUrl(new RegExp(Formfield.regex).test(eventValue))
          }
        } catch (error) {
          console.log(error);
          setValidUrl(false)
        }
        return formData;
      }),
    );
  };

  const handleFormValidity = (valid) => {
    setIsFormValid(valid);
  };

  if (isLoading || errMsg || successMsg) {
    return (
      <PassiveModal
        canCloseDetailsPopup={() => close()}
      >
        {isLoading && (
          <InlineLoading
            status="active"
            iconDescription="Loading"
            description={t(`InstrumentationHub.instrumentationCommon.loaderText`)}
          />
        )}
        {errMsg && <h4>{errMsg}</h4>}
        {successMsg && <h4>{successMsg}</h4>}
      </PassiveModal>
    );
  }

  return (
    <DefaultModal
      // heading={t(`Edit ${tool} Configuration`)}
      heading={`${t(`InstrumentationHub.dataCatalogue.confFormCommons.formTitleEdit`)} ${tool} ${t(`InstrumentationHub.dataCatalogue.confFormCommons.formTitleConf`)}`}
      onCloseModal={() => close()}
      onModalSubmit={() => close()}
      // onModalSubmit={() => onSubmit()}
      primaryButtonDisabled={!isFormValid || !(testStatus === ACTION_TYPES.SUCCESS)}
      primaryText={t(`InstrumentationHub.dataCatalogue.confFormCommons.submit`)}
      secondaryText={t(`InstrumentationHub.dataCatalogue.confFormCommons.cancel`)}
    >
      <div style={{ maxWidth: "400px" }}>
        <div onChange={(event) => handleFormChangeEdit(event)}>
          <DynamicFormClass
            formData={fields}
            getFormValidity={handleFormValidity}
            type="Tool"
          />
        </div>
      </div>
      <div className="flex-display flex-end">
        <div>
        <Button
            disabled={!validUrl}
            onClick={() => testUrlReach(keycloak, uuidv4())}
            kind="tertiary"
            className="create--button test--connection--button"
        >
            {t(`InstrumentationHub.dataCatalogue.confFormCommons.testConn`)}
            <div>
                {testStatus !== ACTION_TYPES.YET_TO_START ? <InlineLoader
                    description=""
                    status={getStatus()}
                /> : null}
            </div>
        </Button>
        {
          testStatus === ACTION_TYPES.FAILED ? <p className="error mt-1">Url is not reachable</p>
          : null
        }
        </div>
      </div>
    </DefaultModal>
  );
};

export default ToolFormFields;
