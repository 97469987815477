import TraceDetails from "Components/ICPSA/AppPerformanceAvailabililty/TraceDetails";
import React, { useEffect, useState } from "react";
import { ErrorBoundary } from "@carbon/react";
import TroubleView from "./troubleshoot.json";


const TroubleshootView = (props) => {
    const [incidentData, setIncidentData] = useState([])

    useEffect(() =>{ 
        if(props.location.state !== undefined) {
            setIncidentData(props.location.state)
        }
    }, [props])

    return (
        <>
            <ErrorBoundary fallback={""}>
                <TraceDetails
                    traceObj={TroubleView.data.trace_summary[0]}
                    traceResponse={TroubleView.data}
                    tableData={props.location.state}
                    flow="DO"
                />
            </ErrorBoundary>
        </>
    )
}

export default TroubleshootView