import React, { useEffect, useState } from "react";
import ProcessJson from "./Process.json";
import { FlexGrid, Row, Column, Tile, Breadcrumb, BreadcrumbItem, Toggle, SkeletonText, Tag, ClickableTile } from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import { QueryCache, useQuery } from "@tanstack/react-query";
import Process from "./ProcessSteps";
import { getElasticAnyCollection } from "Services/ServerApi";
import { ComboChart, AlluvialChart } from "@carbon/charts-react";
import ProcessSvg from "./PmProcessSvg";
import PassiveModal from "Carbon-Components/Modals/PassiveModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const CpgPlusProcess = (props) => {  
    const history = useHistory();
    const { keycloak } = useKeycloak();
    const queryCache = new QueryCache();
    const [domainDetails, setDomainDetails] = useState({});
    const [selectedDomain, setSelectedDomain] = useState({});
    const [toggleLab, setToggleLab] = useState(false);
    const [processJson, setProcessJson] = useState(ProcessJson);
    const [businessProcess, setBusinessProcess] = useState("Add Minutes to Account");
    const [activityDetails, setActivityDetails] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [svgProcess, setSvgProcess] = useState(false);

    const { data: processData, refetch } = useQuery(['process_activity'], async () => await getElasticAnyCollection(keycloak, "process_activity", "name", selectedDomain.projectName, "cpg_plus"), { enabled: false });

    const { data: processDeviation, refetch: devationRefresh, isFetching } = useQuery(['processDeviation'], async () => await getElasticAnyCollection(keycloak, "process_deviation", "name", selectedDomain.projectName, "cpg_plus"), { enabled: false });

    useEffect(() => {
        // console.log("processData.data ================================>>>>", processData.data.name)
        // console.log("selectedDomain ================================>>>>", selectedDomain.projectName);
        if(processData !== undefined && processData.data !== undefined) {
            const process = processData.data.filter((fil) => fil.name === selectedDomain.projectName);
            setDomainDetails(process)
        }
    }, [processData, selectedDomain])

    useEffect(() => {
        let activity = [], deviation = [];        
        domainDetails.length > 0 && processDeviation !== undefined && processDeviation.data !== undefined && domainDetails.forEach((pro) => {
            console.log("businessProcess", businessProcess, processDeviation.data)
            const devi = processDeviation.data[0].data.filter((fil) => fil.activityName !== undefined && fil.activityName === businessProcess)
            deviation = devi[0];
            pro.activity !== undefined && pro.activity.model.nodes.forEach((nde) => {
                if(nde.activityName === businessProcess) {
                    activity.push(nde)
                }
            })
        })

        console.log("activity", activity);
        console.log("deviation", deviation);
        
        activity = activity.map((act) => {
            return {
                ...act,
                deviation:deviation
            }
        })
        
        console.log("useEffcet",activity);
        
        setActivityDetails(activity)

    }, [businessProcess, domainDetails, processDeviation])

    // useEffect(() => {
    //     if(processDeviation !== undefined && processDeviation.data !== undefined) {
    //         const devi = processDeviation.data.process_deviation[0].data.filter((fil) => fil.activityName !== undefined && fil.activityName === businessProcess)
    //        // const deviation = processDeviation.data.process_deviation.filter((fil) => fil.name === selectedDomain.projectName);
    //         const deviation = devi[0];
    //         setActivityDetails(prevItems => prevItems.map((prev) => { return {
    //             ...prev, deviation
    //         }}))
    //        // setDomainDetails(deviation)
    //     }
    // }, [processDeviation, businessProcess])
    
    useEffect(() => {
        if(props.location.state !== undefined) {
            setSelectedDomain(props.location.state.data)
        }
    }, [props.state])

    useEffect(() => {
        if(selectedDomain !== undefined && Object.entries(selectedDomain).length > 0) {
            refetch()
            devationRefresh()
        }
    }, [selectedDomain])

    useEffect(() => {
        setRefresh(toggleLab)
        if(toggleLab) {
            setTimeout(() => {
                setRefresh(false)
            }, 300)
        }
    }, [toggleLab])

    const getActivityChart = () => {
        let data = [], frequen = [];

        domainDetails.length > 0 && domainDetails.forEach((pro) => {
            pro.activity.model.nodes.forEach((nde) => {
                const actv = {
                    group: "Activity",
                    date: nde.activityName,
                    value: Math.round(nde.statistics.activity_duration / 60)
                };
                const freq = {
                    group: 'Frequency',
                    date: nde.activityName,
                    temp: Math.round(nde.statistics.frequency / 60)
                };
                data.push(actv)
                frequen.push(freq)
            })
        })

        const mergeObj = [...data, ...frequen];

        const idsToRemove = ["START", "STOP"]

        const rmSt = mergeObj.filter(item => !idsToRemove.includes(item.date));

        const options = {
            title: 'Activity Duration',
            axes: {
              top: {
                mapsTo: 'value',
                scaleType: 'linear',
                title: ''
              },
              bottom: {
                mapsTo: 'temp',
                scaleType: 'linear',
                title: 'Frequency',
                correspondingDatasets: [
                  'Frequency'
                ]
              },
              left: {
                title: 'Process Activities',
                mapsTo: 'date',
                scaleType: 'labels'
              }
            },
            comboChartTypes: [
              {
                type: 'simple-bar',
                options: {
                },
                correspondingDatasets: [
                  'Activity'
                ]
              },
              {
                type: 'line',
                options: {
                },
                correspondingDatasets: [
                  'Frequency'
                ]
              }
            ],
            height: '350px'
          }

        return <ComboChart
        data={rmSt}
        options={options}
      ></ ComboChart>
    }

    const getAlluvialChart = () => {

        const anon = [{
            name: "APPLICANT",
            category: "Roles"
        }, {
            name: "BACK-OFFICE",
            category: "Roles"
        }, {
            name: "DIRECTOR",
            category: "Roles"
        }];
        let data = [], actnam = [];


        console.log("kpi ===========================", domainDetails)

        domainDetails.length > 0 && domainDetails.forEach((pro) => {
            pro.activity.model.nodes.forEach((nde) => {
                if(!["START", "STOP"].includes(nde.activityName)) {
                const actv = {
                    name: nde.activityName,
                    role: nde.roles[0],
                };
                data.push(actv)
                actnam.push({
                    name: nde.activityName,
                    category: "Activities"
                })
            }
            })
        })

        const mergeObj = [...anon, ...actnam]

        console.log("mergeObj ====================", mergeObj)

        let finalOp = [];
        anon.forEach((non) => {
            let count = 1;
            data.forEach((act, index) => {
                if(non.name === act.role) {
                    finalOp.push({		
                        "source": non.name,
                        "target": act.name,
                        "value": count
                    })
                }
            })
        })



        let color = {}
        finalOp.forEach((ops) => {
            if(ops.target === businessProcess && toggleLab) {
                color[ops["source"]] = "#edf5ff";
                color[ops.target] = "#009d9a";
            } else if(toggleLab) {
                color[ops.target] = '#edf5ff';
                color[ops["source"]] = "#edf5ff";
            }
        })
        const options = {
            "title": "Resource Mapping",
            "alluvial": {
                "nodes": mergeObj
            },
            "height": "400px",
            "color": {
                "scale": Object.entries(color).length > 0 ? color : {
                    "APPLICANT": "#da1e28",
                    "BACK-OFFICE": "#b28600",
                    "DIRECTOR": "#198038",
                    "Add Minutes to Account": "#ee538b",
                    "Manual Override": "#08bdba",
                    "Network Adjustment Requested": "#1192e8",
                    "Update Billing": "#a56eff",
                    "Validate Customer": "#009d9a",
                    "Validate Account": "#fa4d56",
                    "Account Adjustment Requested": "#198038"
                },
                "gradient": {
                    "enabled": true
                }
            }
        }
        console.log("finalOp ===================, ", finalOp, options)

        return !refresh ? <AlluvialChart
        data={finalOp}
        options={options}
      ></ AlluvialChart> : <SkeletonText />
    }

    const onClickToggle = (e) => {
        setToggleLab(e)
    }

    const onClickShowTopology = (bussProc) => {

        setRefresh(true)
        if(bussProc === "Add Minutes") {
            setToggleLab(false)
        } else {
            setToggleLab(true);
        }

        if(toggleLab) {
            setTimeout(() => {
                setRefresh(false)
            }, 300)
        }

        console.log("bussProc",bussProc)
        setBusinessProcess(bussProc.activityName);
    }

    const onClickShowProcess = () => {
        setToggleLab(false);
        setRefresh(true)
        if(toggleLab) {
            setTimeout(() => {
                setRefresh(false)
            }, 300)
        }
    }

    const redirectTo = (e) => {
        e.stopPropagation();
        history.push({
            pathname: "/ITOperations",
        })
    }

    const showSvgProcess = () => {
        setSvgProcess(true)
    }

    const getExecution = () => {
        const exec = domainDetails.length > 0 && domainDetails[0].execution.map((exe) => {
            return <li>
                <h5 onClick={(e) => {redirectTo(e)}} style={{ cursor: 'pointer' }}>
                    
                       <a> {exe.label} </a>
                    
                    
                </h5>
                <span>{exe.avgDuration}</span>
            </li>
        })
        return <ul className="exe-dom"><li><h4>Application</h4><h4>Average Duration</h4></li>{exec}</ul>
    }

    const getTechnology = () => {
        const exec = domainDetails.length > 0 && domainDetails[0].execution.map((exe) => {
            return <li>
                <h6 onClick={(e) => {redirectTo(e)}} style={{ cursor: 'pointer' }}>                   
                        {exe.label}                                         
                </h6>
            </li>
        })
        return <ul>{exec}</ul>
    }
    

    console.log("isFetching =======",domainDetails)

    return (
        <FlexGrid>
            <Row>
                <Column lg={5} md={4} sm={4}>
                    <Breadcrumb noTrailingSlash>
                        <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
                        <BreadcrumbItem href="#/valueStream">Value Stream</BreadcrumbItem>
                        <BreadcrumbItem href="#/cpgPlusSubDomains">Sub Domains</BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage>
                            Domain Details
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Column>
                <Column lg={9} md={6} sm={2}>
                    {/* {toggleLab && <Tag className="teal-heading" type="teal">
                        Activity Name: {businessProcess}
                    </Tag>} */}
                </Column>
                <Column lg={2} md={2} sm={2}>
                    <Toggle onToggle={(e) => onClickToggle(e)} defaultToggled={toggleLab} toggled={toggleLab} size="sm" labelText="" labelA="Activity" labelB="Activity" id="toggle-1" />
                </Column>
            </Row>
            <Row>
                <Column lg={4} md={2} sm={2}>
                    <ClickableTile className="title-til">
                        <h5>{selectedDomain.projectTitle}</h5>
                        <div className="box-view">
                        <div className="view-ml">View Model</div>
                        <div className="appli">      <Tag className="some-class" type="purple">
                        Application: {domainDetails.length > 0 && domainDetails[0].execution.length}
                        </Tag></div>
                        </div>
                        <ul>
                            <li>Process: <span>1</span></li>
                            <li>Activities: <span>{domainDetails.length > 0 ? domainDetails[0].activity.model.nodes.length - 2 : null}</span></li>
                        </ul>
                    </ClickableTile>
                    <Process 
                        enableTopology={(e) => onClickShowProcess(e)}
                        secondaryLabel={(e) => onClickShowTopology(e)}
                        domainDetails={domainDetails}
                        businessProcess={businessProcess}
                        activityDetails={processDeviation}
                        toggleLab={toggleLab}
                    />
                    {domainDetails.length > 0 && domainDetails[0].execution.length > 0 ? <Tile>
                    {getExecution()}
                    </Tile> : null}
                </Column>
                <Column lg={12} md={8} sm={4}>
                {!toggleLab ? <>
                    <Row>
                        <Column lg={16} md={8} sm={4}>
                        <Tile className="til-bx green">
                        <h5>People</h5>
                        <ul>
                            {domainDetails.length > 0 ? domainDetails.map((pro) => {
                                return <>
                                    <li><span>Customer Count</span>{pro.performance.caseCount}</li>
                                    <li><span>Arrival Rate</span>{Math.floor(pro.activity.processAnalysis.avgArrivalRate)}<span className="child">cases/day</span></li>
                                </>
                            }) : <SkeletonText /> }
                        </ul>
                    </Tile>
                    <Tile className="til-bx con green">
                        <h5>Process</h5>
                        <ul>
                            {domainDetails.length > 0 ? domainDetails.map((pro) => {
                                return <>
                                    <li><span>Similarity</span>{pro.conformance.similarity}<span className="child">%</span></li>
                                    <li><span>Average fitness</span>{pro.conformance.averageFitness}<span className="child">%</span></li>
                                    <li><span>Minimum fitness</span>{pro.conformance.minimumFitness}<span className="child">%</span></li>
                                    <li><span>Maximum fitness</span>{pro.conformance.maximumFitness}<span className="child">%</span></li>
                                </>
                            }) : <SkeletonText /> }
                        </ul>
                    </Tile>
                    <Tile className="til-bx tech red">
                        <h5>Technology</h5>
                        <ul>
                            {/* {domainDetails.length > 0 ? domainDetails.map((pro) => {
                                return <>
                                    <li><span>Average</span>{pro.performance.averageCaseLeadTime}<span className="child"></span></li>
                                    <li><span>Median</span>{pro.performance.medianCaseLeadTime}<span className="child"></span></li>
                                    <li><span>Minimum</span>{pro.performance.minimumCaseLeadTime}<span className="child"></span></li>
                                    <li><span>Maximum</span>{pro.performance.maximumCaseLeadTime}<span className="child"></span></li>
                                </>
                            }) : <SkeletonText /> } */}
                            {getTechnology()}
                        </ul>
                    </Tile>
                    </Column>
                    </Row>
                    <Row>
                        <Column lg={16} md={8} sm={4}>
                            <Tile>
                            {getActivityChart()}
                            </Tile>
                        </Column>
                    </Row>
                </> : <>
                <Row>
                    <Column lg={16} md={8} sm={4}>
                        <Tile className="til-bx green">
                        <h5>People</h5>
                        <ul>
                            {activityDetails.map((pro) => {
                                return pro.statistics && <>
                                    <li><span>Average</span>{Math.floor(pro.statistics.avgRepetition)}</li>
                                    <li><span>Case</span>{pro.statistics.caseRepetition}<span className="child">cases/day</span></li>
                                </>
                            })}
                        </ul>
                    </Tile>
                    <Tile className="til-bx con green">
                        <h5>Process</h5>
                        <ul>
                            {activityDetails.map((pro) => {
                                return pro.statistics && <>
                                    <li><span>Average</span>{pro.statistics.avgDuration}<span className="child"></span></li>
                                    <li><span>Median</span>{pro.statistics.medianDuration}<span className="child"></span></li>
                                    <li><span>Minimum</span>{pro.statistics.minDuration}<span className="child"></span></li>
                                    <li><span>Maximum</span>{pro.statistics.maxDuration}<span className="child"></span></li>
                                </>
                            })}
                        </ul>
                    </Tile>
                    <Tile className="til-bx tech red">
                        <h5>Technology</h5>
                        <ul>
                            {/* {activityDetails.map((pro) => {
                                return pro.deviation ? <>
                                    <li><span>Case Count</span>{pro.deviation.caseCount}<span className="child"></span></li>
                                    <li><span>Case Ratio</span>{Math.floor(pro.deviation.caseRatio)}<span className="child"></span></li>
                                    <li><span>Steps</span>{Math.floor(pro.deviation.steps)}<span className="child"></span></li>
                                    <li><span>Throughput</span>{pro.deviation.throughput}<span className="child"></span></li>
                                </> : <h6 style={{padding:"20px"}}>No Deviation found</h6>
                            })} */}
                            {getTechnology()}
                        </ul>
                    </Tile>
                    </Column>
                    </Row>
                </>}
                    <Row>
                        <Column lg={16} md={8} sm={4}>
                          <Tile>
                            {!toggleLab ? getAlluvialChart() : getAlluvialChart()}
                            </Tile>
                        </Column>
                    </Row>
                </Column>
            </Row>
            {svgProcess ?  <PassiveModal size="lg" canCloseDetailsPopup={() => setSvgProcess(false)} className="process-svg" >
                <ProcessSvg selectedDomain={selectedDomain} />
            </PassiveModal>  : null }
        </FlexGrid>
    )
}

export default CpgPlusProcess;