import {
  Breadcrumb,
  BreadcrumbItem,
  Column,
  ErrorBoundary,
  FlexGrid,
  Modal,
  Row,
  StructuredListBody,
  StructuredListCell,
  StructuredListHead,
  StructuredListRow,
  StructuredListWrapper,
  Tile,
} from "@carbon/react";
import React, { useContext, useState } from "react";
import TreeDiagram from "./TreeDiagram";
import "./fins.scss";
import ChartContainer from "Carbon-Charts/ChartContainer";
import CommonMLModal from "Carbon-Components/Modals/CommonModal";
import { Catalog } from "@carbon/react/icons";
import Context from "Context/Context";
import { v4 as uuidv4 } from "uuid";

const Go2Application = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [recentIncidents, setRecentIncidents] = useState({});
  const [dateIndexChange, onDateIndexChange] = useState(2);
  const [dateChange, onDateChange] = useState("1 Day");
  const [alertLogs, setShowAlert] = useState(false);
  const context = useContext(Context);
  const title = sessionStorage.getItem("portfolioTitle");
  const selectedFlow = context.valueStreamData.data.filter(
    (data) => data.title === title
  );
  const CJstepName = sessionStorage.getItem("CJstepName");
  const selectedCustomerJourney = selectedFlow[0].journeySteps.filter(
    (fil) => fil.name === CJstepName
  );

  const getEvents = () => {
    return selectedCustomerJourney[0].recentIncidents.map((similar) => {
      const newSim = Object.entries(similar).map(([key, value]) => ({
        key,
        value,
      }));
      return (
        <ul className="err-log" key={uuidv4()}>
          {newSim.map((erlog) => {
            return (
              <li key={uuidv4()}>
                <h5>{erlog.key}</h5>
                <span>
                  {erlog.key === "eventId" ? (
                    <a
                      href="https://aiopsri-ibmdso.instana.io/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {erlog.value}
                    </a>
                  ) : (
                    erlog.value
                  )}
                </span>
              </li>
            );
          })}
        </ul>
      );
    });
  };

  const getInstanaInfraMemoryMetrics = () => {
    return {
      label: "",
      dataType: "area",
      data: {
        chartData: selectedCustomerJourney[0].cpuUsed,
        chartOptions: {
          title: "CPU Used",
          axes: {
            left: {
              title: "",
              mapsTo: "value",
              scaleType: "linear",
            },
            bottom: {
              title: "Time",
              mapsTo: "date",
              scaleType: "time",
            },
          },
          height: "250px",
          grid: {
            x: {
              enabled: false,
            },
            y: {
              enabled: false,
            },
          },
          color: {
            scale: {
              "cpu.used": "#004144",
            },
          },
        },
      },
    };
  };

  const getErrorMetrics = () => {

    return {
      label: "",
      dataType: "bar",
      data: {
        chartData: selectedCustomerJourney[0].errors,
        chartOptions: {
          title: "Error",
          axes: {
            left: {
              title: "",
              mapsTo: "value",
              scaleType: "linear",
            },
            bottom: {
              title: "Time",
              mapsTo: "date",
              scaleType: "time",
            },
          },
          height: "250px",
          grid: {
            x: {
              enabled: false,
            },
            y: {
              enabled: false,
            },
          },
          color: {
            scale: {
              "errors.mean.300": "#da1e28",
            },
          },
        },
      },
    };
  };

  const getInstanaInfraLatency = () => {
    return {
      label: "Latency",
      dataType: "line",
      data: {
        chartData: selectedCustomerJourney[0].latencyMean,
        chartOptions: {
          title: "Latency",
          axes: {
            left: {
              title: "",
              mapsTo: "value",
              scaleType: "linear",
            },
            bottom: {
              title: "Time",
              mapsTo: "date",
              scaleType: "time",
            },
          },
          height: "250px",
          grid: {
            x: {
              enabled: false,
            },
            y: {
              enabled: false,
            },
          },
          color: {
            gradient: {
              enabled: true,
            },
          },
        },
      },
    };
  };
  const getAlertLogs = () => {
    return selectedCustomerJourney[0].InfraLogs.map((err) => {
      const newEr = Object.entries(err).map(([key, value]) => ({ key, value }));
      return (
        <ul className="err-log" key={uuidv4()}>
          {newEr.map((erlog) => {
            return (
              <li key={`${erlog.key}-${erlog.value}`}>
                <h5>{erlog.key}</h5>
                <span>{erlog.value}</span>
              </li>
            );
          })}
        </ul>
      );
    });
  };

  return (
    <FlexGrid fullWidth>
      <Row>
        <Column lg={16} md={8} sm={4}>
          <Breadcrumb noTrailingSlash>
            <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
            <BreadcrumbItem href="#/valueStream">
              IT Value Stream Observability
            </BreadcrumbItem>
            <BreadcrumbItem href="#/workFlowJourney">Workflow</BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              {props.location.state.data}
            </BreadcrumbItem>
          </Breadcrumb>
        </Column>
      </Row>
      <Row className="go2">
        <Column lg={16} md={8} className="tickets pr-0">
          <Tile className="full-height">
            <h5 className="last-row-title" style={{ fontWeight: "800" }}>
              Recent Changes
            </h5>
            <StructuredListWrapper>
              <StructuredListHead>
                <StructuredListRow head>
                  <StructuredListCell head>Change Ticket</StructuredListCell>
                  <StructuredListCell head>Description</StructuredListCell>
                </StructuredListRow>
              </StructuredListHead>
              {selectedCustomerJourney[0].recentChanges.map((details) => {
                return (
                  <>
                    <StructuredListBody>
                      <StructuredListRow className="table-row">
                        <StructuredListCell noWrap>
                          {details.Ticket}
                        </StructuredListCell>
                        <StructuredListCell>
                          {details.Description}
                        </StructuredListCell>
                      </StructuredListRow>
                    </StructuredListBody>
                  </>
                );
              })}
            </StructuredListWrapper>
          </Tile>
        </Column>
      </Row>
      <Row className="row-sec go2">
        <Column lg={6} md={4} sm={4} className="recent-head pl-0 pr-0">
          <Tile className="full-height">
            <h5 className="box-heading">Recent Events</h5>
            {getEvents()}
          </Tile>
        </Column>
        <Column
          lg={10}
          md={6}
          sm={4}
          className="pr-0"
          style={{ position: "relative" }}
        >
          <TreeDiagram />
        </Column>
      </Row>
      <Row className="row-sec go2">
        <Column lg={6} md={8} sm={4} className="pl-0 pr-0">
          <ErrorBoundary fallback={"Data is unavailable"}>
            <ChartContainer data={getInstanaInfraMemoryMetrics()} />
          </ErrorBoundary>
        </Column>
        <Column lg={6} md={8} sm={4} className="pr-0">
          <ErrorBoundary fallback={"Data is unavailable"}>
            <ChartContainer data={getErrorMetrics()} />
          </ErrorBoundary>
        </Column>
        <Column lg={4} md={8} sm={4} className="pr-0">
          <ErrorBoundary fallback={"Data is unavailable"}>
            <ChartContainer data={getInstanaInfraLatency()} />
          </ErrorBoundary>
        </Column>
      </Row>
      <Row>
        <Column lg={10} md={8} sm={4} className="pl-0">
          <Tile className="full-height">
            <h5 style={{ fontWeight: "600" }}>Application Alerts</h5>
            <StructuredListWrapper>
              <StructuredListHead>
                <StructuredListRow head>
                  <StructuredListCell head>API</StructuredListCell>
                  <StructuredListCell head>Failure Rate</StructuredListCell>
                  <StructuredListCell head>Count</StructuredListCell>
                  <StructuredListCell head>Error</StructuredListCell>
                </StructuredListRow>
              </StructuredListHead>
              {selectedCustomerJourney[0].applicationAlerts.map((details) => {
                return (
                  <>
                    <StructuredListBody>
                      <StructuredListRow className="table-row">
                        <StructuredListCell noWrap>
                          /{details.apis}
                        </StructuredListCell>
                        <StructuredListCell>
                          {details["Failure Rate"]}
                        </StructuredListCell>
                        <StructuredListCell>{details.Count}</StructuredListCell>
                        <StructuredListCell>{details.error}</StructuredListCell>
                      </StructuredListRow>
                    </StructuredListBody>
                  </>
                );
              })}
            </StructuredListWrapper>
          </Tile>
        </Column>
        <Column lg={6} md={4} sm={4} className="pl-0 pr-0">
          <Tile className="full-height infra-alert">
            <h5>Infrastructure Alerts</h5>
            <StructuredListWrapper>
              <StructuredListHead>
                <StructuredListRow head>
                  <StructuredListCell head>Event Name</StructuredListCell>
                  <StructuredListCell head>Logs</StructuredListCell>
                </StructuredListRow>
              </StructuredListHead>
              {selectedCustomerJourney[0].InfraAlerts.map((details) => {
                return (
                  <>
                    <StructuredListBody>
                      <StructuredListRow className="table-row">
                        <StructuredListCell noWrap>
                          {details.apis}
                        </StructuredListCell>
                        <StructuredListCell onClick={() => setShowAlert(true)}>
                          <Catalog />
                        </StructuredListCell>
                      </StructuredListRow>
                    </StructuredListBody>
                  </>
                );
              })}
            </StructuredListWrapper>
          </Tile>
        </Column>
      </Row>
      {showModal && (
        <CommonMLModal
          setShowModal={setShowModal}
          ticketDetails={recentIncidents}
        />
      )}
      {alertLogs ? (
        <Modal
          open
          passiveModal
          secondaryButtonText={null}
          heading="Logs"
          size="lg"
          onRequestClose={() => setShowAlert(false)}
        >
          <div>{getAlertLogs()}</div>
        </Modal>
      ) : null}
    </FlexGrid>
  );
};

export default Go2Application;
