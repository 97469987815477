import { Breadcrumb, BreadcrumbItem, Column, FlexGrid, Row } from "@carbon/react";
import CarbonTabs from "Carbon-Components/Tabs";
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
// import DAST from "./DAST/index";
// import SAST from "./SAST";
import PerformanceAvailabilityComponent from "Components/ICPSA/AppPerformanceAvailabililty/PerformanceAvailabilityComponent";
import AutoPatcher from "Components/ICPSA/AutoPatcher/AutoPatcher";
import EventBridge from "Components/ICPSA/EventBridge/EventBridge";
import PlatformInsights from "Components/ICPSA/PlatformInsights/PlatformInsights";
import GetPermissions from "Roles-n-Permissions/PermissionScopeNew";
import AdaniIndex from "../AdaniNerve";
import IncidentAnalyticsReports from "../IncidentAnalyticsReports";
import KenvueIndex from "../JobObservability/JobObservability";
import KenvueIndex1 from "../JobObservability1/JobObservability";
import IncidentAnalytics from "./IncidentAnalytics";
import OperationInsights from "./OperationInsights";
import QueryAnalytics from "./QueryAnalytics";
import AlertAnalytics from "../../DO/AlertAnalytics/AlertAnalytics";
import IncidentManagement from "../../DO/IncidentManagementDashboard/IncidentManagement";

const IntegratedOps = () => {
    //const [tabSelect, setTabSelection] = useState(sessionStorage.getItem("selectedTab") !== null ? parseInt(sessionStorage.getItem("selectedTab")) : 0)
    const [tabSelect, setTabSelection] = useState(0)
    const { t } = useTranslation();

    const hubs = sessionStorage.getItem("HubFlow");

    // useEffect(() => {
    //     if(hubs === "insights") {
    //         setTabSelection(0)
    //     } else {
    //         const tab = sessionStorage.getItem("selectedTab")
    //         setTabSelection(0)
    //     }
    // }, [])

    const getTabList = () => {
        if (hubs === "insights") {
            let tabs = [];
            const tenant = sessionStorage.getItem("tenant");

            if (tenant === "Pfizer") {
                return ["ERP AMS", "PGS AMS", "ITOPS AMS"];
            }
                    
            if (tenant !== "Pfizer-Orals" && tenant !== "Pfizer") {
                // tabs.push("Alert Analytics");
                tabs.push(t(`CarbonTabs.itGovernanceReporting.operations.alertAnalytics`))
                tabs.push(t(`CarbonTabs.itGovernanceReporting.operations.incidentManagementEfficiency`))
            }

            if (GetPermissions("incident_analytics::read")) {
                tabs.push(t(`CarbonTabs.itGovernanceReporting.operations.incidentAnalytics`))
            }
            if (GetPermissions("incident_analytics_reports::read")) {
                tabs.push(t(`CarbonTabs.itGovernanceReporting.operations.incidentAnalyticsReports`))
            }
            if (GetPermissions("operation_insights::read")) {
                tabs.push(t(`CarbonTabs.itGovernanceReporting.operations.operationsInsights`))
            }
            if (GetPermissions("event_insights::read")) {
                tabs.push(t(`CarbonTabs.itGovernanceReporting.operations.eventInsights`))
            }
            if (GetPermissions("patch_events::read")) {
                tabs.push(t(`CarbonTabs.itGovernanceReporting.operations.patchEvents`))
            }
            if (GetPermissions("query_analytics::read")) {
                tabs.push(t(`CarbonTabs.itGovernanceReporting.operations.queryAnalytics`))
            }
            // return [
            //     "Incident Analytics",
            //     "Incident Analytics Reports",
            //     "Operation Insights",
            //     "Event Insights",
            //     "Patch Events",
            //     "Query Analytics",
            // ];
            return tabs;
        }
        else if (hubs === "operations") {
            return [
                t(`CarbonTabs.integratedOperations.apa`),
                t(`CarbonTabs.integratedOperations.jobObsv`),
                t(`CarbonTabs.integratedOperations.nerveCenter`),
                t(`CarbonTabs.integratedOperations.platformObsv`),
                // "Incident Management",
                // "Talk to Docs"
            ];
        }
        else {
            return [
                t(`CarbonTabs.integratedOperations.apa`),
                t(`CarbonTabs.integratedOperations.jobObsv`),
                t(`CarbonTabs.integratedOperations.nerveCenter`),
                t(`CarbonTabs.integratedOperations.platformObsv`),
                t(`CarbonTabs.integratedOperations.jobInsights`),
                // "Ticket Analytics"
                // "Incident Management",
                // "Talk to Docs"
            ];
        }
    }

    const getTabPanelsList = () => {
        if (hubs === "insights") {
            let panelList = [];
            const tenant = sessionStorage.getItem("tenant")
            if (tenant !== "Pfizer-Orals" && tenant !== "Pfizer"){
                panelList.push(<AlertAnalytics/>);
                panelList.push(<IncidentManagement />);
            }
            if (GetPermissions("incident_analytics::read")) {
                panelList.push(<IncidentAnalytics />);
            }
            if (GetPermissions("incident_analytics_reports::read")) {
                panelList.push(<IncidentAnalyticsReports />);
            }
            if (GetPermissions("operation_insights::read")) {
                panelList.push(<OperationInsights />);
            }
            if (GetPermissions("event_insights::read")) {
                panelList.push(<EventBridge />);
            }
            if (GetPermissions("patch_events::read")) {
                panelList.push(<AutoPatcher />);
            }
            if (GetPermissions("query_analytics::read")) {
                panelList.push(<QueryAnalytics />);
            }
            return panelList;
            // return [
            //     <IncidentAnalytics />,
            //     <OperationInsights />,
            //     <EventBridge />,
            //     <AutoPatcher />,
            //     <QueryAnalytics />,
            //     <IncidentAnalyticsReports/>
            // ];
        }
        else if (hubs === "operations") {
            let panelList = [];

            if (GetPermissions("application_performance_availability::read")) {
                panelList.push(<PerformanceAvailabilityComponent />);
            }
            if (GetPermissions("job_observability::read")) {
                panelList.push(<KenvueIndex />);
            }
            if (GetPermissions("nerve_center::read")) {
                panelList.push(<AdaniIndex />);
            }
            if (GetPermissions("platform_observability::read")) {
                panelList.push(<PlatformInsights />);
            }
            return panelList;
            // return [
            //     <PerformanceAvailabilityComponent />,
            //     <KenvueIndex />,
            //     <AdaniIndex />,
            //     <PlatformInsights />
            //     // <PncIncidents />,
            //     // <TalktoDocs />
            // ];
        }
        else {
            let panelList = [];

            if (GetPermissions("application_performance_availability::read")) {
                panelList.push(<PerformanceAvailabilityComponent />);
            }
            if (GetPermissions("job_observability::read")) {
                panelList.push(<KenvueIndex />);
            }
            if (GetPermissions("nerve_center::read")) {
                panelList.push(<AdaniIndex />);
            }
            if (GetPermissions("platform_observability::read")) {
                panelList.push(<PlatformInsights />);
            }
            if (GetPermissions("job_insights::read")) {
                panelList.push(<KenvueIndex1 />);
            }
            return panelList;
            // return [
            //     <PerformanceAvailabilityComponent />,
            //     <KenvueIndex />,
            //     <AdaniIndex />,
            //     <PlatformInsights />,
            //     <KenvueIndex1 />,
            //     // <HCSCIncidents />
            //     // <PncIncidents />,
            //     // <TalktoDocs />
            // ];
        }
    }

    const tabSelection = (e) => {
        sessionStorage.setItem("selectedTab", e)
        setTabSelection(e);
    }

    const getDisabledList = () => {

        let disabledList = [];

        const tenant = sessionStorage.getItem("tenant")
        if (tenant === "Pfizer") {
            disabledList.push("PGS AMS");
            disabledList.push("ITOPS AMS");
        }

        if (!GetPermissions("incident_analytics::read")) {
            disabledList.push("Incident Analytics");
        }
        if (!GetPermissions("operation_insights::read")) {
            disabledList.push("Operation Insights");
        }
        if (!GetPermissions("event_insights::read")) {
            disabledList.push("Event Insights");
        }
        if (!GetPermissions("patch_events::read")) {
            disabledList.push("Patch Events");
        }
        if (!GetPermissions("query_analytics::read")) {
            disabledList.push("Query Analytics");
        }
        if (!GetPermissions("incident_analytics_reports::read")) {
            disabledList.push("Incident Analytics Reports");
        }
        if (!GetPermissions("application_performance_availability::read")) {
            disabledList.push("Application Performance & Availability");
        }
        if (!GetPermissions("job_observability::read")) {
            disabledList.push("Job Observability");
        }
        if (!GetPermissions("nerve_center::read")) {
            disabledList.push("Nerve Center");
        }
        if (!GetPermissions("platform_observability::read")) {
            disabledList.push("Platform Observability");
        }
        if (!GetPermissions("job_insights::read")) {
            disabledList.push("Job Insights");
        }

        console.log("disabledList: ", disabledList);
        return disabledList;
    }

    console.log("tabSelect", tabSelect)

    return (
        <FlexGrid className="integratedOps">
            <Row>
                <Column lg={13} md={8} sm={4}>
                    <Breadcrumb noTrailingSlash>
                        <BreadcrumbItem href="#/home">{t(`breadcrumb.home`)}</BreadcrumbItem>
                        {hubs === "insights" ? <BreadcrumbItem href="#/itGovernance">{t(`common.landingPage.itGovernanceReporting`)}</BreadcrumbItem> : null}
                        <BreadcrumbItem isCurrentPage>
                        {t(`common.landingPage.integratedOperations`)}
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Column>
            </Row>
            <Row>
                <Column lg={16} md={8} sm={4}>
                    <CarbonTabs
                        tabIndex={tabSelect}
                        selectionMode="automatic"
                        type="default"
                        Tablist={getTabList()}
                        tabSelection={true}
                        tabSelected={tabSelection}
                        disabledList={getDisabledList()}
                        TabPanelsList={getTabPanelsList()}
                    />
                </Column>
            </Row>
        </FlexGrid>
    )
}

export default IntegratedOps;