import React, { useState, useEffect } from 'react';
import { getPDFContent } from '../Helper';

const PDFViewer = ({ content }) => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAndDisplayPDF = async () => {
      try {
        const pdfBlob = await getPDFContent({ fileName: content });

        // If we get a valid Blob, create a Blob URL
        if (pdfBlob) {
          const pdfBlobUrl = URL.createObjectURL(pdfBlob);
          setPdfUrl(pdfBlobUrl);
        } else {
          setError("Error loading PDF");
        }
      } catch (err) {
        console.error("Error fetching the PDF content", err);
        setError("Error loading PDF");
      }
    };

    if (content) {
      fetchAndDisplayPDF(); // Fetch PDF only when content changes
    }

    // Cleanup: Revoke the URL when the component unmounts or content changes
    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl); // Clean up memory
      }
    };
  }, [content]); // Dependency only on content

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      {pdfUrl ? (
        <iframe src={pdfUrl} width="100%" height="600px" title="PDF Viewer" />
      ) : (
        <p>Loading PDF...</p>
      )}
    </div>
  );
};

export default PDFViewer;