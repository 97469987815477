import { Column, DataTable, Row } from "@carbon/react";
import React from "react";
import ShowMoreText from "./ShowMoreText";
import { TABLE_HEADING_MAPPING } from "Constant";
import ChartContainer from "Carbon-Charts/ChartContainer";
const { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } =
  DataTable;

const CarbonTable = ({ content }) => {
  const headers = content?.headers?.map((header) => header.toLowerCase());

  // Replace JSON keys with display names from TABLE_HEADING_MAPPING
  const displayHeaders = headers.map(
    (header) => TABLE_HEADING_MAPPING[header] || header
  );

  const rows = content?.rows?.map((item) => {
    const rowObj = {};
    headers.forEach((header, index) => {
      rowObj[header] = item[index];
    });
    return rowObj;
  });

  let severityArray = [];
  let priorityArray = [];
  let defectSeverityArray = [];
  const severityIndex = content.headers.indexOf("severity");
  const priorityIndex = content.headers.indexOf("priority");
  const DefectIndex = content.headers.indexOf("defect_id");

  if (severityIndex === -1 || priorityIndex === -1) {
    console.log("Severity or Priority not found in headers.");
  } else {
    const severityCounts = {};
    const priorityCounts = {};
    content.rows.forEach((row) => {
      const severity = row[severityIndex];
      const priority = row[priorityIndex];

      severityCounts[severity] = (severityCounts[severity] || 0) + 1;
      priorityCounts[priority] = (priorityCounts[priority] || 0) + 1;
    });
    severityArray = Object.entries(severityCounts).map(([key, count]) => ({
      group: key,
      value: count,
    }));

    priorityArray = Object.entries(priorityCounts).map(([key, count]) => ({
      group: key,
      value: count,
    }));

    console.log({ severity: severityArray, priority: priorityArray });
  }

  if (severityIndex === -1 || DefectIndex === -1) {
    console.log("Severity not found in headers.");
  } else {
    const severityCounts = {};
    content.rows.forEach((row) => {
      const severity = row[severityIndex];

      severityCounts[severity] = (severityCounts[severity] || 0) + 1;
    });

    defectSeverityArray = Object.entries(severityCounts).map(
      ([key, count]) => ({
        group: key,
        value: count,
      })
    );

    console.log({ severity: defectSeverityArray });
  }

  const getIncidentCharts = (array, title) => {
    let data = {
      dataType: "pie",
      "col-size": 4,
      data: {
        chartData: array,
        chartOptions: {
          title: title,
          canvasZoom: {
            enabled: true,
          },
          pie: {
            alignment: "center",
            labels: {
              enabled: true,
            },
          },
          toolbar: {
            enabled: false,
          },
          legend: {
            enabled: true,
          },
          height: "200px",
        },
      },
    };
    if (severityIndex === -1 || priorityIndex === -1) {
      return null;
    } else
      return (
        <Column lg={4}>
          <ChartContainer data={data} />
        </Column>
      );
  };

  const getDefectCharts = (array, title) => {
    let data = {
      dataType: "simpleBar",
      "col-size": 8,
      data: {
        chartData: array,
        chartOptions: {
          title: title,
          axes: {
            bottom: {
              mapsTo: "group",
              scaleType: "labels",
            },
            left: {
              mapsTo: "value",
            },
          },
          toolbar: {
            enabled: false,
          },
          legend: {
            enabled: false,
          },
          height: "200px",
        },
      },
    };
    if (severityIndex === -1 || DefectIndex === -1) {
      return null;
    } else
      return (
        <Column lg={8}>
          <ChartContainer data={data} />
        </Column>
      );
  };

  let tableTotalWidth = 0;
  let cellMaxWidth = [];
  // Function to calculate width based on text length
  const calculateColumnWidth = (text, cellIndex) => {
    if (cellIndex === 0) {
      tableTotalWidth = 0;
    }
    let columnWidth = 0;
    if (text?.length > 200) {
      columnWidth = 450; // for longer text
    } else if (text?.length > 50) {
      columnWidth = 250; // for medium text
    } else {
      columnWidth = 150; // for short text
    }
    if (cellMaxWidth[cellIndex]) {
      if (cellMaxWidth[cellIndex] > columnWidth) {
        columnWidth = cellMaxWidth[cellIndex];
      } else {
        cellMaxWidth[cellIndex] = columnWidth;
      }
    } else {
      cellMaxWidth[cellIndex] = columnWidth;
    }
    tableTotalWidth = tableTotalWidth + columnWidth;
    return columnWidth + "px";
  };

  const getTableBody = (rows) => {
    return (
      <>
        <TableBody className="tableBodyClass">
          {rows.map((row, rowIndex) => (
            <TableRow
              key={rowIndex}
              style={{ paddingTop: "4px", paddingBottom: "4px" }}
            >
              {Object.keys(row)
                .filter((key) => key !== "id")
                .map((key, cellIndex) => (
                  <TableCell
                    key={cellIndex}
                    style={{
                      paddingLeft: "16px",
                      width: calculateColumnWidth(row[key], cellIndex),
                    }}
                  >
                    <ShowMoreText text={row[key]} maxLength={50} />
                  </TableCell>
                ))}
            </TableRow>
          ))}
        </TableBody>
      </>
    );
  };
  if (content?.headers) {
    const headers = content?.headers;
    const rowLength = content?.rows?.length;
    if (content?.headers?.length > 0) {
      let tableBodyHtml = getTableBody(rows);
      return (
        <>
          <Table
            style={{ width: 3000 + "px" }}
            size="lg"
            useStaticWidth={true}
            useZebraStyles={false}
            aria-label="nim table"
          >
            <TableHead className="tableHeaderClass">
              <TableRow>
                {displayHeaders.map((header, index) => (
                  <TableHeader
                    style={{
                      width: cellMaxWidth[index]
                        ? cellMaxWidth[index] + "px"
                        : "",
                    }}
                    key={index}
                  >
                    {header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            {tableBodyHtml}
          </Table>
          <Row style={{ background: "#80808026", paddingBottom: "5px" }}>
            {getIncidentCharts(severityArray, "Incidents by Severity")}
            {getIncidentCharts(priorityArray, "Incidents by Priority")}
          </Row>
          <Row style={{ background: "#80808026", paddingBottom: "5px" }}>
            {getDefectCharts(defectSeverityArray, "Defects by Severity")}
          </Row>
        </>
      );
    } else
      return (
        <h6
          style={{
            color: "black",
            border: "2px solid #333333",
            padding: "10px",
          }}
        >
          No records found.
        </h6>
      );
  } else
    return (
      <h6
        style={{ color: "black", border: "2px solid #333333", padding: "10px" }}
      >
        No records found.
      </h6>
    );
};

export default CarbonTable;
