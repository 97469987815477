import {
  ClickableTile,
  Column,
  Row,
  SkeletonText,
  Tile
} from "@carbon/react";
import { CheckmarkFilled, Renew } from "@carbon/react/icons";
import { useKeycloak } from "@react-keycloak/web";
import ErrorNotify from "Carbon-Components/ErrorNotify";
import ErrorFallback from "Common-Modules/ErrorFallback";
import React, { useEffect, useState } from "react";
import useData from "./Hooks/useData";
import BlockedHosts from "./Tables/BlockedHosts";
import OOMS from "./Tables/OOMS";
import OpenIssues from "./Tables/OpenIssues";
import Sessions from "./Tables/Sessions";
import Tasks from "./Tables/Tasks";
import TilesLoader from "./TilesLoader";
import "./citi.scss";
import EpochTime from "./epochTime";
import { getCluserTilesData } from "./utils";

const ClusterWiseDetails = () => {
  const { keycloak } = useKeycloak();
  const asset = JSON.parse(sessionStorage.getItem("asset_class"));
  const cluster = JSON.parse(sessionStorage.getItem("cluster_name"));
    // const clusterData = JSON.parse(sessionStorage.getItem("cluster_data"));
  const selecteDates = JSON.parse(sessionStorage.getItem("selecteDates"));
  const [dateChange, onDateChange] = useState(() => selecteDates ? selecteDates.time : {
    currentTime: new Date(new Date().getTime() - 15 * 60 * 1000).getTime(),
    prevTime: new Date().getTime(),
  });
  const [refresh, onRefresh] = useState(false);
  const [selected, setSelected] = useState("");
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isCalculating, setIsCalculating] = useState(true);
  const [onLoading, setonLoading] = useState(0);

  const { isLoading, isErrorDate, assets, sessions, tasks, ooms, incidents, apiRefresh, sessionCount, taskCount } = useData(
    keycloak,
    dateChange,
    refresh
  );

  useEffect(() => {
    if (!apiRefresh) {
      setonLoading(loading => loading + 1)
    }
  }, [apiRefresh])

  useEffect(() => {
    if (onLoading > 1) {
      onRefresh(false);
    }
  }, [onLoading])

  useEffect(() => {
    const sess = sessions;
    const oom = ooms;
    const task = tasks;
    const incident = incidents;
    try {
      const calculatedData = getCluserTilesData(
        asset,
        sess,
        oom,
        task,
        incident,
        sessionCount,
        taskCount
      );
      setData(calculatedData);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsCalculating(false);
    }
  }, [assets, sessions, tasks, ooms]);

  const dateOnChange = (e) => {
    onDateChange(e);
    setonLoading(1)
  };

  const onClickRefresh = () => {
    onRefresh(true)
    setonLoading(1)
  }

  const renderTile = (id, count, label, selectedLabel) => (
    <div>
      {onLoading > 1 ? (
        <ClickableTile
          id={id}
          className={`mock--radio ${count > 0 ? "red" : null}`}
          onClick={() => setSelected(selectedLabel)}
        >
          <h3>{count || 0}</h3>
          <h5>{label}</h5>
          {selected === selectedLabel && <CheckmarkFilled fill="#0062ff" />}
        </ClickableTile>
      ) : (
        <ClickableTile>
          <SkeletonText />
        </ClickableTile>
      )}
    </div>
  );

  const renderSelectedContent = (selectedType) => {
    const contentMap = {
      sessions: data.sessionsCount[cluster] ? <Sessions asset={asset.toolchainName} cluster={cluster} title="Failed Sessions" /> : <ErrorNotify title="No Failed Sessions" kind="info-square" />,
      ooms: data.oomCount[cluster] ? <OOMS asset={asset.toolchainName} cluster={cluster} title="OOM Errors" /> : <ErrorNotify title="No OOM Errors" kind="info-square" />,
      tasks: data.taskCounts[cluster] ? <Tasks asset={asset.toolchainName} cluster={cluster} title="Failed Tasks" /> : <ErrorNotify title="No Failed Tasks" kind="info-square" />,
      blockedHosts: data.blockedHostsCount[cluster] ? <BlockedHosts asset={asset.displayName} cluster={cluster} title="Blocked Host" /> : <ErrorNotify title="No Blocked Host" kind="info-square" />,
      nodeIssue: data.nodeIssuesCount[cluster] ? <OpenIssues asset={asset.displayName} cluster={cluster} /> : <ErrorNotify title="No Node Issues" kind="info-square" />,
      longRunning: data.longRunning[cluster] ? <OpenIssues asset={asset.displayName} cluster={cluster} /> : <ErrorNotify title="No Long Running Issues" kind="info-square" />,
    };

    return contentMap[selectedType] || null;
  };

  if (isCalculating) {
    return <TilesLoader />;
  }

  if (isError) {
    return <ErrorFallback />;
  }

  return (
     // <FlexGrid className="att-dash circle-view">
    <>
      <Row className="citi--specific--styles">
        <Column lg={16} md={8} sm={4}>
          <div className="flex-1 flex-space-between flex-wrap">
            <div></div>
            <div className="flex-space-between flex-wrap" style={{gap:"0.5rem"}}>
              <div className="refresh-icon">
                <Renew
                  aria-label="refresh"
                  className={onLoading <= 1 ? "rotateIcons" : ""}
                  onClick={onClickRefresh}
                />
                Refresh
              </div>
              <EpochTime dateOnChange={dateOnChange} />
            </div>
          </div>
        </Column>
      </Row>
      <Row className="mt-1">
        <Column lg={3} md={4} sm={4}>
          <Tile className="headings--tile" style={{ height: "94%" }}>
              {/* <h5>
              Asset Class:{" "}
              <span style={{ color: "#0062ff" }}>{asset.displayName}</span>
            </h5> */}
            <h5>
              Cluster: <span style={{ color: "#0062ff" }}>{cluster}</span>
              </h5>
          </Tile>
        </Column>
        <Column lg={13} md={4} sm={4}>
          <Row className="tiles--wrapper">
            {renderTile("sessions", data.sessionsCount[cluster], "Failed Sessions", "sessions")}
            {renderTile("ooms", data.oomCount[cluster], "OOM Errors", "ooms")}
            {renderTile("tasks", data.taskCounts[cluster], "Failed Tasks", "tasks")}
            {renderTile("blockedHosts", data.blockedHostsCount[cluster], "Blocked Host", "blockedHosts")}
            {renderTile("nodeIssue", data.nodeIssuesCount[cluster], "Node Issues", "nodeIssue")}
            {renderTile("longRunning", 0, "Long Running", "longRunning")}
          </Row>
        </Column>
      </Row>
      {onLoading > 1 && renderSelectedContent(selected)}
    </>
  );
};

export default ClusterWiseDetails;
