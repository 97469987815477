import React, { useState, useEffect } from 'react'
import { SimpleBarChart } from "@carbon/charts-react";
import { Tooltip, ContentSwitcher, Switch, Dropdown, InlineLoading } from '@carbon/react';
import moment from 'moment'
import { Information } from "@carbon/react/icons/index";
import { useTranslation } from 'react-i18next';

import TableToolbarDownload from '../CarbonComponents/TableToolbarDownload';
import * as durationConst from "../config/TimeInterval";


const DashboardSimpleBarChart = (props) => {
 const { t } = useTranslation();
    const defaultSimpleBarGraphOptions = {
        "title": props.tableTitle || '',
        "axes": {
            "left": {
                "mapsTo": "group",
                "scaleType": "labels",
                "title": props.leftAxesTitle || '',
            },
            "bottom": {
                "mapsTo": "value",
                "title": props.graphBottomTitle || '',
            }
        },
        "legend": {
            "position": props.legendPosition || "bottom",
            "orientation": props.legendOrientation || "horizontal",
            "alignment": props.legendAlignment || "left",
            "truncation": {
                "type": props.legendTruncation || "end_line" // "end_line", "front_line", "middle_line, "none"
            }
        },
        "data": {
            "loading": props.loading || false
        },
        // "color": {
        //     "scale": props.colors || null
        // },
        "tooltip": {
            "enabled": props.tooltipEnabled === false ? false : true,
            "showTotal": props.showTotal === false ? false : true,
            "customHTML": (data, defaultHTML) => props.getCustomTooltip ? props.getCustomTooltip(data, defaultHTML) : defaultHTML
        },
        "height": props.height || "400px",
        "toolbar": { "enabled": props.toolbarEnabled === false ? false : true },
        "theme": props.theme || 'white'
    };

    const simpleGraphHeaders = [{ key: 'group', header: t('DigitalOperations.Group') }, { key: 'key', header: props.leftAxesTitle },
    { key: 'value', header: t('DigitalOperations.Value') }];

    const dataList = [
        {
            id: "weekly",
            label: "Weekly"
        },
        {
            id: "monthly",
            label: "Monthly"
        }
    ]

    const changeDataViewValue = (e) => {
        props.handleRefresh(e.selectedItem);
    }

    let startTime, endTime, duration;
    if (props.showIntervalDropdown && props.timeFilterSelections.startDateTime && props.timeFilterSelections.endDateTime) {
        startTime = moment(props.timeFilterSelections.startDateTime);//now
        endTime = moment(props.timeFilterSelections.endDateTime);
        duration = endTime.diff(startTime, 'hours');
    }

    const getTitleWithToolTip = () => {
        return (<div>
            {props.title ?
                <div style={{ display: "flex" }}>
                    <div className="graphTitle">{props.title}
                        {props.graphDescription && <span>
                            <Tooltip direction="right"
                                    label={props.graphDescription}
                                    style={{ color: "black", fontSize: "14px" }}>
                                    <button
                                    className="tooltip-button"
                                    type="button"
                                >
                                    <Information />
                                </button>
                            </Tooltip>
                        </span>}
                    </div>
                </div> : null}
        </div>)
    }

    return (
        <>
            {!defaultSimpleBarGraphOptions.data.loading && !props.simpleBarChartData.length ?
                <>
                    <div className="graph-title carbon-display-Regular-20">{getTitleWithToolTip()}</div>
                    <div>{t('DigitalOperations.noDataAvailable')}</div>
                </> :
                defaultSimpleBarGraphOptions.data.loading ?
                    <>
                        <div className="graph-title carbon-display-Regular-20">{getTitleWithToolTip()}</div>
                        <div><InlineLoading description={t('DigitalOperations.loading')} /></div>
                    </>
                    :
                    <div className="dashboard-stacked-bar-chart">
                        {props.showSwitcher ?
                            <div className="graph-title carbon-display-Regular-20">
                                {getTitleWithToolTip()}
                                <div>
                                    <div className="switcher-container">
                                        {props.showIntervalDropdown === true ?
                                            <div className='data-range'>
                                                <Dropdown
                                                    id="data-select"
                                                    size="sm"
                                                    label="Select"
                                                    items={dataList}
                                                    itemToString={(item) => (item ? item.label : '')}
                                                    selectedItem={props.selectedValue && Object.keys(props.selectedValue).length > 0 ? props.selectedValue : ''}
                                                    onChange={(e) => changeDataViewValue(e)}
                                                    disabled={duration <= (durationConst.oneDay) ? true : false}
                                                />
                                            </div> : null}
                                        <ContentSwitcher onChange={(e) => props.switchView(e)} className="content-switcher" size='sm' selectedIndex={1} >
                                            <Switch name={'GraphView'} text={props.leftSwitcherName || props.leftSwitcher} />
                                            <Switch name={'TableView'} text={props.rightSwitcherName || props.rightSwitcher} />
                                        </ContentSwitcher>
                                        {props.hideDownloadButton !== true ? <div className="download-button">
                                            <TableToolbarDownload iconDescription={t('DigitalOperations.export')} rows={props.simpleBarChartData} headers={simpleGraphHeaders} title={props.title} filename={props.title || t('DigitalOperations.export')} label={t('DigitalOperations.download')} />
                                        </div> : null}
                                    </div>
                                </div>
                            </div> :
                            <div className="graph-title carbon-display-Regular-20">
                                {getTitleWithToolTip()}
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {props.hideDownloadButton !== true ? <div className="download-button">
                                        <TableToolbarDownload iconDescription={t('DigitalOperations.export')} rows={props.simpleBarChartData} headers={simpleGraphHeaders} title={props.title} filename={props.title || t('DigitalOperations.export')} label={t('DigitalOperations.download')} />
                                    </div> : null}
                                </div>
                            </div>}
                        <SimpleBarChart
                            data={props.simpleBarChartData}
                            options={defaultSimpleBarGraphOptions}>
                        </SimpleBarChart>
                    </div>
            }
        </>
    );
};

export default DashboardSimpleBarChart;
