
import { DataTable } from '@carbon/react';
import React from 'react';
import ShowMoreText from './ShowMoreText';
const {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow
} = DataTable;


const CarbonTable = ({content}) => {

  const headers = content?.headers;

  const rows = content?.rows?.map(item => {
    const rowObj = {};
    headers.forEach((header, index) => {
      rowObj[header] = item[index];
    });
    return rowObj;
  });



  return (<Table size="lg" useZebraStyles={false} aria-label="NOC Table">
  <TableBody>
    {headers.map((header, index) => (
      <TableRow key={header}>
        <TableHeader>{header}</TableHeader> {/* Header as the first cell */}
        {rows.map((row, rowIndex) => (       <TableCell key={rowIndex}>
            {/* Use ShowMoreText for cells with potentially long content */}
            <ShowMoreText text={row[Object.keys(row)[index]].toString()} maxLength={100} />
          </TableCell>
        ))}
      </TableRow>
    ))}
  </TableBody>
</Table>)

}


export default CarbonTable;