import { Column, FlexGrid, Row } from "@carbon/react";
import React, { useState } from "react";
const sign = require('jwt-encode');
const secret = 'secret';

const DoIndex = () => {
    const [loading, setLoading] = useState(true);

    // const onLoadIframe = () => {
    //     setLoading(false);
    // }

    // let loadingText = <InlineLoading
    //     description={`Loading..`}
    //     className="event-loader"
    // />


    const jwttoken = () => {
        const json = {
            filters: {
                tenant: [""],
                org: [""],
                time: { start_datetime: "", end_datetime: "" }
            },
            Tab: "Services Management"
        }

        const jwt = sign(json, secret);
      
        // Get the URL from sessionStorage
        const doUrl = sessionStorage.getItem("doUrl");
      
        // Construct the full URL
        const url = `${doUrl}?userinfo=${jwt}`;
      
        // Create an anchor element
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.target = "_self";  // Open the URL in the same tab
        anchor.rel = "noopener noreferrer";  // Security best practices
      
        // Temporarily append the anchor to the body
        document.body.appendChild(anchor);
      
        // Simulate a click on the anchor to navigate to the new URL
        anchor.click();
      
        // Clean up by removing the anchor element
        document.body.removeChild(anchor);
      };
      

    return (
        <FlexGrid className="integratedOps">
            <Row>
                <Column lg={16} md={8} sm={4}>
                {jwttoken()}
                    {/* <div>
                        {loading && loadingText}
                        <iframe
                            id="digital-operations"
                            title={' '}
                            src={jwttoken()}
                            width="100%"
                            height="900"  //adjust this accordingly
                            sandbox='allow-scripts allow-same-origin allow-popups allow-top-navigation allow-forms allow-top-navigation-by-user-activation
                allow-modals allow-popups-to-escape-sandbox allow-pointer-lock'
                            style={{ display: loading ? 'none' : 'block' }}
                            allow="fullscreen"
                            onLoad={onLoadIframe}
                        >
                        </iframe>
                    </div> */}
                </Column>
            </Row>
        </FlexGrid>
    )
}

export default DoIndex;