import React, { useEffect, useState } from "react";
import Elk from "./elkTopology";
// import KenvueElk from "./kenvue/kenvueElk";
import {
  SkeletonPlaceholder,
  SkeletonText
} from "@carbon/react";

const Legend = () => {
  return (
    <div style={{
      position: 'absolute',
      top: '10px',
      right: '10px',
      backgroundColor: 'white',
      border: '1px solid #ccc',
      borderRadius: '8px',
      padding: '10px',
      marginRight: '20px',
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
      fontSize: '14px'
    }}>
      <h4 style={{ margin: '0 0 10px 0', fontSize: '16px', textAlign: 'center', borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>Legend</h4>
      <div><span style={{ display: 'inline-block', width: '16px', height: '16px', backgroundColor: '#c6c6c6', marginRight: '8px' }}></span>Manual Activity</div>
      <div><span style={{ display: 'inline-block', width: '16px', height: '16px', backgroundColor: '#82cfff', marginRight: '8px' }}></span>Automated Step</div>
    </div>
  );
};

const TopologyGraph = (props) => {
  const [linkData, setlinkData] = useState([]);
  const [nodeData, setNodeData] = useState([]);

  // const nodeHeight = 64;
  // const nodeWidth = 180;
  // const size = 48;

  // useEffect(() => {

  // }, [])

  useEffect(() => {
    let keyData = [];
    console.log("props.valueStreamData", props.valueStreamData)
    props.valueStreamData.forEach((vs) => {
      vs.workflowDetails.forEach((workflow) => {
        workflow["Row2"] !== undefined && workflow["Row2"].forEach((row2) => {
          row2.steps !== undefined && row2.steps.forEach((step) => {
            console.log("keyData", sessionStorage.getItem("CusJouStep"), step)
            if (sessionStorage.getItem("CusJouStep") === step["name"] && step["topology"]) {
              keyData = step["topology"]
            }
          })
        })
      })
    })
    console.log("keyData", keyData)
    if(Object.entries(keyData).length > 0) {
      getTopologyData(keyData)
    }
  }, [props])

  const getTopologyData = (instanaTopology) => {
    // const nodeData = [
    //   { id: "a", height: size, width: size, label: "Prepaid", "types": ["jdbc"] },
    //   { id: "b", height: 160, width: 60, label: "Integration Svc Layer", "types": ["HTTP"] },
    //   { id: "c", height: size, width: size, label: "Billing System", "types": ["MESSAGING"] },
    //   { id: "d", height: size, width: size, label: "MDMS", "types": ["unknown"] },
    //   { id: "e", height: 160, width: 60, label: "Integration Svc Layer", "types": ["jdbc"] },
    //   { id: "f", height: size, width: size, label: "HES", "types": ["unknown"] },
    //   { id: "g", height: 160, width: 60, label: "Integration Svc Layer", "types": ["unknown"] },
    //   { id: "h", height: size, width: size, label: "Meter", "types": ["unknown"] }
    // ];

    // const linkData = [

    //   { id: "1", source: "a", target: "b" },
    //   { id: "2", source: "b", target: "c" },
    //   { id: "3", source: "c", target: "d" },
    //   { id: "4", source: "d", target: "e" },
    //   { id: "5", source: "e", target: "f" },
    //   { id: "6", source: "f", target: "g" },
    //   { id: "7", source: "g", target: "h" }
    // ];

    const connected = instanaTopology.connections.map((topo, index) => {
      let newObj = {
        source: topo.from,
        target: topo.to,
        posx: topo.x,
        posy: topo.y
      };
      // instanaTopology.connections.forEach((conn) => {
      //   if (topo.from === conn.from && topo.to === conn.to) {
      //     newObj = {
      //       ...newObj,
      //       errorRate: conn.errorRate,
      //     }
      //   }
      // })
      return {
        id: (index + 1).toString(),
        ...newObj
      }
    })
    const node = instanaTopology.services.map((serv, index) => {
      let newServ = serv;
      connected.forEach((con) => {
        if (con.target === serv.id) {
          newServ = {
            ...newServ,
            error: con.errorRate
          }
        }
      })
      console.log("newServ", newServ)
      return {
        ...newServ,
        width: props.flow === "polaris" ? 60 : props.flow === "ViSecurity" ? 120 : newServ.width,
        height: 80,
        posx: newServ.x,
        posy: newServ.y
      }
    })
    setlinkData(connected)
    setNodeData(node)
  }

  const redirectTo = (node) => {
    props.redirectToIne(true, node);
  };

  console.log("props.flow", props.flow)

  return (
    <div style={{ position: 'relative' }}>
      {nodeData.length >
       0 ?
         <Elk nodes={nodeData} flow={props.flow} links={linkData} direction={props.direction} layout="layered" viewBox={props.flow === "North America CPG" ? "300 0 550 2200" : "800 0 550 1200"} width="98%" height="1150" cardClick={redirectTo} />
        : <div style={{ height: "500px" }}>
          <SkeletonText />
          <SkeletonText />
          <SkeletonText />
          <br />
          <SkeletonPlaceholder />
        </div>}
        <Legend />
    </div>
  );
};

export default TopologyGraph;
