import {
  Breadcrumb,
  BreadcrumbItem,
  ClickableTile,
  Column,
  FlexGrid,
  Row,
  SkeletonText,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Toggle,
} from "@carbon/react";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ATOChart from "./AtoChart";
//{ getChartData, getBasicInfo } from "./AtoChart";
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "@tanstack/react-query";
import { getServiceNowData } from "Services/ServerApi";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import NorkfolkBusiness from "./NorkfolkBusiness";

const NorkfolkDashboard = () => {
  const { name } = useParams();
  const { keycloak } = useKeycloak();
  const chartRef = useRef(null);
  const history = useHistory();
  const [atoFetchData, setAtoData] = useState({});
  const [atoDuplicate, setATODuplicate] = useState({});
  const [snowTableDat, setSnowTable] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [chartTitle, setChartTitle] = useState({});
  const [detailName, setDetailName] = useState("");
  const [tabSelect, setTabsSelect] = useState("application");

  const { data: AtoFetchData } = useQuery(["cj_details"]);

  const { data: snowTableData, refetch: snowfetch } = useQuery(
    ["servicenTab"],
    async () => await getServiceNowData(keycloak, uuidv4(), "pnc", "ato"),
    { retry: 1 }
  );

  useEffect(() => {
    setEventListener();
  }, []);

  useEffect(() => {
    if (AtoFetchData !== undefined && snowTableData !== undefined) {
      const filt = AtoFetchData.data.cj_details.filter(
        (fil) => fil.Name === sessionStorage.getItem("norfolkName")
      );

      const newObj = {
        ...filt[0].data,
        ATO: filt[0].data.map((att) => {
          return {
            ...att,
            serv: snowTableData.data.data.ato_incident_analysis.filter(
              (ana) => ana.Application === att.Name
            ),
          };
        }),
      };
      setATODuplicate(newObj);
      setAtoData(filt[0].data);
    }
  }, [AtoFetchData, snowTableData]);

  const setEventListener = () => {
    if (chartRef.current) {
      chartRef.current.chart.services.events.addEventListener(
        "pie-slice-click",
        chartOnClick
      );
    }
  };

  const chartOnClick = (e) => {
    console.log("chartOnClick ======", e);
  };

  const onClickAtoChart = (e, servnow) => {
    //e.stopPropagation();
    sessionStorage.setItem("atoFlowTitle", e);
    const name = sessionStorage.getItem("norfolkName")
    history.push({
      pathname: `/atoDetails`,
      state: {
        data: e,
        name,
        toggle,
        servnow: servnow.length > 0 ? servnow : [],
      },
    });
  };

  // const onClickAtChart = (e, servnow, data) => {
  //   //e.stopPropagation();
  //   setChartTitle({
  //     Title: e,
  //     data: data,
  //   });
  //   if (e !== "component") {
  //     const incident = servnow.filter(
  //       (ser) => ser["Engaged By"] !== "Alerting"
  //     );
  //     sessionStorage.setItem("atoFlowTitle", e);
  //     history.push({
  //       pathname: `/atoDetails`,
  //       state: {
  //         data: e,
  //         name,
  //         toggle,
  //         servnow: incident.length > 0 ? incident : [],
  //       },
  //     });
  //   }
  // };

  // const onClickTabs = (tab) => {
  //   setTabsSelect(tab);
  // };

  const handleOnClickTile = (e, data) => {
    console.log("Selected Journeyyy", e, data);
    const name = sessionStorage.getItem("norfolkName")
    setDetailName(name);
    setChartTitle({
      Title: e,
      data: data,
    });
  };

  // const getIncTileInfo = (data) => {
  //   const incident = data.serv.filter(
  //     (ser) => ser["Engaged By"] !== "Alerting" && ser["state"] === "1"
  //   );
  //   const alertcnt = data.serv.filter(
  //     (ser) => ser["Engaged By"] === "Alerting" && ser["state"] === "1"
  //   );
  //   const alert = data.serv.filter((ser) => ser["Engaged By"] === "Alerting");
  //   return (
  //     <>
  //       <ul className="basic-info ato">
  //         <li
  //           onClick={() =>
  //             incident.length > 0 && onClickAtoChart("outage", incident)
  //           }
  //         >
  //           <h5>Recent Incidents</h5>
  //           <span>{incident.length}</span>
  //         </li>
  //         <li
  //           onClick={() => alert.length > 0 && onClickAtoChart("outage", alert)}
  //         >
  //           <h5>Recent Alerts</h5>
  //           <span>{alertcnt.length}</span>
  //         </li>
  //         <li onClick={() => onClickAtoChart("alert", data.Anomaly)}>
  //           <h5>Anomaly</h5>
  //           <span className={data.Anomaly > 0 ? "red" : ""}>
  //             {data.Anomaly}
  //           </span>
  //         </li>
  //       </ul>
  //     </>
  //   );
  // };

  const getPercent = (data) => {
    return (
      <p className="avail">
        Availability:{" "}
        <span style={data.Availability === "100" ? {color : "green"} : {color:"red"}}>
          {data.Availability}
        </span>
      </p>
    );
  };

  // const onClickToggle = (e) => {
  //   if (toggle) {
  //     setToggle(false);
  //     setAtoData(atoDuplicate);
  //   } else {
  //     setToggle(true);
  //     const letObj = {
  //       ...atoFetchData,
  //       ATO: atoFetchData.ATO.map((fil) => {
  //         return {
  //           ...fil,
  //           serv: fil.serv.filter((ser) => ser.simulation === true),
  //         };
  //       }),
  //     };
  //     setAtoData(letObj);
  //   }
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 5000);
  // };

  return (
    <FlexGrid fullWidth>
      <Row>
        <Column lg={12} md={8} sm={4}>
          <Breadcrumb noTrailingSlash>
            <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
            <BreadcrumbItem href="#/valueStream">
              IT Value Stream Observability
            </BreadcrumbItem>
            {sessionStorage.getItem("norfolkName") !== null ? <BreadcrumbItem isCurrentPage>{sessionStorage.getItem("norfolkName")}</BreadcrumbItem> : null}
          </Breadcrumb>
        </Column>
      </Row>
      <Row className="ato-row">
        {/* <Tabs>
                <TabList aria-label="List of tabs">
                <Tab onClick={() => onClickTabs("application")}>Application View</Tab>
                <Tab onClick={() => onClickTabs("business")}>Business View</Tab>
                </TabList>
                <TabPanels>
                <TabPanel>   
                <Row>             
                {!isLoading && Object.entries(atoFetchData).length > 0 ? atoFetchData.ATO.map((data, index) => data.category === "Application" && (
                    <Column
                        lg={5}
                        md={4}
                        sm={4}
                        className="clickable--tile insights-hb"
                        key={index}
                    >
                        <ClickableTile
                            id={index}
                            className={!data.disabled ? "" : "disable-tile"}
                            aria-label={`clickableTile-${index}`}
                        >
                            <h5 className="report-title">
                                {data.title}
                            </h5>
                            <ATOChart data={data.metrics} dat={data} onClickAtoChart={onClickAtChart}  />
                            {getIncTileInfo(data)}
                        </ClickableTile>
                    </Column>
                )) : [1,2,3,4].map(() => {
                    return <Column
                            lg={4}
                            md={4}
                            sm={4}
                            className="clickable--tile insights-hb"
                        >
                            <ClickableTile>
                            <SkeletonText />
                            </ClickableTile>
                </Column>
                })}
                </Row>
                {chartTitle.Title === "component" && 
                <AtoBusiness name={chartTitle.data.title} chartTitle={chartTitle} atoFetch={atoFetchData} flow="component" />
                }
                </TabPanel>
      <TabPanel> */}
        {/* <Row> */}
        {atoFetchData.length > 0 &&
          atoFetchData.map((data, index) => (
            <Column
              lg={4}
              md={4}
              sm={4}
              className="clickable--tile insights-hb business"
              key={index}
            >
              <ClickableTile
                id={index}
                onClick={() => handleOnClickTile(data[["Flow Name"]], data)}
                className={!data.disabled ? "" : "disable-tile"}
                aria-label={`clickableTile-${index}`}
              >
                <div className="ato-title">
                  <h4 className="report-title">{data["Flow Name"]}</h4>
                  {/* <ul className="type">
                        {data.type.length > 0
                          ? data.type.map((type) => <li>{type}</li>)
                          : null}
                      </ul> */}
                </div>
                <ATOChart
                  data={data.metrics}
                  onClickAtoChart={onClickAtoChart}
                />
                {getPercent(data)}
              </ClickableTile>
            </Column>
          ))}
        {/* </Row> */}
        {
          Object.entries(chartTitle).length > 0 && (
            // chartTitle.Title === chartTitle.data.title && (
            <>
              <NorkfolkBusiness
                name={chartTitle.Title}
                chartTitle={chartTitle}
                atoFetch={atoFetchData}
                flow=""
              />
            </>
          )
          // )
        }
        {/* </TabPanel>
    </TabPanels>
    </Tabs> */}
      </Row>
    </FlexGrid>
  );
};

export default NorkfolkDashboard;
