import React, { useState, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "@tanstack/react-query";
import { getDoRemediation } from "../Services/ServerApi";
import CarbonDataTable from "Carbon-Components/DataTable";
import SFGarage from "./SalesFG.json";
import ErrorWhileFetching from "../../ICPSA/utilities/ErrorWhileFetching";
import {
    ErrorBoundary,
    SkeletonPlaceholder,
    Tag
  } from "@carbon/react";

const tableHeaderData = [
    {
      key: "id",
      header: "Name",
    },
    {
      key: "alertDesc",
      header: "Description",
    },
    {
      key: "remedType",
      header: "Type",
    }
  ];

const DoRemediation = (props) => {
const { keycloak } = useKeycloak();
const [isAPIDataLoading, setIsAPIDataLoading] = useState(true);
const [tableRowData, setTableRowData] = useState();

const {
    data: remediationData,
    isLoading: loading1,
    isError: err1,
    isFetching: fetching1,
    refetch: refetch1
} = useQuery(
    [`remediationDO`],
    async () => await getDoRemediation(keycloak, props.tableData[0]?._source?.alert?.name), { enabled: false }
);

  useEffect(() => {

    if(sessionStorage.getItem("tenant") !== "SFGarage") {
      refetch1()
    }
    
  }, [])

    useEffect(() => {
        console.log("remediationData", remediationData)
        if(remediationData !== undefined) {
            setIsAPIDataLoading(false);
            updateTableRowData(remediationData?.result);
        }
    }, [remediationData])

    const isUnique = (obj, array) => {
        if (array.length > 0) {
          if (array.find(item => item.id === obj.id) === undefined) {
            return true;
          } else {
            return false;
          }
        } else { return true; }
      }

    const updateTableRowData = (data) => {
        let tableRowDataArray = [];
      console.log("data =====================", data)
      data !== undefined &&
        data["execution-results"]?.results.forEach((obj) => {
          let tableRow = {};
            console.log("obj", obj)
          tableRow.id = obj.value["com.aiops.remediation_config.AlertRemedConf"].alertName;
          tableRow.alertDesc = obj.value["com.aiops.remediation_config.AlertRemedConf"].alertDesc;
          tableRow.remedType = obj.value["com.aiops.remediation_config.AlertRemedConf"].remedType;
    
          if (isUnique(tableRow, tableRowDataArray)) {
            tableRowDataArray.push(tableRow);
          }
        });
        setTableRowData(tableRowDataArray);
    };

    const getLoading = () => {
        return isAPIDataLoading ? (
          <div className="skeletonPlaceholder--it-health">
            <SkeletonPlaceholder />
          </div>
        ) : (
          <ErrorWhileFetching errMessage="" />
        );
    };

    const getRowCellData = (id, data, row) => {
        return data;
      };
    
    const getTableHeader = (header) => {
        return header;
    };

    const getRunbookAction = () => {
      const Incident = props.tableData !== undefined ? props.tableData[0] : {};
      let type = Incident._source?.source?.type;
      let subType = Incident?._source?.source.subtype !== null ? Incident?._source?.source.subtype : type;
      const actionArray = SFGarage.pMonitoring
      .filter(item => item.type.includes(type)) // Match the type
      .flatMap(item => item.subtype !== null ? item.subtype : type) // Flatten subtypes
      .filter(sub => sub.name.toLowerCase().includes(subType.toLowerCase()) || sub.name.toLowerCase().includes(type.toLowerCase()) || subType !== type ? sub.name : "") // Match the subtype
      .flatMap(sub => sub.action); // Extract the action array

      return <div>
        <h5>Next Best Actions from SOP</h5>
        <ul className="nxt-action">
          {actionArray.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>
    }

    const getTable = () => {
      const Incident = props.tableData !== undefined ? props.tableData[0] : {};
        return Incident._source !== undefined && Incident._source?.source?.name !== undefined && Incident._source?.source?.name.includes("Salesforce")  ? getRunbookAction() : isAPIDataLoading ? (
          getLoading()
        ) : (
          <div className="common-top-margin">
            <ErrorBoundary fallback={<ErrorWhileFetching />}>
              <CarbonDataTable
                rowData={tableRowData}
                headerData={tableHeaderData}
                getRowCellData={getRowCellData}
                getTableHeader={getTableHeader}
                actionsNeeded={false}
              />
            </ErrorBoundary>
          </div>
        );
    };

    console.log("props ================ >>>>>>>>>>>>>>>>", props, props._source?.source?.name, props._source?.source?.name.includes("Salesforce"))

  return (
    <>
        {getTable()}
    </>
  ) 
};

export default DoRemediation;