import { LineChart } from "@carbon/charts-react";
import {
  ClickableTile,
  Column,
  ErrorBoundary,
  Grid,
  Modal,
  ProgressBar,
  SkeletonPlaceholder,
  Tile
} from "@carbon/react";
import { Launch } from "@carbon/react/icons";
import { useKeycloak } from "@react-keycloak/web";
import React, { useCallback, useContext, useEffect, useState } from "react";

import Context from "Context/Context";
import GenAISummaryFeedback from "../AppPerformanceAvailabililty/GenAISummaryFeedback";
import GenAISummary from "../AppPerformanceAvailabililty/GenAiSummary";
import RunBookAutomationCommon from "../AppPerformanceAvailabililty/TraceComponent/RunbookAutomationCommon";
import SimilarRCATicketsGenAI from "../AppPerformanceAvailabililty/TraceComponent/SimilarRCATicketsGenAI";
import { getGenAISummaryAPI, getPlatformLogsDetails, getPlatformMetricsDetails, getProbableRCA } from "../Services/ServerApi";
import AddUserActivity from "../utilities/AddUserActivity";
import { updateErrorMessage } from "../utilities/CommonReportUtility";
import ErrorWhileFetching from "../utilities/ErrorWhileFetching";
import ErrorWhileFetchingShort from "../utilities/ErrorWhileFetchingShort";
import AlertStatusPopup from "./AlertStatusPopup";
import RunbookRecommendation from "./RunbookRecommendation";


const PlatformMetricsReport = () => {
  const { keycloak } = useKeycloak();
  const context = useContext(Context);
  const [isAPIDataLoading, setIsAPIDataLoading] = useState(true);
  const [errMessage, setErrorMessage] = useState("");
  const [apiData, setAPIData] = useState("");

  const [errMessageLogs, setErrorMessageLogs] = useState("");
  const [apiDataLogs, setAPIDataLogs] = useState("");
  const [errMessageGenAI, setErrorMessageGenAI] = useState("");
  const [genAISummary, setGenAISummary] = useState("");
  const [showGenAITile, setShowGenAITile] = useState(false);

  const [isAlertData, setIsAlertData] = useState(true);

  const [probableRootCause, setProbableRootCause] = useState("");
  const [similarRCA, setSimilarRCA] = useState("");
  const [runBookAutomation, setRunBookAutomation] = useState("");
  const [runbookRecommendation, setRunBookRecommendation] = useState("");
  const [showPopUP, setShowPopUP] = useState(false);
  const [showPopUPRunbook, setShowPopUPRunbook] = useState(false);
  const [showPopUPRunbookRec, setShowPopUPRunbookRec] = useState(false);
  const [namespace, setNamespace] = useState("");
  const [hover, setHover] = useState(false);

  const [errMessageRCA, setErrorMessageRCA] = useState("");

  const genAISteps = ["RCA Summarization", "Remediation Recommendation", "Finalizing"]
  const size = 3;
  const [progress, setProgress] = useState(0);

  const [alertStatusPopup, setAlertStatusPopup] = useState(false)
  const [platformAlert, setPlatformAlert] = useState();
  const [genAIInput, setGenAIInput] = useState({});

  useEffect(() => {
    let platformAlert = JSON.parse(sessionStorage.getItem("platformAlert"));
    setPlatformAlert(platformAlert);
    if (platformAlert) {
      if (platformAlert.overall_state === "open") {
        getAPIData(platformAlert);
        getAPIDataLogs(platformAlert);
      } else {
        setNamespace(getNamespace());
        getProbableRCAData(platformAlert.description, getMetric(platformAlert));
      }
    } else {
      setIsAlertData(false);
    }
  }, []);

  useEffect(() => {
    if (showGenAITile) {
      setProgress(0);
      if (genAISummary || errMessageGenAI) {

      } else {
        setTimeout(() => {
          const interval = setInterval(() => {
            setProgress(currentProgress => {
              if (currentProgress > size || genAISummary || errMessageGenAI) {
                clearInterval(interval);
              }
              return currentProgress + 0.05;
            });
          }, 50);
        }, 3000);
      }
    }
  }, []);

  const getNamespace = () => {
    let platformHost = JSON.parse(sessionStorage.getItem("platformHost"));
    switch (platformHost.monitoring_tool_specific.monitored_by) {
      case "Instana": return "VMs";
      case "AWSCloudWatch": return "AWS/EC2";
      case "Datadog": return "VMs";
    }
  }

  const getMetric = (platformAlert) => {
    return platformAlert.metrics.name;
  }

  const getProbableRCAData = async (anomaly, metrics) => {
    const response = await getProbableRCA(keycloak.token, anomaly, metrics);
    AddUserActivity(keycloak, context, "FEATURE", "Probable Root Cause", response);

    setIsAPIDataLoading(false);

    if (!response.data) {
      setErrorMessageRCA("Error while fetching data");
    }

    if (response.status >= 400 || response === "Error") {
      // if bff fails
      setErrorMessageRCA("Error while fetching data");
    } else {
      setProbableRootCause(getTextBoxData("Probable Root Cause", response.data.data["Probable root cause"]));
      setSimilarRCA(getTextBoxData("Similar Incidents", ""));
      setRunBookAutomation(getTextBoxData("RunBook Automation", ""));
      setRunBookRecommendation(getTextBoxData("RunBook Recommendation", ""));
    }
  }

  const getAPIData = async (platformAlert, sDate, eDate) => {
    const response = await getPlatformMetricsDetails(
      keycloak.token,
      sDate,
      eDate,
      platformAlert.id,
      platformAlert.hostId
    );
    AddUserActivity(keycloak, context, "FEATURE", "Platform Metrics", response);

    setIsAPIDataLoading(false);
    setErrorMessage(updateErrorMessage(response));
    setAPIData(response.data);

    setNamespace(getNamespace());
    getProbableRCAData(platformAlert.description, getMetric(platformAlert));

    return response.data;
  };

  const getGenAISummary = async (anomaly, metrics, log) => {

    let genAIInputObj = {};
    genAIInputObj.anomaly = anomaly;
    genAIInputObj.metrics = metrics;
    genAIInputObj.log = log;
    setGenAIInput(genAIInputObj);

    setShowGenAITile(true);
    const response = await getGenAISummaryAPI(keycloak.token, anomaly, metrics);
    AddUserActivity(keycloak, context, "FEATURE", "GenAI Summary", response);
    // setIsAPIDataLoading(false);
    if (!response.data) {
      setErrorMessageGenAI("Error while fetching data");
    }
    if (response.status >= 400 || response === "Error") {
      // if bff fails
      setErrorMessageGenAI("Error while fetching data");
    } else {
      // setGenAISummary(response.data.data);
      setGenAISummary(response.data.data);
      if (response?.data?.data?.reason && response?.data?.data?.remediation) {
        setShowGenAITile(true);
      } else {
        setShowGenAITile(false);
      }
    }
  }

  const getAPIDataLogs = async (platformAlert, sDate, eDate) => {

    const responseLogs = await getPlatformLogsDetails(
      keycloak.token,
      sDate,
      eDate,
      platformAlert.id,
      platformAlert.hostId
    );
    AddUserActivity(keycloak, context, "FEATURE", "Platform Logs", responseLogs);

    // setIsAPIDataLogsLoading(false);
    setErrorMessageLogs(updateErrorMessage(responseLogs));
    setAPIDataLogs(responseLogs.data);

    getGenAISummary(platformAlert.description, getMetric(platformAlert), responseLogs.data && responseLogs.data.data.rowData.length > 0 ? responseLogs.data.data.rowData[0].message?.slice(0, 100) : "");

    if (!responseLogs.data) {
      setErrorMessageLogs("Error while fetching data");
    }
  };

  const getTextBoxData = (titleText, value) => {
    let data = { title: "", value: "" };
    data.title = titleText;
    data.value = value;
    return data;
  }

  const handleOnClick = useCallback(() => {
    const link = "https://appops-devops.slack.com/archives/C03EQQ6LFC7";
    const anchor = document.createElement('a');
    anchor.href = link;
    anchor.target = "_blank"; 
    anchor.rel = "noopener noreferrer"; 
    document.body.appendChild(anchor);
    anchor.click(); 
    document.body.removeChild(anchor); 
}, []);


  const onHover = () => {
    setHover(true);
  };

  const onOut = () => {
    setHover(false);
  };

  const getRCATextTile = (tile) => {
    return (
      < ClickableTile className="prca" onClick={() => handleOnClick()}
        onMouseEnter={onHover}
        onMouseLeave={onOut}>

        <h5 className="text-tile">{tile.title}</h5>
        <p>{!hover && tile.value}</p>
        {hover && <p>{tile.value}</p>}
      </ClickableTile >
    );
  }

  const getSimilarRCATextTile = (tile) => {
    return (
      < ClickableTile className="chart-container" onClick={() => setShowPopUP(true)}      >
        <h5 className="text-tile">{tile.title}</h5><p><img src={require(`../../../Assets/carbonIcons/launch.svg`).default} className="launch-icon" alt="Similar Incidents" /></p>
      </ClickableTile>
    );
  }

  const getRunBookAutomationTile = (tile) => {
    return (
      < ClickableTile className="chart-container" onClick={() => setShowPopUPRunbook(true)}      >
        <h5 className="text-tile">{tile.title}</h5><p><img src={require(`../../../Assets/carbonIcons/launch.svg`).default} className="launch-icon" alt="Runbook Automations" /></p>
      </ClickableTile>
    );
  }

  // const getRunBookCodeRecommendationTile = (tile) => {
  //   return (
  //     < ClickableTile className="chart-container" onClick={() => setShowPopUPRunbookRec(true)}      >
  //       <h5 className="text-tile">{tile.title}</h5><p><img src={require(`../../../Assets/carbonIcons/launch.svg`).default} className="launch-icon" alt="Runbook recommendations" /></p>
  //     </ClickableTile>
  //   );
  // }

  const getAlertTextTile = (name, description) => {
    return (
      <Tile className="chart-container">
        <h5 className="text-tile">Alert</h5>
        <p>Query: {name}</p>
      </Tile>
    );
  };

  const getAlertApiTile = (event_id) => {
    if (event_id) {
      return (
        <ClickableTile className="chart-container" onClick={() => setAlertStatusPopup(true)}>
          <h5 className="text-tile">
            Check current Alert status
          </h5>
          <p><Launch /></p>
        </ClickableTile>
      );
    }
    else {
      return null
    }
  }

  const getPopupData = () => {
    return (
      <ErrorBoundary fallback={<ErrorWhileFetching />}>
        {/* <SimilarRCATickets
          probableRCA={probableRootCause.value}
          namespace={namespace}
        /> */}
        <SimilarRCATicketsGenAI
          namespace={namespace}
          // title={getUpdatedAnomaly()}
          probableRCA={probableRootCause.value}
        />
      </ErrorBoundary>
    );
  };

  const getPopupDataRunBook = () => {
    return (
      <ErrorBoundary fallback={<ErrorWhileFetching />}>
        <RunBookAutomationCommon
          servicetype={namespace}
          rootcause={probableRootCause.value}
          title={JSON.parse(sessionStorage.getItem("platformAlert")).description}
        />
      </ErrorBoundary>
    );
  };

  const loadSkeleton = () => {
    return isAPIDataLoading ? (
      <div className="skeletonPlaceholder--it-health-short">
        <SkeletonPlaceholder />
      </div>
    ) : (
      <ErrorWhileFetching errMessage={errMessage} />
    );
  };

  const getLoadingRCA = () => {
    return isAlertData ?
      <Column lg={16}>
        {
          errMessageRCA ?
            <ErrorWhileFetchingShort errMessage={errMessageRCA} />
            :
            <div className="skeletonPlaceholder--it-health-short">
              <SkeletonPlaceholder />
            </div>
        }
      </Column>
      : "";
  }

  const getProgressLoader = () => {
    const running = progress > 0;
    let helperText = running ? `Processing data for ${genAISteps[Math.floor(progress)]}...` : 'Fetching data...';

    if (progress >= size) {
      helperText = 'Done';
    }
    return <ProgressBar value={running ? progress : null} max={size} status={progress === size ? 'finished' : 'active'} label={<h6>Augmented Assistance</h6>} helperText={helperText} />;
  }

  const getLoadingGenAI = () => {
    return <Column lg={16}>
      {
        errMessageGenAI ?
          <ErrorWhileFetchingShort errMessage={errMessageGenAI} />
          :
          <div className="skeletonPlaceholder--it-health-short">
            {getProgressLoader()}
          </div>
      }
    </Column >
  }

  // const getLoadingLogs = () => {
  //   return <Column lg={16}>
  //     {
  //       errMessageLogs ?
  //         <ErrorWhileFetchingShort errMessage={errMessageLogs} />
  //         :
  //         <div className="skeletonPlaceholder--it-health-short">
  //           <SkeletonPlaceholder />
  //         </div>
  //     }
  //   </Column>
  // }

  // const getTitle = (title) => {
  //   return <h5 className="text-tile">{title}</h5>
  // }

  // const highlightError = (data) => {
  //   data.forEach((obj) => {
  //     obj.message = <><span className="common-text--highlight">{obj.alarm_reason}</span> {obj.message}</>;
  //   })
  //   return data;
  // }

  return (
    <div className="content-div trace">

      <div className="container-multichart">
        <Grid>
          <Column lg={8}>
            {getAlertTextTile(
              JSON.parse(sessionStorage.getItem("platformAlert")).name,
              JSON.parse(sessionStorage.getItem("platformAlert")).description
            )}
          </Column>
          <Column lg={8}>
            {getAlertApiTile(JSON.parse(sessionStorage.getItem("platformAlert")).event_id)}
          </Column>
          {
            JSON.parse(sessionStorage.getItem("platformAlert")).overall_state === "open" ?
              (
                apiData ? (
                  <Column lg={16}>
                    <Tile className="chart-container">
                      <LineChart
                        data={apiData.data[0].data.chartData}
                        options={apiData.data[0].data.chartOptions}
                      ></LineChart>
                    </Tile>
                  </Column>
                ) : (
                  <Column lg={16}>
                    {
                      loadSkeleton()
                    }
                  </Column>
                )
              ) : ""
          }
          {
            probableRootCause ? <>
              <Column lg={6}>{getRCATextTile(probableRootCause)}</Column>
              <Column lg={5}>{getSimilarRCATextTile(similarRCA)}</Column>
              <Column lg={5}>{getRunBookAutomationTile(runBookAutomation)}</Column>
              {/* <Column lg={4}>{getRunBookCodeRecommendationTile(runbookRecommendation)}</Column> */}
            </>
              :
              getLoadingRCA()
          }
          {
            showGenAITile ?
              genAISummary ?
                <Column lg={16} md={8} sm={4}>
                  <GenAISummary
                    data={genAISummary}
                    type="pi"
                    platformAlert={platformAlert}
                    genAIInput={genAIInput}
                  />
                </Column>
                :
                getLoadingGenAI()
              : ""
          }
          {
            JSON.parse(sessionStorage.getItem("platformAlert")) && JSON.parse(sessionStorage.getItem("platformAlert")).overall_state !== "open" ?
              <Column lg={16} md={8} sm={4}>
                <GenAISummaryFeedback
                  // data={genAISummary}
                  type="pi"
                  platformAlert={JSON.parse(sessionStorage.getItem("platformAlert"))}
                // genAIInput={genAIInput}
                />
              </Column>
              : ""
          }
          {/* {
            JSON.parse(sessionStorage.getItem("platformAlert")).overall_state === "open" ?
              (
                apiDataLogs ? (
                  <>
                    <Column lg={16} className="common-top-margin">
                      {apiDataLogs.data.rowData &&
                        apiDataLogs.data.rowData.length > 0 ? (
                        <ErrorBoundary fallback={<ErrorWhileFetching />}>
                          <CarbonDataTableLogs
                            rowData={highlightError(JSON.parse(JSON.stringify(apiDataLogs.data.rowData)))}
                            headerData={apiDataLogs.data.headerData}
                            title="Logs"
                            getRowCellData={getRowCellData}
                            getTableHeader={getTableHeader}
                          />
                        </ErrorBoundary>
                      ) : (
                        <Column lg={16} md={8} sm={4}>
                          <DataUnAvailable
                            description="Error due to data unavailablity for the selected Alert"
                            title="Logs are unavailable"
                          />
                        </Column>
                      )}
                    </Column>
                  </>
                ) :
                  getLoadingLogs()
              )
              : ""
          } */}
        </Grid>
        {
          showPopUP ? (
            <Modal
              open
              size="lg"
              passiveModal
              onRequestClose={() => setShowPopUP(false)}
              modalHeading="Similar Incidents"
            >
              {getPopupData()}
            </Modal>
          ) : (
            ""
          )
        }
        {
          showPopUPRunbook ? (
            <Modal
              open
              size="lg"
              passiveModal
              onRequestClose={() => setShowPopUPRunbook(false)}
              modalHeading="Prescribed Runbook Automations"
            >
              {getPopupDataRunBook()}
            </Modal>
          ) : (
            ""
          )
        }
        {
          alertStatusPopup ? (
            <AlertStatusPopup close={() => setAlertStatusPopup(false)} event_id={(JSON.parse(sessionStorage.getItem("platformAlert")).event_id)} />
          ) : (
            ""
          )
        }
        {
          showPopUPRunbookRec ? (
            <Modal
              open
              size="lg"
              passiveModal
              onRequestClose={() => setShowPopUPRunbookRec(false)}
              modalHeading="Runbook Recommendation"
            >
              <RunbookRecommendation />
            </Modal>
          ) : (
            ""
          )
        }
      </div >
    </div>
  );
};

export default PlatformMetricsReport;
