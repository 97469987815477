import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Column,
  ErrorBoundary,
  FlexGrid,
  Row,
} from "@carbon/react";

import Chatbot from "./Chatbox";
import { getConversation, getMessages } from "./Helper";
import Sidebar from "./Sidebar";
import { useKeycloak } from "@react-keycloak/web";
import { attachClickHandlers } from "Common-Modules/Utilities";

const TelcoDashboard = () => {
  const [chatHistory, setChatHistory] = useState([]);
  const [likedMessageIds, setLikedMessageIds] = useState([]);
  const [dislikedMessageIds, setDislikedMessageIds] = useState([]);
  const [allConversations, setAllConversations] = useState({});
  const [inputValueCopy, setInputValueCopy] = useState("");
  const [controller, setController] = useState(null);

  const [conversationData, setConversationData] = useState({
    conversation_id: "",
    title: "",
    status: "",
  });
  const { keycloak } = useKeycloak();


  const [isLoading, setIsLoading] = useState(false);
  const [isNavLoading, setIsNavLoading] = useState(false);
  const [enableSidebar, setEnableSidebar] = useState(true);
  useEffect(() => {
    // Call the function after the component mounts and the DOM is rendered
    attachClickHandlers();
  }, [chatHistory]); 

  useEffect(() => {
    setIsNavLoading(true);
    setLikedMessageIds([]);
    setDislikedMessageIds([]);
    const userName = keycloak.tokenParsed.email;
    getConversation({userName})
      .then((data) => {
        setAllConversations({ ...data });
        setIsNavLoading(false);
      })
      .catch((error) => {
        setIsNavLoading(false);
        console.error("Error:", error);
      });
  }, []);

  const loadMessages = (conversation_id) => {
    if (controller) {
      controller.abort();
    }
    // setIsLoading(true);
    getMessages({ conversation_id })
      .then((data) => {
        const formattedMessages = data.map((msg) => {
          return {
            question: msg.user_query,
            answer: JSON.parse(msg.watsonx_response),
            messageId: msg.message_id,
            feedback_resp_models: msg?.feedback_resp_models,
          };
        });

        setIsLoading(false);

        setConversationData({
          conversation_id: conversation_id,
          title: "",
          status: "",
        });

        setChatHistory(formattedMessages);
        setInputValueCopy("")
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });
  };

  const getAllConversations = () => {
    const userName = keycloak.tokenParsed.email;
    getConversation({userName})
      .then((data) => {
        setAllConversations({ ...data });
        setIsNavLoading(false);
      })
      .catch((error) => {
        setIsNavLoading(false);
        console.error("Error:", error);
      });
  };

  const reloadNavItems = () => {
    // setIsNavLoading(true);
    getAllConversations()
  };

  const getChatBoxJsx = () => {
    return (
      <ErrorBoundary fallback={<h3>Something went wrong.</h3>}>
        <Chatbot
          likedMessageIds={likedMessageIds}
          setLikedMessageIds={setLikedMessageIds}
          dislikedMessageIds={dislikedMessageIds}
          setDislikedMessageIds={setDislikedMessageIds}
          chatHistory={chatHistory}
          setChatHistory={setChatHistory}
          conversationData={conversationData}
          setConversationData={setConversationData}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          reloadNavItems={reloadNavItems}
          setInputValueCopy={setInputValueCopy}
          inputValueCopy={inputValueCopy}
          controller={controller}
          setController= {setController}
        />
      </ErrorBoundary>
    );
  };

  return (
    <FlexGrid className="rcaContainer">
      <Row className="breadcrumbs">
        <Column lg={12} md={8} sm={4}>
          <Breadcrumb noTrailingSlash>
            <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
            {/* <BreadcrumbItem href="#/operations">Operations</BreadcrumbItem> */}
            <BreadcrumbItem isCurrentPage>NOC AI Assistant</BreadcrumbItem>
          </Breadcrumb>
        </Column>
      </Row>
      <Row className="chat_container">
      <ErrorBoundary fallback={<h6>Something went wrong.</h6>}>
        <Sidebar
          setIsNavLoading={setIsNavLoading}
          setLikedMessageIds={setLikedMessageIds}
          setDislikedMessageIds={setDislikedMessageIds}
          setAllConversations={setAllConversations}
          setIsLoading={setIsLoading}
          setConversationData={setConversationData}
          conversationData={conversationData}
          allConversations={allConversations}
          setChatHistory={setChatHistory}
          loadMessages={loadMessages}
          enableSidebar={enableSidebar}
          isNavLoading={isNavLoading}
          setEnableSidebar={setEnableSidebar}
          getAllConversations={getAllConversations}
          setInputValueCopy={setInputValueCopy}
          controller={controller}
          reloadNavItems={reloadNavItems}
          key={allConversations}
        />
        </ErrorBoundary>
        {enableSidebar && (
          <Column lg={13} md={13} sm={13} xs={16}>
            {getChatBoxJsx()}
          </Column>
        )}

        {!enableSidebar && (
          <Column lg={15} md={15} sm={15} xs={15}>
            {getChatBoxJsx()}
          </Column>
        )}
      </Row>
    </FlexGrid>
  );
};

export default TelcoDashboard;
