import { InlineLoading } from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import { QueryCache, useQuery } from "@tanstack/react-query";
import { getCitiSesssionDetails } from "Services/ServerApi";
import React, { useEffect, useState } from "react";

const SessionDetails = ({ sessionDet }) => {
    const { keycloak } = useKeycloak();
    const queryCache = new QueryCache();
    const [errorMsg, setErrorMsg] = useState("");
    const [params, setParams] = useState("");
    const [sessionsData, setSessionData] = useState([]);

    const { data: session_details, refetch, isFetching } = useQuery(['citi_session_details'],async () => await getCitiSesssionDetails(keycloak, sessionDet.sessionId, sessionDet.clustername, params), { cache: queryCache, retry: ({ message: status }) => (status !== '404' && status !== '401') });

    useEffect(() => {
        if (session_details && session_details.length > 0) {
            setSessionData(session_details);
            setErrorMsg("");
        } else if (session_details && session_details.err) {
            setErrorMsg(session_details.message || "Unknown error occurred.");
        } else if (session_details && session_details.error !== undefined) {
            setParams("SESSION_ATTRIBUTES");
            if (params === "SESSION_ATTRIBUTES") {
                setErrorMsg(session_details.error || "Error occurred during fetching session attributes.");
            }
        } else {
            setErrorMsg("Timed Out. Please try again.");
        }
    }, [session_details, params]);
    

    useEffect(() => {
        if (params !== "") {
            refetch()
        }
    }, [params])

    const getSessionData = () => {
        return sessionsData.length > 0 && sessionsData.map((err) => {
            const newEr = Object.entries(err).map(([key, value]) => ({ key, value }))
            return <ul className="session-log" key={err.SESSION_NAME}>{newEr.map((erlog) => {
                return <li key={`${err.SESSION_NAME}-${erlog.key}-${erlog.value}`}>
                    <h5>{erlog.key}</h5><span>{erlog.value}</span>
                </li>
            })}</ul>
        })
    }

    const retryApi = () => {
        refetch()
    }

    return (
        <>
            {isFetching ? <InlineLoading
                description={"Fetching Session Details"}
                className="event-loader"
            /> : !isFetching && errorMsg !== "" ? <div className="error">{errorMsg} <span className="retry" onClick={() => retryApi()}>Retry</span></div> : getSessionData()}
        </>
    )
}

export default SessionDetails;
