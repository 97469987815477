import React, { useEffect, useRef } from 'react';
import { HeatmapChart } from "@carbon/charts-react";
import { InlineLoading, Tooltip } from '@carbon/react';
import { Information } from "@carbon/react/icons/index";
import { useTranslation } from 'react-i18next';
import TableToolbarDownload from './TableToolbarDownload';

const DashboardHeatmapChart = (props) => {
    const chartRef = useRef(null);
    const { t } = useTranslation();
    // const chartOnClick = ({ detail }) => {
    //     console.log(detail, "detail")
    // }

    // useEffect(() => {
    //     chartRef?.current?.chart?.services?.events.addEventListener("heatmap-click", chartOnClick);
    // }, [chartRef.current]);

    // useEffect(() => () => {
    //     if (chartRef.current) {
    //         chartRef.current.chart.services.events.removeEventListener("heatmap-click", chartOnClick);
    //     }
    // }, []);

    const graphOptions = {
        "title": props?.modalTitle || "",
        "axes": {
            "bottom": {
                "title": props.bottomAxestitle || "Bottom title",
                "mapsTo": props.bottomMapsTo || "day",
                "scaleType": props.bottomScaleType || "labels",
                "domain": props.bottomDomain || null,
                "ticks": {
                    "rotation": props.bottomTicksRotation || 'auto'
                }
            },
            "left": {
                "title": props.leftAxestitle || "Left title",
                "mapsTo": props.leftMapsTo || "group",
                "scaleType": props.leftScaleType || "labels",
                "domain": props.leftDomain || null,
                "ticks": {
                    "rotation": props.leftTicksRotation || 'auto'
                }
            }
        },
        "heatmap": {
            "colorLegend": {
                "title": props.colorLegendTitle || "Legend Title"
            }
        },
        "legend": {
            "position": props.legendPosition || "top",
            "orientation": props.legendOrientation || "horizontal",
            "alignment": props.legendAlignment || "right",
            "enabled": props.legendEnabled === false ? false : true
        },
        "data": {
            "loading": props.loading || false
        },
        "color": {
            "gradient": {
                "enabled": true,
                "colors": props.colors || ["#ffd7d9", "#ffb3b8", "#ff8389", "#fa4d56", "#da1e28", "#a2191f", "#750e13"]
            }
        },
        "experimental": true,
        "toolbar": { "enabled": props.toolbarEnabled === false ? false : true },
        "height": props.height ? props.height : "400px",
        "tooltip": {
            "enabled": props.tooltipEnabled === false ? false : true,
            "showTotal": props.showTotal === false ? false : true
        }
    }

    const headers = [{ key: 'group', header: props.leftAxestitle || t('DigitalOperations.Group') }, { key: 'day', header: props.bottomAxestitle || "y-axis" },
    { key: 'value', header: t('DigitalOperations.Value') }];


    const getTitleWithToolTip = () => {
        return (
            <div>
                {props.title ?
                    <div style={{ display: "flex" }}>
                        <div className="graphTitle">{props.title}
                            {props.graphDescription && <span>
                                <Tooltip direction="right"
                                    label={props.graphDescription}
                                    style={{ color: "black", fontSize: "14px" }}>
                                    <button
                                        className="tooltip-button"
                                        type="button"
                                    >
                                        <Information />
                                    </button>
                                </Tooltip>
                            </span>}
                        </div>
                    </div> : null}
            </div>
        )
    }

    return (
        <>
            <div className="">
                <div className="graph-title carbon-display-Regular-20">
                    {getTitleWithToolTip()}
                    {props.hideDownloadButton !== true && (!props?.data?.length > 0 || props?.data?.every(item => item.value === 0)) &&
                        <div className="download-button">
                            <TableToolbarDownload iconDescription={t('DigitalOperations.export')} rows={props.data || []} headers={headers} title={props.title} filename={props.title || t('DigitalOperations.export')} label={t('DigitalOperations.download')} />
                        </div>}
                </div>
                {!graphOptions?.data?.loading && (!props?.data?.length > 0 || props?.data?.every(item => item.value === 0)) ?
                    <>
                        <div>{t('DigitalOperations.noDataAvailable')}</div>
                    </>
                    :
                    graphOptions.data.loading ?
                        <>
                            <div><InlineLoading description={t('DigitalOperations.loading')} /></div>
                        </>
                        :
                        <div>
                            <HeatmapChart
                                ref={chartRef}
                                data={props.data}
                                options={graphOptions}>
                            </HeatmapChart>
                        </div>
                }
            </div>
        </>
    );
};

export default DashboardHeatmapChart;
