import { useKeycloak } from "@react-keycloak/web";
import DefaultModal from "Carbon-Components/Modals/CommonModal";
import PassiveModal from "Carbon-Components/Modals/PassiveModal";
import InlineLoader from "Components/Common/loaderModule/InlineLoader";
import React, { useState } from "react";
import { deleteTenantDetails } from "Services/RBACServerApi";
import { v4 as uuidv4 } from "uuid";

const DeleteTenant = ({ cancel, tenant }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [resMsg, setResMsg] = useState("")
  const [refresh, setRefresh] = useState(false)
  const { keycloak } = useKeycloak()

  const onSubmit = async () => {
    setIsLoading(true)
    const res = await deleteTenantDetails(keycloak, uuidv4(), "", tenant.name)
    if (res?.data?.data?.success) {
      setRefresh(true)
      setResMsg("Tenant Deleted Succesfully")
    } else {
      setRefresh(false)
      setResMsg("Failed to Delete Tenant")
    }
    setIsLoading(false)
  }

  if (resMsg) {
    return <PassiveModal canCloseDetailsPopup={() => cancel(refresh)} >
      <h4>{resMsg}</h4>
    </PassiveModal>
  }

  return (
    <DefaultModal
      heading="Alert"
      primaryText="Submit"
      secondaryText="Cancel"
      primaryButtonDisabled={isLoading}
      onModalSubmit={() => onSubmit()}
      onCloseModal={() => cancel(false)}
      onRequestClose={() => cancel(false)}
    >
      {
        isLoading
          ? <InlineLoader status="active" description="Deleting Tenant..." />
          : <>
            <p>Are you sure you want to Delete the Tenant?</p>
            <p className="mt-1"><strong>Tenant Name: </strong> {tenant.name}</p>
          </>
      }
    </DefaultModal>
  );
}

export default DeleteTenant