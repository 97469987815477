/** @format */

import {
  AreaChart,
  BubbleChart,
  BulletChart,
  CirclePackChart,
  ComboChart,
  DonutChart,
  GaugeChart,
  GroupedBarChart,
  HeatmapChart,
  HistogramChart,
  LineChart,
  LollipopChart,
  MeterChart,
  PieChart,
  SimpleBarChart,
  StackedAreaChart,
  StackedBarChart,
  TreeChart,
  TreemapChart,
  WordCloudChart,
} from "@carbon/charts-react";
import { Dropdown, ErrorBoundary, Layer, Tile } from "@carbon/react";
import CodeQualityMultiChartContainer from "./CodeQualityMultiChartContainer";
import DefectsMultiChartContainer from "./DefectsMultiChartContainer";
import TextBox from "./TextBox";
import HeatMapAnalyticsList from "./HeatMapAnalyticsList";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import ComboWithdropdown from "./ChartsWithFilters/ComboWithdropdown";
import HeatMapWithDropDown from "./ChartsWithFilters/HeatMapWithDropDown";
import LineChartWithDropdowns from "./ChartsWithFilters/LineChartWithDropdowns";
import LineChartWithGroupDropdown from "./ChartsWithFilters/LineChartWithGroupDropdown";
import SparkLinewithFilter from "./ChartsWithFilters/SparkLineWithDrodown";
import DataTableForCharts from "./DataTableChart";
import StructuredListChart from "./StructuredListChart";
import ChartLoader from "./Utilities/ChartLoader";
import "./css/_chart.scss";

const ChartContainer = (props) => {
  const [dropdownList, setDrodownList] = useState(["sample", "test"]);
  const { t } = useTranslation();

  useEffect(() => {
    const highlight = document.getElementsByClassName("highlight-bar");
    for (let i = 0; i < highlight.length; i++) {
      highlight[i].setAttribute("id", `lights-${i}`);
      let ids = document.getElementById(`lights-${i}`);
      ids.style.transform = `translateX(${props.data.translateValue})`;
    }
  }, [props.data.data.chartData]);

  const getChart = (type) => {
    const chartOptions = props.data.data.chartOptions;

    // Mapped chart types to components for better maintainability
    const chartMap = {
      "bar": <GroupedBarChart data={props.data.data.chartData} options={chartOptions} ref={props.chartRef} />,
      "lollipop": <LollipopChart data={props.data.data.chartData} options={chartOptions} ref={props.chartRef} />,
      "histogram": <HistogramChart data={props.data.data.chartData} options={chartOptions} ref={props.chartRef} />,
      "stackedArea": <StackedAreaChart data={props.data.data.chartData} options={chartOptions} ref={props.chartRef} />,
      "simpleBar": <SimpleBarChart data={props.data.data.chartData} options={chartOptions} ref={props.chartRef} />,
      "pie": <PieChart data={props.data.data.chartData} options={chartOptions} ref={props.chartRef} />,
      "combo": <ComboChart data={props.data.data.chartData} options={chartOptions} ref={props.chartRef} />,
      "comboWithDropdown": <ComboWithdropdown data={props.data.data.chartData} options={chartOptions} ref={props.chartRef} />,
      "meter": <MeterChart data={props.data.data.chartData} options={chartOptions} />,
      "donut": <DonutChart data={props.data.data.chartData} options={chartOptions} ref={props.chartRef} />,
      "line": <LineChart data={props.data.data.chartData} options={chartOptions} ref={props.chartRef} />,
      "sparkline": <SparkLinewithFilter data={props.data.data.chartData} options={chartOptions} ref={props.chartRef} />,
      "lineWithDropdown": <LineChartWithDropdowns data={props.data.data.chartData} options={chartOptions} ref={props.chartRef} />,
      "lineWithGroupDropdown": <LineChartWithGroupDropdown data={props.data.data.chartData} options={chartOptions} ref={props.chartRef} />,
      "tree": <TreeChart data={props.data.data.chartData} options={chartOptions} />,
      "treeMap": <TreemapChart data={props.data.data.chartData} options={chartOptions} ref={props.chartRef} />,
      "heatMap": <HeatmapChart data={props.data.data.chartData} options={chartOptions} />,
      "area": <AreaChart data={props.data.data.chartData} options={chartOptions} />,
      "bubble": <CirclePackChart data={props.data.data.chartData} options={chartOptions} ref={props.chartRef} />,
      "bullet": <BulletChart data={props.data.data.chartData} options={chartOptions} />,
      "gauge": <GaugeChart data={props.data.data.chartData} options={chartOptions} />,
      "wordCloud": <WordCloudChart data={props.data.data.chartData} options={chartOptions} />,
      "dataTableInsideChart": <DataTableForCharts data={props.data.data} />,
      "dataTableHeatMap": <HeatMapAnalyticsList data={props.data.data.chartData[0]} />,
      "structuredList": <StructuredListChart data={props.data.data.chartData[0]} />,
      "text": <TextBox data={props.data.data} />,
      "report": <TextBox data={props.data.data} />,
    };

    return chartMap[type] || null;
  };

  // const getMultiCharts = (type) => {
  //   switch (type) {
  //     case "Defects":
  //       return <DefectsMultiChartContainer data={props.data} />;
  //     case "Code Quality":
  //       return <CodeQualityMultiChartContainer data={props.data} />;
  //     default:
  //       return null;
  //   }
  // };

  const getChartLoader = (message) => (
    <Layer className="chart-loader__layer">
      {message || "Data is unavailable"}
    </Layer>
  );

  return (
    <>
      {
        props.data.dataType === "structuredList" ?
          <StructuredListChart data={props.data.data.chartData[0]} />
          :
          <Tile className={`chart-container ${props.data.scrollNeeded ? "chart-scroll" : ""} ${props.data.minText ? "minText" : ""}`}>
            {props.data.dataType === "multi" && <h5>{props.data.label}</h5>}
            <ErrorBoundary fallback={"Data is unavailable"}>
              {props.data.dropdown && (
                <Dropdown
                  ariaLabel="Dropdown"
                  id="carbon-dropdown-example"
                  items={dropdownList}
                  itemToString={(item) => (item ? item : "")}
                  label={t(`common.selectSprint`)}
                  titleText={t(`common.selectSprint`)}
                  size="sm"
                  onChange={(e) => props.onDropdownChange(e)}
                />
              )}
              <div className={props.leftSection ? "show-chart" : ""}>
                {props.AddAdditional && (
                  <AreaChart
                    data={props.AddAdditional.data.chartData}
                    options={props.AddAdditional.data.chartOptions}
                  />
                )}
                {
                  (props.data.data?.chartData?.length === 0 && props.data.data.chartOptions?.data?.loading)
                    ? getChartLoader(props.message)
                    : getChart(props.data.dataType)
                }
              </div>
            </ErrorBoundary>
            {(props.belowTitle || props.data.belowTitle) && (
              <>
                <h6 className="belowTitle">{props.belowTitle}</h6>
                <h6 className="belowTit" style={{ left: props.data.left }}>{props.data.belowTitle}</h6>
              </>
            )}
          </Tile>
      }
    </>
  );
};

export default ChartContainer;
