import React, { useState, useEffect } from 'react';
import {
    DataTable, TableContainer,
    Table,
    TableHead,
    TableRow,
    TableExpandHeader,
    TableExpandRow,
    TableExpandedRow,
    TableHeader,
    TableBody,
    TableCell,
    TableToolbar,
    TableToolbarContent,
    TableToolbarMenu,
    TableToolbarSearch,
    Button,
    Checkbox,
    Loading,
    Tag
} from "@carbon/react";
import moment from 'moment';
import { useLocation, useHistory } from 'react-router';
import { Filter, NewTab, SkipForwardFilled } from "@carbon/react/icons/index";
import { useTranslation } from 'react-i18next';
import { useKeycloak } from "@react-keycloak/web";

import { defaultSortRow } from '../Utils/ReusableFunctions';
import TableToolbarDownload from './TableToolbarDownload';
import Filters from '../Filters';
import '../scss/DashboardTable.scss';
const sign = require('jwt-encode');

export default function AlertsTable(props) {
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();
    let history = useHistory();
    const [showFilters, setShowFilters] = useState(false);
    const [loader, setLoader] = useState(false);
    const [filteredTableHeaders, setFilteredTableHeaders] = useState([]);
    const secret = 'secret';

    const handleFilters = () => {
        setShowFilters(!showFilters);
    };
    const handleClose = () => {
        setShowFilters(!showFilters);
    }

    const buildURLWithQueryParams = (newQueryParam) => {
        const DOBaseURL = 'https://aiops-do-aiops.aiops-dev2-85ee131ed8e71cabc202e5781fab5c58-0000.eu-de.containers.appdomain.cloud';
        const params = new URLSearchParams(
            {
                alertType: newQueryParam.alertType,
                applicationName: newQueryParam.applicationName,
                client: newQueryParam.client,
                createdAt: newQueryParam.createdAt,
                incidentId: newQueryParam.incidentId,
                sysId: newQueryParam.sysId,
                status: 'Ticketed',
                workspace: 'sap_ops'
            });
        const json = {
            filters: {
                tenant: ['CAI'],
                org: ["DevSecOps-INT"],
                time: { start_datetime: "", end_datetime: "" }
            },
            notification: {
                assigned_to: keycloak.tokenParsed.preferred_username,
            },
            incidentDetailsPageURL: `https://aiops-do-aiops.aiops-dev2-85ee131ed8e71cabc202e5781fab5c58-0000.eu-de.containers.appdomain.cloud/IncidentDetails?${params.toString()}`,
            Tab: "Services Management"
        }
        //console.log(`https://aiops-do-aiops.aiops-dev2-85ee131ed8e71cabc202e5781fab5c58-0000.eu-de.containers.appdomain.cloud/IncidentDetails?${params.toString()}`, 'details url')
        const jwt = sign(json, secret);
        //console.log(jwt,"444");
        return `${DOBaseURL}?userinfo=${jwt}`;
    }

    const displayCell = (cell, currentRow) => {
        let currentHeader = [...props?.headers].filter(function (header) { return header.key === cell.info.header });
        if (displayHeader(currentHeader[0])) {
            if (cell.info.header) {
                if (cell.info.header === 'incidentId') {
                    let sysId;
                    sysId = (currentRow.cells).find(cell => cell.info.header === "sysId");
                    sysId = sysId ? sysId.value : '';
                    if (sysId === '' || sysId === undefined) {
                        sysId = cell.value ? cell.value.substr(cell.value.indexOf("/") + 1) : "";
                    }
                    let ticketingToolPath = (currentRow.cells).find(cell => cell.info.header === "incidentURL");
                    let incidentId = cell.value;
                    let client = (currentRow.cells).find(cell => cell.info.header === "client");
                    client = client ? client.value : '';
                    let applicationName = (currentRow.cells).find(cell => cell.info.header === "source provider");
                    applicationName = applicationName ? applicationName.value : '';
                    let createdAt = (currentRow.cells).find(cell => cell.info.header === "createdAt");
                    createdAt = createdAt && createdAt.value !== '-' ? moment(createdAt.value).toISOString() : '';
                    let newQueryParam = {};
                    newQueryParam = {
                        sysId: sysId,
                        alertType: props?.alertType,
                        incidentId: incidentId,
                        workspace: props.workspace,
                        client: client,
                        applicationName: applicationName,
                        createdAt: createdAt,
                    }


                    if (cell.value) {
                        return (
                            <TableCell key={cell.id}>
                                <div className="incident-link">
                                    <a className="incident-id remove-underline" href={buildURLWithQueryParams(newQueryParam)} target="_blank" rel="noopener noreferrer">{incidentId}</a>
                                    {ticketingToolPath &&
                                        <Button
                                            className="buttonIcon"
                                            hasIconOnly
                                            tooltipAlignment="center"
                                            tooltipPosition="bottom"
                                            iconDescription="open in new tab"
                                            renderIcon={NewTab}
                                            kind='ghost'
                                            onClick={() => openTicketingTool(ticketingToolPath.value)}
                                        />
                                    }
                                </div>
                            </TableCell>
                        )
                    }
                    else return <TableCell key={cell.id}>{cell.value}</TableCell>
                }
                else if (cell.info.header === 'alertName')
                    return <TableCell key={cell.id} onClick={() => onClickView(cell, currentRow)}> {cell.value}</TableCell>
                else return <TableCell key={cell.id}>{cell.value}</TableCell>
            }
        }
        else {
            return null;
        }
    }

    const onClickView = (cell, currentRow) => {
        let sysId = props.rows.filter(ce => ce.id === currentRow.id)
        const alertRes = sessionStorage.getItem("tenant") === "SFGarage" ? props.alertsResponse?.data?.hits.hits.filter((hit) => hit._source.incident.id === sysId[0].incId) : props.alertsResponse?.data?.hits.hits.filter((hit) => hit._source.alert.time === sysId[0].alertTime)
        history.push({ pathname: "/troubleShootView", state: alertRes });
    }

    const manageHeader = (event, checkedItem) => {
        props?.headers?.forEach(header => {
            if (header.key === checkedItem?.id) {
                header.hidden = !checkedItem?.checked;
            }
        });
        let filteredheader = props?.headers?.filter(header => header.hidden === false);
        setFilteredTableHeaders(filteredheader);
    };

    const openTicketingTool = (url) => {
        window.open(url, "_blank");
        console.log("url ======= >>>>>", url)
        history.push()
    };

    const displayHeader = (header) => {
        console.log(header, "alert summary header")
        return header?.hidden ? false : true;
    }

    const getRowDescription = (rowId) => {

        const row = props.rows.find(({ id }) => id === rowId);
        return row ? <>
          {row.type ? <div className='sub-desc'> <span>Type: </span> <Tag
    className="some-class"
    size="md"
    title="Clear filter"
    type="magenta"
  >
    {row.type}
  </Tag> </div> : null }
  {row.subType ? <div className='sub-desc'> <span>Sub Type: </span> <Tag
    className="some-class"
    size="md"
    title="Clear filter"
    type="purple"
  >
    {row.subType}
  </Tag>
  </div> : null }
        <p>{row.errorInfo}</p>
        </> : '';
    };

    return (
        props.loading ?
            <>
                <div><Loading description={t('DigitalOperations.loading')} /></div>
            </>
            :
            <div className='dataTable-container'>
                <DataTable
                    style={{ padding: 0 }}
                    rows={props?.rows}
                    headers={props?.headers}
                    isSortable={props.isSortingAllowed}
                    sortRow={(cellA, cellB, sortDirection, sortStates) => defaultSortRow(cellA, cellB, sortDirection, sortStates)}
                    render={({ rows, headers, getHeaderProps, getRowProps, getTableProps, getToolbarProps, onInputChange, getTableContainerProps,
                    }) => (
                        <TableContainer {...getTableContainerProps}>
                            <TableToolbar {...getToolbarProps} aria-label="data table toolbar">
                                <TableToolbarContent style={{ clipPath: 'none' }}>
                                    <TableToolbarSearch onChange={props.onSearch ? (event) => props.onSearch(event) : onInputChange} expanded={true} />
                                    {/* <Button className='filter-icon' kind='ghost' iconDescription='Filters' onClick={handleFilters} renderIcon={Filter}></Button> */}
                                    <TableToolbarMenu light iconDescription='Headers'>
                                        <fieldset className="optional-headers">
                                            {headers.map(header => (
                                                header.optional ?
                                                    <Checkbox labelText={header.header} checked={!header?.hidden} onChange={manageHeader} id={header.key} />
                                                    : null
                                            ))}
                                        </fieldset>
                                    </TableToolbarMenu>
                                    {/* download header has been filtered to not show values with hidden true and optional false combo */}
                                    <TableToolbarDownload iconDescription={t('DigitalOperations.export')} rows={props.fullRows} headers={filteredTableHeaders.length > 0 ? filteredTableHeaders : headers.filter((headerItem) => !(headerItem.hidden === true && headerItem.optional === false))} title={t('DigitalOperations.alertSummary')} filename={t('DigitalOperations.alertSummary')} label={t('DigitalOperations.download')} />
                                </TableToolbarContent>
                            </TableToolbar>
                            {showFilters ?
                                <Filters filterData={props.filterData} filterSelections={props?.filterSelections} handleClose={handleClose} disabledSelects={props.disabledSelects} updateState={props.updateState} />
                                : null}
                            <Table {...getTableProps()}>
                                <TableHead>
                                    <TableRow>
                                        <TableExpandHeader />
                                        {headers.map(header => (
                                            displayHeader(header) ?
                                                <TableHeader
                                                    {...getHeaderProps({ header })}
                                                >
                                                    {header.header}
                                                </TableHeader> : null
                                        ))}
                                        <TableHeader />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map(row => (
                                        <React.Fragment key={row.id}>
                                            <TableExpandRow className="carbon-display" {...getRowProps({ row })}>
                                                {row.cells.map(cell => (
                                                    displayCell(cell, row, rows)
                                                ))}
                                            </TableExpandRow>
                                            {row.isExpanded ?
                                                <TableExpandedRow className="carbon-display" colSpan={headers.length + 1}>
                                                    {loader ?
                                                        <div>Loading...</div>
                                                        :
                                                        <div>
                                                            <div className="error-info carbon-display">
                                                                <div className="error-msg">{getRowDescription(row.id)}</div>
                                                            </div>
                                                        </div>}
                                                </TableExpandedRow>
                                                : null}
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                />
               
            </div>
    )
}