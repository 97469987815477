import moment from 'moment';

import * as DashboardUtils from './DashboardUtils';
import * as workspaces from './config/WorkspaceConstants';
import * as technologies from './config/TechnologyConstant';


export function PayloadWithCalendarInterval(timeFilterSelections, dashboardFilterSelectionsAutomation, dashboardFilterSelectionsSAP, dashboardFilterSelectionsSalesforce, workspace, id, dashboardFilterSelectionsCustom) {
    let calenderInterval;
    if (id)
        calenderInterval = DashboardUtils.WeeklyMonthlyInterval(id);
    else
        calenderInterval = DashboardUtils.getCalenderInterval(timeFilterSelections);
    let timeZone = moment.tz.guess();
    let technology;
    let franchise;
    let client;
    let bodyJSON;

    if (workspace === workspaces.automation_ops) {
        technology = dashboardFilterSelectionsAutomation.technology;
        franchise = dashboardFilterSelectionsAutomation.franchise;
        client = dashboardFilterSelectionsAutomation.client;
        bodyJSON = {
            "filters": {
                "time": {
                    "startDateTime": timeFilterSelections.startDateTime,
                    "endDateTime": timeFilterSelections.endDateTime
                },
                "franchises": franchise,
                "clients": client,
                "technologies": technology,
                "deployment_models": dashboardFilterSelectionsAutomation.deployment_model,
                "geos": dashboardFilterSelectionsAutomation.geo,
                "automata_state": dashboardFilterSelectionsAutomation.automata_state,
                "business_competencies": dashboardFilterSelectionsAutomation.competency,
                "buisness_component": dashboardFilterSelectionsAutomation.buisness_component,
                "automata_priority": dashboardFilterSelectionsAutomation.priority,
                "automata_name": dashboardFilterSelectionsAutomation.automata_activity_name,
                "resource": dashboardFilterSelectionsAutomation.resource,
                "bot_id": dashboardFilterSelectionsAutomation.bot_id,
                "status": dashboardFilterSelectionsAutomation.status,
                "process_name": dashboardFilterSelectionsAutomation.process_name
            },
            "interval": {
                "calender_Interval": calenderInterval,
                "time_zone": timeZone
            },
            "workspace": workspace
        };
    }
    else if (workspace === workspaces.salesforce_ops) {
        technology = [technologies.Salesforce];
        franchise = dashboardFilterSelectionsSalesforce.franchise;
        client = dashboardFilterSelectionsSalesforce.client;
        bodyJSON = {
            "filters": {
                "time": {
                    "startDateTime": timeFilterSelections.startDateTime,
                    "endDateTime": timeFilterSelections.endDateTime
                },
                "franchises": franchise,
                "clients": client,
                "technologies": technology,
                // "deployment_models": dashboardFilterSelectionsSalesforce.deployment_model,
                // "business_competencies": dashboardFilterSelectionsSalesforce.competency,
                // "incident_status": dashboardFilterSelectionsSalesforce.incident_status,
                // "assignment_groups": dashboardFilterSelectionsSalesforce.assigned_group,
                // "process_name": dashboardFilterSelectionsSalesforce.process_name
            },
            "interval": {
                "calender_Interval": calenderInterval,
                "time_zone": timeZone
            },
            "workspace": workspace,
        };
    }
    else if (workspace === workspaces.custom_ops) {
        technology = [technologies.SAP];
        franchise = dashboardFilterSelectionsCustom !== undefined ? dashboardFilterSelectionsCustom.franchise : [];
        client = dashboardFilterSelectionsCustom !== undefined ? dashboardFilterSelectionsCustom.client : [];
        bodyJSON = {
            "filters": {
                "time": {
                    "startDateTime": timeFilterSelections.startDateTime,
                    "endDateTime": timeFilterSelections.endDateTime,
                },
                "franchises": franchise,
                "clients": client,
                "alert_source": dashboardFilterSelectionsCustom !== undefined ? dashboardFilterSelectionsCustom.alert_source : '',
                "alert_system_name": dashboardFilterSelectionsCustom !== undefined ? dashboardFilterSelectionsCustom.alert_system_name : '',
                "technologies": technology
            },
            "interval": {
                "calender_Interval": calenderInterval,
                "time_zone": timeZone
            },
            "workspace": workspace
        };
    }
    else {
        if (workspace === workspaces.oem_ops) {
            technology = [technologies.Oracle];
        } else {
            technology = [technologies.SAP, technologies.BW_PCM];
        }
        franchise = dashboardFilterSelectionsSAP.franchise;
        client = dashboardFilterSelectionsSAP.client;
        bodyJSON = {
            "filters": {
                "time": {
                    "startDateTime": timeFilterSelections.startDateTime,
                    "endDateTime": timeFilterSelections.endDateTime
                },
                "franchises": franchise,
                "clients": client,
                "technologies": technology,
                "assignment_groups": dashboardFilterSelectionsSAP.assignment_group,
                "incident_status": dashboardFilterSelectionsSAP.incident_status,
                "automata_priority": dashboardFilterSelectionsSAP.priority,
                "bot_type": dashboardFilterSelectionsSAP.bot_type,
                "bot_analytics_user": dashboardFilterSelectionsSAP.bot_analytics_user
            },
            "interval": {
                "calender_Interval": calenderInterval,
                "time_zone": timeZone
            },
            "workspace": workspace
        };
    }

    return bodyJSON;
};