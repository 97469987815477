import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, TableToolbar, TableToolbarContent, ContentSwitcher, Switch, TableToolbarSearch, Tabs, Tab, TabList, TabPanels, TabPanel } from '@carbon/react';
import { Information } from "@carbon/react/icons/index";
import ReactCardFlip from 'react-card-flip';

import DashboardSummary from '../DashboardSummary';
import DataTableWithExpandedRow from './DataTableWithExpandedRow';
import DashboardLineChartWithCalendarInterval from '../CarbonComponents/DashboardLineChartWithCalendarInterval';
import DashboardAreaChart from '../CarbonComponents/DashboardAreaChart';
import DashboardStackedBarChart from '../CarbonComponents/DashboardStackedBarChart';
import DashboardGaugeChart from '../CarbonComponents/DashboardGaugeChart';
import IncidentManagementTable from '../CarbonComponents/IncidentManagementTable';
import DashboardTable from '../CarbonComponents/DashboardTable';
import { VelocityTrendData, AlertDistributionData, clientAlertDistribution, top5AlertCategories, AlertsMetricsData, InfraAlertsMetricsData } from './Utils';
import TableToolbarDownload from '../CarbonComponents/TableToolbarDownload';
import * as velocityTrendData from './MockData/InfraAlertVelocityTrendMock.json';
import * as applicationInfraAlertCountData from './MockData/ApplicationInfraAlertCountMock.json';
import * as clientDistributionData from './MockData/ClientWiseDistributionMock.json';
import * as alertCategoryData from './MockData/AlertCategoryMock.json';
import * as alertMetricsData from './MockData/AlertMetricsMock.json';
import * as infraAlertMetricsData from './MockData/InfraAlertsMetricsMock.json';
import '../scss/_alertAnalytics.scss';


export default function AlertAnalytics() {
    const { t } = useTranslation();
    let filterSelections = {
        tenant: '',
        org: ''
    }
    const [showMainView, setShowMainView] = useState(true);
    const [isTop5AlertGraphFlipped, setIsTop5AlertGraphFlipped] = useState(false);
    const [combinedTotalAlerts, setCombinedTotalAlerts] = useState(0);
    const [alertSummary, setAlertSummary] = useState([]);
    const [incidentVelocityTrend, setIncidentVelocityTrend] = useState({ loading: true, data: [] });
    const [alertDistribution, setAlertDistribution] = useState({ loading: true, data: [] });
    const [clientWiseDistribution, setClientWiseDistribution] = useState({ loading: true, data: [] });
    const [gaugeChartData, setGaugeChartData] = useState({ yellowAlerts: [], redAlerts: [] });
    const [alertCategories, setAlertCategories] = useState({ loading: true, data: [] });
    const [currentIncidentDetailListForTop5Alert, setCurrentIncidentDetailListForTop5Alert] = useState([]);
    const [alertKpi, setAlertKpi] = useState({ loading: true, data: [] });
    const [alertMetrics, setAlertMetrics] = useState({ loading: true, data: [], downloadData: [], headers: [], downloadHeaders: [] });
    const [infraAlertMetrics, setInfraAlertMetrics] = useState({ loading: true, data: [], downloadData: [], headers: [], downloadHeaders: [] });
    const [selectedView, setSelectedView] = useState({ alertView: 'GroupedBySID', infraAlertView: 'GroupedBySID' });
    const [searchValue, setSearchValue] = useState({ alertValue: '', infraAlertValue: '' });
    const [searchMetricReport, setSearchMetricReport] = useState({ alertReport: [], infraAlertReport: [] });
    const selectedViewRef = useRef('GroupedBySID');
    const selectedInfraViewRef = useRef('GroupedBySID');

    const alertTableHeaders = [
        {
            key: 'item',
            header: t('DigitalOperations.AlertCategory')
        },
        {
            key: 'count',
            header: t('DigitalOperations.Count')
        },
        {
            key: 'percentage',
            header: t('DigitalOperations.Percentage')
        },
        {
            key: 'alertDetailsList',
            header: t('DigitalOperations.AlertDetailsList'),
            hidden: true
        },

    ];

    const alertDetailTableHeaders = [
        {
            key: 'alertName',
            header: t('DigitalOperations.AlertName')
        },
        {
            key: 'numberOfAlerts',
            header: t('DigitalOperations.NumberOfAlerts')
        }
    ];

    const alertKpiTableHeaders = [
        {
            key: 'engagement',
            header: t('DigitalOperations.Engage')
        },
        {
            key: 'red',
            header: t('DigitalOperations.Red')
        },
        {
            key: 'yellow',
            header: t('DigitalOperations.Yellow')
        },
        {
            key: 'ticketedAlerts',
            header: t('DigitalOperations.ticketedAlerts')
        },
        {
            key: 'duplicatedAlerts',
            header: t('DigitalOperations.duplicateAlerts')
        },
        {
            key: 'nonTicketsAlerts',
            header: t('DigitalOperations.InformationalAlertsCount')
        },
        {
            key: 'ticketConversionRate',
            header: t('DigitalOperations.TicketConversionRate')
        },
        {
            key: 'avgAlertAnalysisTime',
            header: t('DigitalOperations.AvgAlertAnalysisTimeMin')
        },
        {
            key: 'avgAlertResolutionTime',
            header: t('DigitalOperations.AvgAlertResolutionTimeMin')
        }
    ];

    useEffect(() => {
        getOccinfraTop5AlertCategories(filterSelections);
    }, [combinedTotalAlerts])

    useEffect(() => {
        getAlertsIncidentVelocityTrend(filterSelections);
        getAlertDistributionData(filterSelections);
        getClientAlertDistributionData(filterSelections);
        getOccinfraTop5AlertCategories(filterSelections);
        getAlertsMetrics(filterSelections, "GroupedBySID");
        getInfraAlertMetrics(filterSelections, "GroupedBySID");
    }, [sessionStorage.getItem('localeLanguage')]);

    useEffect(() => {
        if (searchValue?.alertValue !== undefined) {
            searchTable(searchValue?.alertValue, 'AlertMetric');
        }
    }, [selectedView?.alertView, alertMetrics?.data, searchValue?.alertValue]);

    useEffect(() => {
        if (searchValue?.infraAlertValue !== undefined) {
            searchTable(searchValue?.infraAlertValue, 'InfraMetric');
        }
    }, [selectedView?.infraAlertView, infraAlertMetrics?.data, searchValue?.infraAlertValue]);

    const handleFlip = (setParam, param, key, tableType, currentHeaderKey, currentRow) => {
        if (key !== 'goBack') {
            setShowMainView(false);
            let filteredArray = [];
            switch (tableType) {
                case 'top5AlertCategory':
                    filteredArray = (currentRow.cells).find(cell => cell.info.header === "alertDetailsList").value;
                    // combining duplicate entries
                    // filteredArray = combineDuplicateEntries(filteredArray);
                    setCurrentIncidentDetailListForTop5Alert(filteredArray);
                    break;

                default:
                    // code block
                    break;
            }
        } else {
            setShowMainView(true);
        }
        setParam(!param);
    };

    const calculatePercentage = (alertList) => {
        let total = combinedTotalAlerts || 0;
        if (total > 0) {
            alertList = alertList.map(obj => ({ ...obj, percentage: Math.round((obj.count * 100) / total) }));
        }
        return alertList;
    };


    const prepareSummaryData = (param) => {
        setCombinedTotalAlerts(param?.totalAlerts);
        let incidentCRatio = (param?.uniqueAlerts / param?.totalAlerts) * 100
        let summary = [
            {
                title: t('DigitalOperations.totalAlerts'),
                value: param?.totalAlerts
            },
            {
                title: t('DigitalOperations.duplicateAlerts'),
                value: param?.totalDuplicateAlerts
            },
            {
                title: t('DigitalOperations.TicketsCreated'),
                value: param?.uniqueAlerts
            },
            {
                title: t('DigitalOperations.InformationalAlertsCount'),
                value: Number(param?.totalAlerts - (param?.totalDuplicateAlerts + param?.uniqueAlerts))
            },
            {
                title: t('DigitalOperations.IncidentConversionRatio'),
                value: isNaN(incidentCRatio) ? 0 : incidentCRatio.toFixed(2),
                showPercent: true
            },
            {
                title: t('DigitalOperations.EliminatedNoise'),
                value: isNaN(((param?.totalDuplicateAlerts + (param?.totalAlerts - (param?.totalDuplicateAlerts + param?.uniqueAlerts))) / param?.totalAlerts) * 100) ? 0 : (((param.totalDuplicateAlerts + (param.totalAlerts - (param.totalDuplicateAlerts + param.uniqueAlerts))) / param.totalAlerts) * 100).toFixed(2),
                showPercent: true
            },
            {
                title: t('DigitalOperations.NoiseReductionforActionable'),
                value: isNaN(((param?.totalAlerts - Number(param?.totalAlerts - (param?.totalDuplicateAlerts + param?.uniqueAlerts))) / param?.totalAlerts) * 100) ? 0 : (((param.totalAlerts - Number(param.totalAlerts - (param.totalDuplicateAlerts + param.uniqueAlerts))) / param.totalAlerts) * 100).toFixed(2),
                showPercent: true
            }
        ];
        return summary;
    };

    const getAlertsIncidentVelocityTrend = () => {
        if (filterSelections) {
            let response = [];
            let bodyJSON = { franchise: '', client: '' };
            response = VelocityTrendData(velocityTrendData.default);
            setIncidentVelocityTrend({ loading: false, data: response });
            // multiplePostRequests(['alertIncidentvelocityTrend', 'GETINFRAVELOCITYANDCOUNT'], {
            //     'Content-Type': 'application/json'
            // }, bodyJSON)
            //     .then(resp => {
            //         formattedData = VelocityTrendData(resp);
            //         setIncidentVelocityTrend({ loading: false, data: formattedData });
            //     }).catch((error) => {
            //         setIncidentVelocityTrend({ loading: false, data: [] });
            //     });
        }
    }

    const getAlertDistributionData = () => {
        if (filterSelections) {
            let response = [];
            let bodyJSON = { franchise: '', client: '' };
            response = AlertDistributionData(applicationInfraAlertCountData.default);
            setAlertDistribution({ loading: false, data: response });
            // multiplePostRequests(['alertIncidentvelocityTrend', 'GETINFRAVELOCITYANDCOUNT'], {
            //     'Content-Type': 'application/json'
            // }, bodyJSON)
            //     .then(resp => {
            //         formattedData = AlertDistributionData(resp);
            //         setAlertDistribution({ loading: false, data: formattedData });
            //     }).catch((error) => {
            //         setAlertDistribution({ loading: false, data: [] });
            //     });
        }
    }

    const getClientAlertDistributionData = () => {
        if (filterSelections) {
            let response = [];
            let bodyJSON = { franchise: '', client: '' };
            response = clientAlertDistribution(clientDistributionData.default);
            let summary = prepareSummaryData(response?.summaryData);
            console.log(response?.tableDataArr, "alert kpi data")
            setAlertKpi({ loading: false, data: response?.tableDataArr });
            setClientWiseDistribution({ loading: false, data: response?.chartDataArr });
            setGaugeChartData({ yellowAlerts: response?.yellowGraphData, redAlerts: response?.redGraphData })
            setAlertSummary(summary);
            // multiplePostRequests(['alertIncidentvelocityTrend', 'GETINFRAVELOCITYANDCOUNT'], {
            //     'Content-Type': 'application/json'
            // }, bodyJSON)
            //     .then(resp => {
            //         formattedData = clientAlertDistribution(resp);
            //         setClientWiseDistribution({ loading: false, data: formattedData });
            //     }).catch((error) => {
            //         setClientWiseDistribution({ loading: false, data: [] });
            //     });
        }
    }

    const getOccinfraTop5AlertCategories = () => {
        if (filterSelections) {
            console.log(alertCategoryData.default, 'alert categories')
            let alertList = top5AlertCategories(alertCategoryData.default);
            console.log(alertList, 'alert list')
            alertList = calculatePercentage(alertList);
            setAlertCategories({ loading: false, data: alertList.slice(0, 5) })
            // multiplePostRequests(['alertIncidentvelocityTrend', 'GETINFRAVELOCITYANDCOUNT'], {
            //     'Content-Type': 'application/json'
            // }, bodyJSON)
            //     .then(resp => {
            //         formattedData = clientAlertDistribution(resp);
            //         setClientWiseDistribution({ loading: false, data: formattedData });
            //     }).catch((error) => {
            //         setClientWiseDistribution({ loading: false, data: [] });
            //     });
        }
    };

    const searchTable = (e, metric) => {
        metric === 'AlertMetric' ? setSearchValue({ alertValue: e, ...searchValue.infraAlertValue }) : setSearchValue({ ...searchValue.alertValue, infraAlertValue: e });
        let searchString = (e).toUpperCase();
        let searchResult = [];
        let newList = [];
        let string, pattern;
        let matchedStrings;
        if (searchString) {
            let metricData = metric === 'AlertMetric' ? alertMetrics?.data : infraAlertMetrics?.data;
            newList = metricData.filter(element => {
                for (var property in element) {
                    if (element.hasOwnProperty(property)) {
                        if (element[property] && property !== "id" && property !== "total") {
                            string = element[property];
                        }
                        else string = '';
                        string = string ? string.toString() : '';
                        pattern = new RegExp('(\\w*' + searchString + '\\w*)', 'gi');
                        matchedStrings = string.match(pattern);
                        if (matchedStrings && matchedStrings.length) {
                            searchResult = searchResult.concat([element]);
                            return searchResult;
                        }
                    }
                }
            });
            metric === 'AlertMetric' ? setSearchMetricReport({ alertReport: newList, ...searchMetricReport.infraAlertReport }) : setSearchMetricReport({ ...searchMetricReport.alertReport, infraAlertReport: newList });
        } else {
            metric === 'AlertMetric' ? setSearchMetricReport({ alertReport: [], ...searchMetricReport.infraAlertReport }) : setSearchMetricReport({ ...searchMetricReport.alertReport, infraAlertReport: [] });
        }
    };

    const switcherView = (e, type) => {
        if (type === "InfraMetric") {
            selectedInfraViewRef.current = e.name;
            setSelectedView({ ...selectedView, infraAlertView: e.name });
            getInfraAlertMetrics(filterSelections, e.name);
        }
        else if (type === "AlertMetric") {
            selectedViewRef.current = e.name;
            setSelectedView({ alertView: e.name, ...selectedView });
            getAlertsMetrics(filterSelections, e.name);
        }
    };

    const getAlertsMetrics = (filterSelections, alertMetric) => {
        if (filterSelections) {
            setAlertMetrics({ loading: true, data: [], downloadableData: [], ...alertMetrics });
            //let bodyJSON = getPayload(timeFilterSelections, dashboardFilterSelectionsAutomation, dashboardFilterSelectionsSAP, workspace, dashboardFilterSelectionsCustom);
            let bodyJSON = {};
            bodyJSON["GroupedBy"] = alertMetric;
            let headers = [{
                key: "item",
                header: 'Alert'
            }];
            let downloadableHeaders = [{
                key: "alertItem",
                header: 'Alert',
            }, {
                key: "entity",
                header: alertMetric === 'GroupedBySID' ? t('DigitalOperations.sid') : t('DigitalOperations.sourceName'),
            },
            ];
            let formattedData = AlertsMetricsData(alertMetric === 'GroupedBySID' ? alertMetricsData.default[0] : alertMetricsData.default[1], headers, downloadableHeaders);
            setAlertMetrics({ loading: false, data: formattedData.tableData, downloadData: formattedData.downloadableData, headers: formattedData.headers, downloadHeaders: formattedData.downloadableHeaders });
            // callPostApi(Constants.GETALERTSMETRICS, {
            //     'Content-Type': 'application/json'
            // }, bodyJSON)
            //     .then(resp => {
            //         setAlertsMetricLoading(false);
            //         formattedData = prepareAlertsMetricData(resp);
            //         setAlertsMetricData(formattedData.tableData);
            //         setAlertsMetricDownloadData(formattedData.downloadableData)
            //     }).catch((error) => {
            //         setAlertsMetricLoading(false);
            //         setAlertsMetricData([]);
            //         setAlertsMetricDownloadData([]);
            //     });
        }
    }

    const getInfraAlertMetrics = (filterSelections, alertMetric) => {
        if (filterSelections) {
            setInfraAlertMetrics({ loading: true, data: [], downloadableData: [], ...infraAlertMetrics });
            //let bodyJSON = getPayload(timeFilterSelections, dashboardFilterSelectionsAutomation, dashboardFilterSelectionsSAP, workspace, dashboardFilterSelectionsCustom);
            let bodyJSON = {};
            bodyJSON["GroupedBy"] = alertMetric;
            let headers = [{
                key: "item",
                header: t('DigitalOperations.Alert')
            }];
            let downloadableHeaders = [{
                key: "alertItem",
                header: t('DigitalOperations.Alert'),
            }, {
                key: "sourceProvider",
                header: t('DigitalOperations.SourceProvider'),
            },
            {
                key: "sourceName",
                header: t('DigitalOperations.SourceName'),
            }
            ];
            let formattedData = InfraAlertsMetricsData(alertMetric === 'GroupedBySID' ? infraAlertMetricsData.default[0] : infraAlertMetricsData.default[1], headers, downloadableHeaders);
            setInfraAlertMetrics({ loading: false, data: formattedData.tableData, downloadData: formattedData.downloadableData, headers: formattedData.headers, downloadHeaders: formattedData.downloadableHeaders });
            // callPostApi(Constants.GETINFRAALERTMETRICS, {
            //     'Content-Type': 'application/json'
            // }, bodyJSON)
            //     .then(resp => {
            //         formattedData = prepareInfraAlertMetricsData(resp);
            //         setInfraAlertsMetricsData(formattedData);
            //         setInfraAlertsMetricLoading(false);
            //     }).catch((error) => {
            //         setInfraAlertsMetricLoading(false);
            //         setInfraAlertsMetricsData([]);
            //     });
        }
    }

    return (
        <div className='top-margin dashboard alertAnalytics-container'>
            <div className="dashboard-detail-container col-lg-12 no-gutters">
                <div className="dashboard-roleContainer col-lg-12">
                    <DashboardSummary loading={clientWiseDistribution.loading} summary={alertSummary} title={t('DigitalOperations.monitoring')} />
                </div>
                <div className="dashboard-charts-container">
                    <section className="border-bottom">
                        <div className="tile-view">
                            <div className="report-container no-padding">
                                <DashboardLineChartWithCalendarInterval
                                    lineGraphData={incidentVelocityTrend?.data}
                                    title={t('DigitalOperations.IncidentVelocityTrend')}
                                    loading={incidentVelocityTrend?.loading}
                                    graphTitle={t('DigitalOperations.Count')}
                                    modalTitle=""
                                    graphDescription={t('DigitalOperations.IncidentVelocityTrendDescription')}
                                />
                            </div>
                            <div className="report-container">
                                <DashboardAreaChart
                                    areaGraphData={alertDistribution.data}
                                    title={t('DigitalOperations.AlertDistribution')}
                                    loading={alertDistribution.loading}
                                    graphTitle={t('DigitalOperations.Count')}
                                    graphBottomTitle={t('DigitalOperations.Count')}
                                    modalTitle=""
                                    graphDescription={t('DigitalOperations.AlertDistributionDescription')}
                                />
                            </div>
                        </div>
                    </section>
                    <section className="border-bottom">
                        <div className="tile-view">
                            <div className="report-container">
                                <DashboardStackedBarChart
                                    loading={clientWiseDistribution.loading}
                                    stackedBarChartData={clientWiseDistribution.data}
                                    title={t('DigitalOperations.ClientWiseAlertDistribution')}
                                    bottomTitle={t('DigitalOperations.Count')}
                                    leftAxesTitle={t('DigitalOperations.Engage')}
                                    showTooltip={true}
                                    showSwitcher={false}
                                    showIntervalDropdown={false}
                                    leftStack={true}
                                    graphDescription={t('DigitalOperations.ClientWiseAlertDistributionDescription')}

                                />
                            </div>
                            <div className="report-container">
                                <div className="graph-title carbon-display-Regular-20">
                                    <div>{t('DigitalOperations.TicketedAlertsGauge')}
                                        <Tooltip
                                            align="right"
                                            label={t('DigitalOperations.TicketedAlertsGaugeDescription')}
                                            style={{ color: "black", fontSize: "14px" }}
                                        >
                                            <button
                                                className="tooltip-button"
                                                type="button"
                                            >
                                                <Information />
                                            </button>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className="gauge-report">
                                    <div className="gauge-container">
                                        <div>
                                            <DashboardGaugeChart
                                                loading={clientWiseDistribution.loading}
                                                gaugeChartData={gaugeChartData?.yellowAlerts}
                                                title={t('DigitalOperations.Yellow')}
                                                color="#F1C21B"
                                            />
                                        </div>
                                        <div>
                                            <DashboardGaugeChart
                                                loading={clientWiseDistribution.loading}
                                                gaugeChartData={gaugeChartData?.redAlerts}
                                                title={t('DigitalOperations.Red')}
                                                color="#DA1E28"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="border-bottom top5-alert-kpi grid-color">
                        <div className="tile-view">
                            <div className="bold-border-table">
                                <ReactCardFlip isFlipped={isTop5AlertGraphFlipped} flipDirection="horizontal">
                                    <IncidentManagementTable
                                        loading={alertCategories?.loading}
                                        rows={alertCategories?.data}
                                        headers={alertTableHeaders}
                                        downloadableHeaders={alertTableHeaders}
                                        showPagination={true}
                                        isSortable={true}
                                        isExpandable={false}
                                        handleFlip={handleFlip}
                                        flipState={isTop5AlertGraphFlipped}
                                        setFlipState={setIsTop5AlertGraphFlipped}
                                        tableType="top5AlertCategory"
                                        showIndicator={true}
                                        hideColoredCell={false}
                                        title={t('DigitalOperations.Top5AlertCategory')}
                                        graphDescription={t('DigitalOperations.Top5AlertCategoryDescription')} />
                                    <IncidentManagementTable
                                        key={showMainView}
                                        loading={alertCategories?.loading}
                                        rows={currentIncidentDetailListForTop5Alert}
                                        headers={alertDetailTableHeaders}
                                        showPagination={true}
                                        isSortable={false}
                                        isExpandable={false}
                                        showIndicator={true}
                                        title={t('DigitalOperations.Top5AlertCategory')}
                                        showFlipClose={true}
                                        handleFlip={handleFlip}
                                        flipState={isTop5AlertGraphFlipped}
                                        setFlipState={setIsTop5AlertGraphFlipped}
                                        tableType="top5AlertCategory"
                                        graphDescription={t('DigitalOperations.Top5AlertCategoryDescription')} />
                                </ReactCardFlip>
                            </div>
                        </div>
                    </section>
                    <section className="border-bottom grid-color">
                        <div className="graph-title">
                            <div className="carbon-display-Regular-20">{t('DigitalOperations.alertKpi')}
                                <Tooltip
                                    align="right"
                                    label={t('DigitalOperations.AlertKPIDescription')}
                                    style={{ color: "black", fontSize: "14px" }}
                                >
                                    <button
                                        className="tooltip-button"
                                        type="button"
                                    >
                                        <Information />
                                    </button>
                                </Tooltip>
                            </div>
                            <div className="table-toolbar">
                                <TableToolbar>
                                    <TableToolbarContent>
                                        {/* <TableToolbarSearch  /> */}
                                    </TableToolbarContent>
                                </TableToolbar>
                                <div className="download-btn">
                                    <TableToolbarDownload
                                        iconDescription={t('DigitalOperations.export')}
                                        rows={alertKpi?.data}
                                        headers={alertKpiTableHeaders}
                                        title={t('DigitalOperations.alertKpi')}
                                        filename={t('DigitalOperations.export')}
                                        label={t('DigitalOperations.download')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="bold-border-table">
                            <DashboardTable
                                loading={alertKpi.loading}
                                rows={alertKpi.data}
                                headers={alertKpiTableHeaders}
                                showPagination={true}
                                isSortable={true}
                            />
                        </div>
                    </section>
                    <section className="border-bottom grid-color">
                        <div className="graph-title">
                            <div className="title carbon-display-Regular-20">{t('DigitalOperations.AlertMetric')}
                                <Tooltip
                                    align="right"
                                    label={t('DigitalOperations.AlertMetricDescription')}
                                    style={{ color: "black", fontSize: "14px" }}
                                >
                                    <button
                                        className="tooltip-button"
                                        type="button"
                                    >
                                        <Information />
                                    </button>
                                </Tooltip>
                            </div>
                        </div>
                        <Tabs>
                            <TabList aria-label="List of tabs">
                                <Tab href='#' >{t('DigitalOperations.ApplicationAlertMetric')}</Tab>
                                <Tab href='#'>{t('DigitalOperations.InfraAlertMetric')}</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel>
                                    <div className="alert-metrics">
                                        <div className="graph-title">
                                            <div className="table-toolbar">
                                                <TableToolbar>
                                                    <TableToolbarContent>
                                                        <ContentSwitcher onChange={(e) => switcherView(e, "AlertMetric")} className="content-switcher" >
                                                            <Switch name={'GroupedBySID'} text={t('DigitalOperations.GroupedBySID')} />
                                                            <Switch name={'GroupedByEntity'} text={t('DigitalOperations.GroupedByEntity')} />
                                                        </ContentSwitcher>
                                                        <TableToolbarSearch onChange={(e) => { searchTable(e.target.value, "AlertMetric") }} className="search-button" />
                                                    </TableToolbarContent>
                                                </TableToolbar>
                                                <div className="download-btn">
                                                    <TableToolbarDownload iconDescription={t('DigitalOperations.export')} rows={alertMetrics?.downloadData} headers={alertMetrics?.downloadHeaders} title={t('DigitalOperations.AlertMetric')} filename={t('DigitalOperations.export')} label={t('DigitalOperations.download')} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bold-border-table nested-table">
                                            <DashboardTable
                                                loading={alertMetrics?.loading}
                                                rows={searchValue?.alertValue?.length ? searchMetricReport?.alertReport : alertMetrics?.data} headers={alertMetrics?.headers}
                                                showPagination={true}
                                                isSortable={true}
                                                isExpandable={true}
                                                expandedTableTitle={selectedViewRef.current === "GroupedByEntity" ? t('DigitalOperations.sourceName') : t('DigitalOperations.sid')}
                                            />
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="alert-metrics expanded-alert-table">
                                        <div className="graph-title">
                                            {/* <div className="carbon-display-Regular-20">Infra Alert Metric</div> */}
                                            <div className="table-toolbar">
                                                <TableToolbar>
                                                    <TableToolbarContent>
                                                        <ContentSwitcher onChange={(e) => switcherView(e, "InfraMetric")} className="content-switcher">
                                                            <Switch name={'GroupedBySID'} text={t('DigitalOperations.GroupedBySourceSID')} />
                                                            <Switch name={'GroupedBySourceName'} text={t('DigitalOperations.GroupedBySourceName')} />
                                                        </ContentSwitcher>
                                                        <TableToolbarSearch onChange={(e) => { searchTable(e.target.value, "InfraMetric") }} className="search-button" />
                                                    </TableToolbarContent>
                                                </TableToolbar>
                                                <div className="download-button">
                                                    <TableToolbarDownload iconDescription={t('DigitalOperations.export')} rows={infraAlertMetrics?.downloadData} headers={infraAlertMetrics?.downloadHeaders} title={t('DigitalOperations.InfraAlertMetric')} filename={t('DigitalOperations.export')} label={t('DigitalOperations.download')} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bold-border-table nested-table infra-alert-metrics-table">
                                            <DataTableWithExpandedRow
                                                loading={infraAlertMetrics?.loading}
                                                rows={searchValue?.infraAlertValue?.length ? searchMetricReport?.infraAlertReport : infraAlertMetrics?.data}
                                                headers={infraAlertMetrics?.headers}
                                                isExpandable={true}
                                                expandedTableTitle={t('DigitalOperations.SourceProvider')}
                                                expandedTableL2Title={selectedInfraViewRef.current === "GroupedBySourceName" ? t('DigitalOperations.SourceName') : t('DigitalOperations.SourceSID')}
                                                showPagination={true}
                                                isSortingAllowed={true}
                                            />
                                        </div>
                                    </div>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </section>
                </div>
            </div >
        </div >

    )
}