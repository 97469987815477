const isEmptyResponse = (res) => {
    return !Boolean(res) || (Array.isArray(res) && res.length === 0) || (typeof res === "object" && Object.keys(res).length === 0);
};

const isEmptyData = (data) => {
    return !Boolean(data) || (typeof data === "object" && !Array.isArray(data) && Object.keys(data).length === 0);
};

const hasInvalidStatusCode = (data) => {
    return data?.itvsdpStatusCode && data?.itvsdpStatusCode !== 200;
};

const hasErrorMessage = (data) => {
    return data?.error;
};

const isInvalidStatus = (status) => {
    return status !== 200;
};

export const findApiError = (res) => {
    try {
        if (isEmptyResponse(res)) {
            throw new Error("Request Failed with empty response");
        }
        if (isEmptyData(res.data)) {
            throw new Error(`Request Failed with status ${res.status} but empty response`);
        }
        if (hasInvalidStatusCode(res.data)) {
            throw new Error(res?.data?.itvsdpResponseMessage || "Something has Went Wrong");
        }
        if (hasErrorMessage(res.data)) {
            throw new Error(res.data.error);
        }
        if (isInvalidStatus(res.status)) {
            throw new Error(`Request Failed with status code: ${res.status}`);
        }
        return { error: false };
    } catch (err) {
        console.log(err);
        return { error: true, message: err.message };
    }
};
