/** @format */

import { Button, DataTable, DataTableSkeleton, Pagination } from "@carbon/react";
import { Add, Renew } from "@carbon/react/icons";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
const {
  Table,
  TableHead,
  TableHeader,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
} = DataTable;

const CarbonDataTableRBAC = (props) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [rowData, setRowData] = useState([])

  useEffect(() => {
    updatePagination(page, pageSize);
  }, [])

  const updatePagination = (pageCount, pageSizeCount) => {
    let startIndex = 0;
    let endIndex = 0;

    if (props.rowData.length > 0) {
      startIndex = (pageCount - 1) * pageSizeCount;
      endIndex = pageCount * pageSizeCount;
    }

    let updatedRowData = props.rowData.slice(startIndex, endIndex);
    setRowData(updatedRowData);
  }

  const changePaginationState = (pageInfo) => {
    if (page != pageInfo.page) {
      setPage(pageInfo.page)
    }
    if (pageSize != pageInfo.pageSize) {
      setPageSize(pageInfo.pageSize)
    }
    updatePagination(pageInfo.page, pageInfo.pageSize);
  }

  return (
    <>
      {props.rowData.length > 0 ? (
        <>
          <DataTable rows={rowData} headers={props.headerData}>
            {({
              rows,
              headers,
              getHeaderProps,
              getTableProps,
              getBatchActionProps,
              onInputChange,
            }) => (
              <TableContainer title={props.title}>
                <TableToolbar>
                  <TableToolbarContent>
                    <TableToolbarSearch
                      tabIndex={
                        getBatchActionProps().shouldShowBatchActions ? -1 : 0
                      }
                      onChange={onInputChange}
                      placeholder={
                        props.placeholder ? props.placeholder : "Filter Table"
                      }
                    />
                    {props.onRefresh && (
                      <div
                        onClick={() => props.onRefresh()}
                        className="refresh-nopadding"
                        role="button"
                      >
                        {props.showRefreshIcon && (
                          <Renew size={20} className="pointer" />
                        )}
                      </div>
                    )}
                    {props.createTitle && !props.disableCreate && (
                      <Button
                        tabIndex={
                          getBatchActionProps().shouldShowBatchActions ? -1 : 0
                        }
                        onClick={() => props.createFunction(true)}
                        kind="primary"
                        className="create--button"
                      >
                        {props.createTitle}
                        <Add />
                      </Button>
                    )}
                  </TableToolbarContent>
                </TableToolbar>
                <Table {...getTableProps()}>
                  <TableHead>
                    <TableRow>
                      {headers.map((header) => (
                        <TableHeader {...getHeaderProps({ header })}>
                          {props.getTableHeader(header.header)}
                        </TableHeader>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={row.id}>
                        {row.cells.map((cell) => (
                          <TableCell key={uuidv4()}>
                            {props.getRowCellData(cell.id, cell.value, row)}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </DataTable>
          <Pagination onChange={changePaginationState} page={page} pageSize={pageSize} pageSizes={[5, 10, 100]} totalItems={props.rowData.length}></Pagination>
        </>
      ) : (
        <DataTableSkeleton
          columnCount={5}
          rowCount={5}
          headers={props.headerData}
          showToolbar={false}
        />
      )}
    </>
  );
};

export default CarbonDataTableRBAC;
