import React from 'react'
import { GaugeChart } from "@carbon/charts-react";
import { InlineLoading } from '@carbon/react';


const DashboardGaugeChart = (props) => {
    const graphOptions = {
        "title": props.title,
        "resizable": true,
        "height": props.height || "150px",
        "width": "100%",
        "color": { "scale": { 'value': props.color } },
        "gauge": {
            "type": props.isType || "semi",
            "status": "danger",
            "showPercentageSymbol": props.isPercentage || false
        },
        "toolbar": { "enabled": props.toolbarEnabled || false }
    };

    return (
        <>
            <div className="dashboard-gauge-danger-graph">
                {!props.loading ?
                    props.gaugeChartData && props.gaugeChartData.length ?
                        <GaugeChart
                            data={props.gaugeChartData}
                            options={graphOptions}>
                        </GaugeChart>
                        :
                        <div>No Data Available</div>
                    :
                    <div className="tile loading-container">
                        <InlineLoading
                            description="Loading"
                        />
                    </div>
                }
            </div>
        </>
    );
};

export default DashboardGaugeChart;
