import React, { useState, useEffect, useContext } from 'react'
import { InlineLoading } from '@carbon/react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import DashboardSummary from '../DashboardSummary';
//import callPostApi from '../requests/PostRequests';
import DashboardTable from '../CarbonComponents/DashboardTable';
import Breakfix from './Breakfix';

import * as DashboardUtils from '../DashboardUtils';
import * as workspaces from '../config/WorkspaceConstants';
import DetailedIncidentKpis from './DetailedIncidentKpis';
import * as technologies from '../config/TechnologyConstant'
import DashboardStackedBarChart from '../CarbonComponents/DashboardStackedBarChart';
import BreakFixJson from "./MockData/breakFix.json";
import IncidentStatus from "./MockData/incidentStatus.json";
import CountSla from "./MockData/countSla.json";
import Context from "Context/Context";
require("../scss/_incident-management.scss");



const IncidentManagement = (props) => {
    const context = useContext(Context);
    const [reopenCountLoading, setReopenCountLoading] = useState(true);
    const [reopenCountSLAData, setReopenCountSLAData] = useState([]);
    const [reopenCountSLATable, setReopenCountSLATable] = useState([]);
    const [reopenCountDownloadabelData, setReopenCountDownloadabelData] = useState([]);
    const [breakfixLoading, setBreakfixLoading] = useState(true);
    const [breakfixData, setBreakfixData] = useState([]);
    const [severityWiseIncidentLoading, setSeverityWiseIncidentLoading] = useState(true);
    const [severityWiseIncidentData, setSeverityWiseIncidentData] = useState([]);
    const [severityWiseIncidentTableData, setSeverityWiseIncidentTableData] = useState([]);
    const [severityWiseIncidentDownloadableData, setSeverityWiseIncidentDownloadableData] = useState([]);
    const [incidentManagementSummary, setIncidentManagementSummary] = useState([]);
    const [incidentAgeingSummary, setIncidentAgeingSummary] = useState(0);
    const [reopenCountSummary, setReopenCountSummary] = useState({});
    const { t } = useTranslation();

    const severityWiseTableHeaders = [
        {
            key: 'item',
            header: t('DigitalOperations.IncidentStatus')
        },
        {
            key: 'count',
            header: t('DigitalOperations.Count')
        }
    ];

    const reopenCountTableHeaders = [
        {
            key: 'item',
            header: t('DigitalOperations.franchise')
        },
        {
            key: 'total',
            header: t('DigitalOperations.Total')
        },
        {
            key: 'reOpenCount',
            header: t('DigitalOperations.ReopenCount')
        },
        {
            key: 'percentage',
            header: t('DigitalOperations.PercentageSymbol')
        }
    ];

    const reopenCountDownloadableTableHeaders = [
        {
            key: 'item',
            header:  t('DigitalOperations.franchise')
        },
        {
            key: 'priority',
            header: t('DigitalOperations.priority')
        },
        {
            key: 'total',
            header: t('DigitalOperations.Total')
        },
        {
            key: 'reOpenCount',
            header: t('DigitalOperations.ReopenCount')
        },
        {
            key: 'percentage',
            header: t('DigitalOperations.PercentageSymbol')
        }
    ];
    
    useEffect(() => {
            getIncidentManagementSummary();
      }, [context.setLanguage]);


    useEffect(() => {
        getIncidentStatusViaSeverity(props.timeFilterSelections, props.filterSelectionsAutomation, props.filterSelectionsSAP, props.filterSelectionsSalesforce, props.workspace);
        getReopenCountSLA(props.timeFilterSelections, props.filterSelectionsAutomation, props.filterSelectionsSAP, props.filterSelectionsSalesforce, props.workspace);
        getIncidentBreakfix(props.timeFilterSelections, props.filterSelectionsAutomation, props.filterSelectionsSAP, props.filterSelectionsSalesforce, props.workspace);
    }, [props.preferencesChanged])

    useEffect(() => {
        getIncidentManagementSummary();
    }, [incidentAgeingSummary, reopenCountSummary.reOpenCount, reopenCountSummary.percentage]);

    const getPayload = (timeFilterSelections, dashboardFilterSelectionsAutomation, dashboardFilterSelectionsSAP, dashboardFilterSelectionsSalesforce, workspace) => {
        let calenderInterval = DashboardUtils.getCalenderInterval(timeFilterSelections);
        let timeZone = moment.tz.guess();
        let technology;
        let franchise;
        let client;
        let bodyJSON;

        if (workspace === workspaces.automation_ops) {
            technology = dashboardFilterSelectionsAutomation.technology;
            franchise = dashboardFilterSelectionsAutomation.franchise;
            client = dashboardFilterSelectionsAutomation.client;
            bodyJSON = {
                "filters": {
                    "time": {
                        "startDateTime": timeFilterSelections.startDateTime,
                        "endDateTime": timeFilterSelections.endDateTime
                    },
                    "franchises": franchise,
                    "clients": client,
                    "technologies": technology,
                    "incident_priority": dashboardFilterSelectionsAutomation.incident_priority,
                    "deployment_models": dashboardFilterSelectionsAutomation.deployment_model,
                    "business_competencies": dashboardFilterSelectionsAutomation.competency,
                    "incident_status": dashboardFilterSelectionsAutomation.incident_status,
                    "assignment_groups": dashboardFilterSelectionsAutomation.assignment_group,
                    "process_name": dashboardFilterSelectionsAutomation.process_name
                },
                "interval": {
                    "calender_Interval": calenderInterval,
                    "time_zone": timeZone
                },
                "workspace": workspace
            };
        }
        else if (workspace === workspaces.salesforce_ops) {
            technology = [technologies.Salesforce];
            franchise = dashboardFilterSelectionsSalesforce.franchise;
            client = dashboardFilterSelectionsSalesforce.client;
            bodyJSON = {
                "filters": {
                    "time": {
                        "startDateTime": timeFilterSelections.startDateTime,
                        "endDateTime": timeFilterSelections.endDateTime
                    },
                    "franchises": franchise,
                    "clients": client,
                    "technologies": technology,
                    // "deployment_models": dashboardFilterSelectionsSalesforce.deployment_model,
                    // "business_competencies": dashboardFilterSelectionsSalesforce.competency,
                    // "incident_status": dashboardFilterSelectionsSalesforce.incident_status,
                    // "assignment_groups": dashboardFilterSelectionsSalesforce.assigned_group,
                    // "process_name": dashboardFilterSelectionsSalesforce.process_name
                },
                "interval": {
                    "calender_Interval": calenderInterval,
                    "time_zone": timeZone
                },
                "workspace": workspace,
            };
        }
        else if (workspace === workspaces.custom_ops) {
            //pass custom filters when it is applicable for this space 
        }
        else {
            if (workspace === workspaces.oem_ops) {
                technology = [technologies.Oracle];
            } else {
                technology = [technologies.SAP, technologies.BW_PCM];
            }
            franchise = dashboardFilterSelectionsSAP.franchise;
            client = dashboardFilterSelectionsSAP.client;
            bodyJSON = {
                "filters": {
                    "time": {
                        "startDateTime": timeFilterSelections.startDateTime,
                        "endDateTime": timeFilterSelections.endDateTime
                    },
                    "franchises": franchise,
                    "clients": client,
                    "technologies": technology,
                    "assignment_groups": dashboardFilterSelectionsSAP.assignment_group,
                    "incident_status": dashboardFilterSelectionsSAP.incident_status,
                    "automata_priority": dashboardFilterSelectionsSAP.priority
                },
                "interval": {
                    "calender_Interval": calenderInterval,
                    "time_zone": timeZone
                },
                "workspace": workspace
            };
        }

        return bodyJSON;
    };

    const colors = {
        "P3-Normal": "#012749", //cyan30 
        "P4 - Low": "#003A6D", //cyan80
        "4 - Low": "#33B1FF", //cyan40
        "1 - Critical": "#BAE6FF", //cyan20
        "3 - Moderate": "#08BDBA", //teal40
        "5 - Planning": "#3DDBD9", //$teal30
        "2 - High": "#0072C3", //$cyan60 
        "P1 - Critical": "#007d79", //$teal60
        "P3 - Medium": "#005d5d", //$teal70
        "P2 - High": "#009d9a", //$teal50
        "NA": "#00539a", //$cyan70 
    };

    const setAgingSummary = (number) => {
        let value = number ? number : 0;
        setIncidentAgeingSummary(value);
    };

    const getIncidentManagementSummary = () => {
        let summary = [
            {
                title: t('DigitalOperations.IncidentAgeing'),
                value: incidentAgeingSummary ? incidentAgeingSummary.toFixed(2) : 0
            },
            {
                title: t('DigitalOperations.ReopenCount'),
                value: reopenCountSummary.reOpenCount ? reopenCountSummary.reOpenCount : 0
            },
            {
                title: t('DigitalOperations.FirstTimeResolved'),
                value: reopenCountSummary.percentage ? reopenCountSummary.percentage.toFixed(2) : 0,
                showPercent: true
            }
        ];
        setIncidentManagementSummary(summary);
    };

    const prepareSeverityWiseIncidentData = (response) => {
        let graphData = [];
        let tableData = [];
        let tableObj = {};
        let graphObj = {};
        let expandedItemObj = {};
        let expandedTable = [];
        let downloadableData = [];
        let buckets = [];
        if (response.body) {
            buckets = response.body.aggregations.incident_status.buckets;
            for (let i = 0; i < buckets.length; i++) {
                let priorityBucket = buckets[i].incident_priority.buckets;
                expandedTable = [];
                tableObj = {
                    id: i + 1,
                    item: buckets[i].key,
                    count: buckets[i]?.count?.value || 0,
                };

                for (let j = 0; j < priorityBucket.length; j++) {
                    graphObj = {
                        "group": buckets[i]?.key || "NA",
                        "key": priorityBucket[j]?.key || "NA",
                        "value": priorityBucket[j]?.count?.value || 0
                    }
                    graphData.push(graphObj);
                    downloadableData.push({
                        group: buckets[i].key,
                        item: priorityBucket[j].key || "NA",
                        count: priorityBucket[j]?.count?.value || 0
                    })
                    expandedItemObj = {
                        id: j + 1,
                        item: priorityBucket[j]?.key || "NA",
                        count: priorityBucket[j]?.count?.value || 0
                    }
                    expandedTable.push(expandedItemObj);
                }
                tableObj.expandedItems = expandedTable;
                tableData.push(tableObj);
            }
        }
        return { tableData, graphData, downloadableData };
    };

    const getIncidentStatusViaSeverity = (timeFilterSelections, dashboardFilterSelectionsAutomation, dashboardFilterSelectionsSAP, dashboardFilterSelectionsSalesforce, workspace) => {
        let formattedData = prepareSeverityWiseIncidentData(IncidentStatus);
        setSeverityWiseIncidentData(formattedData.graphData);
        setSeverityWiseIncidentTableData(formattedData.tableData);
        setSeverityWiseIncidentDownloadableData(formattedData.downloadableData);
        setSeverityWiseIncidentLoading(false);
        if (timeFilterSelections && workspace && (dashboardFilterSelectionsAutomation || dashboardFilterSelectionsSAP || dashboardFilterSelectionsSalesforce)) {
            setSeverityWiseIncidentLoading(true);
            let formattedData = [];
            let bodyJSON = getPayload(timeFilterSelections, dashboardFilterSelectionsAutomation, dashboardFilterSelectionsSAP, dashboardFilterSelectionsSalesforce, workspace);
            // callPostApi(Constants.GETINCIDENTSTATUSVIASEVERITY, {
            //     'Content-Type': 'application/json'
            // }, bodyJSON)
            //     .then(resp => {
            //         formattedData = prepareSeverityWiseIncidentData(resp);
            //         setSeverityWiseIncidentData(formattedData.graphData);
            //         setSeverityWiseIncidentTableData(formattedData.tableData);
            //         setSeverityWiseIncidentDownloadableData(formattedData.downloadableData);
            //         setSeverityWiseIncidentLoading(false);
            //     }).catch((error) => {
            //         setSeverityWiseIncidentLoading(false);
            //     });
        }
    }

    const prepareReopenCountSLAData = (response) => {
        let graphData = [];
        let tableData = [];
        let tableObj = {};
        let graphObj = {};
        let expandedItemObj = {};
        let expandedTable = [];
        let summaryData = {
            reOpenCount: 0,
            percentage: 0
        };
        let downloadableData = [];
        let downloadableObj = {};
        let reOpenCount = 0, total = 0, percentage = 0;
        if (response.body) {
            let buckets = response.body.aggregations["2"].buckets;
            for (let i = 0; i < buckets.length; i++) {
                expandedTable = [];
                total = 0;
                reOpenCount = 0;
                percentage = 0;
                tableObj = {
                    id: i + 1,
                    item: buckets[i].key
                };
                for (let j = 0; j < buckets[i]["5"].buckets.length; j++) {
                    total = total + buckets[i]["5"].buckets[j].doc_count;
                    reOpenCount = reOpenCount + buckets[i]["5"].buckets[j]["re-open-count"].value;
                    graphObj = {
                        "group": buckets[i]["5"].buckets[j].key,
                        "key": buckets[i].key === '' ? 'NA' : buckets[i].key,
                        "value": Math.round((((buckets[i]["5"].buckets[j].doc_count - buckets[i]["5"].buckets[j]["re-open-count"].value) * 100) / buckets[i]["5"].buckets[j].doc_count).toFixed(2))
                    };
                    expandedItemObj = {
                        id: j + 1,
                        item: buckets[i]["5"].buckets[j].key,
                        total: buckets[i]["5"].buckets[j].doc_count,
                        reOpenCount: buckets[i]["5"].buckets[j]["re-open-count"].value,
                        percentage: (((buckets[i]["5"].buckets[j].doc_count - buckets[i]["5"].buckets[j]["re-open-count"].value) * 100) / buckets[i]["5"].buckets[j].doc_count).toFixed(2)
                    };
                    downloadableObj = {
                        priority: buckets[i]["5"].buckets[j].key,
                        item: buckets[i].key,
                        total: buckets[i]["5"].buckets[j].doc_count,
                        reOpenCount: buckets[i]["5"].buckets[j]["re-open-count"].value,
                        percentage: (((buckets[i]["5"].buckets[j].doc_count - buckets[i]["5"].buckets[j]["re-open-count"].value) * 100) / buckets[i]["5"].buckets[j].doc_count).toFixed(2)
                    }
                    expandedTable.push(expandedItemObj);
                    graphData.push(graphObj);
                    downloadableData.push(downloadableObj);
                }
                tableObj.total = total;
                tableObj.reOpenCount = reOpenCount;
                tableObj.percentage = isNaN((((total - reOpenCount) * 100) / total).toFixed(2)) ? 0 : (((total - reOpenCount) * 100) / total).toFixed(2);
                tableObj.expandedItems = expandedTable;
                tableData.push(tableObj);
                summaryData.reOpenCount = summaryData.reOpenCount + tableObj.reOpenCount;
                summaryData.percentage = parseInt(summaryData.percentage) + parseInt(tableObj.percentage);
            }
            summaryData.reOpenCount = summaryData.reOpenCount;
            summaryData.percentage = summaryData.percentage / buckets.length;
            graphData.sort((a, b) => { return a.value - b.value }).reverse();//sorting based on value
        }
        return { tableData, graphData, summaryData, downloadableData };
    };

    const getReopenCountSLA = (timeFilterSelections, dashboardFilterSelectionsAutomation, dashboardFilterSelectionsSAP, dashboardFilterSelectionsSalesforce, workspace) => {
        let formattedData = prepareReopenCountSLAData(CountSla);
        setReopenCountSLAData(formattedData.graphData);
        setReopenCountSLATable(formattedData.tableData);
        setReopenCountSummary(formattedData.summaryData);
        setReopenCountDownloadabelData(formattedData.downloadableData);
        setReopenCountLoading(false);
        if (timeFilterSelections && workspace && (dashboardFilterSelectionsAutomation || dashboardFilterSelectionsSAP || dashboardFilterSelectionsSalesforce)) {
            let formattedData = [];
            setReopenCountLoading(true);
            let bodyJSON = getPayload(timeFilterSelections, dashboardFilterSelectionsAutomation, dashboardFilterSelectionsSAP, dashboardFilterSelectionsSalesforce, workspace);
        
            // callPostApi(Constants.GETREOPENCOUNTSLA, {
            //     'Content-Type': 'application/json'
            // }, bodyJSON)
            //     .then(resp => {
            //         formattedData = prepareReopenCountSLAData(resp);
            //         setReopenCountSLAData(formattedData.graphData);
            //         setReopenCountSLATable(formattedData.tableData);
            //         setReopenCountSummary(formattedData.summaryData);
            //         setReopenCountDownloadabelData(formattedData.downloadableData);
            //         setReopenCountLoading(false);
            //     }).catch((error) => {
            //         setReopenCountLoading(false);
            //     });

        }
    }

    const getIncidentBreakfix = (timeFilterSelections, dashboardFilterSelectionsAutomation, dashboardFilterSelectionsSAP, dashboardFilterSelectionsSalesforce, workspace) => {
        setBreakfixData(BreakFixJson);
        setBreakfixLoading(false);
        if (timeFilterSelections && workspace && (dashboardFilterSelectionsAutomation || dashboardFilterSelectionsSAP || dashboardFilterSelectionsSalesforce)) {
            setBreakfixLoading(true);
            let bodyJSON = getPayload(timeFilterSelections, dashboardFilterSelectionsAutomation, dashboardFilterSelectionsSAP, dashboardFilterSelectionsSalesforce, workspace);
            // callPostApi(Constants.GETINCIDENTBREAKFIX, {
            //     'Content-Type': 'application/json'
            // }, bodyJSON)
            //     .then(resp => {
            //         setBreakfixData(resp);
            //         setBreakfixLoading(false);
            //     }).catch((error) => {
            //         setBreakfixLoading(false);
            //     });
        }
    }

    return (
        <div className="top-margin dashboard incident-management">
            <div className="dashboard-detail-container col-lg-12 no-gutters">
                <div className="dashboard-roleContainer col-lg-12">
                    <DashboardSummary loading={reopenCountLoading} summary={incidentManagementSummary} title={t('DigitalOperations.IncidentManagementEfficiencySummary')} />
                </div>
                <div className="dashboard-charts-container">
                    <section className="border-bottom">
                        <DetailedIncidentKpis preferencesChanged={props.preferencesChanged} timeFilterSelections={props.timeFilterSelections}
                            filterSelectionsAutomation={props.filterSelectionsAutomation} filterSelectionsSAP={props.filterSelectionsSAP}
                            filterSelectionsSalesforce={props.filterSelectionsSalesforce} workspace={props.workspace}
                            getPayload={getPayload} setAgingSummary={setAgingSummary} />
                    </section>
                    <section className="border-bottom first-time-resolution">
                        <DashboardStackedBarChart
                            loading={reopenCountLoading}
                            title={t('DigitalOperations.FirstTimeResolution')}// "% First Time Resolution"
                            bottomTitle={t('DigitalOperations.AvgIncidentAgeingResolvedTickets')}//"Avg Incident Ageing resolved tickets"
                            leftAxesTitle={t('DigitalOperations.franchise')}
                            stackedBarChartData={reopenCountSLAData}
                            headers={reopenCountDownloadableTableHeaders}
                            downloadableData={reopenCountDownloadabelData}
                            isPercentage={true}
                            showTooltip={true}
                            colors={colors}
                            leftStack={true}
                            graphDescription={t('DigitalOperations.FirstTimeResolutionDesc')}
                        />
                        <div className="bold-border-table nested-table">
                            <DashboardTable loading={reopenCountLoading} rows={reopenCountSLATable} headers={reopenCountTableHeaders}
                                showPagination={true} isSortable={true} isExpandable={true} />
                        </div>
                    </section>
                    <section className="border-bottom">
                        <div className="tile-view">
                            <div className="report-container severity-wise">
                                <DashboardStackedBarChart
                                    loading={severityWiseIncidentLoading}
                                    title={t('DigitalOperations.SeverityWiseIncidentStatus')}
                                    bottomTitle={t('DigitalOperations.Count')}
                                    leftAxesTitle={t('DigitalOperations.Severity')}
                                    stackedBarChartData={severityWiseIncidentData}
                                    downloadableData={severityWiseIncidentDownloadableData}
                                    colors={colors}
                                    leftStack={true}
                                    graphDescription={t('DigitalOperations.SeverityWiseDesc')}
                                    showTooltip={true}
                                />
                                <div className="bold-border-table nested-table severity-wise-incident-table" style={{ marginLeft: '15px' }}>
                                    <DashboardTable loading={severityWiseIncidentLoading} rows={severityWiseIncidentTableData} headers={severityWiseTableHeaders}
                                        showPagination={true} isSortable={true} isExpandable={true} />
                                </div>
                            </div>
                            <div className="report-container">
                                {breakfixLoading ? (
                                    <>
                                        <div className="graph-title carbon-display-Regular-20">{props.title}</div>
                                        <div><InlineLoading description={t('DigitalOperations.loading')} /></div>
                                    </>
                                ) : (
                                    <Breakfix breakfixData={breakfixData} loading={breakfixLoading} />
                                )}
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default IncidentManagement;
