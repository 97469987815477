import moment from 'moment';

export const VelocityTrendData = (response) => {
    let data1 = []; let data2 = []; let data = [];
    let objTotalTickets = {};
    let objTicketsCreated = {};
    for (let j = 0; j < response.length; j++) {
        if (response) {
            let buckets = [];
            if (j === 0) {
                buckets = (response[j]?.data?.aggregations?.client?.buckets) || [];
            }
            else buckets = (response?.[j]?.data?.aggregations?.Time?.buckets) || [];

            for (let i = 0; i < buckets.length; i++) {
                let total_alerts = buckets[i]?.totalAlerts?.value || 0;
                let unique_count = buckets[i]?.tickets?.unique_count?.value || 0;
                objTotalTickets = {
                    group: 'Alerts',
                    date: moment(new Date(buckets[i]?.key_as_string)).format("DD-MMM-YY"),
                    value: (total_alerts).toFixed(2)
                };
                objTicketsCreated = {
                    group: 'Incidents',
                    date: moment(new Date(buckets[i]?.key_as_string)).format("DD-MMM-YY"),
                    value: unique_count.toFixed(2)
                };
                if (j === 0) {
                    data1.push(objTotalTickets, objTicketsCreated);
                }
                else {
                    data2.push(objTotalTickets, objTicketsCreated);
                }
            }
        }
    }
    if (data1 && data1.length > 0) {
        data1.map((item) => {
            data2.map((dataItem) => {
                if (item['group'] === dataItem['group'] && item['date'] === dataItem['date']) {
                    item['value'] = Number(item['value']) + Number(dataItem['value']);
                }
            })
            data.push(item);
        })
    }
    else data.push(...data2);

    return data;
}

export const AlertDistributionData = (response) => {
    let data = [];
    let applicationAlertCount = {};
    let infraAlertCount = {};
    let applicationAlertCountBucket = (response && response?.[0]?.data?.aggregations?.Time?.buckets) || [];
    let infraAlertCountBucket = (response && response?.[1]?.data?.aggregations?.Time?.buckets) || [];
    for (let i = 0; i < applicationAlertCountBucket.length; i++) {
        applicationAlertCount = {
            group: 'Application Alert',
            date: applicationAlertCountBucket[i] && moment(new Date(applicationAlertCountBucket[i].key_as_string)).format("DD-MMM-YY"),
            value: (applicationAlertCountBucket[i]?.totalAlerts?.value || 0).toFixed(2)
        };
        data.push(applicationAlertCount);
    }
    for (let i = 0; i < infraAlertCountBucket.length; i++) {
        infraAlertCount = {
            group: 'Infra Alert',
            date: moment(new Date(infraAlertCountBucket[i].key_as_string)).format("DD-MMM-YY"),
            value: (infraAlertCountBucket[i]?.totalAlerts?.value || 0).toFixed(2)
        };
        data.push(infraAlertCount);
    }
    return data;
}

export const clientAlertDistribution = (response) => {
    let tableData = [];
    let tableObj;
    let duplicatedAlerts = 0, ticketConversionRate = 0, averageAnalysisTime = 0, averageResolutionTime = 0;
    let redGraphDataTotal = 0, yellowGraphDataTotal = 0;
    let redGraphData = [], yellowGraphData = [];
    let totalAlerts = 0;
    let summaryData = { totalAlerts: 0, totalDuplicateAlerts: 0, uniqueAlerts: 0 }
    let totalDuplicateAlerts = 0, uniqueAlerts = 0;
    let chartData = [], tableDataArr = [];
    let totalGroupGraphObj = {};
    let uniqueGroupGraphObj = {};
    let infraClientArr = [], chartDataArr = [];

    for (let j = 0; j < response.length; j++) {
        let buckets;
        if (j === 0) {
            buckets = (response && response[j]?.data?.aggregations?.client?.buckets) || [];
        }
        else buckets = (response && response[j]?.data?.aggregations?.['client name']?.buckets) || [];

        if (j === 0) {
            for (let i = 0; i < buckets.length; i++) {
                let ticketed_count = buckets[i]?.total?.buckets?.ticketed?.count?.value || 0;
                let unique_count = buckets[i]?.tickets?.unique_count?.value || 0;
                let total_alerts = buckets[i]?.totalAlerts?.value || 0;
                let red_alerts = buckets[i]?.["Red-Alerts"]?.count?.value || 0;
                let yellow_alerts = buckets[i]?.["Yellow-Alerts"]?.count?.value || 0;
                let informational_count = buckets[i]?.total?.buckets?.informational?.count?.value || 0;
                duplicatedAlerts = ticketed_count - unique_count;
                ticketConversionRate = total_alerts > 0 ? (unique_count / total_alerts) * 100 : 0;
                averageAnalysisTime = buckets[i]?.tickets?.TicketingElapse?.value || 0;
                averageResolutionTime = buckets[i]?.tickets?.ResolutionElapse?.value || 0;

                tableObj = {
                    id: Math.random(),
                    engagement: buckets[i].key,
                    ticketedAlerts: unique_count,
                    duplicatedAlerts: duplicatedAlerts || 0,
                    nonTicketsAlerts: informational_count,
                    ticketConversionRate: isNaN(ticketConversionRate) ? 0 : (ticketConversionRate || 0).toFixed(2),
                    avgAlertAnalysisTime: averageAnalysisTime ? (averageAnalysisTime).toFixed(2) : 0,
                    avgAlertResolutionTime: averageResolutionTime ? (averageResolutionTime).toFixed(2) : 0,
                    totalAlerts: total_alerts
                };

                tableObj.red = red_alerts;
                redGraphDataTotal = redGraphDataTotal + red_alerts;
                tableObj.yellow = yellow_alerts;
                yellowGraphDataTotal = yellowGraphDataTotal + yellow_alerts;
                tableData.push(tableObj);
                totalAlerts = totalAlerts + total_alerts;
                uniqueAlerts = uniqueAlerts + unique_count;
                totalDuplicateAlerts = totalDuplicateAlerts + duplicatedAlerts;
                summaryData = {
                    totalAlerts: totalAlerts,
                    totalDuplicateAlerts: totalDuplicateAlerts,
                    uniqueAlerts: uniqueAlerts
                }

                //gauge data
                redGraphData = [
                    {
                        "group": "value",
                        "value": redGraphDataTotal || 0
                    }
                ];
                yellowGraphData = [
                    {
                        "group": "value",
                        "value": yellowGraphDataTotal || 0
                    }
                ]

                //graph data
                totalGroupGraphObj = {
                    "group": 'Alerts',
                    "key": buckets[i]?.key,
                    "value": total_alerts
                };
                uniqueGroupGraphObj = {
                    "group": 'Incidents',
                    "key": buckets[i]?.key,
                    "value": unique_count
                };
                chartData.push(totalGroupGraphObj, uniqueGroupGraphObj);
            }
        }
        else {
            let totInfraAlerts = 0, totInfraTickets = 0, dupInfraAlerts = 0;
            for (let i = 0; i < buckets.length; i++) {
                let total_alerts = buckets[i]?.totalAlerts?.value || 0;
                let unique_count = buckets[i]?.tickets?.unique_count?.value || 0;
                let ticketed_count = buckets[i]?.total?.buckets?.ticketed?.count?.value || 0;
                let informational_count = buckets[i]?.total?.buckets?.informational?.count?.value || 0;

                totInfraAlerts = totInfraAlerts + total_alerts;
                totInfraTickets = totInfraTickets + unique_count; //unique alerts
                dupInfraAlerts = dupInfraAlerts + (ticketed_count - unique_count);

                for (let j = 0; j < tableData.length; j++) {

                    //infra and application common clients data
                    if (buckets[i].key === tableData[j].engagement) {
                        let dAlerts = ticketed_count - unique_count;

                        let infraAverageAnalysisTime = (buckets[i]?.tickets?.TicketingElapse?.value || 0).toFixed(2);
                        let infraAverageResolutionTime = (buckets[i]?.tickets?.ResolutionElapse?.value || 0).toFixed(2);

                        tableData[j].duplicatedAlerts = tableData[j].duplicatedAlerts + dAlerts;
                        tableData[j].nonTicketsAlerts = tableData[j].nonTicketsAlerts + informational_count;//informational alerts
                        tableData[j].ticketedAlerts = tableData[j].ticketedAlerts + unique_count;
                        let infraTicketCR = (tableData[j].ticketedAlerts / (tableData[j].totalAlerts + (total_alerts))) * 100;
                        tableData[j].ticketConversionRate = (infraTicketCR || 0).toFixed(2);
                        tableData[j].avgAlertAnalysisTime = (Number(tableData[j].avgAlertAnalysisTime) + Number(infraAverageAnalysisTime)).toFixed(2) / 2;
                        tableData[j].avgAlertResolutionTime = (Number(tableData[j].avgAlertResolutionTime) + Number(infraAverageResolutionTime)).toFixed(2) / 2;
                    }
                }

                // if the clients are same then adds the total tickets and alerts
                let infraTotalGroupGraphObj = {
                    "group": 'Alerts',
                    "key": buckets[i].key,
                    "value": total_alerts
                };
                let infraUniqueGroupGraphObj = {
                    "group": 'Incidents',
                    "key": buckets[i].key,
                    "value": unique_count
                };
                chartData.push(infraTotalGroupGraphObj, infraUniqueGroupGraphObj);
            }

            summaryData.totalAlerts = Number(summaryData.totalAlerts || 0) + Number(totInfraAlerts || 0);
            summaryData.totalDuplicateAlerts = Number(summaryData.totalDuplicateAlerts || 0) + Number(dupInfraAlerts || 0);
            summaryData.uniqueAlerts = Number(summaryData.uniqueAlerts || 0) + Number(totInfraTickets || 0);

            let resDataArr = chartData.reduce(function (acc, val) {
                var o = acc.filter(function (obj) {
                    return obj.key == val.key && obj.group === val.group;
                }).pop() || { key: val.key, group: val.group, value: 0 };

                o.value += val.value;
                acc.push(o);
                return acc;
            }, []);

            let arr1 = [], arr2 = [];
            resDataArr.map((item) => {
                if (item.group === 'Alerts') arr1.push(item);
                else arr2.push(item);
            })
            let dataArr1 = [
                ...new Map(arr1.map((item) => [item['key'], item])).values(),
            ];
            let dataArr2 = [
                ...new Map(arr2.map((item) => [item['key'], item])).values(),
            ];

            chartDataArr = dataArr1.concat(dataArr2);

            let commonKey = [];

            tableData.map((item) => {
                buckets.map((infraItem) => {
                    if (item.engagement === infraItem.key) {
                        commonKey.push(item.engagement);
                    }
                })
            })

            if (commonKey.length > 0) {
                for (let k = 0; k < commonKey.length; k++) {
                    for (let i = 0; i < buckets.length; i++) {

                        if (commonKey[k] !== buckets[i].key) {
                            let total_alerts = buckets[i]?.totalAlerts?.value || 0;
                            let unique_count = buckets[i]?.tickets?.unique_count?.value || 0;
                            let ticketed_count = buckets[i]?.total?.buckets?.ticketed?.count?.value || 0;
                            let informational_count = buckets[i]?.total?.buckets?.informational?.count?.value || 0;
                            let dAlerts = ticketed_count - unique_count;
                            let infraTicketCR = total_alerts > 0 ? (unique_count / total_alerts) * 100 : 0;
                            let infraAverageAnalysisTime = (buckets[i]?.tickets?.TicketingElapse?.value || 0).toFixed(2);
                            let infraAverageResolutionTime = (buckets[i]?.tickets?.ResolutionElapse?.value || 0).toFixed(2);

                            let infraTableObj = {
                                id: Math.random(),
                                engagement: buckets[i].key,
                                ticketedAlerts: unique_count,
                                duplicatedAlerts: dAlerts || 0,
                                nonTicketsAlerts: informational_count,
                                ticketConversionRate: isNaN(infraTicketCR) ? 0 : (infraTicketCR || 0).toFixed(2),
                                avgAlertAnalysisTime: infraAverageAnalysisTime,
                                avgAlertResolutionTime: infraAverageResolutionTime,
                                red: '-',
                                yellow: '-'
                            };
                            infraClientArr.push(infraTableObj);
                        }
                    }

                }

                let DataArr = tableData.concat(infraClientArr);
                const uniqueIds = [];

                tableDataArr = DataArr.filter(element => {
                    const isDuplicate = uniqueIds.includes(element.engagement);

                    if (!isDuplicate) {
                        uniqueIds.push(element.engagement);

                        return true;
                    }
                    return false;
                });
            }
            else {
                for (let i = 0; i < buckets.length; i++) {
                    let total_alerts = buckets[i]?.totalAlerts?.value || 0;
                    let unique_count = buckets[i]?.tickets?.unique_count?.value || 0;
                    let ticketed_count = buckets[i]?.total?.buckets?.ticketed?.count?.value || 0;
                    let informational_count = buckets[i]?.total?.buckets?.informational?.count?.value || 0;
                    let dAlerts = ticketed_count - unique_count;
                    let infraTicketCR = ((unique_count) / (total_alerts)).toFixed(2) * 100;
                    let infraAverageAnalysisTime = (buckets[i]?.tickets?.TicketingElapse?.value || 0).toFixed(2);
                    let infraAverageResolutionTime = (buckets[i]?.tickets?.ResolutionElapse?.value || 0).toFixed(2);
                    let infraTableObj = {
                        id: Math.random(),
                        engagement: buckets[i].key,
                        ticketedAlerts: unique_count,
                        duplicatedAlerts: dAlerts || 0,
                        nonTicketsAlerts: informational_count,
                        ticketConversionRate: isNaN(infraTicketCR) ? 0 : (infraTicketCR || 0).toFixed(2),
                        avgAlertAnalysisTime: infraAverageAnalysisTime,
                        avgAlertResolutionTime: infraAverageResolutionTime,
                        red: '-',
                        yellow: '-'
                    };
                    infraClientArr.push(infraTableObj);
                }
            }
            if (commonKey.length === 0) {
                tableDataArr = tableData.concat(infraClientArr);
            }
        }
    }
    chartDataArr.sort((a, b) => { return a.value - b.value });
    return { tableDataArr, summaryData, chartDataArr, redGraphData, yellowGraphData };

};

const combineCountOfDuplicates = (response) => {
    let result = Array.from(new Set(response.map(s => s.item)))
        .map((value, i) => {
            return {
                item: value,
                id: i + 1,
                key: i + 1,
                count: response.filter(s => s.item === value).map(alert => alert.count).reduce(function (a, b) { return a + b; }, 0),
                alertDetailsList: response.filter(s => s.item === value).map(alert => alert.alertDetailsList).reduce(function (a, b) { return a.concat(b); })
            }
        });
    return result;
};

const sortTableData = (tableData) => {
    tableData.sort((item1, item2) => (item1.count < item2.count) ? 1 : (item1.count > item2.count) ? -1 : 0);
    return tableData;
};

export const top5AlertCategories = (response) => {
    let occResponse = response?.[0]?.alertListData || [];
    let infraResponse = response?.[1]?.alertListData || [];
    let alertList = occResponse.concat(infraResponse);
    alertList = combineCountOfDuplicates(alertList);
    alertList = sortTableData(alertList);
    return alertList;
}

export const AlertsMetricsData = (response, headers, downloadableHeaders) => {
    if (response) {
        let tableData = [], expandedTable = [];
        let expandedItemObj;
        let downloadableDataObj;
        let downloadableData = [];
        let entityBucket, histogramBucket;
        let key, keyAsString;
        let buckets = response?.data?.aggregations.alert_name.buckets;

        for (let i = 0; i < buckets.length; i++) {
            tableData[i] = {
                id: i,
                item: buckets[i].key,
                total: 0
            }
            expandedTable = [];
            entityBucket = buckets[i].entity.buckets;
            for (let j = 0; j < entityBucket.length; j++) {
                let expandedtotal = 0;
                histogramBucket = entityBucket[j].histogram.buckets;
                expandedItemObj = {
                    item: entityBucket[j].key
                }
                downloadableDataObj = {
                    entity: entityBucket[j].key
                }
                for (let k = 0; k < histogramBucket.length; k++) {
                    keyAsString = histogramBucket[k]["key_as_string"];
                    key = 'date' + k;
                    if (i === 0 && j === 0) {
                        headers[k + 1] = {
                            key: key,
                            header: keyAsString ? moment(new Date(keyAsString)).format("DD-MMM-YY") : ''
                        }
                        downloadableHeaders[k + 2] = {
                            key: key,
                            header: keyAsString ? moment(new Date(keyAsString)).format("DD-MMM-YY") : ''
                        }
                    }
                    let total_alerts = histogramBucket?.[k]?.totalAlerts?.value || 0;
                    expandedItemObj[key] = total_alerts; //entity bucket , j.key
                    expandedItemObj.id = (j + 1).toString();
                    downloadableDataObj[key] = total_alerts;
                    downloadableDataObj.id = (j + 1).toString();
                    downloadableDataObj.alertItem = buckets[i].key;
                    expandedtotal = expandedtotal + total_alerts;
                    expandedItemObj['expandedtotal'] = expandedtotal;
                }
                expandedTable.push(expandedItemObj);
                expandedTable.sort((a, b) => { return a.expandedtotal - b.expandedtotal }).reverse();
                downloadableData.push(downloadableDataObj)
            }
            // calculating total to display
            expandedTable.reduce(function (accumulator, item, index) {
                let total = 0;
                Object.keys(item).forEach(function (key, i) {
                    accumulator[key] = (accumulator[key] || 0) + item[key];
                });
                for (let j = 0; j < Object.keys(item).length; j++) {
                    tableData[i]['date' + j] = accumulator['date' + j] ? accumulator['date' + j] : 0;
                }
                let datekeys = Object.keys(accumulator).filter(key => key.includes('date'))
                for (let l = 0; l < datekeys.length; l++) {
                    total = total + (accumulator[datekeys[l]] ? accumulator[datekeys[l]] : 0);
                    tableData[i]['total'] = total;
                }
                return accumulator;
            }, {});

            tableData[i].expandedItems = expandedTable;
        }
        tableData.sort((a, b) => { return a.total - b.total }).reverse();
        return { tableData, downloadableData, headers, downloadableHeaders };
    }
}

function filterObj(keys, obj) {
    const newObj = {};
    Object.keys(obj).forEach(key => {
        if (!keys.includes(key)) {
            newObj[key] = obj[key];
        }
    });
    return newObj;
}

export const InfraAlertsMetricsData = (response, headers, downloadableHeaders) => {
    if (response) {
        let tableData = [], tableObj = {}, SPArr = [], SNArr = [], SNObj = {}, expandedLevel1Obj = {}, keyArr = [], downloadableDataObj = {}, downloadableData = [];
        let sourceNameBucket, sourceProviderBucket;
        let key, keyAsString;
        let buckets = response?.data?.aggregations?.['Alert Name']?.buckets || [];

        for (let i = 0; i < buckets.length; i++) {
            SPArr = [];
            tableObj = {
                id: (i + 1).toString(),
                item: buckets[i].key,
                total: 0
            };
            sourceProviderBucket = buckets?.[i]?.['Source Provider']?.buckets || [];
            for (let j = 0; j < sourceProviderBucket.length; j++) {
                let expandedtotal = 0; SNArr = [];
                expandedLevel1Obj = {
                    id: (j + 1).toString(),
                    item: sourceProviderBucket[j].key
                };
                SPArr.push(expandedLevel1Obj);
                tableObj.expandedItems = SPArr;

                sourceNameBucket = sourceProviderBucket?.[j]?.['Source Name']?.buckets || [];
                for (let k = 0; k < sourceNameBucket.length; k++) {
                    SNObj = {
                        id: Math.random() + (k + 1).toString(),
                        item: sourceNameBucket[k].key
                    }
                    downloadableDataObj = {
                        sourceName: sourceNameBucket[k].key
                    }
                    let timeBuckets = sourceNameBucket?.[k]?.['Time']?.buckets || [];
                    for (let l = 0; l < timeBuckets.length; l++) {
                        let alert_time = timeBuckets?.[l]?.['totalAlerts']?.value || 0;
                        keyAsString = timeBuckets[l]["key_as_string"];
                        let date = moment(new Date(keyAsString)).format("DD-MMM-YY");
                        key = 'date' + keyArr.length + 1;
                        if (!keyArr.includes(date)) {
                            headers[keyArr.length + 1] = {
                                key: key,
                                header: keyAsString ? moment(new Date(keyAsString)).format("DD-MMM-YY") : ''
                            }
                            downloadableHeaders[keyArr.length + 3] = {
                                key: key,
                                header: keyAsString ? moment(new Date(keyAsString)).format("DD-MMM-YY") : ''
                            }
                            SNObj[key] = alert_time;
                            keyArr.push(moment(new Date(keyAsString)).format("DD-MMM-YY"));
                        }
                        else {
                            let index = keyArr.indexOf(date);
                            key = 'date' + index + 1;
                            SNObj[key] = alert_time;
                        }
                        downloadableDataObj[key] = alert_time;
                        downloadableDataObj.id = (k).toString();
                        downloadableDataObj.alertItem = buckets[i].key;
                        downloadableDataObj.sourceProvider = sourceProviderBucket[j].key;
                        expandedtotal = expandedtotal + alert_time;
                        tableObj['total'] = expandedtotal;

                    }
                    SNArr.push(SNObj);
                    downloadableData.push(downloadableDataObj);
                }
                expandedLevel1Obj.expandedItems = SNArr;

            }

            tableData.push(tableObj);
            SNArr.reduce(function (accumulator, item, index) {
                let total = 0; let keyArray = [];
                keyArray.push(Object.keys(item));
                let newArr = [];
                for (let i = 0; i < keyArr.length; i++) {
                    if (keyArr[i] !== 'id' && keyArr[i] !== 'item') {
                        newArr.push(keyArr[i]);
                    }
                }
                Object.keys(item).forEach(function (key, i) {
                    accumulator[key] = (accumulator[key] || 0) + item[key];
                });
                let keys = ['id', 'item'];
                let a = filterObj(keys, accumulator);

                for (const [key, value] of Object.entries(a)) {
                    tableData[i][key] = value;
                }
                let datekeys = Object.keys(accumulator).filter(key => key.includes('date'))
                for (let l = 0; l < datekeys.length; l++) {
                    total = total + (accumulator[datekeys[l]] ? accumulator[datekeys[l]] : 0);
                    tableData[i]['total'] = total ? total : 0;
                }
                return accumulator ? accumulator : 0;
            }, {});
        }
        tableData.map((tableItem) => {
            headers.map((headerItem) => {
                if (headerItem.key.includes('date')) {
                    if (!tableItem.hasOwnProperty(headerItem.key)) {
                        tableItem[headerItem.key] = '-';
                    }
                }
            })
        })
        return { tableData, downloadableData, headers, downloadableHeaders };
    }
}
