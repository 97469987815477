import React, { useState, useEffect } from 'react';
import { Pagination, Tooltip } from '@carbon/react';
import { useTranslation } from 'react-i18next';
import { Information } from "@carbon/react/icons/index";

import DashboardTable from '../CarbonComponents/DashboardTable';
import TableToolbarDownload from '../CarbonComponents/TableToolbarDownload';


const Breakfix = (props) => {
     const { t } = useTranslation();
    const [paginatedRows, setPaginatedRows] = useState([]);
    const [firstRowIndex, setFirstRowIndex] = useState(0);
    const [currentPageSize, setCurrentPageSize] = useState(2);
    const [data, setData] = useState([]);
    const [downloadableData, setDownloadableData] = useState([]);
    const [BreakfixHeaderData, setBreakfixHeaderData] = useState([]);
    const [BreakfixDownloadableHeaderData, setBreakfixDownloadableHeaderData] = useState([]);
    useEffect(() => {
        let formattedData = prepareData(props.breakfixData);
        console.log("formattedData ========= >>>>>>", formattedData)
        if (formattedData) {
            setData(formattedData.tableData);
            setDownloadableData(formattedData.downloadableData);
        }
    }, [props.loading, props.breakfixData]);
    useEffect(() => {
        getPaginatedRows(firstRowIndex, currentPageSize);
    }, [data]);

    const getPaginatedRows = (param, pageSize) => {
        let startingIndex = Number.isInteger(param) ? param : firstRowIndex;
        let slicedRows = data.slice(startingIndex, startingIndex + pageSize);
        setPaginatedRows(slicedRows);
    };

    const prepareData = (response) => {

        if (response && response.body) {
            let tableData = [];
            let downloadableData = [];
            let tableObj = {};
            let statusBucket = [];
            let statusObj = {};
            let breakfixArray = [];
            let downloadableObj = {};
            let headersObj = [];
            let headerResult = [];
            let downloadableHeaders = [
                {
                    key: 'franchise',
                    header: t('DigitalOperations.franchse')
                },
            ];
            let headers = [
                {
                    key: 'severity',
                    header: t('DigitalOperations.Severity')
                },
                {
                    key: 'total',
                    header: t('DigitalOperations.Total')
                }
            ];
            let buckets = response.body.aggregations.franchise_term_total.buckets;
            for (let i = 0; i < buckets.length; i++) {
                breakfixArray = [];
                tableObj = {
                    id: i + 1,
                    franchise: buckets[i]?.key || "NA"
                };
                for (let j = 0; j < buckets[i].franchise_priority.buckets.length; j++) {
                    statusObj = {};
                    statusObj.id = ((i + j) + 1).toString();
                    statusObj.severity = buckets[i].franchise_priority.buckets[j].key;
                    statusObj.total = buckets[i].franchise_priority.buckets[j].doc_count;
                    statusBucket = buckets[i].franchise_priority.buckets[j].incident_status.buckets;
                    for (let k = 0; k < statusBucket.length; k++) {
                        statusObj[statusBucket[k].key] = statusBucket[k].count.value;
                        headersObj.push({ key: statusBucket[k].key, header: t(`DigitalOperations.analytics.${statusBucket[k].key}`) });
                    }
                    downloadableObj = statusObj;
                    downloadableObj.franchise = buckets[i].key;
                    downloadableData.push(downloadableObj);
                    breakfixArray.push(statusObj);
                }
                tableObj.breakfix = breakfixArray;
                tableData.push(tableObj);
                headerResult = headersObj.filter((v, i, a) => a.findIndex(t => (t.key === v.key)) === i);
            }
            headers = headers.concat(headerResult);
            downloadableHeaders = downloadableHeaders.concat(headers)
            console.log("headers ========= >>>>>>", headers)
            setBreakfixHeaderData(headers);
            setBreakfixDownloadableHeaderData(downloadableHeaders)
            return { tableData, downloadableData };
        }
    };
    return (
        <>
            <div className="graph-title carbon-display-Regular-20">
                <div>{t('DigitalOperations.Breakfix')}
                    <Tooltip direction="right"
                        label={t('DigitalOperations.BreakfixDesc')}
                                    style={{ color: "black", fontSize: "14px" }}>
                                    <button
                                    className="tooltip-button"
                                    type="button"
                                >
                                    <Information />
                                </button>
                    </Tooltip>
                </div>
                <div className="download-button">
                    <TableToolbarDownload iconDescription={t('DigitalOperations.export')} rows={downloadableData} headers={BreakfixDownloadableHeaderData} title={t('DigitalOperations.Breakfix')} filename={t('DigitalOperations.export')} label={t('DigitalOperations.download')} />
                </div>
            </div>
            <div className="breakfix-content">
                {paginatedRows.map((data, i) => (
                    <div className="breakfix-content">
                        <div className="franchise-name carbon-display-Regular-16">
                            {data.franchise}
                        </div>
                        <div className="bold-border-table">
                            <DashboardTable loading={props.loading} rows={data.breakfix} headers={BreakfixHeaderData}
                                showPagination={true} isSortable={true} />
                        </div>
                    </div>
                ))}
                {data.length ?
                    <Pagination
                        totalItems={data.length}
                        backwardText={t('DigitalOperations.previousPage')}
                        forwardText={t('DigitalOperations.nextPage')}
                        pageSize={2}
                        pageSizes={[2, 4, 6, 8]}
                        itemsPerPageText={t('DigitalOperations.itemsPage')}
                        onChange={({ page, pageSize }) => {
                            let newFirstRowIndex = pageSize * (page - 1);
                            setFirstRowIndex(newFirstRowIndex)
                            getPaginatedRows(newFirstRowIndex, pageSize);
                        }}
                    />
                    :
                    ''
                }
            </div>
        </>
    );
}
export default Breakfix
