import React, { useState, useEffect, useReducer } from 'react';
import { Tabs, Tab, TabList, TabPanels, TabPanel, DataTableSkeleton, Pagination, Button } from "@carbon/react";
import { SkipForwardFilled } from "@carbon/react/icons/index";
import moment from "moment";
import { useTranslation } from 'react-i18next';

import DashboardSummary from '../DashboardSummary';
import AlertsTable from '../CarbonComponents/AlertsTable';
import FilterLables from '../FilterLabels';
import SalesForce from "./salesForce.json";
import { getDOInfraAlertsApi, getDOPitApi } from "../Services/ServerApi";
import { prepareInfraAlertsData, prepareInfraSFAlertsData } from './Utils.js';
import '../scss/AlertsSummary.scss';

export default function AlertSummary() {
    const [pit, setPit] = useState("");
    const [searchAfter, setsearchAfter] = useState("");
    const [alertSummary, setAlertSummary] = useState([]);
    const [alertPage, setAlertPage] = useState(1);
    const [alertSearchPage, setAlertSearchPage] = useState(1);
    const [alertCurrentPageSize, setAlertCurrentPageSize] = useState(10);
    const [alertFirstRowIndex, setAlertFirstRowIndex] = useState(0);
    const [summaryLoading, setSummaryLoading] = useState(true);
    const [pitTime, setPitTime] = useState({ current: "", expiry: "" });
    const [alertFilterSelections, setAlertFilterSelections] = useState({});
    const [disabledSelects, setDisabledSelects] = useState({ 'client-select': true });
    const [filterData, alertFilterDispatch] = useReducer(alertFilterDataReducer, ({ clients: [] }));
    const [alertsTableData, setAlertsTableData] = useState({ loading: true, tableData: [], alertsResponse: {} });
    const [alertsTableSearchString, setAlertsTableSearchString] = useState('');
    const [searchedAlertFirstRowIndex, setSearchedAlertFirstRowIndex] = useState(0);
    const [searchAlertRows, setSearchAlertRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [TotalPages, setTotalPages] = useState(0);
    const [alertTotalItems, setAlertTotalItems] = useState(0);
    const [TotalItems, setTotalItems] = useState(0);
    const [NextClicked, setNextClicked] = useState(false);
    const [downloadItems, setdownloadItems] = useState([]);
    const { t } = useTranslation();
    let alertType = 'Infra Alert';
    let index = sessionStorage.getItem("doInfra");

    useEffect(() => {
        getInfraAlertsData(); //function to fetch infra-alerts
        setAlertFirstRowIndex(0);
        setAlertPage(1);
    }, [alertFilterSelections]);

    const alertSummaryHeaders = [
        {
            key: 'alertName',
            header: t('DigitalOperations.alertName'),
            hidden: false,
            optional: false
        },
        {
            key: 'priority',
            header: t('DigitalOperations.priority'),
            hidden: false,
            optional: false
        },
        // {
        //     key: 'incidentId',
        //     header: t('DigitalOperations.incidentNo'),
        //     hidden: false,
        //     optional: false
        // },
        {
            key: 'alertCount',
            header: t('DigitalOperations.alertCount'),
            hidden: false,
            optional: false
        },
        {
            key: 'updatedOn',
            header: t('DigitalOperations.updatedOn'),
            hidden: false,
            optional: false
        },
        {
            key: 'client',
            header: t('DigitalOperations.client'),
            hidden: true,
            optional: true
        },
        // {
        //     key: 'incidentURL',
        //     header: t('DigitalOperations.incidentUrl'),
        //     hidden: true,
        //     optional: false
        // },
        {
            key: 'sysId',
            header: 'sysId',
            hidden: true,
            optional: false
        },
        {
            key: 'sourceName',
            header: t('DigitalOperations.sourceName'),
            hidden: false,
            optional: false
        },
        {
            key: 'sourceProvider',
            header: t('DigitalOperations.sourceProvider'),
            hidden: true,
            optional: true
        },
        {
            key: 'createdAt',
            header: 'createdAt',
            hidden: true,
            optional: false
        },
        {
            key: 'alertTime',
            header: 'alerTime',
            hidden: true,
            optional: false
        },
        {
            key: 'sort',
            header: "sort",
            hidden: true,
            optional: false
        }
    ]

    //Dashboard summary data is set
    const getSummaryData = (data) => {
        setSummaryLoading(false);
        return [
            {
                id: 'totalAlerts',
                title: t('DigitalOperations.totalAlerts'),
                value: data.totalAlerts
            }
            // {
            //     id: 'ticketedAlerts',
            //     title: t('DigitalOperations.ticketedAlerts'),
            //     value: data.ticketedAlerts
            // }
        ]
    }

    //pit function will be called for pagination to fetch next 100 records
    async function preparePitValue(index, clickType) {
        let pitResponse;
        pitResponse = await getDOPitApi(index);
        console.log(pitResponse, "pit123")
        setPit(pitResponse);
        if (clickType !== 'next') {
            //for storing the value of pit for first time
            pitResponse = await getDOPitApi(index)
            setPit(pitResponse);
            setPitTime({ current: moment().format(), expiry: moment().add(30, 'minutes').format() });
        }
        else {
            if (pitTime.expiry < moment().format()) {
                //for checking if pit should be refreshed
                pitResponse = await getDOPitApi(index)
                setPit(pitResponse);
                setPitTime(values => ({ ...values, current: moment().format(), expiry: moment().add(30, 'minutes').format() }));
            }
            else pitResponse = pit;
        }
        return pitResponse;
    };

    function prepareDataForTableAlerts(requestData, response, clickType) {
        console.log(requestData, "formattedData testing")
        let dataArray = requestData?.alertArray || [];
        let rows = [];
        let records = requestData?.summaryData?.totalAlerts || 0; //total records for table
        rows = [...alertsTableData.tableData, ...dataArray];
        clickType === "next" ? setAlertsTableData({ tableData: rows, loading: false, alertsResponse: response }) : setAlertsTableData({ tableData: dataArray, loading: false, alertsResponse: response });
        clickType === "next" ? setAlertTotalItems(rows.length) : setAlertTotalItems(dataArray.length);
        setTotalItems(records);
        const searchAfter = [...alertsTableData.tableData, ...dataArray].pop();
        if (searchAfter && searchAfter.sort) {
            setsearchAfter(searchAfter.sort);
        } else {
            setsearchAfter("");
        }

    };

    const getInfraAlertsData = async (clickType) => {
        setSummaryLoading(true);
        let response = [];
        // if (sessionStorage.getItem("tenant") !== "SFGarage") {
            let pitResponse = await preparePitValue(index, clickType);
            alertFilterSelections.pit = pitResponse;
            alertFilterSelections.searchAfter = clickType === 'next' ? searchAfter : "";
            response = await getDOInfraAlertsApi(alertFilterSelections);
       // }
       
        let formattedData = prepareInfraAlertsData(response);
        setdownloadItems(formattedData?.alertArray);
        prepareDataForTableAlerts(formattedData, response, clickType);
        setAlertSummary(getSummaryData(formattedData?.summaryData));
    }

    function alertFilterDataReducer(state, action) {
        switch (action?.type) {
            case "add_franchise":
                return { ...state, franchises: action.item };
            case "add_clients":
                return { ...state, clients: action.item };
            default:
                return { ...state };
        }
    }

    const updateState = (state, stateValue, type) => {
        switch (state) {
            case 'alertFilterSelections':
                setAlertFilterSelections(stateValue);
                break;
            case 'disabledSelects':
                setDisabledSelects(stateValue);
                break;
            case 'alertFilterData_clients':
                alertFilterDispatch({ type: "add_clients", item: stateValue });
                break;
            case 'alertFilterdata_franchise':
                alertFilterDispatch({ type: "add_franchise", item: stateValue });
                break;
            default:
                break;
        }
    };

    function updateSearchedRowsFirstIndex(index) {
        index = index || 0;
        setSearchedAlertFirstRowIndex(index);
    }

    function setAlertCurrentPageSizeFunction(pageSize) {
        setAlertCurrentPageSize(pageSize);
    };

    function setAlertFirstRowIndexFunction(index) {
        setAlertFirstRowIndex(index)
    };

    function onSearch(event) {
        let searchString = (event.target.value).toUpperCase().trim();
        let searchResult = [];
        let newList = [];
        let string, pattern;
        let matchedStrings;
        let rows = [];
        setAlertsTableSearchString(searchString);
        rows = alertsTableData.tableData;
        if (searchString) {
            newList = rows.filter(element => {
                for (var property in element) {
                    if (element.hasOwnProperty(property)) {
                        if (element[property] && property !== "id") {
                            string = element[property];
                        }
                        else string = '';
                        string = string ? string.toString() : '';
                        pattern = new RegExp('(\\w*' + searchString + '\\w*)', 'gi');
                        matchedStrings = string.match(pattern);
                        if (matchedStrings && matchedStrings.length) {
                            searchResult = searchResult.concat([element]);
                            return searchResult;
                        }
                    }
                }
            });
        }
        updateSearchedRowsFirstIndex();
        setSearchAlertRows(newList);
    }

    function paginationOnChange(pageDetails) {
        setAlertPage(pageDetails?.page);
        if (pageDetails?.pageSize !== alertCurrentPageSize) {
            setAlertCurrentPageSizeFunction(pageDetails?.pageSize);
        }
        let newFirstRowIndex = pageDetails?.pageSize * (pageDetails?.page - 1);
        setAlertFirstRowIndexFunction(newFirstRowIndex)
    }

    function pageRangeText(current, total) {
        setTotalPages(total);
        setCurrentPage(current);
        return `of ${total} pages`
    }

    function paginationOnSeachChange(pageDetails) {
        if (pageDetails?.pageSize !== alertCurrentPageSize) {
            setAlertCurrentPageSizeFunction(pageDetails?.pageSize);
        }
        let newFirstRowIndex = pageDetails?.pageSize * (pageDetails?.page - 1);
        setAlertSearchPage(pageDetails?.page);
        updateSearchedRowsFirstIndex(newFirstRowIndex)

    }

    function handleNextClick() {
        setNextClicked(true);
        getInfraAlertsData("next");
    }


    let alertRowsToBePaginated = alertsTableSearchString ? searchAlertRows : alertsTableData.tableData;
    let updatedAlertFirstRowIndex = alertsTableSearchString ? searchedAlertFirstRowIndex : alertFirstRowIndex;
    let paginatedAlertRows = alertRowsToBePaginated && alertRowsToBePaginated.slice(updatedAlertFirstRowIndex, updatedAlertFirstRowIndex + alertCurrentPageSize);
    return (
        <div className='alert-summary-container'>
            <div className="dashboard-roleContainer col-lg-12">
                <DashboardSummary loading={summaryLoading} summary={alertSummary} title={t('DigitalOperations.monitoring')} />
            </div>
            <div className='alert-summary-table'>
                {/* <div className="manage-automation-page__r3"> */}
                <div className="bx--col title-filter-container">
                    <p className='padding-top manage-automation-page__subheading carbon-display-Regular-20'>{'Alerts'}</p>
                    <FilterLables updateState={updateState} filterSelections={alertFilterSelections}></FilterLables>
                </div>
                <div className="manage-automation-page-alerts">
                    <div className='tabs-container'>
                        <Tabs>
                            <TabList aria-label="List of tabs">
                                {/* <Tab href='#'>{t('DigitalOperations.alertSummary')}</Tab> */}
                                {/* <Tab href='#'>ABC</Tab> */}
                            </TabList>
                            <TabPanels>
                                <TabPanel>
                                    {alertsTableData?.loading ?
                                        <div>
                                            <DataTableSkeleton
                                                columnCount={alertSummaryHeaders.length}
                                                rowCount={10}
                                                headers={alertSummaryHeaders}
                                            />
                                        </div>
                                        :
                                        <div>
                                            <AlertsTable
                                                loading={alertsTableData?.loading}
                                                fullRows={downloadItems}
                                                rows={paginatedAlertRows}
                                                headers={alertSummaryHeaders}
                                                disabledSelects={disabledSelects}
                                                filterData={filterData}
                                                updateState={updateState}
                                                filterSelections={alertFilterSelections}
                                                isSortingAllowed={true}
                                                showPagination={true}
                                                alertType={alertType}
                                                alertsResponse={alertsTableData?.alertsResponse}
                                                onSearch={onSearch}
                                            />
                                            {alertsTableSearchString ?
                                                <div className='pagination-container'>
                                                    <Pagination
                                                        totalItems={searchAlertRows?.length}
                                                        backwardText={t('DigitalOperations.previousPage')}
                                                        forwardText={t('DigitalOperations.nextPage')}
                                                        pageSize={10}
                                                        pageSizes={[5, 10, 15, 25]}
                                                        page={alertSearchPage}
                                                        itemsPerPageText={t('DigitalOperations.itemsPage')}
                                                        onChange={({ page, pageSize }) => paginationOnSeachChange({ page, pageSize })}
                                                    />
                                                </div>
                                                : <div className='pagination-container'>
                                                    <Pagination
                                                        totalItems={alertsTableData?.tableData?.length}
                                                        backwardText={t('DigitalOperations.previousPage')}
                                                        forwardText={t('DigitalOperations.nextPage')}
                                                        pageSize={10}
                                                        pageSizes={[5, 10, 15, 25]}
                                                        page={alertPage}
                                                        itemsPerPageText={t('DigitalOperations.itemsPage')}
                                                        onChange={({ page, pageSize }) => paginationOnChange({ page, pageSize })}
                                                        pageRangeText={(current, total) => { pageRangeText(current, total) }}
                                                        itemRangeText={(min, max, total) => `${min}–${max} of ${total} items`}
                                                    />
                                                    {alertTotalItems < TotalItems && TotalPages === currentPage && handleNextClick ? <div className='fastforward'><Button renderIcon={SkipForwardFilled} hasIconOnly onClick={() => handleNextClick()} kind="ghost" iconDescription="Load more records" tooltipAlignment="start"
                                                    /></div> : null}
                                                </div>}
                                        </div>
                                    }
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    )
}