import moment from 'moment';

import { sortFormattedDate } from '../Utils/ReusableFunctions';

export const preparePerformanceTrendData = (response, headers) => {
    let graphData = [];
    let tableData = [];
    let tableObj = {};
    let graphObj = {};
    let headersObj = [];
    let headerResult = [];

    if (response.body) {
        let buckets = response.body.aggregations.incident_trend.buckets;
        for (let i = 0; i < buckets.length; i++) {
            tableObj = {
                id: buckets[i].key,
                week: moment(buckets[i].key_as_string).format('DD-MMM-YY'),
                total: buckets[i].incident_count.value
            };
            for (let j = 0; j < buckets[i].incident_status.buckets.length; j++) {
                graphObj = {
                    "group": buckets[i].incident_status.buckets[j].key,
                    "key": moment(buckets[i].key_as_string).format('DD-MMM-YY'),
                    "value": buckets[i].incident_status.buckets[j].incident_count.value,
                };
                headersObj.push({ key: buckets[i].incident_status.buckets[j].key, header: buckets[i].incident_status.buckets[j].key });
                tableObj[buckets[i].incident_status.buckets[j].key] = buckets[i].incident_status.buckets[j].incident_count.value;
                tableObj[buckets[i].incident_status.buckets[j].key + 'Table'] = buckets[i].incident_status.buckets[j];
                graphData.push(graphObj);
            }
            tableData.push(tableObj);
            headerResult = headersObj.filter((v, i, a) => a.findIndex(t => (t.key === v.key)) === i);
        }
    }
    graphData = sortFormattedDate(graphData, 'key'); //sorting based on date
    graphData.reverse();
    tableData = sortFormattedDate(tableData, 'week'); //sorting based on date
    headers = headers.concat(headerResult);

    return { tableData, graphData, headers };
};

export const prepareMonthvsDayData = (response) => {
    let graphData = [];
    let graphObj = {};
    if (response.body) {
        let buckets = response?.body?.aggregations?.incident_trend?.buckets || [];
        for (let i = 0; i < buckets.length; i++) {
            graphObj = {
                group: moment(buckets[i]?.key_as_string).format('MMM YYYY').toString(),
                day: moment(buckets[i]?.key_as_string).format('D').toString(),
                value: buckets[i]?.incident_count?.value || 0
            };
            graphData.push(graphObj);
        }

        graphData.sort((a, b) => {
            //sort by month and year
            const dateA = moment(`${a.group} 1`, 'MMM YYYY D');
            const dateB = moment(`${b.group} 1`, 'MMM YYYY D');
            return dateA - dateB;
        });
        return { graphData };
    };
}

export const prepareWeekdayVsOpenHoursData = (response) => {
    let graphData = [];
    let graphObj = {};
    const daysOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    if (response.body) {
        let buckets = response?.body?.aggregations?.incident_trend?.buckets || [];
        for (let i = 0; i < buckets.length; i++) {
            graphObj = {
                group: moment(buckets[i]?.key_as_string).format('dddd').toString(),
                day: moment(buckets[i]?.key_as_string).hour().toString(),
                value: buckets[i]?.incident_count?.value || 0
            };
            graphData.push(graphObj);
        }
    }

    //add all the values for same day and hour
    graphData = Object.values(graphData.reduce((acc, { group, value, day }) => (acc[day + group] = acc[day + group] || { group, value: 0, day }, acc[day + group].value += value, acc), {}));
    // Sort the result array based on the custom order
    graphData.sort((a, b) => daysOrder.indexOf(b.group) - daysOrder.indexOf(a.group));

    return { graphData };
}

export const prepareAgeingGraphData = (resp) => {
    let graphObj = {}, graphArr = [];
    if (resp.length) {
        for (let i = 0; i < resp.length; i++) {
            graphObj = {
                group: 'Aging',
                date: resp[i].group,
                value: resp[i].value
            }
            graphArr.push(graphObj);
        }
    }
    return graphArr;
}

export const prepareTableDataFromGraph = (data) => {
    let tableObj = {};
    let tableData = [];
    if (data.length) {
        for (let i = 0; i < data.length; i++) {
            tableObj = {
                id: i + 1,
                item: data[i].group,
                count: data[i].value
            }
            tableData.push(tableObj);
        }
    }
    return tableData;
}

export const prepareIncidentList = (response, key) => {
    let tableData = []; let tableObj = {};
    let status, priority, category, assignmentGroup, clientBucket, client, sysId, url;
    let partyName = response.party_name.buckets;
    for (let i = 0; i < partyName.length; i++) {
        status = response.key;
        let incidentId = partyName[i].incident_id.buckets;
        for (let j = 0; j < incidentId.length; j++) {
            tableObj = {};
            priority = incidentId[j].incident_priority.buckets.length > 0 ? incidentId[j].incident_priority.buckets[0].key : '';
            category = incidentId[j].category.buckets.length > 0 ? incidentId[j].category.buckets[0].key : '';
            assignmentGroup = incidentId[j].assignment_grp.buckets.length > 0 ? incidentId[j].assignment_grp.buckets[0].key : '';
            clientBucket = response.party_name;
            client = clientBucket.buckets && clientBucket.buckets.length > 0 ? clientBucket.buckets[0].key : '';
            url = incidentId[j].incident_url.buckets && incidentId[j].incident_url.buckets.length > 0 ? incidentId[j].incident_url.buckets[0].key : '';
            sysId = url.split('=').pop();
            tableObj = {
                'id': incidentId[j].key || (i + j + 1).toString(),
                'key': incidentId[j].key,
                'status': status,
                'priority': priority,
                'category': category,
                'assignmentGroup': assignmentGroup,
                'client': client,
                'sysId': sysId,
                'url': url
            }
            tableData.push(tableObj);
        }
    }
    return tableData;
};

export const generateMonthsArray = (data, numMonthsNeeded) => {
    if (data.length > 0) {
        const uniqueMonths = [...new Set(data.map(item => item.group))];
        const sortedMonths = uniqueMonths.sort((a, b) => moment(b, 'MMM YYYY').diff(moment(a, 'MMM YYYY')));

        const additionalMonths = Array.from({ length: Math.max(0, numMonthsNeeded - sortedMonths.length) }, (_, i) =>
            moment(sortedMonths[sortedMonths.length - 1], 'MMM YYYY').subtract(i + 1, 'months').format('MMM YYYY')
        );
        const monthsArray = sortedMonths.concat(additionalMonths);
        return monthsArray.reverse();
    } else return [];
};

export const getDownloadableData = (arr) => {
    let updatedArray = arr.map((data) => {
        return {
            'item': data.item,
            'count': data.count,
            'percentage': data.percentage
        }
    });
    return updatedArray;
};