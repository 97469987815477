import moment from 'moment';

export const uniqueArrayObjects = (array) => {
    //array is array of object;
    return Array.from(new Set(array.map(JSON.stringify)), JSON.parse);
};

export const sortFormattedDate = (data, keyValue) => {
    //data is an array of objects
    //keyValue is the key in the object having the formatted date
    return data.sort((a, b) => {
        // Check if the date format contains month abbreviations (e.g., Jan, Feb, Mar)
        const hasMonthAbbreviationA = /(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)/.test(a[keyValue]);
        let dateA, dateB;
        if (a[keyValue].includes('-') && !hasMonthAbbreviationA) {
            // Assuming format is dd-mm-yy
            dateA = new Date(a[keyValue].split("-").reverse().join("-"));
            dateB = new Date(b[keyValue].split("-").reverse().join("-"));
        }
        else {
            dateA = new Date(a[keyValue]);
            dateB = new Date(b[keyValue]);
        }
        return dateA - dateB;
    });
}

export const convertUTCToLocalTime = (date, format) => {
    let formatValue = format?.toString() || '';
    if (date) {
        let dateValue = moment.utc(date).toDate();
        let result = moment(dateValue).locale("en").format(formatValue);
        return result;
    }
    else {
        return "";
    }
};

export function getSummaryColor(color) {
    switch (color) {
        case 'red': return "#DA1E28";
        case 'yellow': return "#DDAC00";
        case 'green': return "#198038";
        default: return "black";
    }
}
export const addCustomSubtitleforDonutChart = (id, label, total, fontSize) => {
    let elementID = id || "";
    let totalValue = total || 0;
    let labelValue = label || "Total";
    let subtitleText = `${labelValue} : ${totalValue}`;
    let fontSizeValue = fontSize || "14px !important";
    let lineHeight = '12px'; // Adjust line height for subtitle as needed
    if (document.getElementById(elementID)) {
        let element = document.getElementById(elementID);
        if (element && element.getElementsByClassName('cds--cc--donut')) {
            if (element.getElementsByClassName('center')) {
                let donutFigure = element.getElementsByClassName('center')[0];
                donutFigure?.getElementsByClassName('donut-title')?.[0]?.setAttribute('dy', '8px');
                if (donutFigure?.getElementsByClassName('donut-subtitle').length === 0) {
                    let donutSubtitle = document.createElementNS("http://www.w3.org/2000/svg", "text");
                    donutSubtitle.classList += 'donut-subtitle';
                    donutSubtitle.innerHTML = subtitleText;
                    donutFigure.appendChild(donutSubtitle);
                    donutSubtitle.setAttribute("text-anchor", "middle");
                    donutSubtitle.setAttribute("x", "0");
                    donutSubtitle.setAttribute("y", "45");
                    donutSubtitle.setAttribute("font-size", fontSizeValue);
                    donutSubtitle.setAttribute('dy', lineHeight);
                } else {
                    if (donutFigure) {
                        donutFigure.getElementsByClassName('donut-subtitle')[0].innerHTML = subtitleText;
                        donutFigure.getElementsByClassName('donut-subtitle')[0].style.fontSize = fontSizeValue;
                        donutFigure.getElementsByClassName('donut-subtitle')[0].setAttribute('dy', lineHeight);
                    }
                }
            }
        }
    }
}

export const normalizeRows = (rows, headers) => {
    return rows.map(row => {
        const normalizedRow = { ...row };
        headers.forEach(header => {
            if (normalizedRow[header.key] === undefined) {
                normalizedRow[header.key] = 0;
            }
        });
        return normalizedRow;
    });
};

export const sortRowsBasedOnDirectionAndColumn = (rows, direction, column, headers) => {
    //if a column in object is missing then it will normalise it and add it as a null value
    //you can skip passing headers if you don't have dynamic data
    let normalizedRows = headers && headers.length > 0 ? normalizeRows(rows, headers) : rows;
    if (direction === "NONE") {
        return normalizedRows; // return original array if direction is 'NONE'
    }
    else return normalizedRows.sort((a, b) => {
        let aValue = a[column];
        let bValue = b[column];

        // Remove % symbol and convert to number
        if (typeof aValue === 'string' && aValue.endsWith('%')) {
            aValue = aValue.replace('%', '');
        }
        if (typeof bValue === 'string' && bValue.endsWith('%')) {
            bValue = bValue.replace('%', '');
        }
        // Extract numeric value only if string contains all numeric characters
        const extractNumber = (value) => {
            if (typeof value === 'string' && /^\d+(\.\d+)?$/.test(value)) {
                return parseFloat(value);
            }
            return value;
        };

        aValue = extractNumber(aValue);
        bValue = extractNumber(bValue);

        if (direction === "ASC") {
            //return rows in ascending order
            if (aValue < bValue) return -1;
            if (aValue > bValue) return 1;
            return 0;
        } else {
            //return rows in descending order
            if (aValue < bValue) return 1;
            if (aValue > bValue) return -1;
            return 0;
        }
    });
}

export const getDirectionColumnForCarbonTable = (sortDirection, currentColumn, prevColumn) => {
    let direction;
    let column;

    if (prevColumn !== currentColumn) {
        //check if clicked column is not equal to the previously clicked column
        direction = "ASC"; // New column click should start with ascending order
    } else {
        // Carbon is sending wrong direction values when when the icon is stating differently (known issue). To fix this, we need to adjust the direction logic:
        // 1 - When the initial click is "NONE", we should treat it as "ASC" (i.e., start with ascending order)
        // 2 - When the current direction is "ASC", we should toggle to "DESC" (i.e., switch to descending order)
        // 3 - When the current direction is "DESC", we should toggle to "NONE" (i.e., reset to no sorting)
        direction = sortDirection === "NONE" ? "ASC" : sortDirection === "ASC" ? "DESC" : "NONE";
    }

    if (direction === "NONE") {
        column = "";
    }
    else {
        column = currentColumn;
    }

    return { direction, column }
};

function compare(a, b, locale) {
    if (a === false) {
        a = null;
    }
    if (b === false) {
        b = null;
    }
    if (a === b) {
        return 0;
    }
    // nulls sort after anything else
    else if (a === null) {
        return 1;
    }
    else if (b === null) {
        return -1;
    }
    // otherwise, if we're ascending, lowest sorts first
    else if (locale === "asc") {
        return a < b ? -1 : 1;
    }
    // if descending, highest sorts first
    else {
        return a < b ? 1 : -1;
    }

};

export const defaultSortRow = (cellA, cellB, sortDirection, sortStates) => {
    if (sortDirection.sortDirection === sortDirection.sortStates.DESC) {
        return compare(cellA, cellB, "desc");
    }

    return compare(cellA, cellB, "asc");

}

