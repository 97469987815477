import React from 'react'
import { LineChart } from "@carbon/charts-react";
import { Information } from "@carbon/react/icons/index";

import TableToolbarDownload from './TableToolbarDownload';
import { Tooltip, InlineLoading } from '@carbon/react';
import '../scss/_dashboard.scss';

const DashboardLineChartWithCalendarInterval = (props) => {
    const defaultLineGraphOptions = {
        "title": props?.modalTitle || "",
        "axes": {
            "bottom": {
                "title": props.graphXAxisTitle || "Time",
                "mapsTo": props.Key ? "key" : "date",
                "scaleType": props.bottomScaleType || "labels"
            },
            "left": {
                "mapsTo": "value",
                "title": props.graphTitle,
                "scaleType": "linear",
                "percentage": props.isPercentage,
                "ticks": {
                    formatter: function (num) { if (parseInt(num) === num) return num; }
                }
            }
        },
        "curve": "curveMonotoneX",
        "legend": {
            "position": props.legendPosition || "bottom",
            "orientation": "horizontal",
            "alignment": props.legendAlignment || "left",
            "truncation": {
                "type": props.legendTruncation || "end_line" // "end_line", "front_line", "middle_line, "none"
            }
        },
        "data": {
            "loading": props.loading
        },
        "height": props.height ? props.height : "400px",
        "tooltip": {
            "showTotal": props.showTotal === false ? false : true
        },
        "color": {
            "scale": props.colors || null
        },
        "toolbar": { "enabled": props.toolbarEnabled === false ? false : true }
    };

    const lineGraphHeaders = [{ key: 'group', header: "Group" }, { key: props.Key ? 'key' : 'date', header: props.Key ? props.Key : "Date" },
    { key: 'value', header: "Value" }];

    const dataList = [
        {
            id: "weekly",
            label: "Weekly"
        },
        {
            id: "monthly",
            label: "Monthly"
        }
    ]

    // const changeDataViewValue = (e) => {
    //     props.handleRefresh(e.selectedItem, props.title);
    // }

    // let startTime, endTime, duration;
    // if (props.timeFilterSelections.startDateTime && props.timeFilterSelections.endDateTime) {
    //     startTime = moment(props.timeFilterSelections.startDateTime);//now
    //     endTime = moment(props.timeFilterSelections.endDateTime);
    //     duration = endTime.diff(startTime, 'hours');
    // }

    const getTitleWithToolTip = () => {
        return (<div>
            {props.title ?
                <div style={{ display: "flex" }}>
                    <div className="graphTitle">{props.title}
                        {props.graphDescription && <span>
                            <Tooltip
                                align="right"
                                label={props.graphDescription}
                                style={{ color: "black", fontSize: "14px" }}
                            >
                                <button
                                    className="tooltip-button"
                                    type="button"
                                >
                                    <Information />
                                </button>
                            </Tooltip>
                        </span>}
                    </div>
                </div> : null}
        </div>)
    }
    return (
        <div className='trend-graph'>
            {!defaultLineGraphOptions.data.loading && !props.lineGraphData?.length ?
                <>
                    <div className="graph-title carbon-display-Regular-20">{getTitleWithToolTip()}</div>
                    <div>No Data Available</div>
                </>
                :
                defaultLineGraphOptions.data.loading ?
                    <>
                        <div className="graph-title carbon-display-Regular-20">{getTitleWithToolTip()}</div>
                        <div><InlineLoading description="Loading" /></div>
                    </>
                    :
                    <div className="dashboard-line-graph">
                        <div className="graph-title carbon-display-Regular-20">
                            {getTitleWithToolTip()}
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {/* {props.hideWeeklyMonthlyView !== true ? <div className='data-range' style={{ paddingRight: '20px' }}>
                                        <Dropdown
                                            id="data-select"
                                            size="sm"
                                            label="Select"
                                            items={dataList}
                                            itemToString={(item) => (item ? item.label : '')}
                                            selectedItem={props.selectedValue && Object.keys(props.selectedValue).length > 0 ? props.selectedValue : ''}
                                            onChange={(e) => changeDataViewValue(e)}
                                            disabled={duration <= (durationConst.lessThanWeek) ? true : false}
                                        />
                                    </div> : null} */}
                                {props.hideDownloadButton !== true ? <div className="download-btn">
                                    <TableToolbarDownload iconDescription="Export" rows={props.lineGraphData} headers={lineGraphHeaders} title={props.title} filename={props.downloadFileName || "Export"} label="Download" />
                                </div> : null}
                            </div>
                        </div>
                        <LineChart
                            data={props.lineGraphData}
                            options={defaultLineGraphOptions}>
                        </LineChart>
                    </div>
            }
        </div>
    );
};

export default DashboardLineChartWithCalendarInterval;
