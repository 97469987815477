import { Breadcrumb, BreadcrumbItem, Column, SkeletonPlaceholder, ErrorBoundary, FlexGrid, Row, Tile } from "@carbon/react";
import ChartContainer from "Carbon-Charts/ChartContainer";
import CarbonTabs from "Carbon-Components/Tabs";
import DeploymentInsights from "Components/ICPSA/DeploymentInsights/DeploymentInsights";
import GitHub from "Components/ICPSA/GitHub/GitHub";
import PipelineUsage from "Components/ICPSA/PipelineUsage/PipelineUsage";
import ReleaseInsights from "Components/ICTE/IntegratedOperations/ReleaseInsights";
import React, { useEffect, useState } from "react";
import GetPermissions from "Roles-n-Permissions/PermissionScopeNew";
import PipelineActivity from "../PipelineActivity/PipelineActivity";
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "@tanstack/react-query";
import {
  getAnyCollection
} from "Services/ServerApi";
import "../scss/ci_cd.scss";
import chartDataJSON from "./chartData.json";

const Overview = (props) => {
  //const [tabSelect, setTabSelection] = useState(sessionStorage.getItem("selectedTabALM") !== null ? parseInt(sessionStorage.getItem("selectedTabALM")) : 0)
  const [tabSelect, setTabSelection] = useState(0)
  const { keycloak } = useKeycloak();
  const [selectedApp, setSelectedApp] = useState("");
  const [tableData, setTableData] = useState([]);
  const [applicationName, setApplicationName] = useState("All");

  const {
    isLoading: loading,
    data: product,
    isFetching,
    isError,
    refetch,
  } = useQuery(
    ["product_engineer"],
    () => getAnyCollection(keycloak, "product_engineer"),
    {
      retry: 1
    }
  );

  useEffect(() => {
    if(product !== undefined) {
      const response = product.data.product_engineer[0].jobs;
      const dataRes = response.map((res, index) => {
        return {
          ...res,
          id: (index + 1).toString()
        }
      })
      console.log("dataRes", dataRes)
      setTableData(dataRes)
    }
  }, [product])

  useEffect(() => {
    if (props.location) {
      setSelectedApp(props.location.state)
    }
  }, [])

  const getChartData = () => {
    let filteredData = chartDataJSON.chartData.filter(
      (fil) => fil.applicationName === applicationName
    );
    let success = 0, failed = 0, failedDur = 0, successDur = 0, deploySuccess = 0, deployFailure = 0;
    tableData.forEach((fil) => {
        if(fil.last_successful_build !== null){
          success = success + 1;
        }
        if(fil.last_failed_build !== null){
          failed = failed + 1;
        }
        if(fil.failed_build !== undefined && fil.failed_build.duration > fil.failed_build.estimatedDuration) {
          failedDur = failedDur + 1;
        }
        if(fil.success_build !== undefined && fil.success_build.duration > fil.success_build.estimatedDuration) {
          successDur = successDur + 1;
        }
        Array.isArray(fil.stages) && fil.stages.forEach((sta) => {
          if(sta.name === "Openshift Deploy" && sta.status === "SUCCESS") {
            deploySuccess = deploySuccess + 1;
          } else if(sta.name === "Openshift Deploy" && sta.status === "FAILURE") {
            deployFailure = deployFailure + 1;
          }
        })
    })
    const finRes = filteredData[0].data.map((dat) => {
      if(dat.title === "Build"){
        dat.values[0].value = success;
        dat.values[1].value = failed;
      }
      if(dat.title === "Long Lasting tasks"){
        dat.values[0].value = successDur;
        dat.values[1].value = failedDur;
      }
      if(dat.title === "Deployment"){
        dat.values[0].value = deploySuccess;
        dat.values[1].value = deployFailure;
      }
      return dat
    })
    if (finRes.length > 0) {
      return finRes.map((chartRes, index) => {
        console.log("gokul", chartRes)
        let data = {
          dataType: "donut",
          data: {
            chartData: chartRes.values,
            chartOptions: {
              title: chartRes.title,
              resizable: true,
              donut: {
                alignment: "center",
                center: {
                  label: "",
                },
              },
              color: {
                scale: {
                  Success: "#24a148",
                  Failure: "#fa4d56",
                },
              },
              toolbar: {
                enabled: false,
              },
              height: "250px",
            },
          },
        };

        return (
          <Column lg={index === 2 ? 6 : 5} md={3} className="padding-lft">
            <ChartContainer data={data} />
          </Column>
        );
      });
    }
  };

  const formatTime = (ms) => {
    let totalSeconds = Math.floor(ms / 1000);
    let hours = Math.floor(totalSeconds / 3600);
    let minutes = Math.floor((totalSeconds % 3600) / 60);
    let seconds = totalSeconds % 60;
    
    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const countOccurrences = (arr) => {
    const countMap = {};

    arr.forEach(value => {
        countMap[value] = (countMap[value] || 0) + 1;
    });

    return countMap;
  };

  const getTileInfo = () => {
    let filteredData = chartDataJSON.chartData.filter(
      (fil) => fil.applicationName === applicationName
    );
    let duration = [], timeStmp = [];
    tableData.forEach((fil) => {
      if(fil.failed_build !== undefined && fil.failed_build.duration > fil.failed_build.estimatedDuration) {
        duration.push(fil.failed_build.duration);
        timeStmp.push(fil.failed_build.timestamp)
      }
      if(fil.success_build !== undefined && fil.success_build.duration > fil.success_build.estimatedDuration) {
        duration.push(fil.success_build.duration);
        timeStmp.push(fil.success_build.timestamp)
      }
  })

  const timestampCounts = countOccurrences(timeStmp);
  const counts = Object.values(timestampCounts);
  const totalOccurrences = counts.reduce((sum, count) => sum + count, 0);
  const uniqueTimestamps = counts.length;
  const averageCount = totalOccurrences / uniqueTimestamps;

  const averageMs = duration.reduce((sum, val) => sum + val, 0) / duration.length;
  console.log("averageMs ======", averageMs, duration)
    if (filteredData.length > 0) {
      return (
        <>
          <Tile className="tiles3">
            <ErrorBoundary fallback={"Data is unavailable"}>
              <h5 className="text-title report-title">{filteredData[0].tileData[0].title}</h5>
              <h3 className="text-description">{Math.floor((averageMs % 3600) / 60)} <span>Minutes</span></h3>
            </ErrorBoundary>
          </Tile>
          {/* <Tile className="tiles3 vulner">
            <ErrorBoundary fallback={"Data is unavailable"}>
              <h5 className="text-title report-title">{filteredData[0].tileData[1].title}</h5>
              <h3 className="text-description">{filteredData[0].tileData[1].value}</h3>
            </ErrorBoundary>
          </Tile> */}
          <Tile className="tiles3 last-child">
            <ErrorBoundary fallback={"Data is unavailable"}>
              <h5 className="text-title report-title">{filteredData[0].tileData[2].title}</h5>
              <h3 className="text-description">{averageCount}</h3>
            </ErrorBoundary>
          </Tile>
        </>
      );
    }
  };

  const onApplicationNameChange = (e) => {
    if (e["selectedItem"] !== null) {
      setApplicationName(e.selectedItem);
    } else {
      setApplicationName("All");
    }
  };


  const hubs = sessionStorage.getItem("HubFlow");

  const getTabList = () => {
    let tabs = [];
    if (GetPermissions("version_control::read")) {
      tabs.push("Version Control");
    }
    if (GetPermissions("pipeline_usage::read")) {
      tabs.push("Pipeline Usage");
    }
    if (GetPermissions("deployment_insights::read")) {
      tabs.push("Deployment Insights");
    }
    if (GetPermissions("release_insights::read")) {
      tabs.push("Release Insights");
    }
    return tabs;
    // return [
    //   "Version Control",
    //   "Pipeline Usage",
    //   "Deployment Insights",
    //   "Release Insights"
    // ];
  }

  const getTabPanelsList = () => {
    let panelList = [];

    if (GetPermissions("version_control::read")) {
      panelList.push(<GitHub />);
    }
    if (GetPermissions("pipeline_usage::read")) {
      panelList.push(<PipelineUsage />);
    }
    if (GetPermissions("deployment_insights::read")) {
      panelList.push(<DeploymentInsights />);
    }
    if (GetPermissions("release_insights::read")) {
      panelList.push(<ReleaseInsights />);
    }
    return panelList;
    // return [
    //   <GitHub />,
    //   <PipelineUsage />,
    //   <DeploymentInsights />,
    //   <ReleaseInsights />
    // ];
  }

  const tabSelection = (e) => {
    sessionStorage.setItem("selectedTabALM", e)
    setTabSelection(e);
  }

  const getDisabledList = () => {
    let disabledList = [];
    // disabledList.push("Version Control");
    if (!GetPermissions("version_control::read")) {
      disabledList.push("Version Control");
    }
    if (!GetPermissions("pipeline_usage::read")) {
      disabledList.push("Pipeline Usage");
    }
    if (!GetPermissions("deployment_insights::read")) {
      disabledList.push("Deployment Insights");
    }
    if (!GetPermissions("release_insights::read")) {
      disabledList.push("Release Insights");
    }
    console.log("disabledList:", disabledList)
    return disabledList;
  }

  return (
    hubs === "insights" ?
      <FlexGrid className="integratedOps">
        <Row>
          <Column lg={12} md={8} sm={4}>
            <Breadcrumb noTrailingSlash>
              <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
              {hubs === "insights" ? <BreadcrumbItem href="#/itGovernance">IT Governance and Reporting</BreadcrumbItem> : null}
              <BreadcrumbItem isCurrentPage>Product Engineering</BreadcrumbItem>
            </Breadcrumb>
          </Column>
        </Row>
        <Row>
          <Column lg={16} md={8} sm={4}>
            <CarbonTabs
              tabIndex={tabSelect}
              selectionMode="automatic"
              type="default"
              Tablist={getTabList()}
              tabSelection={true}
              tabSelected={tabSelection}
              disabledList={getDisabledList()}
              TabPanelsList={getTabPanelsList()}
            />
          </Column>
        </Row>
      </FlexGrid>
      :
      <FlexGrid className="cicd" fullWidth={true}>
        <Row>
          <Column lg={12} md={8} sm={4}>
            <Breadcrumb noTrailingSlash>
              <BreadcrumbItem href="#/home">Home</BreadcrumbItem>
              {hubs === "insights" ? <BreadcrumbItem href="#/itGovernance">IT Governance and Reporting</BreadcrumbItem> : null}
              <BreadcrumbItem isCurrentPage>Product Engineering</BreadcrumbItem>
            </Breadcrumb>
          </Column>
        </Row>
        <>
          <Row>
            {/* <Column lg={4} md={2} sm={2}>
              <ComboBox
                ariaLabel="ComboBox"
                id="ComboBox1"
                items={["All", "Service", "UI"]}
                itemToString={(item) => (item ? item : "")}
                placeholder="All"
                titleText="Pipelines"
                size="sm"
                onChange={(e) => onApplicationNameChange(e)}
              />
            </Column> */}
            <Column lg={4} md={2} sm={2}>
              <h4 className="pro-heading">Project: <span>Liberty-Pipeline</span></h4>
            </Column>
            <Column lg={8} md={4} sm={4}></Column>
            <Column lg={4} md={2} sm={2}>
              <h5 className="h5-title">Date: Last 30 Days</h5>
            </Column>
          </Row>
          <Row>
            <Column lg={4} md={4} sm={2}>
              {getTileInfo()}
              {/* <Tile className="top5">
                <h5>
                  Top 5 Build Failure Category
                </h5>
                <ul>
                  <li>Code Issue</li>
                  <li>Infrastructure</li>
                  <li>Configuration</li>
                  <li>Quality Gate Failure</li>
                </ul>
              </Tile> */}
            </Column>
            <Column lg={12} md={8} sm={2}>
              <Row>
                {tableData.length > 0 ? getChartData() : <Column lg={5} md={3} className="padding-lft">
                  <SkeletonPlaceholder />
                </Column>} 
              </Row>
            </Column>
          </Row>
          <Row className="pipeline-activity--row">
          {tableData.length > 0 ? <PipelineActivity tableData={tableData} /> : null}
          </Row>
        </>
      </FlexGrid>
  )
};

export default Overview;