import { Column, ComboBox, Row, Toggletip, ToggletipButton, ToggletipContent } from '@carbon/react';
import { Information } from "@carbon/react/icons";
import ChartContainer from 'Carbon-Charts/ChartContainer';
import { HP_440_G7_CE, accumulatedLegend, monthlyLegend, oneTimeLegend } from 'Constant';
import React, { useState } from 'react';
import ActualAccumulated from './ActualAccumulated';
import AppInferences from './AppInfrences';
import Applications from './Applications';
import { monthNames } from './RowsHeaders';
import YearWiseChart from './YearWiseChart';
import apps from "./newData.json";

const applications = Array.from(new Set(apps.map(app => app["App Name"]).sort()))
const years = Array.from(new Set(apps.map(app => app["Year"]).sort((a,b) => b - a).map(String)))
const categories = Array.from(new Set(apps.map(app => app["CI Category"]).filter(Boolean)))
const towers = Array.from(new Set(apps.map(app => app["Tower"]).filter(Boolean)))
const beniftCats = Array.from(new Set(apps.map(app => app["Benefit Category"]).filter(Boolean)))

const EmissionChart = () => {
    const [application, setApplication] = useState("")
    const [category, setCategory] = useState("");
    const [benifit, setBenifit] = useState("");
    const [year, setyear] = useState(years[0]);
    const [tower, setTower] = useState("");

    const transformData1 = (data) => {
        let inputData = [...data]
        if(application){
            inputData = inputData.filter(obj => obj["App Name"] === application)
        }
        if(benifit){
            inputData = inputData.filter(obj => obj["Benefit Category"] === benifit)
        }
        if(category){
            inputData = inputData.filter(obj => obj["CI Category"] === category)
        }
        if(tower){
            inputData = inputData.filter(obj => obj["Tower"] === tower)
        }

        // Create an object to store the transformed data
        const transformedData = {}; // To store hours monthly app wise -chart1
        const savings = {} // to store carbon saving month wise - line on 1 and 2 charts
        const actualAccumulatedHrs = {} // To store hours for that month and accumulated - 2nd chart
        const targetDate = new Date('2023-12-31');

        const monthDate = new Date(); 

        function parseCompletionDate(dateString){
            return new Date(dateString)
        }

        for (const item of inputData) {
            const appName = item["App Name"];
            const completionDate = parseCompletionDate(item["Completion (MMM-YY)"]);
            const annualBenefit = item["Monthly OT Effort (Hrs.)"];
            const remainingMonths = (targetDate.getFullYear() - completionDate.getFullYear()) * 12 + (12 - completionDate.getMonth());
            const currentMonthBenefit = annualBenefit / 2;

            monthDate.setFullYear(completionDate.getFullYear(), completionDate.getMonth(), 1);
            const completionMonthDate = monthDate

            if (item["Benefit Category"] === "OT-ES" || remainingMonths === 1) {
                const key = `${appName}-${completionDate.getMonth() + 1}-${completionDate.getFullYear()}`;
                
                if (!transformedData[key]) {
                    transformedData[key] = {
                        group: appName,
                        date: completionMonthDate,
                        value: 0,
                    };
                }

                transformedData[key].value += annualBenefit;
            } else if (remainingMonths > 1) {
                const monthlyBenefit = annualBenefit;

                for (let j = completionDate.getFullYear(); j <= targetDate.getFullYear(); j++) {
                    for (let i = j === completionDate.getFullYear() ? completionDate.getMonth() : 0; i < 12; i++) {
                        const key = `${appName}-${i + 1}-${j}`;

                        if (!transformedData[key]) {
                            monthDate.setFullYear(j, i, 1);
                            transformedData[key] = {
                                group: appName,
                                date: monthDate,
                                value: 0,
                            };
                        }

                        const isCurrentMonth = i === completionDate.getMonth() && j === completionDate.getFullYear();
                        const benefit = isCurrentMonth ? currentMonthBenefit : monthlyBenefit;

                        transformedData[key].value += benefit;
                    }
                }
            }
        }

          
        const resultArray = Object.values(transformedData);

        // calculate emission
        const savingsArray = Object.values(savings)

        // calculate accumulated monthly
        const accMonthlyArray = Object.values(actualAccumulatedHrs) 

        let appWiseMonthly = [...resultArray, ...savingsArray]
        let acutualAccMonthly = [...savingsArray, ...accMonthlyArray]

        if(year){
            appWiseMonthly = appWiseMonthly.filter(obj => obj.date.getFullYear() === Number(year))
            acutualAccMonthly = acutualAccMonthly.filter(obj => new Date(obj.date).getFullYear() === Number(year))
        }
    
        return {
          appWiseMonthly,
          acutualAccMonthly
        };
    };
  
  const data = transformData1(JSON.parse(JSON.stringify(apps)))

  const tickCount = data["appWiseMonthly"].filter(data => data.group === "Carbon Savings(kgCO₂eq)").length

  const options = {
    title: 'MoM Effort Saving and Reduction in CO₂',
    color:{
        scale: {
            'Carbon Savings(kgCO₂eq)' : "#198038"
        }
    },
    timeScale: {
        addSpaceOnEdges: year ? 1 : 0
    },
    axes: {
      bottom: {
        title: 'Month',
        mapsTo: 'date',
        scaleType: 'time',
        ticks: (year) ? {
            formatter: ((tick) => `${monthNames[tick.getMonth()]} ${tick.getFullYear()}`),
            number: tickCount,
          } : {},
      },
      left: {
        mapsTo: 'value',
        title: 'Monthly Benefit (Hrs)',
        scaleType: 'linear',
        stacked: true
      },
      right: {
        mapsTo: 'carbonsaving',
        scaleType: 'linear',
        title: 'Reduction in Carbon Emission (kgCO₂eq)',
        correspondingDatasets: [
          'Carbon Savings(kgCO₂eq)'
        ]
      },
    },
    comboChartTypes: [
        {
          type: 'line',
          correspondingDatasets: ["Carbon Savings(kgCO₂eq)"]
        },
        {
          type: 'stacked-bar',
          correspondingDatasets: applications
        }
      ],
    height: '350px',
    legend: {
        truncation: {
          type: "none",
        },
        order: applications
    },
    curve: 'curveMonotoneX'
}

  return (
    <>
        <Row style={{justifyContent: "flex-end"}}>
            <Column lg={3} md={2} sm={2}>
                <ComboBox
                    onChange={(e) => setApplication(e.selectedItem)}
                    id="carbon-combobox"
                    items={applications}
                    selectedItem={application}
                    itemToString={(item) => (item ? item : "")}
                    placeholder="Select Application"
                    className="select-app"
                    size="sm"
                    />
            </Column>
            <Column lg={4} md={2} sm={2}>
                <ComboBox
                    onChange={(e) => setBenifit(e.selectedItem)}
                    id="carbon-combobox"
                    items={beniftCats}
                    selectedItem={benifit}
                    itemToString={(item) => (item ? item : "")}
                    placeholder="Select Benefit Category"
                    className="select-app"
                    size="sm"
                    />
            </Column>
            <Column lg={3} md={2} sm={2}>
                <ComboBox
                    onChange={(e) => setCategory(e.selectedItem)}
                    id="carbon-combobox"
                    items={categories}
                    selectedItem={category}
                    itemToString={(item) => (item ? item : "")}
                    placeholder="Select CI Category"
                    className="select-app"
                    size="sm"
                    />
            </Column>
            <Column lg={3} md={2} sm={2}>
                <ComboBox
                    onChange={(e) => setTower(e.selectedItem)}
                    size="sm"
                    id="carbon-combobox"
                    items={towers}
                    selectedItem={tower}
                    itemToString={(item) => (item ? item : "")}
                    placeholder="Select Tower"
                    className="select-app"
                />
            </Column>
            <Column lg={3} md={2} sm={2}>
                <ComboBox
                    onChange={(e) => setyear(e.selectedItem)}
                    className="select-app"
                    id="carbon-combobox"
                    items={years}
                    size="sm"
                    selectedItem={(year)}
                    itemToString={(item) => (item ? item : "")}
                    placeholder="Select Year"
                />
            </Column>
        </Row>
        <Row>
            <Column lg={8} md={4} sm={4} className="heading-parent">
                <div className="per-incident-heading" style={{right: "1.5rem"}}>
                  <Toggletip className="manual-heading-info" align="left">
                    <ToggletipButton label="Show information">
                      <Information className="per-incident-icon" size={16} />
                    </ToggletipButton>
                    <ToggletipContent>
                      <div>
                      The calculation of carbon savings is solely determined by emissions from devices.
                        <ul className="assumption">
                            <li>The carbon emission rate is 0.00788 kgCO₂eq per hour, taking into account the HP ProBook 440 G7 Notebook PC model.</li>
                        </ul>
                      </div>
                    </ToggletipContent>
                  </Toggletip>
                </div>
                <ChartContainer
                    data={{
                        dataType: "combo",
                        data:{
                            chartData: data["appWiseMonthly"],
                            chartOptions: options 
                        }
                    }}
                />
            </Column>
            <Column lg={8} md={4} sm={4}>
                <ActualAccumulated data={data["acutualAccMonthly"]} year={year} />
            </Column>
        </Row>
        <Row>
            <Column lg={8} md={4} sm={4} className="heading-parent">
                <YearWiseChart dataACC={data["acutualAccMonthly"]}/>
            </Column>
            <Column lg={8} md={4} sm={4}>
                <AppInferences />
            </Column>
        </Row>
        <Row>
            <Column lg={16} md={8} sm={4}>
                <Applications />
            </Column>
        </Row>
    </>
  )
}

export default EmissionChart
