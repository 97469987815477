import React from 'react'
import { AreaChart } from "@carbon/charts-react";
import { Information } from "@carbon/react/icons/index";
import { InlineLoading, Tooltip } from '@carbon/react';

import TableToolbarDownload from './TableToolbarDownload';

const DashboardAreaChart = (props) => {
    const defaultAreaChartOptions = {
        "title": props?.modalTitle || "",
        "axes": {
            "bottom": {
                "title": props.graphXAxisTitle || 'Time',
                "mapsTo": "date",
                "scaleType": props.bottomScaleType || "labels" //"time"
            },
            "left": {
                "title": props.graphTitle,
                "mapsTo": "value",
                "scaleType": "linear"
            }
        },
        "legend": {
            "position": props.legendPosition || "bottom",
            "orientation": props.legendOrientation || "horizontal",
            "alignment": props.legendAlignment || "left"
        },
        "curve": "curveMonotoneX",
        "data": {
            "loading": props.loading
        },
        "height": props.height ? props.height : "400px",
    };

    const areaGraphHeaders = [
        {
            key: 'group',
            header: 'Group'
        },
        {
            key: 'value',
            header: props.graphTitle || 'Value'
        },
        {
            key: 'date',
            header: props.graphXAxisTitle || 'Date'
        }
    ];

    const graphOptions = defaultAreaChartOptions;

    return (
        <div className="dashboard-area-chart-container">
            {!props.loading && props.areaGraphData.length ?
                <div className="graph-title carbon-display-Regular-20">
                    <div>{props.title}
                        {props.graphDescription ?
                            <Tooltip
                                align="right"
                                label={props.graphDescription}
                                style={{ color: "black", fontSize: "14px" }}
                            >
                                <button
                                    className="tooltip-button"
                                    type="button"
                                >
                                    <Information />
                                </button>
                            </Tooltip>
                            :
                            null}
                    </div>

                    {props.hideDownloadButton !== true && <div className="download-btn"><TableToolbarDownload iconDescription="Export" rows={props.areaGraphData} headers={areaGraphHeaders} title="Title" filename="Export" label="Download" /></div>}

                </div>
                :
                ''
            }
            {!props.loading ?
                props.areaGraphData.length ?
                    <AreaChart
                        data={props.areaGraphData}
                        options={graphOptions}>
                    </AreaChart>
                    :
                    <div>No Data  Available</div>
                :
                <div className="tile">
                    <div className="graph-title carbon-display-Regular-20">{props.title}</div>
                    <InlineLoading
                        description="Loading"
                    />
                </div>
            }
        </div>
    );
};

export default DashboardAreaChart;
