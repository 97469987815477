import { Column, Tag } from "@carbon/react";
import {
  Catalog,
  Collaborate,
  DotMark,
  IbmWatsonDiscovery,
  PlayFilledAlt
} from "@carbon/react/icons/index";
import { useKeycloak } from "@react-keycloak/web";
import DataTable from "Carbon-Components/DataTable";
import React, { useEffect, useState } from "react";
import GenPipeline from "../genPipeline";
import Analyze from "./analyze";
import ErrorLog from "./errorLog";
import "../scss/ci_cd.scss";

const error = `[ERROR] COMPILATION ERROR : 
[ERROR] /var/jenkins_home/workspace/Liberty-GenAI/Gen2-Portal/src/main/java/com/abg/LCT.java:[6,53] package com.ulisesbocchio.jasyptspringboot.annotation does not exist
[ERROR] /var/jenkins_home/workspace/Liberty-GenAI/Gen2-Portal/src/main/java/com/abg/LCT.java:[9,2] cannot find symbol
[ERROR] Failed to execute goal org.apache.maven.plugins:maven-compiler-plugin:3.8.0:compile (default-compile) on project LCT_NEW: Compilation failure: Compilation failure: 
[ERROR] /var/jenkins_home/workspace/Liberty-GenAI/Gen2-Portal/src/main/java/com/abg/LCT.java:[6,53] package com.ulisesbocchio.jasyptspringboot.annotation does not exist
[ERROR] /var/jenkins_home/workspace/Liberty-GenAI/Gen2-Portal/src/main/java/com/abg/LCT.java:[9,2] cannot find symbol
[ERROR]   symbol: class EnableEncryptableProperties
[ERROR] -> [Help 1]
[ERROR] 
[ERROR] To see the full stack trace of the errors, re-run Maven with the -e switch.
[ERROR] Re-run Maven using the -X switch to enable full debug logging.
[ERROR] 
[ERROR] For more information about the errors and possible solutions, please read the following articles:
[ERROR] [Help 1] http://cwiki.apache.org/confluence/display/MAVEN/MojoFailureException`;


const deploy = `docker login -u iamapikey -p **************** us.icr.io
WARNING! Using --password via the CLI is insecure. Use --password-stdin.
Error response from daemon: Get "https://us.icr.io/v2/": unauthorized: Authorization required. See https://cloud.ibm.com/docs/Registry?topic=Registry-troubleshoot-auth-req`;

const getCurrentDateTime = (newDate) => {
  let date =
    newDate.getDate() <= 9 ? `0${newDate.getDate()}` : newDate.getDate();
  let month =
    newDate.getMonth() + 1 <= 9
      ? `0${newDate.getMonth() + 1}`
      : newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  let hour =
    newDate.getHours() <= 9 ? `0${newDate.getHours()}` : newDate.getHours();
  let minute =
    newDate.getMinutes() <= 9
      ? `0${newDate.getMinutes()}`
      : newDate.getMinutes();
  let sec =
    newDate.getSeconds() <= 9
      ? `0${newDate.getSeconds()}`
      : newDate.getSeconds();
  return `${year}-${month}-${date} ${hour}:${minute}:${sec}`;
};

const PipelineActivity = (props) => {
  const { keycloak } = useKeycloak();
  const [showModal, showModals] = useState(false);
  const [errorLog, setErrorLog] = useState(false);
  const [logData, setLogData] = useState({});
  const [analyze, setAnalyze] = useState(false);
  const [tableData, setTableData] = useState(props.tableData);
  const [buildStatus, setBuildStatus] = useState({});

  // useEffect(() => {
  //   getBuildStatus();
  // }, []);

  // useEffect(() => {
  //   if (Object.keys(buildStatus).length > 0) {
  //     const stat = buildStatus.jobs[0]?.lastBuild?.result;
  //     tableDataJSON[0].status = stat === null ? "In Progress" : stat;
  //     tableDataJSON[0].pipeline.map((pipe) => {
  //       if(pipe.name === "Build" && stat === null) {
  //         pipe.value = "In Progress"
  //       } else if(pipe.name === "Build") {
  //         pipe.value = stat
  //       }
  //       return pipe;
  //     })
  //     let triggeredTime = new Date(buildStatus.jobs[0]?.lastBuild?.timestamp);
  //     tableDataJSON[0].triggered = getCurrentDateTime(triggeredTime);

  //     setTableData(tableDataJSON);
  //   }
  // }, [buildStatus]);

  const headerData = [
    {
      key: "name",
      header: "Name",
    },
    {
      key: "stages",
      header: "Pipeline"
    },  
    {
      key: "health_score",
      header: "Health Score",
    },
    {
      key: "last_failed_build",
      header: "Failed Build"
    },
    {
      key: "last_successful_build",
      header: "Success Build"
    },
    {
      key: "job_status",
      header: "Status",
    },
  ];

  const getTableHeader = (header) => {
    return header;
  };

  const getRowCellData = (id, data, row) => {
    console.log("id----------->>>>>>>>>", id, data)
    if(id.includes(":job_status")){
      return data !== null && <Tag
      className="some-class"
      size="md"
      title="Clear filter"
      type={data === "FAILURE" ? "red" : "green"}
    >
      {data}
    </Tag>
    }
    if (id.includes(":stages")) {
      //const status = row.cells.filter((fil) => fil["id"].includes("status"));
      console.log("data ============>>", data);
      return (
        <div className="pipeline-name">
          <ul>
            {data !== undefined && Array.isArray(data) && data.map((ta) => <li>
              <div className="svg">              
              <DotMark
                size={20}
                className={
                  ["FAILURE","FAILED"].includes(ta.status)
                    ? "red"
                    : ["IN PROGRESS","In Progress"].includes(ta.status)
                    ? "orange"
                    : ["HOLD","hold"].includes(ta.status)
                    ? "grey" : "green"
                }
              /></div>
              <span>{ta.name}</span>
            </li>)}
          </ul>
        </div>
      );
    }
    return data;
  };

  const createFunction = () => {
    showModals(true);
  };

  const closeModal = () => {
    showModals(false);
    setErrorLog(false);
    setAnalyze(false);
  };

  // const getBuildStatus = async () => {
  //   const status = await JenkinsBuildStatusforGenAI(keycloak, uuidv4());
  //   setTableData([]);
  //   setBuildStatus(status);
  //   if (status.jobs[0]?.lastBuild?.result === null) {
  //     setTimeout(() => {
  //       getBuildStatus();
  //     }, 15000);
  //   }
  // };

  // const triggerBuild = async () => {
  //   // const triggerBuildStatus = await triggerJenkinsBuildforGenAI(
  //   //   keycloak,
  //   //   uuidv4()
  //   // );
  //   window.location.href = "https://gbsjenkins.edst.ibm.com/job/Liberty-GenAI/job/Gen2-Portal";
  //   setTableData([]);
  //   const triggerBuildStatus = "true";
  //   if (triggerBuildStatus !== "") {
  //     tableDataJSON[0].pipeline.map((pipe) => {
  //       if(pipe.name === "Build") {
  //         pipe.value = "In Progress"
  //       }
  //       return pipe;
  //     })
  //     tableDataJSON[0].status = "In Progress";
  //     setTableData(tableDataJSON);
  //   }
  //   setTimeout(() => {
  //     getBuildStatus();
  //   }, 20000);
  // };

  const getSelectedRow = (row) => {
    let data = null;
    console.log("tableData,", tableData, row.cells)
    tableData.forEach((tab) => {
      console.log("tabbbbbb", tab, row)
      row.cells.forEach((cells) => {
        if (cells.id.includes("name")) {
          if(tab.name === cells.value) {
            data = tab
          }
        }
      });
    })
    console.log("data", data)
    return data;
  }

  const getActions = (row, cell) => {
    let status = "", name = "";
    row.cells.forEach((cells) => {
      if (cells.id.includes("status")) {
        status = cells.value;
      }
      if (cells.id.includes("name")) {
        name = cells.value;
      }
    });
    const selectedRow = getSelectedRow(row)
    return (
      <ul className="action-icons">
        <li
          className={`${name === "Cart" ? status === "FAILURE" ? "disable" : "enable" : "enable"}`}
          title="Trigger Build"
          // onClick={() => triggerBuild()}
        >
          <a href="https://gbsjenkins.edst.ibm.com/job/Liberty-GenAI/job/Gen2-Portal" target="_blank" rel="noopener noreferrer"><PlayFilledAlt /></a>
          
        </li>
        <li
          className={`${status === "FAILURE" ? "disable" : "enable"}`}
          title="Logs"
          onClick={() => onClickActions("logs", selectedRow)}
        >
          <Catalog />
        </li>
        <li
          className={`${status === "FAILURE" ? "disable" : "enable"}`}
          title="Analyze"
          onClick={() => onClickActions("analyze", selectedRow)}
        >
          <IbmWatsonDiscovery />
        </li>
        <li
          className={`${status === "FAILURE" ? "disable" : "enable"}`}
          title="Collaborate"
        >
          <a
            href="https://ibm-test.enterprise.slack.com/archives/C081XC8KZU4"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Collaborate />
          </a>
        </li>
      </ul>
    );
  };

  const onClickActions = (name, onClickActions) => {
    console.log("onClickActions", onClickActions)
    setLogData(onClickActions)
    if (name === "logs") {
      setErrorLog(true);
    }
    if (name === "analyze") {
      setAnalyze(true);
    }
    if (name === "pipeline") {
      showModals(true);
    }
  };

  return (
    <>
      <Column lg={16} md={8} sm={4} className="padding-rgt">
        <DataTable
          rowData={props.tableData}
          headerData={headerData}
          getRowCellData={getRowCellData}
          getTableHeader={getTableHeader}
          actionsNeeded={false}
          table_particular_actions={true}
          createTitle="Generate Pipeline"
          createFunction={createFunction}
          getActions={getActions}
        />
      </Column>
      {/* <Column lg={8} md={4} sm={2}>
        <TreeMap />
      </Column> */}
      {showModal ? <GenPipeline onCloseModal={closeModal} /> : null}
      {errorLog ? <ErrorLog logs={logData} onCloseModal={closeModal} /> : null}
      {analyze ? <Analyze logs={logData} onCloseModal={closeModal} /> : null}
    </>
  );
};

export default PipelineActivity;