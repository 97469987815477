import React, { useState, useEffect } from 'react';
import {
    DataTable, TableContainer, Table, TableHead,
    TableRow, TableHeader, TableBody, TableCell, Pagination,
    TableExpandRow, TableExpandedRow, TableExpandHeader, Button, InlineLoading
} from '@carbon/react';
import { SkipForwardFilled32 } from '@carbon/react/icons/index';
import { useTranslation } from 'react-i18next';

import '../scss/_multiExpandedTable.scss';

const DashboardTable = (props) => {
    const defaultFirstRowIndex = 0;
    const defaultCurrentPageSize = props.defaultCurrentPageSize || 5;
    const [paginatedRows, setPaginatedRows] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [firstRowIndex, setFirstRowIndex] = useState(defaultFirstRowIndex);
    const [currentPage, setCurrentPage] = useState(0);
    const [TotalPages, setTotalPages] = useState(0);
    const { t } = useTranslation();

    useEffect(() => {
        if (props.rows && props.rows.length) {
            setTotalItems(props.rows.length);
            getPaginatedRows(defaultFirstRowIndex, defaultCurrentPageSize);
        }
    }, [props.rows])

    function getPaginatedRows(param, pageSize) {
        let startingIndex = Number.isInteger(param) ? param : firstRowIndex;
        let slicedRows = props.rows.slice(startingIndex, startingIndex + pageSize);
        if (props.isExpandedTable && props.isExpandedTable === true) {
            setPaginatedRows(props.rows);
        }
        else setPaginatedRows(slicedRows);
    }

    return (
        props.loading ?
            <div><InlineLoading description={t('DigitalOperations.loading')} /></div>
            :
            props.rows && props.rows.length && !props.loading ?
                <div className="table-container">
                    <DataTable size={props.size} rows={paginatedRows} headers={props.headers} isSortable={props.isSortingAllowed} render={({
                        rows,
                        headers,
                        getHeaderProps,
                        getRowProps,
                        getTableProps }) => (
                        <TableContainer>
                            <Table {...getTableProps()}>
                                <TableHead>
                                    <TableRow>
                                        {props.isExpandable && <TableExpandHeader />}
                                        {headers.map(header => (
                                            header.header && <TableHeader {...getHeaderProps({ header })}>
                                                {header.header}
                                            </TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows && rows.map((row, i) => (
                                        props.isExpandable && paginatedRows[i] && paginatedRows[i].expandedItems !== undefined ?
                                            <React.Fragment key={row.id}>
                                                <TableExpandRow {...getRowProps({ row })}>
                                                    {row.cells.map(cell => (
                                                        <TableCell key={cell.id}>{cell.value}</TableCell>
                                                    ))}
                                                </TableExpandRow>
                                                {row.isExpanded && (
                                                    <TableExpandedRow colSpan={props.headers.length + 1}>
                                                        <div className="expanded-table-title carbon-display-Semibold-14">{props.expandedTableTitle}</div>
                                                        <DashboardTable loading={false} rows={paginatedRows[i].expandedItems} headers={props.headers} isExpandable={true} isExpandedTable={true} className="expa" expandedTableTitle={props.expandedTableL2Title} />
                                                    </TableExpandedRow>
                                                )}
                                            </React.Fragment>
                                            :
                                            <TableRow key={"row" + i} {...getRowProps({ row })}>
                                                {row.cells.map((cell, index) => (
                                                    <TableCell key={"cell" + index}>
                                                        {cell.value || "-"}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>)}
                    />
                    {props.showPagination && props.rows.length > (props.tablePaginationLimit || 5) &&
                        <div className='pagination-container'>
                            <Pagination
                                totalItems={totalItems}
                                backwardText={t('DigitalOperations.previousPage')}
                                forwardText={t('DigitalOperations.nextPage')}
                                pageSize={props.defaultCurrentPageSize || 5}
                                pageSizes={[5, 10, 15, 25]}
                                itemsPerPageText={ t('DigitalOperations.itemsPage')}
                                onChange={({ page, pageSize }) => {
                                    let newFirstRowIndex = pageSize * (page - 1);
                                    setFirstRowIndex(newFirstRowIndex)
                                    getPaginatedRows(newFirstRowIndex, pageSize);
                                }}
                                pageRangeText={(current, total) => {
                                    props.getPages && setTotalPages(total);
                                    props.getPages && setCurrentPage(current);
                                    props.getPages && props.getPages(total, current);
                                    return `of ${total} pages`
                                }}
                                itemRangeText={(min, max, total) => props.paginateRecords && props.paginateRecords === true && total !== props.totalItems ? `${min}–${max} of Top ${total} from ${props.totalItems} items` : `${min}–${max} of ${total} items`}
                            />
                            {props.paginateRecords && props.totalItems && totalItems < props.totalItems && TotalPages === currentPage ?
                                <div className='fastforward'><Button renderIcon={SkipForwardFilled32} hasIconOnly onClick={() => props.handleNextClick()} kind="ghost" iconDescription="Load more records"
                                /></div> : null}
                        </div>
                    }
                </div>
                :
                <div className="message">{t('DigitalOperations.noDataAvailable')}</div>
    );
}

export default DashboardTable
