import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionSkeleton,
  Column,
  ErrorBoundary,
  MenuItem,
  TextInput,
} from "@carbon/react";

// @ts-ignore
import navCloseIcon from "Assets/carbonIcons/nav-close.svg";
// @ts-ignore
import navOpenIcon from "Assets/carbonIcons/nav-open.svg";
// @ts-ignore
import newChatIcon from "Assets/carbonIcons/new-chat.svg";
import {
  deleteConversationAPI,
  updateConversationTitle,
} from "./Helper";
import { Close, Edit } from "@carbon/react/icons";

const Sidebar = (props) => {
  const {
    setIsNavLoading,
    setLikedMessageIds,
    setDislikedMessageIds,
    setConversationData,
    allConversations,
    setChatHistory,
    loadMessages,
    enableSidebar,
    isNavLoading,
    setEnableSidebar,
    getAllConversations,
    conversationData,
    setInputValueCopy,
    controller,
    reloadNavItems
  } = props;

  const [editMode, setEditMode] = useState(null); 
  const [editText, setEditText] = useState("");

  const inputRef = useRef(null);

  useEffect(() => {
    setIsNavLoading(true);
    setLikedMessageIds([]);
    setDislikedMessageIds([]);
    getAllConversations();
  }, []);

  useEffect(() => {
    // Focus the input when edit mode is activated
    if (editMode !== null && inputRef.current) {
      inputRef.current.focus();
    }

    // Click event listener to handle clicks outside the input field or sidebar
    const handleClickOutside = (event) => {
      if (
        editMode !== null &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        
        updateConversationTitle({ conversation_id: editMode, title: inputRef.current.value }).then(() => {
          setEditMode(null);
          getAllConversations();
        });
      }
    };

    // Attach event listener after component has mounted
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [editMode]);

  const handleEditClick = (id, currentTitle) => {

    setEditMode(id);
    setEditText(currentTitle); // Set the current title in the input field
  };

  const handleRename = (e, conversation_id) => {
    if (e.key === "Enter") {
      updateConversationTitle({ conversation_id, title: editText }).then(() => {
        setEditMode(null);
        getAllConversations();
      });
    }
  };

  const createNewChat = () => {
    if (controller) {
      controller.abort();
      setTimeout(() => {
        getAllConversations();
      },2000)
      setConversationData({
        conversation_id: "",
        title: "",
        status: "",
      });
      // getAllConversations();
    }
    setChatHistory([]);
    setInputValueCopy("");
  };

  const deleteConversation = (conversation_id) => {
    // setIsNavLoading(true);
    deleteConversationAPI({ conversation_id })
      // @ts-ignore
      .then((data) => {
        // setIsNavLoading(false);
        getAllConversations();
      })
      .catch((error) => {
        console.error("Error:", error);
        // setIsNavLoading(false);
      });
  };

  const getAccordionsJsx = () => {
    return (
      <Accordion align="end">
        {Object.entries(allConversations).map(([key, value], index) => (
          <AccordionItem
            key={index}
            title={key.replace(/_/g, " ")}
            className="accordian_title"
            open={true}
          >
            {value.length > 0 ? (
              value.map((item, idx) => (
                <div className="nav_item" key={idx}>
                  <MenuItem
                  className={conversationData.conversation_id === item.conversation_id ? "selected" : ""}
                    key={idx}
                    label={
                      editMode === item.conversation_id ? (
                        // @ts-ignore
                        <TextInput 
                         type="text"
                         ref={inputRef}
                         value={editText}
                         onChange={(e) => setEditText(e.target.value)}
                         onKeyPress={(e) =>
                          handleRename(e, item.conversation_id)
                         }
                        />
                      ) : (
                        item.title
                      )
                    }
                    onClick={() => loadMessages(item.conversation_id)}
                  />
                  <span className="icon-container">
                    <span
                      onClick={() => deleteConversation(item.conversation_id)}
                      className="icon"
                    >
                      <Close size={18} />
                    </span>

                    <span
                      onClick={() =>
                        editMode !== item.conversation_id &&
                        handleEditClick(item.conversation_id, item.title)
                      }
                      className="icon"
                    >
                      {editMode === item.conversation_id ? (
                        ""
                      ) : (
                        <Edit size={16} />
                      )}
                    </span>
                  </span>
                </div>
              ))
            ) : (
              <MenuItem label={`No Items in ${key.replace(/_/g, " ")}`} />
            )}
          </AccordionItem>
        ))}
      </Accordion>
    );
  };

  return (
    <>
      {!enableSidebar && (
        <Column lg={1} md={1} sm={1} className="sidebar_container_closed">
          <div className="navbar sidenav-closed">
            <div onClick={() => setEnableSidebar(true)}>
              <img
                src={navOpenIcon}
                className="hide-sidebar-icon carbon--icons-20 icon--black"
                alt="hide-sidebar"
              />
            </div>
            <div onClick={() => createNewChat()}>
              <img
                src={newChatIcon}
                className="hide-sidebar-icon carbon--icons-20 icon--black"
                alt="new chat"
              />
            </div>
          </div>
        </Column>
      )}

      {enableSidebar && (
        <Column lg={3} md={3} sm={3} xs={16} className="sidebar_container">
          <div className="navbar">
            <div onClick={() => setEnableSidebar(false)}>
              <img
                src={navCloseIcon}
                className="hide-sidebar-icon carbon--icons-20 icon--black"
                alt="hide-sidebar"
              />
            </div>
            <div onClick={() => createNewChat()}>
              <img
                src={newChatIcon}
                className="hide-sidebar-icon carbon--icons-20 icon--black"
                alt="new chat"
              />
            </div>
          </div>
          <div>
            <div className="nav-data">
              <ErrorBoundary fallback={<h6>Something went wrong.</h6>}>
                {isNavLoading && <AccordionSkeleton count={10} open />}
                {allConversations && !isNavLoading && getAccordionsJsx()}
              </ErrorBoundary>
            </div>
          </div>
        </Column>
      )}
    </>
  );
};

export default Sidebar;
