import { Column, FlexGrid } from "@carbon/react";
import React from "react";
import CpgPlusDomains from "./CpgPlusDomains";
import "./Pm.scss";

const CpgPlusIndex = () => {
    return (
        <>
            <CpgPlusDomains />
        </>
    )
}

export default CpgPlusIndex;