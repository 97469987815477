import React, { useState, useEffect } from 'react';
import {
    DataTable, TableContainer, Table, TableHead,
    TableRow, TableHeader, TableBody, TableCell, Pagination,
    TableExpandRow, TableExpandedRow, TableExpandHeader, Button, InlineLoading
} from '@carbon/react';
import { SkipForwardFilled32 } from '@carbon/react/icons/index';
import { useTranslation } from 'react-i18next';

import { sortRowsBasedOnDirectionAndColumn as sortRows, getDirectionColumnForCarbonTable as getDirectionAndColumn } from '../Utils/ReusableFunctions';

const DashboardTable = (props) => {
    const defaultFirstRowIndex = 0;
    const defaultPage = 1;
    const defaultCurrentPageSize = props.defaultCurrentPageSize || 5;
    const [paginatedRows, setPaginatedRows] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [firstRowIndex, setFirstRowIndex] = useState(defaultFirstRowIndex);
    const [currentPageSize, setCurrentPageSize] = useState(defaultCurrentPageSize);
    const [currentPage, setCurrentPage] = useState(0);
    const [TotalPages, setTotalPages] = useState(0);
    const [sortDirection, setSortDirection] = useState("ASC");
    const [sortedColumn, setSortedColumn] = useState("");
    const [page, setPage] = useState(defaultPage);
    const { t } = useTranslation();

    useEffect(() => {
        if (props.rows && props.rows.length) {
            setTotalItems(props.rows.length);
            getPaginatedRows(defaultFirstRowIndex, defaultCurrentPageSize);
        }
    }, [props.rows])

    function getPaginatedRows(param, pageSize) {
        let startingIndex = Number.isInteger(param) ? param : firstRowIndex;
        let rows = props.rows || [];
        if (props.isSortingAllowed) {
            rows = sortRows(props.rows, sortDirection, sortedColumn, props.headers);
        }
        let slicedRows = rows.slice(startingIndex, startingIndex + pageSize);
        if (props.isExpandedTable && props.isExpandedTable === true) {
            setPaginatedRows(rows);
        }
        else setPaginatedRows(slicedRows);
    }

    const handleSort = (event) => {
        const { sortDirection, sortHeaderKey } = event;
        const { direction, column } = getDirectionAndColumn(sortDirection, sortHeaderKey, sortedColumn);
        setSortedColumn(column);
        setSortDirection(direction);

        let sortedRows = sortRows(props.rows, direction, sortHeaderKey, props.headers);
        let slicedRows = sortedRows.slice(defaultFirstRowIndex, defaultFirstRowIndex + currentPageSize);
        setPaginatedRows(slicedRows);
        setFirstRowIndex(defaultFirstRowIndex);
        setPage(defaultPage);
    };

    return (
        props.loading ?
            <>
                <div className="graph-title carbon-display-Regular-20">{props.title}</div>
                <div><InlineLoading description={t('DigitalOperations.loading')} /></div>
            </>
            :
            props.rows.length && !props.loading ?
                <div className="table-container">
                    <DataTable size={props.size} rows={paginatedRows} headers={props.headers} isSortable={props.isSortingAllowed} render={({
                        rows,
                        headers,
                        getHeaderProps,
                        getRowProps,
                        getTableProps }) => (
                        <TableContainer>
                            <Table {...getTableProps()}>
                                <TableHead>
                                    <TableRow>
                                        {props.isExpandable && <TableExpandHeader />}
                                        {headers.map(header => (
                                            header.header && <TableHeader {...getHeaderProps({ header, isSortable: header.isSortable, onClick: (event, param) => handleSort(param) })}>
                                                {header.header}
                                            </TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row, i) => (
                                        props.isExpandable ?
                                            <React.Fragment key={row.id}>
                                                <TableExpandRow {...getRowProps({ row })}>
                                                    {row.cells.map(cell => (
                                                        <TableCell key={cell.id}>{cell.value}</TableCell>
                                                    ))}
                                                </TableExpandRow>
                                                {row.isExpanded && (
                                                    <TableExpandedRow colSpan={props.headers.length + 1}>
                                                        <div className="expanded-table-title carbon-display-Semibold-14">{props.expandedTableTitle}</div>
                                                        <DashboardTable loading={false} rows={paginatedRows[i] ? paginatedRows[i].expandedItems : []} headers={props.headers} isExpandedTable={true} />
                                                    </TableExpandedRow>
                                                )}
                                            </React.Fragment>
                                            :
                                            <TableRow key={"row" + i} {...getRowProps({ row })}>
                                                {row.cells.map((cell, index) => (
                                                    <TableCell key={"cell" + index}>
                                                        {cell.value ? typeof cell.value === "boolean" ? (cell.value).toString() : cell.value : "-"}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>)}
                    />
                    {props.showPagination && props.rows.length > (props.tablePaginationLimit || 5) &&
                        <div className='pagination-container'>
                            <Pagination
                                totalItems={totalItems}
                                backwardText={t('DigitalOperations.previousPage')}
                                forwardText={t('DigitalOperations.nextPage')}
                                pageSize={props.defaultCurrentPageSize || 5}
                                pageSizes={[5, 10, 15, 25]}
                                page={page}
                                itemsPerPageText={props.itemsPerPageText ? props.itemsPerPageText : t('DigitalOperations.itemsPage')}
                                onChange={({ page, pageSize }) => {
                                    let newFirstRowIndex = pageSize * (page - 1);
                                    setPage(page);
                                    setFirstRowIndex(newFirstRowIndex);
                                    getPaginatedRows(newFirstRowIndex, pageSize);
                                    setCurrentPageSize(pageSize);
                                }}
                                pageRangeText={(current, total) => {
                                    props.getPages && setTotalPages(total);
                                    props.getPages && setCurrentPage(current);
                                    props.getPages && props.getPages(total, current);
                                    return `of ${total} pages`
                                }}
                                itemRangeText={(min, max, total) => props.paginateRecords && props.paginateRecords === true && total !== props.totalItems ? `${min}–${max} of Top ${total} from ${props.totalItems} items` : `${min}–${max} of ${total} items`}
                            />
                            {props.paginateRecords && props.totalItems && totalItems < props.totalItems && TotalPages === currentPage ?
                                <div className='fastforward'><Button renderIcon={SkipForwardFilled32} hasIconOnly onClick={() => props.handleNextClick()} kind="ghost" iconDescription="Load more records"
                                /></div> : null}
                        </div>
                    }
                </div>
                :
                <div className="message">{t('DigitalOperations.noDataAvailable')}</div>
    );
}

export default DashboardTable
