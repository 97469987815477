
import React, { useCallback, useEffect, useState } from "react";
import TextComponent from "./Text";
import Table from "./Table";
import InvertedTable from "./InvertedTable";
// @ts-ignore
import watsonx from "Assets//watsonx.png";
// import { SkeletonText } from 'carbon-components-react';

import { ErrorBoundary, Row, TextArea, SkeletonText } from "@carbon/react";
import DefaultModal from "Carbon-Components/Modals/Modal";
import { addFeedback } from "../Helper";
import {
  ThumbsDown,
  ThumbsDownFilled,
  ThumbsUp,
  ThumbsUpFilled,
  Copy,
  Checkmark,
} from "@carbon/react/icons";
import PDFViewer from "./PDFViewer";

const DynamicRenderer = ({
  chunks,
  likedMessageIds,
  setLikedMessageIds,
  dislikedMessageIds,
  setDislikedMessageIds,
  isLoading,
  lastObject,
}) => {
  const [isModelBoxOpen, setIsModelOpen] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const [forceReload, setForceReload] = useState(0); // Dummy state to force re-render
  const [isCopied, setIsCopied] = React.useState(false);
  const maxFeedbackCharacters = 1000;
  const initialVisibleCount = 200;
  const [visibleChunks, setVisibleChunks] = useState(initialVisibleCount);
  const copyTimeoutRef = React.useRef(null); // Ref for the timer

  // Function to display the next batch of chunks when "Show More" is clicked
  const showMoreChunks = () => {
    setVisibleChunks((prevVisibleChunks) => prevVisibleChunks + 2);
  };

  // Show less chunks, revert to initial count
  const showLessChunks = () => {
    setVisibleChunks(initialVisibleCount);
  };

  const messageId = chunks?.messageId;

  const setLike = (rating) => {
    if (chunks.messageId) {
      rating === "like" && setLikedMessageIds([...likedMessageIds, messageId]);

      addFeedback({ messageId: messageId, review: "", rating: rating })
        .then((data) => {})
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const setDislike = (rating) => {
    if (chunks.messageId) {
      rating === "dislike" &&
        setDislikedMessageIds([...dislikedMessageIds, messageId]);

      addFeedback({ messageId, review: feedbackText, rating: rating })
        .then((data) => {})
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    setIsModelOpen(false);
  };

  const handleFeedbackText = (e) => {
    const value = e.target.value;
    if (value.length <= maxFeedbackCharacters) {
      setFeedbackText(value);
    }
  };

  const openFeedback = () => {
    return (
      isModelBoxOpen && (
        <DefaultModal
          heading="Submit Feedback"
          open={isModelBoxOpen}
          primaryText="Submit"
          primaryButtonDisabled={false}
          onModalSubmit={() => setDislike("dislike")}
          onCloseModal={() => setIsModelOpen(false)}
        >
          <TextArea
            placeholder="Start writing..."
            helperText="Let’s us know how can we make better!"
            onChange={(e) => handleFeedbackText(e)}
            rows={4}
            id="text-area-1"
            value={feedbackText}
            labelText={""}          />
        </DefaultModal>
      )
    );
  };

  const handleCopy = useCallback(() => {
    setIsCopied(true);

    const textToCopy = chunks?.answer
      ?.map((chunk) => {
        if (chunk.type === "text") {
          return chunk.content;
        }
        return ""; // Ensure empty string for non-text types
      })
      .join("\n");

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        // Reset "copied" state after 2 seconds
        copyTimeoutRef.current = setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch((error) => {
        console.error("Copy failed:", error);
        setIsCopied(false);
      });
  }, [chunks]);

  useEffect(() => {
    return () => {
      if (copyTimeoutRef.current) {
        clearTimeout(copyTimeoutRef.current);
      }
    };
  }, []);

  const getFeedbackIcons = () => {
    if (chunks.answer[0].content) {
      return (
        <Row className="chat-icons">
          {isCopied ? (
            <span>
              <Checkmark className="filled-icon" />
            </span>
          ) : (
            <span onClick={handleCopy}>
              <Copy />
            </span>
          )}

          {likedMessageIds.includes(messageId) ? (
            <span
              onClick={() => {
                setDislikedMessageIds((prev) =>
                  prev.filter((item) => item !== messageId)
                );
                setLikedMessageIds((prev) =>
                  prev.filter((item) => item !== messageId)
                );
                setForceReload((prev) => prev + 1);
                setLike("");
              }}
            >
              <ThumbsUpFilled className="filled-icon" />
            </span>
          ) : (
            <span
              onClick={() => {
                setDislikedMessageIds((prev) =>
                  prev.filter((item) => item !== messageId)
                );
                setForceReload((prev) => prev + 1);
                setLike("like");
              }}
            >
              <ThumbsUp />
            </span>
          )}
          {dislikedMessageIds.includes(messageId) ? (
            <span
              onClick={() => {
                setLikedMessageIds((prev) =>
                  prev.filter((item) => item !== messageId)
                );
                setDislikedMessageIds((prev) =>
                  prev.filter((item) => item !== messageId)
                );
                setForceReload((prev) => prev + 1);
                setDislike("");
              }}
            >
              <ThumbsDownFilled className="filled-icon" />
            </span>
          ) : (
            <span
              onClick={() => {
                setLikedMessageIds((prev) =>
                  prev.filter((item) => item !== messageId)
                );
                setForceReload((prev) => prev + 1);
                setIsModelOpen(true);
              }}
            >
              <ThumbsDown />
            </span>
          )}
        </Row>
      );
    }
  };


  const getData = chunks?.answer?.slice(0, visibleChunks).map((chunk) => {

    switch (chunk.type) {
      case "text":
        return (
          <>
            <TextComponent content={chunk.content} />
            <br></br>
          </>
        );

      case "table":
        return (
          <>
            <Table content={chunk.content} />
            <br></br>
          </>
        );

      case "inverted_table":
        return (
          <>
            <InvertedTable content={chunk.content} />
            <br></br>
          </>
        );
      case "FileName":
          return (
            <>
              <PDFViewer content={chunk.content?.document_name} />
              {/* <TextComponent content={chunk.content.description} /> */}
              <br></br>
            </>
        );

      default:
        return null;
    }
  });

  const showAiImage = (lastObject && !isLoading) || !lastObject;

  return (
    <div className="dynamic-response">
        <div className="ai-image">
          <img src={watsonx} alt="Watsonx AI" />
        </div>
      <div className="ai-response">
        <ErrorBoundary fallback={<h3>Something went wrong.</h3>}>
          {!showAiImage ? (
            <SkeletonText paragraph lineCount={2} width="100%" />
          ) : (
            getData
          )}
          {visibleChunks < chunks?.answer?.length && (
            <span className="show-more" onClick={showMoreChunks}>
              Click here for more details
            </span>
          )}
          {/* Show the "Show Less" button only if there are more than the initial number of chunks displayed */}
          {visibleChunks > initialVisibleCount && visibleChunks === chunks?.answer?.length (
            <span className="show-more" onClick={showLessChunks}>
              Show Less
            </span>
          )}
          {getFeedbackIcons()}
          {openFeedback()}
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default DynamicRenderer;
